import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useReactiveVar } from '@apollo/client'

import { ButtonRow } from './button-row'
import Button, { NavigateButton } from './button'
import HeaderPanel, { Panel } from './header-panel'
import Link from './link'
import { Heading } from './typography'
import ToggleBox from './toggle-box'
import Tooltip from './tooltip'
import { DropdownsTable } from './track-dropdowns-table'
import { currentUserDetails } from '../api/apollo/variables'
import { getDropdownUsageReport } from '../api/REST/track-client'
import { GeneratorFields, ValidationChecks } from '../api/types'
import { getUrlQuery } from '../helpers'
import styles from '../styles/track-edit-existing-dropdowns.module.scss'

interface EditExistingDropdownsProps {
  dropdownParams: GeneratorFields[]
  validationChecks: ValidationChecks[]
}

const EditExistingDropdowns = ({
  dropdownParams,
  validationChecks,
}: EditExistingDropdownsProps) => {
  const { workspaceID } = useReactiveVar(currentUserDetails)

  const history = useHistory()

  const query = getUrlQuery()

  const fieldID = query?.get('fieldID')

  const [openBoxState, setOpenBoxState] = useState(
    dropdownParams.map((param) => param.fieldID === fieldID),
  )
  const [reportDownloading, setReportDownloading] = useState(false)
  const [reportDownloadError, setReportDownloadError] = useState(false)
  const [scrolled, setScrolled] = useState(false)

  const onToggle = (index: number, value: boolean) => {
    const copy = dropdownParams.map((_) => false)
    copy[index] = value
    setOpenBoxState(copy)
  }

  const scrollIntoView = useCallback(
    (ref: HTMLDivElement) => {
      if (!fieldID || scrolled || !ref) return

      ref.scrollIntoView()

      setScrolled(false)
    },
    [fieldID, scrolled],
  )

  return (
    <>
      <HeaderPanel className={styles.existingDropdownsHeader}>
        <Panel>
          <Heading type={3} align="left">
            <Tooltip
              id="dropdown-params-header-tooltip"
              useIcon
              tooltipMessage="Parameters in your campaign link that have a fixed set of options to choose from. Click to expand and edit those options here."
              tooltipPosition="bottom"
            >
              Dropdown parameters
            </Tooltip>
          </Heading>
          <div>
            <Button
              variant="secondary"
              className={styles.usageButton}
              loading={reportDownloading}
              isDisabled={reportDownloading}
              onPress={async () => {
                setReportDownloadError(false)
                setReportDownloading(true)

                const res: boolean = await getDropdownUsageReport()

                if (!res) setReportDownloadError(true)

                setReportDownloading(false)
              }}
            >
              Download usage
            </Button>
            {reportDownloadError && (
              <p className={styles.downloadError}>
                Download failed, please try again later or contact support (
                <Link href="mailto:support@uplifter.ai">
                  support@uplifter.ai
                </Link>
                ).
              </p>
            )}
          </div>
        </Panel>
      </HeaderPanel>
      <div className={styles.dropdownsContainer}>
        {dropdownParams.map((param, index) => {
          if (!param.selectFields) return null

          if (
            param.parameterDependsOn &&
            param.parameterDependsOn.parentFieldID === 'account' &&
            param.parameterDependsOn.parentOptionIDs.indexOf(workspaceID) === -1
          )
            return null

          const availableParents = dropdownParams.filter(
            (parentParam) => parentParam.fieldID !== param.fieldID,
          )

          const replaceSpaces = validationChecks.find(
            (rule) => rule.name === 'REPLACE_SPACES_WITH',
          ) || {
            enabled: true,
            name: 'REPLACE_SPACES_WITH',
            value: '_',
          }

          const noSpecialChars = validationChecks.find(
            (rule) => rule.name === 'NO_SPECIAL_CHARS',
          ) || {
            enabled: true,
            name: 'NO_SPECIAL_CHARS',
            value: null,
          }

          const allLowerCase = validationChecks.find(
            (rule) => rule.name === 'ALL_LOWER_CASE',
          ) || {
            enabled: true,
            name: 'ALL_LOWER_CASE',
            value: null,
          }

          const useValidation: ValidationChecks[] | null = [
            !allLowerCase.enabled
              ? {
                  enabled:
                    typeof param.forceLowerCase === 'boolean'
                      ? param.forceLowerCase
                      : true,
                  name: 'ALL_LOWER_CASE',
                  value: null,
                }
              : allLowerCase,
            replaceSpaces,
            noSpecialChars,
          ]

          return (
            <ToggleBox
              key={param.fieldID}
              fwdRef={scrollIntoView}
              className={styles.toggleHeader}
              heading={
                <div className={styles.headerText}>
                  <Heading type={2} align="left">
                    {param.fieldName}
                  </Heading>
                  <span>{param.helpText}</span>
                </div>
              }
              open={openBoxState[index]}
              onToggle={(state: boolean) => onToggle(index, state)}
            >
              <DropdownsTable
                param={param}
                parentsList={availableParents}
                validation={useValidation}
              />
            </ToggleBox>
          )
        })}
      </div>
      <ButtonRow className={styles.buttonRow}>
        <NavigateButton
          className={styles.navButton}
          back
          onPress={() => history.push('/track/edit-parameters-and-rules')}
        >
          Edit parameters and rules
        </NavigateButton>
        <NavigateButton
          className={styles.navButton}
          onPress={() => history.push('/track/create-links')}
        >
          Create campaign links
        </NavigateButton>
      </ButtonRow>
    </>
  )
}

export default EditExistingDropdowns
