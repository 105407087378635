import React, { useEffect, useMemo, useState } from 'react'
import {
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client'
import { useHistory, useLocation } from 'react-router-dom'
import classNames from 'classnames'
import { nanoid } from 'nanoid'
import moment from 'moment'
import numeral from 'numeraljs'

import Button, { NavigateButton } from './button'
import { BetaLabel } from './counter'
import { DowngradeDeleteBlockerModal } from './delete-company-modal'
import Link from './link'
import { Preloader } from './loader'
import Modal from './modal'
import ProgressBar from './progress-bar'
import SelectBox from './select-box'
import Tooltip from './tooltip'
import { OuterBox, InnerBox } from './two-columns'
import { Heading } from './typography'
import { currentUserDetails } from '../api/apollo/variables'
import {
  changeMicrosoftMarketplacePlan,
  getCompanyDetails,
  getPaddleSubscriptionDetails,
  listAzureMarketplaceQueries,
  updateMicrosoftMarketplaceSeats,
  updatePaddleSeatsQuantity,
} from '../api/graphql/company-client'
import SingleArrow from '../assets/logos/uplifter-arrow.png'
import TwoArrows from '../assets/logos/uplifter-two-arrows.png'
import ThreeArrows from '../assets/logos/uplifter-arrows.png'
import UpgradeRocket from '../assets/svgs/upgrade-rocket.svg'
import LinkArrow from '../assets/drop-down-arrow.svg'
import {
  paddleSubscriptionID,
  calendarBookingLink,
  usePaddleSandboxAccount,
} from '../core/constants'
import useLogAction from '../hooks/useLogAction'
import useSubscriptionLevel from '../hooks/useSubscriptionLevel'
import {
  FeatureCategory,
  enterpriseTierFeatures,
  enterpriseTierDefaultPrice,
  enterpriseTierTagline,
  freeTierFeatures,
  freeTierTagline,
  currencyLookup,
  teamTierDefaultPrice,
  teamTierFeatures,
  teamTierMaxUsers,
  teamTierMaxWorkspaces,
  teamTierTagline,
} from '../static-copy/subscription-features'
import styles from '../styles/subscription-comparison.module.scss'

interface UpgradeUsersModal {
  onHideModal: React.Dispatch<React.SetStateAction<boolean>>
  showWarningMessage?: string
}

export function UpdateSubscriptionUsersModal({
  onHideModal,
  showWarningMessage,
}: UpgradeUsersModal) {
  const { companyID } = useReactiveVar(currentUserDetails)

  const logAction = useLogAction()

  const {
    isPaddle,
    paddleData,
    microsoftSubscriptionData,
  } = useSubscriptionLevel()

  const { data: companyData } = useQuery(getCompanyDetails)
  const [updateBillingSeats, { loading }] = useMutation(
    updatePaddleSeatsQuantity,
  )
  const [updateMicrosoftSeats, { loading: loadingMicrosoft }] = useMutation(
    updateMicrosoftMarketplaceSeats,
  )

  const [requestedSeats, setRequestedSeats] = useState(1)

  const currentUserCount = useMemo(() => {
    if (!companyData) return 0

    return companyData.currentCompany.userCount
  }, [companyData])

  const { unitPrice, quantity, currency } = useMemo(() => {
    if (!paddleData && !microsoftSubscriptionData)
      return {
        unitPrice: null,
        quantity: null,
        currency: null,
      }

    if (paddleData) {
      const {
        paddleSubscriptionUnitPrice,
        paddleSubscriptionQuantity,
        paddleSubscriptionCurrency,
      } = paddleData.currentCompany

      return {
        unitPrice: paddleSubscriptionUnitPrice
          ? parseInt(paddleSubscriptionUnitPrice, 10)
          : null,
        quantity: paddleSubscriptionQuantity
          ? parseInt(paddleSubscriptionQuantity, 10)
          : null,
        currency: paddleSubscriptionCurrency
          ? currencyLookup[paddleSubscriptionCurrency]
          : null,
      }
    }

    if (
      microsoftSubscriptionData &&
      microsoftSubscriptionData.currentCompany.microsoftSubscriptionData
    ) {
      const {
        price,
        quantity: msQuantity,
        currency: msCurrency,
      } = microsoftSubscriptionData.currentCompany.microsoftSubscriptionData

      return {
        unitPrice: price ? parseInt(price, 10) : null,
        quantity: msQuantity || null,
        currency: msCurrency ? currencyLookup[msCurrency] : null,
      }
    }

    return {
      unitPrice: null,
      quantity: null,
      currency: null,
    }
  }, [paddleData, microsoftSubscriptionData])

  const userProgress = useMemo(() => {
    if (!currentUserCount || !quantity) return 0

    setRequestedSeats(teamTierMaxUsers)

    return (currentUserCount / quantity) * 100
  }, [currentUserCount, quantity])

  const availableLicences = useMemo(() => {
    return Array.from(new Array(teamTierMaxUsers + 1), (_, index) => {
      if (index === teamTierMaxUsers)
        return { value: `${teamTierMaxUsers + 1}+` }

      return { value: (index + 1).toString() }
    })
  }, [])

  return (
    <Modal
      setIsOpen={onHideModal}
      modalHeader={
        <Heading className={styles.modalTitle} type={3} align="left">
          Add or remove users
          <img src={UpgradeRocket} alt="Upgrade" />
        </Heading>
      }
      loading={!(companyData && quantity)}
      noText="Close"
      yesButtonDisabled={
        !companyData ||
        !!(quantity && requestedSeats === quantity) ||
        companyData.currentCompany.userCount > requestedSeats
      }
      yesButtonLoading={loading || loadingMicrosoft}
      yesText={
        requestedSeats > teamTierMaxUsers ? (
          <>
            Request upgrade{' '}
            <span role="img" aria-label="Chat emoji">
              💬
            </span>
          </>
        ) : (
          `${
            requestedSeats >= (quantity as number)
              ? `Buy ${requestedSeats - (quantity as number)} more`
              : `Remove ${(quantity as number) - requestedSeats}`
          } user${
            Math.abs(requestedSeats - (quantity as number)) === 1 ? '' : 's'
          }`
        )
      }
      onYes={async () => {
        if (requestedSeats > teamTierMaxUsers) {
          window.open(calendarBookingLink, '_blank')

          logAction({
            variables: {
              action: 'request-enterprise-upgrade',
              extra: JSON.stringify({
                from: 'Add users modal',
              }),
              websiteSection: 'upgrade',
              pagePath: window.location.pathname,
              functionName: 'upgradeToEnterprise',
            },
          })

          onHideModal(false)

          return
        }

        if (isPaddle) {
          await updateBillingSeats({
            variables: {
              companyID,
              updatedQuantity: requestedSeats,
              paddleEnv: usePaddleSandboxAccount ? 'sandbox' : 'prod',
            },
            refetchQueries: [getPaddleSubscriptionDetails],
          })
        } else {
          // Microsoft
          await updateMicrosoftSeats({
            variables: {
              companyID,
              totalSeats: requestedSeats,
            },
          })
        }

        await logAction({
          variables: {
            action: `update-${
              isPaddle ? 'paddle' : 'microsoft-marketplace'
            }-seats-count`,
            extra: JSON.stringify({
              newCount: requestedSeats,
            }),
            websiteSection: 'upgrade',
            pagePath: window.location.pathname,
            functionName: 'addRemoveSeats',
          },
        })

        window.location.replace('/settings?show=users')
      }}
    >
      {companyData && quantity && (
        <>
          {showWarningMessage ? (
            <p className={styles.modalError} style={{ textAlign: 'left' }}>
              {showWarningMessage}
            </p>
          ) : (
            <p>
              Save time and money by letting your colleagues and agencies
              self-serve consistent campaign links and reports.
            </p>
          )}
          <p>
            <strong>{companyData.currentCompany.userCount}</strong> of{' '}
            <strong>
              {quantity.toString()} user seat
              {quantity > 1 && 's'}
            </strong>{' '}
            in use.
          </p>
          <ProgressBar
            className={styles.modalUserProgress}
            percentage={userProgress}
            showPercent={false}
            thin
            superThin
            useColors
          />
          <p>Select the total no. of monthly user seats you need:</p>
          <SelectBox
            id="user-licences"
            className={styles.licencesDropdown}
            maxMenuHeight={100}
            labelKey="value"
            value={availableLicences.find(
              (licence) =>
                licence.value ===
                `${requestedSeats.toString()}${
                  requestedSeats > teamTierMaxUsers ? '+' : ''
                }`,
            )}
            options={availableLicences}
            onChange={(newValue) => {
              if (!newValue) return

              setRequestedSeats(
                parseInt(newValue.value.replace(/[^\d]+/, ''), 10),
              )
            }}
          />
          {companyData.currentCompany.userCount > requestedSeats && (
            <p className={styles.modalError} style={{ textAlign: 'left' }}>
              You currently have more users than this. Please remove some in
              Users settings.
            </p>
          )}
          {requestedSeats > teamTierMaxUsers ? (
            <p>
              If you need more than {teamTierMaxUsers} users, you need our
              Enterprise plan. Talk to us to request an upgrade.
            </p>
          ) : (
            <>
              {isPaddle ? (
                <p>
                  <Link href="https://www.paddle.com/resources/proration">
                    We will prorate the new subscription total for the rest of
                    this month to your current billing method.
                  </Link>
                </p>
              ) : (
                <></>
              )}
            </>
          )}
          {unitPrice !== null &&
            quantity &&
            requestedSeats !== quantity &&
            requestedSeats <= teamTierMaxUsers && (
              <p>
                New full monthly bill:{' '}
                <strong>
                  {currency}
                  {unitPrice * requestedSeats}
                </strong>{' '}
                (ex. VAT)
              </p>
            )}
        </>
      )}
    </Modal>
  )
}

interface MicrosoftUpgradeModalProps {
  upgradeTo?: 'team' | 'enterprise'
  onHideModal: React.Dispatch<React.SetStateAction<boolean>>
}

export function MicrosoftUpgradeModal({
  upgradeTo = 'team',
  onHideModal,
}: MicrosoftUpgradeModalProps) {
  const history = useHistory()

  const { companyID } = useReactiveVar(currentUserDetails)

  const { data: microsoftPlansData, loading: loadingPlans } = useQuery(
    listAzureMarketplaceQueries,
  )

  const [
    upgradeMicrosoftMarketplacePlan,
    { loading: upgradingMSPlan, error: upgradeError },
  ] = useMutation(changeMicrosoftMarketplacePlan)

  const upgradeTierData = useMemo(() => {
    if (!microsoftPlansData) return null

    return microsoftPlansData.accountSettings.azureMarketplaceQueries.listPlans.find(
      (plan) =>
        plan.displayName === (upgradeTo === 'team' ? 'Team' : 'Enterprise'),
    )?.billingTerms[0]
  }, [microsoftPlansData])

  return (
    <Modal
      setIsOpen={onHideModal}
      modalHeader={
        <Heading className={styles.modalTitle} type={3} align="left">
          Upgrade to Team tier
          <img src={UpgradeRocket} alt="Upgrade" />
        </Heading>
      }
      loading={loadingPlans}
      noText="Close"
      yesButtonDisabled={!!upgradeError || !upgradeTierData}
      yesButtonLoading={upgradingMSPlan}
      yesText="Confirm upgrade"
      onYes={async () => {
        const { data } = await upgradeMicrosoftMarketplacePlan({
          variables: {
            companyID,
            newPlanID:
              upgradeTo === 'team' ? 'team_edition' : 'enterprise_edition',
          },
        })

        if (data) {
          history.push('/welcome', {
            upgradeSuccess: true,
            isMicrosoftMarketplace: true,
          })
        }
      }}
    >
      <>
        {upgradeTierData && !upgradeError ? (
          <p>
            Your subscription cost will increase to{' '}
            {currencyLookup[upgradeTierData.currency]}
            {numeral(upgradeTierData.price).format('0,0.00')} per month.
          </p>
        ) : (
          <p className={styles.modalError} style={{ textAlign: 'center' }}>
            There was an error fetching the plan information. Please reload and
            try again.
          </p>
        )}
      </>
    </Modal>
  )
}

interface FromPage {
  from: string
}

interface SubscriptionComparisonProps {
  className?: string
  showFreeTier?: boolean
  showIcons?: boolean
  showFeatures?: boolean
  topFeature?: FeatureCategory
  showCurrent?: boolean
  showSubscriptionDetails?: boolean
  showDeleteAccount?: boolean
  highlightCurrent?: boolean
}

export default function SubscriptionComparison({
  className,
  showFreeTier = true,
  showIcons = false,
  showFeatures = false,
  topFeature,
  showCurrent = false,
  showSubscriptionDetails = false,
  showDeleteAccount,
  highlightCurrent = true,
}: SubscriptionComparisonProps) {
  const history = useHistory()

  const { userEmail, companyID } = useReactiveVar(currentUserDetails)

  const {
    loading: loadingSubscriptionLevel,
    isFree,
    isEnterprise,
    isTeam,
    isPaddle,
    loadingPaddleData,
    paddleData,
    getPaddleDetails,
    isMicrosoftMarketplace,
    loadingMicrosoftData,
    microsoftSubscriptionData,
  } = useSubscriptionLevel()

  const { data: companyData } = useQuery(getCompanyDetails)

  const [
    fetchMicrosoftEnterprisePrice,
    { data: microsoftPriceData },
  ] = useLazyQuery(listAzureMarketplaceQueries)

  const logAction = useLogAction()

  // Set which page the user came from when logging actions
  const { state } = useLocation<FromPage>()

  const [localSubscriptionPrice, setLocalSubscriptionPrice] = useState(
    teamTierDefaultPrice,
  )
  const [showUsersModal, setShowUsersModal] = useState(false)
  const [showDowngradeDeleteModal, setShowDowngradeDeleteModal] = useState(
    false,
  )
  const [downgradeDeleteType, setDowngradeDeleteType] = useState<
    null | 'downgrade' | 'downgradeTeam' | 'delete'
  >(null)
  const [showMicrosoftUpgradeModal, setShowMicrosoftUpgradeModal] = useState(
    false,
  )
  const [microsoftUpgradeModalType, setMicrosoftUpgradeModalType] = useState<
    'team' | 'enterprise'
  >('team')

  useEffect(() => {
    if (!isMicrosoftMarketplace) return

    fetchMicrosoftEnterprisePrice()
  }, [isMicrosoftMarketplace])

  // Microsoft Marketplace subscribers do not need to get in touch to upgrade to enterprise
  // The price is fixed already (currently £59 per user per month, Aug 2023)
  // I hate it
  // TODO: Rebuild this entire component so it's not a total mess
  // Currently the different subscription plans and prices are totally garbled
  const microsoftEnterprisePrice = useMemo(() => {
    if (!microsoftPriceData) return null

    const enterpriseTierData = microsoftPriceData.accountSettings.azureMarketplaceQueries.listPlans.find(
      (plan) => plan.displayName === 'Enterprise',
    )?.billingTerms[0]

    if (!enterpriseTierData) return null

    return `${currencyLookup[enterpriseTierData.currency]}${numeral(
      enterpriseTierData.price,
    ).format('0,0.00')}`
  }, [microsoftPriceData])

  // Get correct currency and value from Paddle
  useEffect(() => {
    if (isMicrosoftMarketplace || !window.Paddle) return

    window.Paddle.Product.Prices(paddleSubscriptionID, (prices) => {
      setLocalSubscriptionPrice(prices.recurring.price.net)
    })
  }, [isMicrosoftMarketplace, window.Paddle])

  const currentUserCount = useMemo(() => {
    return companyData?.currentCompany.userCount || 0
  }, [companyData])

  const currentWorkspaceCount = useMemo(() => {
    return companyData?.currentCompany.accountCount || 0
  }, [companyData])

  // Users can subscribe via Paddle or Microsoft Marketplace
  // The data is different but used in the same way
  // This accomodates Paddle
  const paddleDetails = useMemo(() => {
    if (!paddleData) return null

    return paddleData.currentCompany
  }, [paddleData])

  // Users can subscribe via Paddle or Microsoft Marketplace
  // The data is different but used in the same way
  // This accomodates Microsoft Marketplace
  const microsoftDetails = useMemo(() => {
    if (!microsoftSubscriptionData) return null

    return microsoftSubscriptionData.currentCompany.microsoftSubscriptionData
  }, [microsoftSubscriptionData])

  const userProgress = useMemo(() => {
    if (
      !currentUserCount ||
      (!paddleDetails?.paddleSubscriptionQuantity &&
        !microsoftDetails?.quantity)
    )
      return 0

    if (paddleDetails?.paddleSubscriptionQuantity) {
      return (
        (currentUserCount /
          parseInt(paddleDetails.paddleSubscriptionQuantity, 10)) *
        100
      )
    }

    if (microsoftDetails?.quantity) {
      return (currentUserCount / microsoftDetails.quantity) * 100
    }

    return 0
  }, [currentUserCount, paddleDetails, microsoftDetails])

  const workspaceProgress = useMemo(() => {
    if (!currentWorkspaceCount) return 0

    return (currentWorkspaceCount / teamTierMaxWorkspaces) * 100
  }, [currentWorkspaceCount])

  const tierTopFeatures = useMemo(() => {
    if (!topFeature) return null

    const freeTopFeature = freeTierFeatures.find(
      (feature) => feature.category === topFeature,
    )
    const teamTopFeature = teamTierFeatures.find(
      (feature) => feature.category === topFeature,
    )
    const enterpriseTopFeature = enterpriseTierFeatures.find(
      (feature) => feature.category === topFeature,
    )

    return { freeTopFeature, teamTopFeature, enterpriseTopFeature }
  }, [topFeature])

  // Keep sections of grid in line
  const gridTemplateRows = useMemo(() => {
    const currentPlan = !showCurrent || (!showFreeTier && isFree) ? '' : '20px '
    let icons = ''
    const title = ' 50px '
    const description = ' 75px '
    const price = ' 50px '
    const userProgressDiv =
      topFeature !== 'workspaces' &&
      (paddleDetails?.paddleSubscriptionId ||
        (microsoftDetails?.subscriptionID && isTeam))
        ? ' 85px '
        : ''
    const actions = ' 40px '
    const subscriptionDetails =
      showSubscriptionDetails &&
      (paddleDetails?.paddleSubscriptionId ||
        (microsoftDetails?.subscriptionID && isTeam))
        ? ''
        : ''
    let features = ''

    switch (true) {
      case showIcons && showFeatures:
        icons = ' 140px '
        features = ' auto'
        break
      case showIcons:
        icons = ' 140px '
        break
      case showFeatures:
        features = ' auto'
        break
      default:
        break
    }

    return `${currentPlan}${icons}${title}${description}${price}${userProgressDiv}${actions}${subscriptionDetails}${features}`
  }, [showIcons, showFeatures, paddleDetails, microsoftDetails])

  return (
    <>
      <OuterBox
        className={classNames(className, styles.tierCols, {
          [styles.twoCols]: !showFreeTier,
        })}
      >
        {/* Free tier */}
        {showFreeTier && (
          <InnerBox
            className={classNames(styles.innerBox, {
              [styles.highlight]:
                isFree && !loadingSubscriptionLevel && highlightCurrent,
            })}
            style={{ gridTemplateRows }}
          >
            {showCurrent && (
              <div className={styles.currentPlan}>
                {isFree && !loadingSubscriptionLevel && (
                  <BetaLabel
                    title="Current plan"
                    className={styles.recommended}
                  />
                )}
              </div>
            )}
            {showIcons && (
              <img
                className={classNames(styles.tierImage, styles.freeTierImg)}
                src={SingleArrow}
                alt="Free tier"
              />
            )}
            <Heading type={1} className={styles.tierTitle}>
              Free
            </Heading>
            <div>
              <p>{freeTierTagline}</p>
            </div>
            <div className={styles.price}>
              <p>
                <strong>Free</strong>
                <br />
                One user only.
              </p>
            </div>
            {paddleDetails?.paddleSubscriptionId ||
            ((isTeam || isEnterprise) && microsoftDetails?.subscriptionID) ? (
              <div className={styles.tierActions}>
                <Button
                  variant="secondary"
                  className={styles.upgradeButton}
                  loading={loadingSubscriptionLevel}
                  onPress={() => {
                    // @ts-ignore
                    if (window.dataLayer && window.dataLayer.push) {
                      // @ts-ignore
                      window.dataLayer.push({
                        event: 'click-downgrade-to-free',
                      })
                    }

                    logAction({
                      variables: {
                        action: 'click-downgrade-to-free',
                        websiteSection: 'upgrade',
                        pagePath: window.location.pathname,
                        functionName: 'clickDowngrade',
                      },
                    })

                    setDowngradeDeleteType('downgrade')
                    setShowDowngradeDeleteModal(true)
                  }}
                >
                  Downgrade to free
                </Button>
              </div>
            ) : (
              <>
                {showDeleteAccount && !isEnterprise ? (
                  <div>
                    <NavigateButton
                      className={styles.textButton}
                      isDisabled={loadingSubscriptionLevel}
                      onPress={() => {
                        setShowDowngradeDeleteModal(true)
                        setDowngradeDeleteType('delete')
                      }}
                    >
                      Delete account
                    </NavigateButton>
                  </div>
                ) : (
                  <div />
                )}
              </>
            )}
            {showDeleteAccount &&
            !isEnterprise &&
            (paddleDetails?.paddleSubscriptionId ||
              (isTeam && microsoftDetails?.subscriptionID)) ? (
              <div>
                <NavigateButton
                  variant="text"
                  className={styles.textButton}
                  isDisabled={loadingSubscriptionLevel}
                  icon={{
                    src: LinkArrow,
                    alt: 'Link',
                    iconAfter: true,
                    imgHeight: 12,
                  }}
                  onPress={() => {
                    setShowDowngradeDeleteModal(true)
                    setDowngradeDeleteType('delete')
                  }}
                >
                  Delete account
                </NavigateButton>
              </div>
            ) : (
              <>
                {showSubscriptionDetails &&
                  (paddleDetails?.paddleSubscriptionId ||
                    (isTeam && microsoftDetails?.subscriptionID)) && <div />}
              </>
            )}
            {(paddleDetails?.paddleSubscriptionId ||
              (isTeam && microsoftDetails?.subscriptionID)) && <div />}
            {showFeatures && (
              <div>
                <h4>What you can do:</h4>
                <ul className={styles.featureList}>
                  {tierTopFeatures && tierTopFeatures.freeTopFeature && (
                    <li>
                      <Tooltip
                        id={tierTopFeatures.freeTopFeature.id || nanoid()}
                        tooltipMessage={tierTopFeatures.freeTopFeature.tooltip}
                        maxWidth={200}
                      >
                        <strong>{tierTopFeatures.freeTopFeature.text}</strong>
                      </Tooltip>
                    </li>
                  )}
                  {freeTierFeatures.map(({ id, category, text, tooltip }) => {
                    if (topFeature && category === topFeature) return null

                    return (
                      <li key={id}>
                        <Tooltip
                          id={id || nanoid()}
                          tooltipMessage={tooltip}
                          maxWidth={200}
                        >
                          {text}
                        </Tooltip>
                      </li>
                    )
                  })}
                </ul>
              </div>
            )}
          </InnerBox>
        )}

        {/* Team tier */}
        <InnerBox
          className={classNames(styles.innerBox, {
            [styles.highlight]:
              isTeam && !loadingSubscriptionLevel && highlightCurrent,
          })}
          style={{ gridTemplateRows }}
        >
          {showCurrent && (showFreeTier || !isFree) && (
            <div className={styles.currentPlan}>
              {isTeam && !loadingSubscriptionLevel && (
                <BetaLabel
                  title="Current plan"
                  className={styles.recommended}
                />
              )}
            </div>
          )}
          {showIcons && (
            <img
              className={classNames(styles.tierImage, styles.teamTierImg)}
              src={TwoArrows}
              alt="Team tier"
            />
          )}
          <Heading type={1} className={styles.tierTitle}>
            Team
          </Heading>
          <div>
            <p>{teamTierTagline}</p>
          </div>
          <div className={styles.price}>
            <p>
              <strong>{localSubscriptionPrice}</strong> (ex. VAT)
              <br />
              Per user, per month.
            </p>
          </div>
          {(paddleDetails?.paddleSubscriptionId ||
            (isTeam && microsoftDetails?.subscriptionID)) && (
            <div>
              <p>
                <strong>{currentWorkspaceCount}</strong> out of{' '}
                <strong>{teamTierMaxWorkspaces} workspaces</strong> in use.
              </p>
              <ProgressBar
                className={styles.userProgress}
                percentage={workspaceProgress}
                showPercent={false}
                thin
                superThin
                useColors
              />
              {topFeature !== 'workspaces' && (
                <>
                  <p>
                    <strong>{currentUserCount}</strong> out of{' '}
                    <strong>
                      {isPaddle
                        ? paddleDetails?.paddleSubscriptionQuantity
                        : microsoftDetails?.quantity?.toString()}{' '}
                      user seat
                      {(isPaddle &&
                        paddleDetails?.paddleSubscriptionQuantity === '1') ||
                      (!isPaddle && microsoftDetails?.quantity === 1)
                        ? ''
                        : 's'}
                    </strong>{' '}
                    in use.
                  </p>
                  <ProgressBar
                    className={styles.userProgress}
                    percentage={userProgress}
                    showPercent={false}
                    thin
                    superThin
                    useColors
                  />
                </>
              )}
            </div>
          )}
          <div className={styles.tierActions}>
            {!loadingSubscriptionLevel && isTeam ? (
              <>
                {loadingPaddleData || loadingMicrosoftData ? (
                  <Preloader style={{ margin: '0 auto' }} />
                ) : (
                  <Button
                    className={styles.upgradeButton}
                    icon={{
                      src: UpgradeRocket,
                      alt: 'Upgrade',
                      iconAfter: true,
                    }}
                    loading={loadingSubscriptionLevel}
                    onPress={() => {
                      // @ts-ignore
                      if (window.dataLayer && window.dataLayer.push) {
                        // @ts-ignore
                        window.dataLayer.push({
                          event: 'click-buy-more-licences',
                        })
                      }

                      logAction({
                        variables: {
                          action: 'click-buy-more-licences',
                          websiteSection: 'upgrade',
                          pagePath: window.location.pathname,
                          functionName: 'clickUpgrade',
                        },
                      })

                      setShowUsersModal(true)
                    }}
                  >
                    Add or remove users
                  </Button>
                )}
              </>
            ) : (
              <>
                {isEnterprise && isMicrosoftMarketplace ? (
                  <Button
                    variant="secondary"
                    className={styles.upgradeButton}
                    loading={loadingSubscriptionLevel}
                    onPress={() => {
                      // @ts-ignore
                      if (window.dataLayer && window.dataLayer.push) {
                        // @ts-ignore
                        window.dataLayer.push({
                          event: 'click-downgrade-to-team',
                        })
                      }

                      logAction({
                        variables: {
                          action: 'click-downgrade-to-team',
                          websiteSection: 'upgrade',
                          pagePath: window.location.pathname,
                          functionName: 'clickDowngrade',
                        },
                      })

                      setShowDowngradeDeleteModal(true)
                      setDowngradeDeleteType('downgradeTeam')
                    }}
                  >
                    Downgrade to team
                  </Button>
                ) : (
                  <Button
                    icon={{
                      src: UpgradeRocket,
                      alt: 'Upgrade',
                      iconAfter: true,
                    }}
                    className={classNames(
                      'paddle_button',
                      styles.upgradeButton,
                    )}
                    data-product={
                      isMicrosoftMarketplace
                        ? undefined
                        : `"${paddleSubscriptionID}"`
                    }
                    isDisabled={isEnterprise}
                    loading={loadingSubscriptionLevel}
                    onPress={async () => {
                      // @ts-ignore
                      if (window.dataLayer && window.dataLayer.push) {
                        // @ts-ignore
                        window.dataLayer.push({
                          event: `start-${
                            isMicrosoftMarketplace
                              ? 'microsoft-marketplace'
                              : 'paddle'
                          }-upgrade`,
                        })
                      }

                      logAction({
                        variables: {
                          action: `start-${
                            isMicrosoftMarketplace
                              ? 'microsoft-marketplace'
                              : 'paddle'
                          }-upgrade`,
                          extra: JSON.stringify({
                            from: state?.from || '',
                          }),
                          websiteSection: 'upgrade',
                          pagePath: '/upgrade',
                          functionName: `upgrade${
                            isMicrosoftMarketplace
                              ? 'MicrosoftMarketplace'
                              : 'Paddle'
                          }`,
                        },
                      })

                      if (isMicrosoftMarketplace) {
                        setShowMicrosoftUpgradeModal(true)
                        setMicrosoftUpgradeModalType('team')
                      } else {
                        window.Paddle.Checkout.open({
                          product: paddleSubscriptionID,
                          email: userEmail,
                          passthrough: companyID,
                          successCallback: (data) => {
                            history.push('/welcome', {
                              upgradeSuccess: true,
                            })
                          },
                        })
                      }
                    }}
                  >
                    Upgrade now
                  </Button>
                )}
              </>
            )}
          </div>

          {showSubscriptionDetails &&
            (paddleDetails?.paddleSubscriptionId ||
              (isTeam && microsoftDetails?.subscriptionID)) && (
              <>
                <div className={styles.subscriptionDetails}>
                  <p>
                    Current monthly bill:{' '}
                    <strong>
                      {
                        currencyLookup[
                          (isPaddle
                            ? paddleDetails?.paddleSubscriptionCurrency
                            : microsoftDetails?.currency) as string
                        ]
                      }
                      {(isPaddle
                        ? parseInt(
                            paddleDetails?.paddleSubscriptionQuantity as string,
                            10,
                          )
                        : (microsoftDetails?.quantity as number)) *
                        (isPaddle
                          ? parseInt(
                              paddleDetails?.paddleSubscriptionUnitPrice as string,
                              10,
                            )
                          : parseInt(microsoftDetails?.price as string, 10))}
                    </strong>
                  </p>
                  <p>
                    Next payment:{' '}
                    <strong>
                      {moment(
                        isPaddle
                          ? paddleDetails?.paddleSubscriptionNextBillDate
                          : microsoftDetails?.periodEndDate,
                      ).format('Do MMM YYYY ')}
                    </strong>
                  </p>

                  {/* Only Paddle subscribers can change their payment method */}
                  {isPaddle && (
                    <NavigateButton
                      className={styles.textButton}
                      onPress={() => {
                        window.Paddle.Checkout.open({
                          override: paddleDetails?.paddleUpdateUrl,
                          passthrough: companyID,
                          successCallback: async (data) => {
                            // @ts-ignore
                            if (window.dataLayer && window.dataLayer.push) {
                              // @ts-ignore
                              window.dataLayer.push({
                                event: 'update-paddle-subscription',
                              })
                            }

                            logAction({
                              variables: {
                                action: 'update-paddle-subscription',
                                extra: JSON.stringify({
                                  paddleSubscriptionId:
                                    paddleDetails?.paddleSubscriptionId,
                                }),
                                websiteSection: 'upgrade',
                                pagePath: '/upgrade',
                                functionName: 'updatePaddleSubscription',
                              },
                            })

                            await getPaddleDetails({
                              fetchPolicy: 'network-only',
                            })
                          },
                        })
                      }}
                    >
                      Change payment method
                    </NavigateButton>
                  )}
                </div>
              </>
            )}

          {showFeatures && (
            <div>
              <h4>All of Free plus:</h4>
              <ul className={styles.featureList}>
                {tierTopFeatures && tierTopFeatures.teamTopFeature && (
                  <li>
                    <Tooltip
                      id={tierTopFeatures.teamTopFeature.id || nanoid()}
                      tooltipMessage={tierTopFeatures.teamTopFeature.tooltip}
                      maxWidth={200}
                    >
                      <strong>{tierTopFeatures.teamTopFeature.text}</strong>
                    </Tooltip>
                  </li>
                )}
                {teamTierFeatures.map(({ id, category, text, tooltip }) => {
                  if (topFeature && category === topFeature) return null

                  return (
                    <li key={id}>
                      <Tooltip
                        id={id || nanoid()}
                        tooltipMessage={tooltip}
                        maxWidth={200}
                      >
                        {text}
                      </Tooltip>
                    </li>
                  )
                })}
              </ul>
            </div>
          )}
        </InnerBox>

        {/* Enterprise tier */}
        <InnerBox
          className={classNames(styles.innerBox, {
            [styles.highlight]:
              isEnterprise && !loadingSubscriptionLevel && highlightCurrent,
          })}
          style={{ gridTemplateRows }}
        >
          {showCurrent && (showFreeTier || !isFree) && (
            <div className={styles.currentPlan}>
              {isEnterprise && !loadingSubscriptionLevel && (
                <BetaLabel
                  title="Current plan"
                  className={styles.recommended}
                />
              )}
            </div>
          )}
          {showIcons && (
            <img
              className={classNames(styles.tierImage, styles.enterpriseTierImg)}
              src={ThreeArrows}
              alt="Enterprise tier"
            />
          )}
          <Heading type={1} className={styles.tierTitle}>
            Enterprise
          </Heading>
          <div>
            <p>{enterpriseTierTagline}</p>
          </div>
          <div className={styles.price}>
            {!loadingSubscriptionLevel && (
              <p>
                {isMicrosoftMarketplace && microsoftEnterprisePrice ? (
                  <>
                    <strong>{microsoftEnterprisePrice}</strong> (ex. VAT)
                    <br />
                    Per user, per month.
                  </>
                ) : (
                  <>
                    <strong>From {enterpriseTierDefaultPrice}</strong> (ex. VAT)
                    <br />
                    Per 10 users, per month.
                  </>
                )}
              </p>
            )}
          </div>
          {((isTeam && microsoftDetails?.subscriptionID) ||
            paddleDetails?.paddleSubscriptionId) && <div />}
          <div className={styles.tierActions}>
            {!isEnterprise && (
              <Button
                className={styles.upgradeButton}
                loading={loadingSubscriptionLevel}
                icon={
                  isMicrosoftMarketplace && microsoftEnterprisePrice
                    ? {
                        src: UpgradeRocket,
                        alt: 'Upgrade',
                        iconAfter: true,
                      }
                    : undefined
                }
                onPress={() => {
                  if (isMicrosoftMarketplace && microsoftEnterprisePrice) {
                    setShowMicrosoftUpgradeModal(true)
                    setMicrosoftUpgradeModalType('enterprise')
                  } else {
                    if (window.dataLayer && window.dataLayer.push) {
                      window.dataLayer.push({
                        event: 'request-enterprise-upgrade',
                      })
                    }

                    logAction({
                      variables: {
                        action: 'request-enterprise-upgrade',
                        extra: JSON.stringify({
                          from: state?.from || '',
                        }),
                        websiteSection: 'upgrade',
                        pagePath: '/upgrade',
                        functionName: 'upgradeToEnterprise',
                      },
                    })

                    window.open(calendarBookingLink, '_blank')
                  }
                }}
              >
                {isMicrosoftMarketplace && microsoftEnterprisePrice ? (
                  'Upgrade now'
                ) : (
                  <span>
                    Request upgrade{' '}
                    <span role="img" aria-label="Chat emoji">
                      💬
                    </span>
                  </span>
                )}
              </Button>
            )}
          </div>
          {showSubscriptionDetails &&
            isEnterprise &&
            microsoftDetails?.subscriptionID && (
              <>
                <div className={styles.subscriptionDetails}>
                  <p>
                    Current monthly bill:{' '}
                    <strong>
                      {currencyLookup[microsoftDetails?.currency as string]}
                      {(microsoftDetails?.quantity as number) *
                        parseInt(microsoftDetails?.price as string, 10)}
                    </strong>
                  </p>
                  <p>
                    Next payment:{' '}
                    <strong>
                      {moment(microsoftDetails?.periodEndDate).format(
                        'Do MMM YYYY ',
                      )}
                    </strong>
                  </p>
                </div>
              </>
            )}
          {showFeatures ? (
            <div>
              <h4>All of Team plus:</h4>
              <ul className={styles.featureList}>
                {tierTopFeatures && tierTopFeatures.enterpriseTopFeature && (
                  <li>
                    <Tooltip
                      id={tierTopFeatures.enterpriseTopFeature.id || nanoid()}
                      tooltipMessage={
                        tierTopFeatures.enterpriseTopFeature.tooltip
                      }
                      maxWidth={200}
                    >
                      <strong>
                        {tierTopFeatures.enterpriseTopFeature.text}
                      </strong>
                    </Tooltip>
                  </li>
                )}
                {enterpriseTierFeatures.map(
                  ({ id, category, text, tooltip }) => {
                    if (topFeature && category === topFeature) return null

                    return (
                      <li key={id}>
                        <Tooltip
                          id={id || nanoid()}
                          tooltipMessage={tooltip}
                          maxWidth={200}
                        >
                          {text}
                        </Tooltip>
                      </li>
                    )
                  },
                )}
              </ul>
            </div>
          ) : (
            <>
              {(paddleDetails?.paddleSubscriptionId ||
                (isTeam && microsoftDetails?.subscriptionID)) && (
                <div>
                  {window.location.pathname !== '/upgrade' && (
                    <NavigateButton
                      className={styles.textButton}
                      onPress={() => history.push('/upgrade')}
                    >
                      Compare features
                    </NavigateButton>
                  )}
                </div>
              )}
              {(paddleDetails?.paddleSubscriptionId ||
                (isTeam && microsoftDetails?.subscriptionID)) &&
                showSubscriptionDetails &&
                showDeleteAccount &&
                !isEnterprise && <div />}
            </>
          )}
        </InnerBox>
      </OuterBox>

      {showDowngradeDeleteModal && (
        <DowngradeDeleteBlockerModal
          type={downgradeDeleteType}
          onHideModal={setShowDowngradeDeleteModal}
          currentUserCount={currentUserCount}
          currentWorkspaceCount={currentWorkspaceCount}
        />
      )}
      {showUsersModal && (
        <UpdateSubscriptionUsersModal onHideModal={setShowUsersModal} />
      )}
      {showMicrosoftUpgradeModal && (
        <MicrosoftUpgradeModal
          upgradeTo={microsoftUpgradeModalType}
          onHideModal={setShowMicrosoftUpgradeModal}
        />
      )}
    </>
  )
}
