import React, { useState } from 'react'

import Button from './button'
import { addUsersToAccountsBulk } from '../api/graphql/custom-queries'
import { validateEmail } from '../helpers/forms'
import styles from '../styles/resend-invitation-button.module.scss'

interface Props {
  email: string
  permissionLevel: PermissionLevel
  accountID: string
}

export default function ResendInvitationButton({
  email,
  permissionLevel,
  accountID,
}: Props) {
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')

  const handleIviteResend = async () => {
    setError('')
    setSuccess('')

    const { valid } = validateEmail([email], true)

    try {
      await addUsersToAccountsBulk({
        users: valid,
        accountIDs: [accountID],
        permissionLevelList: [permissionLevel],
      })

      setError('')
      setSuccess('Invite resent')
    } catch {
      setError('Invite error')
    }
  }

  if (success) {
    return <p className={styles.success}>{success}</p>
  }

  if (error) {
    return <p className={styles.error}>{error}</p>
  }

  return (
    <Button
      color="blue"
      type="submit"
      className={styles.add}
      onPress={handleIviteResend}
    >
      Reinvite
    </Button>
  )
}
