import React, { useEffect, useMemo, useState } from 'react'
import { useReactiveVar } from '@apollo/client'

import CompanyBillingAndDetails from './company-billing-and-details'
import EnterpriseOrgSettings from './enterprise-org-settings'
import ManageWorkspaces from './manage-workspaces'
import PublicAPI from './public-api'
import TeamMatesSettings from './teammates-settings'
import ToggleBox from './toggle-box'
import { Heading } from './typography'
import { currentUserDetails } from '../api/apollo/variables'
import { getUrlQuery, isAdminUser, isSupportUser } from '../helpers'
import useLogAction from '../hooks/useLogAction'
import styles from '../styles/company-settings.module.scss'

export default function CompanySettings() {
  const { userPermission } = useReactiveVar(currentUserDetails)

  const logAction = useLogAction()

  const [openSection, setOpenSection] = useState('')

  // Open section according to URL query
  const intialOpen = useMemo(() => {
    const query = getUrlQuery()

    return query?.get('show')
  }, [])

  useEffect(() => {
    if (intialOpen) setOpenSection(intialOpen)
  }, [intialOpen])

  if (!userPermission || !isAdminUser(userPermission)) return null

  return (
    <div className={styles.container}>
      <ToggleBox
        noPadding
        className={styles.settingsToggleBox}
        heading={
          <div className={styles.settingHeader}>
            <div className={styles.emoji}>
              <span role="img" aria-label="Billing">
                📝
              </span>
            </div>
            <div className={styles.headerText}>
              <Heading
                type={2}
                align="left"
                className={styles.settingsToggleHeader}
              >
                Your plan and billing
              </Heading>
              <span>Manage your company's settings.</span>
            </div>
          </div>
        }
        open={openSection === 'billing'}
        onToggle={() => {
          if (openSection === 'billing') {
            setOpenSection('')
          } else {
            setOpenSection('billing')

            logAction({
              variables: {
                action: 'open-settings-section',
                extra: '{"sectionOpened": "billing"}',
                websiteSection: 'Settings',
                pagePath: '/settings',
                functionName: 'toggleSection',
              },
            })
          }
        }}
      >
        <CompanyBillingAndDetails className={styles.settingsToggleBoxInner} />
      </ToggleBox>

      <ToggleBox
        noPadding
        className={styles.settingsToggleBox}
        heading={
          <div className={styles.settingHeader}>
            <div className={styles.emoji}>
              <span role="img" aria-label="Users">
                👥
              </span>
            </div>
            <div className={styles.headerText}>
              <Heading
                type={2}
                align="left"
                className={styles.settingsToggleHeader}
              >
                Users
              </Heading>
              <span>Add, remove and reassign users.</span>
            </div>
          </div>
        }
        open={openSection === 'users'}
        onToggle={() => {
          if (openSection === 'users') {
            setOpenSection('')
          } else {
            setOpenSection('users')

            logAction({
              variables: {
                action: 'open-settings-section',
                extra: '{"sectionOpened": "users"}',
                websiteSection: 'Settings',
                pagePath: '/settings',
                functionName: 'toggleSection',
              },
            })
          }
        }}
      >
        <TeamMatesSettings className={styles.settingsToggleBoxInner} />
      </ToggleBox>

      <ToggleBox
        noPadding
        className={styles.settingsToggleBox}
        heading={
          <div className={styles.settingHeader}>
            <div className={styles.emoji}>
              <span
                role="img"
                aria-label="Link builders and analytics integrations"
              >
                🗂️
              </span>
            </div>
            <div className={styles.headerText}>
              <Heading
                type={2}
                align="left"
                className={styles.settingsToggleHeader}
              >
                Manage workspaces
              </Heading>
              <span>Create new workspaces and connect website analytics.</span>
            </div>
          </div>
        }
        open={openSection === 'workspaces'}
        onToggle={() => {
          if (openSection === 'workspaces') {
            setOpenSection('')
          } else {
            setOpenSection('workspaces')

            logAction({
              variables: {
                action: 'open-settings-section',
                extra: '{"sectionOpened": "workspaces"}',
                websiteSection: 'Settings',
                pagePath: '/settings',
                functionName: 'toggleSection',
              },
            })
          }
        }}
      >
        <ManageWorkspaces className={styles.settingsToggleBoxInner} />
      </ToggleBox>

      <ToggleBox
        noPadding
        className={styles.settingsToggleBox}
        heading={
          <div className={styles.settingHeader}>
            <div className={styles.emoji}>
              <span role="img" aria-label="Public API">
                🔑
              </span>
            </div>
            <div className={styles.headerText}>
              <Heading
                type={2}
                align="left"
                className={styles.settingsToggleHeader}
              >
                API token
              </Heading>
              <span>Get your API key to export data from Uplifter.</span>
            </div>
          </div>
        }
        open={openSection === 'publicAPI'}
        onToggle={() => {
          if (openSection === 'publicAPI') {
            setOpenSection('')
          } else {
            setOpenSection('publicAPI')

            logAction({
              variables: {
                action: 'open-settings-section',
                extra: '{"sectionOpened": "publicAPI"}',
                websiteSection: 'Settings',
                pagePath: '/settings',
                functionName: 'toggleSection',
              },
            })
          }
        }}
      >
        <PublicAPI />
      </ToggleBox>

      {isSupportUser(userPermission) && (
        <ToggleBox
          noPadding
          className={styles.settingsToggleBox}
          heading={
            <div className={styles.settingHeader}>
              <div className={styles.emoji}>
                <span role="img" aria-label="Enterprise client details">
                  ⚙️
                </span>
              </div>
              <div className={styles.headerText}>
                <Heading
                  type={2}
                  align="left"
                  className={styles.settingsToggleHeader}
                >
                  Enterprise client details (Support only)
                </Heading>
                <span>Review contract details.</span>
              </div>
            </div>
          }
          open={openSection === 'accountSettings'}
          onToggle={() => {
            if (openSection === 'accountSettings') {
              setOpenSection('')
            } else {
              setOpenSection('accountSettings')

              logAction({
                variables: {
                  action: 'open-settings-section',
                  extra: '{"sectionOpened": "enterprise client details"}',
                  websiteSection: 'Settings',
                  pagePath: '/settings',
                  functionName: 'toggleSection',
                },
              })
            }
          }}
        >
          <EnterpriseOrgSettings className={styles.settingsToggleBoxInner} />
        </ToggleBox>
      )}
    </div>
  )
}
