import React, { useEffect, useMemo, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import ReactMarkdown from 'react-markdown'

import { ButtonRow } from './button-row'
import { ClickEditInput } from './input'
import { LoadingLabel } from './loader'
import { WorkspaceLogo } from './workspace-logo'
import { getCurrentAccountQRDetails } from '../api/graphql/company-client'
import {
  listSavedLostLinksReports,
  updateSavedLostLinkReport,
} from '../api/graphql/report-client'
import uplifter from '../assets/logos/uplogo.png'
import { messages } from '../core/constants'
import { getDataSourceDescription } from '../helpers/report-module'
import useLogAction from '../hooks/useLogAction'
import styles from '../styles/lost-links-report-header.module.scss'
import {
  LostLinksReportDataConfig,
  SavedLostLinksReportDataConfig,
} from '../types/report-module'
import { GetAccountDataSourceQuery } from '../__gql-types__/graphql'
import ReportFiltersSummary from './report-filters-summary'

interface LostLinksReportHeaderProps {
  isMobile: boolean
  dateRange?: string
  dataSourceData?: GetAccountDataSourceQuery
  dataConfig: LostLinksReportDataConfig
  availableDimensionsTotals: { [dimensionParameterID: string]: number }
  updateFilters: ({
    dimensionParameterID,
    optionValue,
  }: {
    dimensionParameterID: string
    optionValue: string
  }) => Promise<void>
  currentReport: SavedLostLinksReportDataConfig | null
  error?: boolean
}

export default function LostLinksReportHeader({
  dateRange,
  dataSourceData,
  isMobile,
  dataConfig,
  availableDimensionsTotals,
  updateFilters,
  currentReport,
  error,
}: LostLinksReportHeaderProps) {
  const logAction = useLogAction()

  const { data: logoData } = useQuery(getCurrentAccountQRDetails)

  const [updateSavedReport] = useMutation(updateSavedLostLinkReport, {
    refetchQueries: [listSavedLostLinksReports],
  })

  const [titleValue, setTitleValue] = useState(
    currentReport
      ? currentReport.reportTitle
      : messages.defaultReportTitle_lostLinks,
  )

  const useLogo = logoData?.currentAccount.logoLink || uplifter
  const logoLink =
    useLogo.indexOf('logos/PNG/uplogo.png') !== -1 ? uplifter : useLogo

  // UI display of data source details
  const dataSourceDescription = useMemo(() => {
    return getDataSourceDescription(dataSourceData)
  }, [dataSourceData])

  // Update title value when saved report changes
  useEffect(() => {
    setTitleValue(
      currentReport?.reportTitle || messages.defaultReportTitle_lostLinks,
    )
  }, [currentReport])

  const appliedFilters = useMemo(() => {
    return (
      dataConfig.applyFilters?.map(
        ({ dimensionParameterID, dimensionName, dimensionOptions }) => ({
          dimensionParameterID,
          dimensionName,
          dimensionOptions: dimensionOptions.map((option) => ({
            optionName: option,
            optionValue: option,
          })),
        }),
      ) || []
    )
  }, [dataConfig])

  return (
    <div className={styles.paperHeader}>
      {isMobile && (
        <div className={styles.logoWrapper}>
          <WorkspaceLogo logoLink={logoLink} loading={!logoData} />
        </div>
      )}
      <div className={styles.paperHeaderContent}>
        <ButtonRow className={styles.headerButtonRow}>
          <div className={styles.editTitleWrapper}>
            {currentReport === null ? (
              <h4>Other links audit</h4>
            ) : (
              <ClickEditInput
                id="report-title"
                name="report-title"
                className={styles.editTitle}
                value={titleValue}
                onChange={async (newValue: string) => {
                  if (newValue !== titleValue) {
                    setTitleValue(newValue)

                    await updateSavedReport({
                      variables: {
                        savedReportID: currentReport.savedReportID,
                        reportTitle: newValue,
                      },
                    })

                    logAction({
                      variables: {
                        action: 'saved-lost-link-reports-update-report-title',
                        extra: JSON.stringify({
                          ...currentReport,
                          reportTitle: newValue,
                        }),
                        websiteSection: 'report',
                        functionName: 'updateSavedReportTitle',
                        pagePath: window.location.pathname,
                      },
                    })
                  }
                }}
              />
            )}
          </div>
        </ButtonRow>
        {!error && (
          <p>
            <strong>Date range:</strong>{' '}
            {dateRange || <LoadingLabel label="Loading" />}
          </p>
        )}
        {dataSourceDescription ? (
          <ReactMarkdown source={dataSourceDescription} />
        ) : (
          <p>
            <strong>Metrics from:</strong> <LoadingLabel label="Loading" />
          </p>
        )}
        <p>
          <strong>Other links:</strong>{' '}
          <span>
            Landing pages with UTMs which were not created by this platform.
          </span>
        </p>
        <ReportFiltersSummary
          interactionLogReportName="link-audit"
          availableDimensionsTotals={availableDimensionsTotals}
          appliedFilters={appliedFilters}
          onRemoveFilterOption={async ({
            dimensionParameterID,
            optionValue,
          }) => {
            await updateFilters({
              dimensionParameterID,
              optionValue,
            })
          }}
        />
      </div>
      {!isMobile && (
        <div className={styles.logoWrapper}>
          <WorkspaceLogo logoLink={logoLink} loading={!logoData} />
        </div>
      )}
    </div>
  )
}
