import React, { forwardRef } from 'react'
import classNames from 'classnames'

import styles from '../styles/row.module.scss'
import useMobile from '../hooks/useMobile'

interface RowProps {
  className?: string
  children?: React.ReactNode
  align?: 'flex-start' | 'flex-end' | 'center' | 'space-between'
  vAlign?: 'stretch' | 'flex-start' | 'flex-end' | 'center'
  style?: object
  width?: number | string
  centerAtMobile?: boolean
  odd?: boolean
}

const Row = forwardRef<HTMLDivElement, RowProps>(
  (
    { children, className, align, vAlign, style, width, odd, centerAtMobile },
    ref,
  ) => {
    const mobile = useMobile(769)
    const oddClassName = odd ? styles.odd : ''

    const _width =
      width && (typeof width === 'number' || /^\d{1,2}%$/.test(width))
        ? width
        : undefined

    return (
      <div
        ref={ref}
        className={`${oddClassName} ${
          centerAtMobile ? styles.rowCenterAtMobile : styles.row
        } ${className}`}
        style={{
          ...style,
          justifyContent: align,
          alignItems: vAlign,
          maxWidth: mobile ? 'auto' : _width,
        }}
      >
        {children}
      </div>
    )
  },
)

Row.defaultProps = {
  className: '',
  align: 'center',
  vAlign: 'stretch',
  odd: false,
}

export default Row

interface SlotProps {
  children?: React.ReactNode
  width?: number
  className?: string
  style?: object
  padding?: string
  align?: 'flex-start' | 'flex-end' | 'center'
  vAlign?: 'flex-start' | 'flex-end' | 'center'
  centerAtMobile?: boolean
  containsForm?: boolean
}

export function Slot({
  children,
  width,
  className,
  style,
  padding,
  align,
  vAlign,
  centerAtMobile,
}: SlotProps): React.ReactElement {
  const mobile = useMobile(769)
  return (
    <div
      className={classNames(className, {
        [styles.slotCenterAtMobile]: centerAtMobile,
        [styles.slot]: !centerAtMobile,
      })}
      style={{
        ...style,
        maxWidth: mobile ? 'auto' : width,
        padding,
        ...(align
          ? { display: 'flex', justifyContent: align, alignItems: vAlign }
          : {}),
      }}
    >
      {children}
    </div>
  )
}

Slot.defaultProps = {
  className: '',
  style: {},
}

interface HeadProps {
  className?: string
  children?: React.ReactNode
}

export function Head({ children, className }: HeadProps): React.ReactElement {
  return (
    <Row
      className={classNames(styles.header, className)}
      align="flex-start"
      vAlign="center"
    >
      {children}
    </Row>
  )
}

interface BodyProps {
  children?: React.ReactNode
  className?: string
}

export function Body({ children, className }: BodyProps): React.ReactElement {
  return <div className={classNames(className, styles.body)}>{children}</div>
}

interface FooterProps {
  children?: React.ReactNode
}

export function Footer({ children }: FooterProps): React.ReactElement {
  return <Row className={styles.footer}>{children}</Row>
}

interface HeaderCellProps {
  children?: React.ReactNode
  width?: number
  className?: string
}

export function HeaderCell({
  children,
  width,
  className,
}: HeaderCellProps): React.ReactElement {
  return (
    <Slot
      align="flex-start"
      vAlign="center"
      width={width}
      className={classNames(styles.headerCell, className)}
    >
      {children}
    </Slot>
  )
}

interface CellProps {
  children?: React.ReactNode
  width?: number
  className?: string
}

export function Cell({
  children,
  width,
  className,
}: CellProps): React.ReactElement {
  return (
    <Slot
      align="flex-start"
      vAlign="center"
      width={width}
      className={classNames(className, styles.cell)}
    >
      {children}
    </Slot>
  )
}

interface BodyItemProps {
  children?: React.ReactNode
  className?: string
}

export function BodyItem({
  children,
  className,
}: BodyItemProps): React.ReactElement {
  return (
    <Row
      className={classNames(className, styles.bodyItem)}
      align="flex-start"
      vAlign="center"
    >
      {children}
    </Row>
  )
}

interface TableProps {
  children?: React.ReactNode
  className?: string
  width?: number
  inline?: boolean
}

export function Table({
  children,
  className,
  width,
  inline,
}: TableProps): React.ReactElement {
  return (
    <div
      className={classNames(styles.table, className, {
        [styles.inline]: inline,
      })}
      style={{ width: `${width}px` }}
    >
      {children}
    </div>
  )
}

interface FormRowProps {
  className?: string
  children?: React.ReactNode
  align?: 'flex-start' | 'flex-end' | 'center' | 'space-between'
  vAlign?: 'stretch' | 'flex-start' | 'flex-end' | 'center'
  style?: object
  width?: number
  centerAtMobile?: boolean
  odd?: boolean
  last?: boolean
  single?: boolean
  wideFields?: boolean
}

export const FormRow = forwardRef<HTMLDivElement, FormRowProps>(
  ({ className, last, single, wideFields, children, ...res }, ref) => {
    const rowClassNames = classNames(className, styles.formRow, {
      [styles.last]: last,
      [styles.single]: single,
      [styles.wideFields]: wideFields,
    })

    return (
      <Row ref={ref} className={rowClassNames} {...res}>
        {children}
      </Row>
    )
  },
)

interface LabelSlotProps {
  children?: React.ReactNode
  className?: string
  style?: object
  column?: boolean
  topAlign?: boolean
  noPadding?: boolean
}

export function LabelSlot({
  className,
  children,
  column,
  topAlign = false,
  noPadding = false,
  ...res
}: LabelSlotProps): React.ReactElement {
  const elementClassNames = classNames(className, styles.labelSlot, {
    [styles.column]: column,
    [styles.topAlign]: topAlign,
    [styles.noPadding]: noPadding,
  })
  return (
    <Slot className={elementClassNames} {...res}>
      {children}
    </Slot>
  )
}

export function FieldSlot({
  className,
  children,
  column,
  noPadding = false,
  ...res
}: LabelSlotProps): React.ReactElement {
  const elementClassNames = classNames(className, styles.fieldSlot, {
    [styles.column]: column,
    [styles.noPadding]: noPadding,
  })
  return (
    <Slot className={elementClassNames} {...res}>
      {children}
    </Slot>
  )
}
