import React, { useMemo } from 'react'
import { useQuery } from '@apollo/client'

import Button from './button'
import { BetaLabel } from './counter'
import Link from './link'
import Tooltip from './tooltip'
import { BoxedText } from './typography'
import { getCompanyDetails } from '../api/graphql/company-client'
import chrisSupport from '../assets/ChrisFormalCircleGrey.png'
import latestFeature from '../assets/latest-feature-oneclick-report.png'
import { calendarBookingLink } from '../core/constants'
import styles from '../styles/welcome-rhs.module.scss'

export function WelcomeLatestFeature() {
  return (
    <div className={styles.latestFeaturesContainer}>
      <h2>
        <BetaLabel className={styles.newPill} title="New" />
        <span>One-click reports</span>
      </h2>
      <p className={styles.featureDescription}>
        Build reports for single links you've created straight from the{' '}
        <BoxedText>
          <Link href="/track/view">Track &gt; View links</Link>
        </BoxedText>{' '}
        page.{' '}
        <Link
          type="arrowForward"
          href="https://support.uplifter.ai/hc/en-us/articles/16157579418269-How-to-create-one-click-reports"
        >
          Learn more
        </Link>
      </p>
      <div className={styles.featureImg}>
        <img src={latestFeature} alt="latest feature" />
      </div>
      <Link type="arrowForward" href="https://uplifter.ai/whats-new">
        More latest features
      </Link>
    </div>
  )
}

function WelcomeHelp() {
  const { data: companyData } = useQuery(getCompanyDetails)

  const internalContactEmail = useMemo(() => {
    if (!companyData) return null

    return companyData.currentCompany.internalContactEmail
  }, [companyData])

  return (
    <div className={styles.helpContainer}>
      <div className={styles.helpContainerInner}>
        <div className={styles.helpButtons}>
          <h2>Get help</h2>
          <Button
            style={{ width: '100%', marginBottom: 16 }}
            onPress={() => window.open(calendarBookingLink, '_blank')}
          >
            Book free training
          </Button>
          <Button
            color="grey"
            style={{ width: '100%', marginBottom: 16 }}
            onPress={() =>
              window.open('https://support.uplifter.ai/hc/en-us', '_blank')
            }
          >
            Visit help centre
          </Button>
        </div>
        <div className={styles.helpImage}>
          <img src={chrisSupport} alt="Uplifter support" />
        </div>
      </div>
      {internalContactEmail && (
        <p className={styles.supportContact}>
          Internal support:{' '}
          <Tooltip
            id="internal-support-tooltip"
            useIcon
            tooltipPosition="left"
            tooltipMessage="Your admin to request additional users, query your company's processes or setup."
          >
            <Link href={`mailto:${internalContactEmail}`}>
              {internalContactEmail}
            </Link>
          </Tooltip>
        </p>
      )}
    </div>
  )
}

export default function WelcomeRightModule() {
  return (
    <div className={styles.containerWrapper}>
      <WelcomeHelp />
      <WelcomeLatestFeature />
    </div>
  )
}
