import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  SetStateAction,
  Dispatch,
} from 'react'
import { useLazyQuery, useMutation, useReactiveVar } from '@apollo/client'
import classNames from 'classnames'
import numeral from 'numeraljs'
import { nanoid } from 'nanoid'

import Button, { ClearButton } from './button'
import CampaignCodeCreatePreviewTable from './campaign-code-create-preview-table'
import { InputRow } from './campaign-code-generator-rows'
import WarningModal from './campaign-code-generator-warning-modal'
import CodeSummary from './campaign-code-summary'
import { UrlValidationMessage } from './campaign-code-url-validation-message'
import { BiLine } from './counter'
import Input, { Label } from './input'
import GeneratorFormFields from './generator-form-fields'
import Link from './link'
import Loader from './loader'
import Modal from './modal'
import QRCodeModal from './qr-code-modal'
import { FormRow, LabelSlot, FieldSlot } from './row'
import { ShortLinkFull } from './short-link'
import Tooltip from './tooltip'
import { InnerBox, OuterBox } from './two-columns'
import { RequestShortLinksModal } from './upgrade-modals'
import {
  currentUserDetails,
  dataSourceReactive,
  recentlyValidatedUrls,
} from '../api/apollo/variables'
import {
  createNewLink,
  getCampaignCodeGenerator,
  getUplifterIDCurrentTotal,
  updateCurrentSequentialCodeID,
} from '../api/graphql/track-create-client'
import { getMinCodesByAccount } from '../api/graphql/track-view-client'
import { CampaignCodeGeneratorStructure, CodesToAdd } from '../api/types'
import {
  FullValidationCheck,
  maxBatchShortLinks,
  messages,
  minBatchShortLinks,
  supportEmail,
} from '../core/constants'
import {
  getItemByKeyValue,
  getValidationChecksObject,
  getValidationCheck,
  isValidInput,
  returnUnique,
  isValidUrl,
  getDomain,
  getCustomDomainID,
  isAdminUser,
  loopApiCall,
} from '../helpers'
import generateCode, {
  makeLinkSecure,
  isAllFormDataValid,
  GeneratedCode,
  getAnchorFromString,
} from '../helpers/track-module'
import useLogAction from '../hooks/useLogAction'
import useOnboarding from '../hooks/useOnboarding'
import useShortLinks from '../hooks/useShortLinks'
import useUrlValidation from '../hooks/useUrlValidation'
import {
  saveFormData,
  getFormData,
  saveUserData,
  getUserData,
} from '../helpers/local-client'
import styles from '../styles/campaign-code-generator.module.scss'

interface CampaignCodeGeneratorFormProps {
  loading?: boolean
  single?: boolean
  setNewLinks?: Dispatch<SetStateAction<string | string[]>>
  onRequestNewField?: (value: string) => void
  defaultGenerator?: CampaignCodeGeneratorStructure | null
  hasCreatedCode?: boolean
}

function _CampaignCodeGeneratorForm({
  loading = false,
  single = true,
  setNewLinks,
  onRequestNewField,
  defaultGenerator = null,
  hasCreatedCode = true,
}: CampaignCodeGeneratorFormProps) {
  const logAction = useLogAction()

  const {
    canUseShortLinks,
    useAliases,
    availableDomains,
    fetchingAliases,
  } = useShortLinks()

  const {
    workspaceID,
    userPermission: currentAccountPermission,
    companySubscriptionLevel,
    userEmail,
  } = useReactiveVar(currentUserDetails)
  const dataSource = useReactiveVar(dataSourceReactive)

  const isAdmin = isAdminUser(currentAccountPermission)

  const { updateOnboardingSection } = useOnboarding()

  const recentlyValidatedLinks = useReactiveVar(recentlyValidatedUrls)

  const [getGenerator, { data: generatorData }] = useLazyQuery(
    getCampaignCodeGenerator,
  )
  const [
    getUplifterID,
    { data: uplifterIdData, error: uplifterIdError },
  ] = useLazyQuery(getUplifterIDCurrentTotal, { fetchPolicy: 'network-only' })
  const [getUserLinks] = useLazyQuery(getMinCodesByAccount)

  const [checkUplifterID, { loading: checkingUplifterIDs }] = useMutation(
    updateCurrentSequentialCodeID,
    {
      notifyOnNetworkStatusChange: true,
    },
  )
  const [addCode, { loading: addingCodes }] = useMutation(createNewLink)

  // Wait for account ID so generator can be cached
  useEffect(() => {
    if (!workspaceID) return

    getGenerator()
  }, [workspaceID])

  // Adobe accounts don't use UplifterID
  useEffect(() => {
    if (
      !!workspaceID &&
      (!dataSource || dataSource.connectionSource !== 'adobe')
    ) {
      getUplifterID()
    }
  }, [workspaceID, dataSource])

  const generatedStructure = useMemo(() => {
    if (!generatorData) return null

    return generatorData.campaignCodeGenerator
  }, [generatorData])

  const useUplifterID = useMemo(() => {
    if (!uplifterIdData || dataSource?.connectionSource === 'adobe')
      return false

    return !!uplifterIdData.track.currentSequentialCodeID.isEnabled
  }, [uplifterIdData, dataSource])

  const [shortLinkDomain, setShortLinkDomain] = useState('')
  const [shortLink, setShortLink] = useState('')
  // shortLinkKey is used to reset short link to previously generated alias
  // When a link with a custom alias is created
  const [shortLinkKey, setShortLinkKey] = useState(nanoid())
  const [shortLinkStatus, setShortLinkStatus] = useState<UrlStatus>('')

  const [previewTableShortLinks, setPreviewTableShortLinks] = useState<{
    [code: string]: string
  }>({})
  const [warningModalType, setWarningModalType] = useState<WarningTypes>('')
  const [showWarningModal, setShowWarningModal] = useState(false)

  const savedState = getUserData()
  const initialState = savedState?.linkType || 'full'
  const [linkType, setLinkType] = useState<string>(initialState)

  const [formKey, setFormKey] = useState(nanoid())
  const [url, setUrl] = useState([''])
  const [form, setForm] = useState<CampaignCodeGeneratorStructure | null>(
    generatedStructure || defaultGenerator,
  )
  const [submitDisabled, setSubmitDisabled] = useState(true)
  const [errors, setErrors] = useState<string[]>([])
  const [createLinkError, setCreateLinkError] = useState(false)
  const [submitError, setSubmitError] = useState(false)
  const [createShortLinkError, setCreateShortLinkError] = useState(false)
  const [combinations, setCombinations] = useState(0)
  const [showPreviewModal, setShowPreviewModal] = useState(false)
  const [previewCodes, setShowPreviewCodes] = useState<GeneratedCode[]>([])
  const [codeCreatedTimeout, setCodeCreatedTimeout] = useState(false)
  const [showQrModal, setShowQrModal] = useState(false)
  const [currentSingleCode, setCurrentSingleCode] = useState('')
  const [showShortLinkModal, setShowShortLinkModal] = useState(false)

  // Don't allow short links from local storage for unpaid accounts
  useEffect(() => {
    if (companySubscriptionLevel && linkType === 'short' && !canUseShortLinks) {
      setLinkType('full')
      saveUserData({ linkType: 'full' })
    }
  }, [companySubscriptionLevel])

  const triggerCacheRefresh = useCallback(() => {
    updateOnboardingSection('createCampaignLink', 'user')
  }, [updateOnboardingSection])

  const inlineErrorsPresent = errors.length !== 0

  const softDisable = useMemo(() => {
    return inlineErrorsPresent || submitDisabled
  }, [inlineErrorsPresent, submitDisabled])

  useEffect(() => {
    if (submitError && errors.length === 0) setSubmitError(false)
  }, [submitError, errors])

  const existingParametersAddedToStart = useMemo(() => {
    return generatedStructure?.existingParametersAddedToStart
  }, [generatedStructure])

  const validationChecks: FullValidationCheck[] | null = useMemo(() => {
    return getValidationChecksObject(generatedStructure)
  }, [generatedStructure])

  const validationChecksLanding = useMemo(() => {
    return getValidationChecksObject(generatedStructure, true)
  }, [generatedStructure])

  // Initial value setter for link type based on validationChecks
  useEffect(() => {
    if (!canUseShortLinks) return

    const foundShortLink = getItemByKeyValue(
      validationChecks,
      'name',
      'FORCE_SHORT_LINK',
    )

    if (
      foundShortLink !== -1 &&
      foundShortLink.enabled &&
      foundShortLink.value
    ) {
      const shortLinkValue = JSON.parse(foundShortLink.value)

      const selectedRule = shortLinkValue.find((option: any) => option.selected)
        .optionValue

      if (selectedRule === 'force-short-links' && linkType === 'full') {
        setLinkType('short')
        return
      }

      if (selectedRule === 'force-long-links' && linkType === 'short') {
        setLinkType('full')
        return
      }

      if (!savedState?.linkType) {
        setLinkType(
          selectedRule === 'recommend-short-links' ||
            selectedRule === 'force-short-links'
            ? 'short'
            : 'full',
        )
        return
      }
    }

    // If rule not found, set based on subscription level
    if (!savedState?.linkType) {
      setLinkType(canUseShortLinks ? 'short' : 'full')
    }
  }, [savedState, generatedStructure, canUseShortLinks])

  // Get short link options
  const {
    hideLongLinkOption,
    hideShortLinkOption,
    showShortLinkOptionFirst,
  } = useMemo(() => {
    if (!canUseShortLinks) {
      return {
        hideLongLinkOption: false,
        hideShortLinkOption: false,
        showShortLinkOptionFirst: false,
      }
    }

    const foundShortLink = getItemByKeyValue(
      validationChecks,
      'name',
      'FORCE_SHORT_LINK',
    )

    if (foundShortLink && foundShortLink.enabled && foundShortLink.value) {
      const shortLinkValue = JSON.parse(foundShortLink.value)

      const selectedRule = shortLinkValue.find((option: any) => option.selected)
        .optionValue

      switch (selectedRule) {
        case 'force-short-links':
          return {
            hideLongLinkOption: true,
            hideShortLinkOption: false,
            showShortLinkOptionFirst: false,
          }
        case 'force-long-links':
          return {
            hideLongLinkOption: false,
            hideShortLinkOption: true,
            showShortLinkOptionFirst: false,
          }
        case 'recommend-short-links':
          return {
            hideLongLinkOption: false,
            hideShortLinkOption: false,
            showShortLinkOptionFirst: true,
          }
        case 'recommend-long-links':
          return {
            hideLongLinkOption: false,
            hideShortLinkOption: false,
            showShortLinkOptionFirst: false,
          }
        default:
          return {
            hideLongLinkOption: false,
            hideShortLinkOption: false,
            showShortLinkOptionFirst: canUseShortLinks,
          }
      }
    }

    return {
      hideLongLinkOption: false,
      hideShortLinkOption: false,
      showShortLinkOptionFirst: canUseShortLinks,
    }
  }, [single, generatedStructure, canUseShortLinks])

  const showShortLinkEdit = useMemo(() => {
    return linkType === 'short' || linkType === 'combined'
  }, [linkType])

  const requireLanding = useMemo(() => {
    return getValidationCheck(validationChecks, 'REQUIRE_LANDING_PAGE')
  }, [validationChecks])

  const landingLengthLimit = useMemo(() => {
    return getValidationCheck(validationChecks, 'LIMIT_URL_LENGTH')
  }, [validationChecks])

  const queryLengthLimit = useMemo(() => {
    return getValidationCheck(validationChecks, 'LIMIT_QUERY_LENGTH')
  }, [validationChecks])

  const showLanding = useMemo(() => {
    return getValidationCheck(validationChecks, 'SHOW_LANDING_PAGE')
  }, [validationChecks])

  const { validateUrls, validationResults } = useUrlValidation()

  // Validate urls that have not already been validated
  useEffect(() => {
    const urls = Object.keys(validationResults)

    const toValidate = url.filter((u) => {
      return u !== '' && isValidUrl(u) && urls.indexOf(u) === -1
    })

    if (toValidate.length > 0) validateUrls(toValidate)
  }, [validationResults, url])

  const updateErrorObject = useCallback(
    (
      key: string,
      isValid: boolean,
      useErrorObject = errors.slice(),
      setErrorValue = true,
    ) => {
      let newError = useErrorObject

      if (isValid) {
        newError = newError.filter((errorKey) => {
          return errorKey !== key
        })
      } else if (key && newError.indexOf(key) === -1) {
        newError.push(key)
      }

      if (setErrorValue) {
        setErrors(newError)
      }

      return newError
    },
    [errors],
  )

  const updateForm = useCallback(
    (name: string, value: string[], useForm = form) => {
      if (
        !useForm ||
        (useForm && !Object.prototype.hasOwnProperty.call(useForm, 'paramDefs'))
      ) {
        return
      }

      let useUrl = url

      if (name === 'url') {
        setUrl([...new Set(value)])
        useUrl = [...new Set(value)]
      }

      const currentItem = getItemByKeyValue(
        useForm.paramDefs,
        'fieldName',
        name,
      )

      const paramDefs = useForm.paramDefs.map((item: any): any => {
        return name === item.fieldName
          ? {
              ...currentItem,
              optionValue: value,
            }
          : item
      })

      const data = {
        ...useForm,
        paramDefs: [...paramDefs],
      }

      const urlValid =
        !requireLanding.enabled ||
        (requireLanding.enabled && useUrl.join('') !== '')

      const disabled = !(urlValid && isAllFormDataValid(data))

      setSubmitDisabled(disabled)

      setForm(data)

      saveFormData({
        url: useUrl,
        form: data,
        currentAccount: workspaceID,
        formType: single ? 'single' : 'multi',
      })
    },
    [url, form, workspaceID, requireLanding, single],
  )

  useEffect(() => {
    if (!generatedStructure) return

    const savedForm = getFormData(
      generatedStructure,
      url,
      workspaceID,
      single ? 'single' : 'multi',
    )

    updateForm('url', savedForm.url, savedForm.form)

    if (savedForm.url.join('').length > 0) validateUrls(savedForm.url)

    setFormKey(nanoid())
  }, [single, generatedStructure])

  const addUplifterIdsToGeneratedCodes = useCallback(
    async (codes: GeneratedCode[]): Promise<GeneratedCode[]> => {
      if (!generatedStructure || !useUplifterID || !uplifterIdData) return codes

      const {
        track: {
          currentSequentialCodeID: { currentTotal, etag, prefix, acctPrefix },
        },
      } = uplifterIdData

      try {
        // Rerun Uplifter ID check until sent etag and currentTotal match new values
        // If checked > 50 times, exit
        const {
          currentTotal: newCurrentTotal,
          updatedTotal,
        } = await loopApiCall(
          async (variables) => {
            const { data: newData } = await checkUplifterID({
              variables,
            })

            return (
              newData?.track.updateCurrentSequentialCodeID || {
                currentTotal: currentTotal || '0',
                etag: '',
                updatedTotal: null,
              }
            )
          },
          // If updatedTotal is null, check failed and must be rerun
          (res) =>
            !!(
              res.etag &&
              res.updatedTotal !== undefined &&
              res.updatedTotal !== null
            ),
          {
            currentTotal: currentTotal || '0',
            etag,
            newTotal: (
              parseInt(currentTotal || '0', 16) + codes.length
            ).toString(16),
          },
          (res) => ({
            currentTotal: res.currentTotal,
            etag: res.etag,
            newTotal: (parseInt(res.currentTotal, 16) + codes.length).toString(
              16,
            ),
          }),
          50,
        )

        if (updatedTotal === null) {
          throw new Error('Reached limit of attempts to get valid Uplifter IDs')
        }

        // Success: Prefix returns null
        const newCodes: GeneratedCode[] = []

        // Add Uplifter ID (before hash) for all codes
        codes.forEach((code, codeIndex) => {
          let fullUplifterID = `${prefix}${acctPrefix}`

          if (codeIndex === 0) {
            fullUplifterID += (parseInt(newCurrentTotal, 16) + 1).toString(16)
          } else {
            fullUplifterID += (
              parseInt(newCurrentTotal, 16) +
              codeIndex +
              1
            ).toString(16)
          }

          const useAnchor = getAnchorFromString(code.code)

          // Add negative lookahead to accomodate hash based routing
          // https://example.com/#/# should not have the first hash removed
          const anchorReplaceRegex = new RegExp(
            `#(?!\/)${useAnchor.replace('#', '')}`,
            'i',
          )

          const useCode = code.code.replace(anchorReplaceRegex, '')
          const useTc = code.tC.replace(anchorReplaceRegex, '')

          newCodes.push({
            ...code,
            code: `${useCode}${fullUplifterID}${useAnchor}`,
            tC: `${useTc}${fullUplifterID}${useAnchor}`,
          })
        })

        return newCodes
      } catch {
        throw new Error('Issue with Uplifter IDs')
      }
    },
    [useUplifterID, uplifterIdData, generatedStructure],
  )

  const multiCodes: GeneratedCode[] = useMemo(() => {
    if (!single && !softDisable) {
      const useUrl = url && url.length > 0 ? url : ['']
      const secureUrls = useUrl.map((link) => makeLinkSecure(link))

      return generateCode(secureUrls, form, !existingParametersAddedToStart)
    }

    return []
  }, [url, form, softDisable, single])

  useEffect(() => {
    setCombinations(multiCodes.length)
  }, [multiCodes])

  const actionAddSingleCode = async (u: null | string[] = null) => {
    setCodeCreatedTimeout(true)

    let useUrl = url && url.length > 0 ? url : ['']

    if (u && u.length > 0) {
      useUrl = u
    }

    const genCodes = generateCode(useUrl, form, !existingParametersAddedToStart)

    try {
      // Get Uplifter IDs to add to end of URLs (before hashes)
      const genCodesWithIDs = await addUplifterIdsToGeneratedCodes(genCodes)

      const useCodesUrl = returnUnique(genCodesWithIDs.map((i) => i.url))

      const lP = useCodesUrl.length > 0 ? useCodesUrl[0] : ''

      const codesToAdd: CodesToAdd = {
        customDomainID: shortLinkDomain,
        lP,
        cL: genCodesWithIDs.map((item) => {
          return {
            fC: item.code, // Full code: URL + params
            lP: item.urlWithHash || item.url || '',
            tC: item.tC.replaceAll(' ', '%20'),
            pDfs: item.pDfs.map((i) => [i[0], i[1].replaceAll(' ', '%20')]),
            shortLinkID:
              linkType === 'short' || linkType === 'combined' ? shortLink : '',
          }
        }),
      }

      await addCode({
        variables: {
          customDomainID: getCustomDomainID(shortLinkDomain),
          codeList: codesToAdd.cL,
        },
      })

      // Refetch totals
      // Posible TODO: Update the cached object's value for currentTotal instead of refetching
      if (
        (!dataSource || dataSource.connectionSource !== 'adobe') &&
        useUplifterID
      ) {
        await getUplifterID()
      }

      if (linkType === 'short' || linkType === 'combined') {
        useAliases([shortLink])
        // Re-render shortLink component so custom alias is replaced with existing generated one
        setShortLinkKey(nanoid())
      }

      // GA Tracking
      // @ts-ignore
      if (window.dataLayer && window.dataLayer.push) {
        // @ts-ignore
        window.dataLayer.push({
          event: 'create_campaign_link',
          link_creation_type:
            linkType === 'short' || linkType === 'combined'
              ? 'add-code-short-link'
              : 'add-code',
          link_count: 1,
        })
      }

      logAction({
        variables: {
          action:
            linkType === 'short' || linkType === 'combined'
              ? 'add-code-short-link'
              : 'add-code',
          functionName: 'addCodes',
          pagePath: '/track/create-links',
          extra: '1',
          websiteSection: 'track',
          getParams: JSON.stringify(lP),
        },
      })

      // Updates the backend cache for onboarding state
      if (!hasCreatedCode) triggerCacheRefresh()

      const useShortLink =
        linkType === 'short' || linkType === 'combined' ? shortLink : ''

      const singleCode =
        useShortLink !== '' ? `${useShortLink}` : genCodesWithIDs[0].code

      if (setNewLinks) {
        const latestCl = codesToAdd.cL[0]

        if (linkType !== 'short') {
          setNewLinks(latestCl.fC)
        } else {
          const domName = availableDomains.find(
            (dom) => dom.optionValue === shortLinkDomain,
          )?.optionName
          setNewLinks(`https://${domName}/${latestCl.shortLinkID}`)
        }
      }

      setCurrentSingleCode(singleCode)

      if (linkType === 'combined') setShowQrModal(true)

      window.setTimeout(() => setCodeCreatedTimeout(false), 800)
    } catch {
      setCreateLinkError(true)
    }
  }

  const onSubmit = async (e: React.SyntheticEvent): Promise<void> => {
    e.preventDefault()

    if (form === null || codeCreatedTimeout) return

    if (softDisable) {
      setSubmitError(true)

      // If URL is empty and required, log action
      if (requireLanding.enabled && url.join('') === '') {
        logAction({
          variables: {
            action: 'track-error-missing-landing-page',
            functionName: 'addCodes',
            pagePath: '/track/create-links',
            websiteSection: 'track',
          },
        })
      }

      const paramErrors = errors.filter((error) => error !== 'landing-page')

      if (paramErrors.length > 0) {
        logAction({
          variables: {
            action: 'track-error-missing-required-field',
            functionName: 'addCodes',
            pagePath: '/track/create-links',
            websiteSection: 'track',
            extra: JSON.stringify(paramErrors),
          },
        })
      }

      return
    }

    setCreateLinkError(false)
    setSubmitError(false)
    setCreateShortLinkError(false)

    if (single) {
      // Check recently created links to confirm alias has not already been used
      if (linkType === 'short' || linkType === 'combined') {
        const { data } = await getUserLinks({
          variables: {
            dimensionFilter: {
              dimensionName: userEmail,
              dimensionParameterID: 'createdBy',
              dimensionOptions: [],
            },
            limit: 1000,
          },
          fetchPolicy: 'cache-only',
        })

        if (data?.minCodesByAccount) {
          const usedAliases = (data.minCodesByAccount.shortLink || []).filter(
            (sL) => sL !== '',
          )

          const shortLinkExists = usedAliases.find(
            (sL) => sL.indexOf(shortLink) > -1,
          )

          if (shortLinkExists) {
            setCreateShortLinkError(true)

            return
          }
        }
      }

      const link = url[0] || ''
      let isValid = isValidUrl(link)

      const anchorPresent = link.match(/#/g)

      const hasAnchor =
        anchorPresent &&
        !(anchorPresent.length === 1 && link.indexOf('/#/') !== -1)

      const useUrl = makeLinkSecure(link)

      const codes = generateCode([useUrl], form)

      const useCodesUrl = returnUnique(codes.map((i) => i.url))

      if (link !== useUrl) {
        updateForm('url', [useUrl])
        isValid = true
      }

      if (!showLanding.enabled) {
        isValid = true
      }

      if (!codes[0]) {
        setCreateLinkError(true)
        return
      }

      const c = codes[0]
      let generatedCode = c.code
      let query = c.code.replace(c.url, '')

      if (uplifterIdData && useUplifterID) {
        const {
          acctPrefix,
          prefix,
          currentTotal,
        } = uplifterIdData.track.currentSequentialCodeID
        generatedCode += `${prefix || ''}${acctPrefix}${currentTotal}`
        query += `${prefix || ''}${acctPrefix}${currentTotal}`
      }

      const urlValidLength = isValidInput(generatedCode, [landingLengthLimit])
      const queryValidLength = isValidInput(query, [queryLengthLimit])

      const isDomain = getDomain(useUrl) !== ''

      setShowPreviewCodes(codes)

      if (
        !hasAnchor &&
        (isDomain || (!showLanding.enabled && linkType !== 'short')) &&
        isValid &&
        urlValidLength &&
        queryValidLength
      ) {
        actionAddSingleCode(useCodesUrl)
      } else {
        setShowWarningModal(true)

        if (!showLanding.enabled && linkType === 'short') {
          setWarningModalType('no-url-shortlink')

          logAction({
            variables: {
              action: 'track-error-missing-landing-page',
              functionName: 'addCodes',
              pagePath: '/track/create-links',
              websiteSection: 'track',
            },
          })
        } else if (!isDomain && showLanding.enabled) {
          setWarningModalType('no-url')

          logAction({
            variables: {
              action: 'track-error-missing-landing-page',
              functionName: 'addCodes',
              pagePath: '/track/create-links',
              websiteSection: 'track',
            },
          })
        } else if (!queryValidLength) {
          setWarningModalType('invalid-query-length')

          const maxLength = parseInt(queryLengthLimit.value || '', 10)

          let queryLength = c.code.replace(c.url, '').length

          if (uplifterIdData && useUplifterID) {
            const {
              acctPrefix,
              prefix,
              currentTotal,
            } = uplifterIdData.track.currentSequentialCodeID

            queryLength +=
              acctPrefix.length + currentTotal.length + (prefix?.length || 0)
          }

          logAction({
            variables: {
              action: 'track-error-landing-query-length',
              functionName: 'addCodes',
              pagePath: '/track/create-links',
              websiteSection: 'track',
              extra: JSON.stringify({
                queryOverLimitBy: queryLength - maxLength,
              }),
            },
          })
        } else if (!urlValidLength) {
          setWarningModalType('invalid-length')

          const maxLength = parseInt(landingLengthLimit.value || '', 10)

          let urlLength = c.code.length

          if (uplifterIdData && useUplifterID) {
            const {
              acctPrefix,
              prefix,
              currentTotal,
            } = uplifterIdData.track.currentSequentialCodeID

            urlLength +=
              acctPrefix.length + currentTotal.length + (prefix?.length || 0)
          }

          logAction({
            variables: {
              action: 'track-error-landing-page-length',
              functionName: 'addCodes',
              pagePath: '/track/create-links',
              websiteSection: 'track',
              extra: JSON.stringify({
                linkOverLimitBy: urlLength - maxLength,
              }),
            },
          })
        } else if (hasAnchor) {
          setWarningModalType('has-anchor')

          logAction({
            variables: {
              action: 'track-error-anchor-warning',
              functionName: 'addCodes',
              pagePath: '/track/create-links',
              websiteSection: 'track',
            },
          })
        } else {
          setWarningModalType('no-url')
        }
      }
    } else {
      const useUrls = url && url.length > 0 ? url : ['']

      const validUrls = useUrls.filter((u) => isValidUrl(u))

      let isValid = validUrls.length === useUrls.length

      // Exclude hash-based routing
      let hasAnchor = false

      useUrls.forEach((link) => {
        if (hasAnchor) return
        const anchorPresent = link.match(/#/g)

        if (
          anchorPresent &&
          !(anchorPresent.length === 1 && link.indexOf('/#/') !== -1)
        ) {
          hasAnchor = true
        }
      })
      if (!showLanding.enabled) {
        isValid = true
      }

      const c = generateCode(useUrls, form)

      const isCorrectDomains =
        useUrls.filter((u) => getDomain(u) !== '').length === useUrls.length

      // TODO: make this check all codes
      let query = c[0].code.replace(c[0].url, '')

      if (uplifterIdData && useUplifterID) {
        const {
          acctPrefix,
          prefix,
          currentTotal,
        } = uplifterIdData.track.currentSequentialCodeID
        query += `${prefix || ''}${acctPrefix}${currentTotal}`
      }

      const queryValidLength = isValidInput(query, [queryLengthLimit])

      const urlsWithIds = c.map((item) => {
        if (!uplifterIdData || !useUplifterID) return item.code

        const {
          acctPrefix,
          prefix,
          currentTotal,
        } = uplifterIdData.track.currentSequentialCodeID

        return `${item.code}${prefix || ''}${acctPrefix}${currentTotal}`
      })

      const urlValidLength = isValidInput(urlsWithIds, [landingLengthLimit])

      setShowPreviewCodes(multiCodes)

      if (
        !hasAnchor &&
        (isCorrectDomains || !showLanding.enabled) &&
        isValid &&
        urlValidLength &&
        queryValidLength
      ) {
        setShowPreviewModal(true)
      } else {
        const invalidLinks = c.filter((item) => isValidUrl(item.urlWithHash))

        setShowWarningModal(true)

        if (!showLanding.enabled && linkType === 'short') {
          setWarningModalType('no-url-shortlink')

          logAction({
            variables: {
              action: 'track-error-missing-landing-page',
              functionName: 'addCodes',
              pagePath: '/track/create-links',
              websiteSection: 'track',
              extra: JSON.stringify({
                totalInvalidLinks: invalidLinks.length,
              }),
            },
          })
        } else if (!isCorrectDomains && showLanding.enabled) {
          setWarningModalType('no-url')

          logAction({
            variables: {
              action: 'track-error-missing-landing-page',
              functionName: 'addCodes',
              pagePath: '/track/create-links',
              websiteSection: 'track',
              extra: JSON.stringify({
                totalInvalidLinks: invalidLinks.length,
              }),
            },
          })
        } else if (!queryValidLength) {
          setWarningModalType('invalid-query-length')

          const maxLength = parseInt(queryLengthLimit.value || '', 10)

          const { length } = multiCodes[0].code.replace(multiCodes[0].url, '')

          logAction({
            variables: {
              action: 'track-error-landing-query-length',
              functionName: 'addCodes',
              pagePath: '/track/create-links',
              websiteSection: 'track',
              extra: JSON.stringify({
                totalInvalidLinks: invalidLinks.length,
                queryOverLimitBy: length - maxLength,
              }),
            },
          })
        } else if (!urlValidLength) {
          setWarningModalType('invalid-length')

          const maxLength = parseInt(landingLengthLimit.value || '', 10)

          const { length } = multiCodes[0].code

          logAction({
            variables: {
              action: 'track-error-landing-page-length',
              functionName: 'addCodes',
              pagePath: '/track/create-links',
              websiteSection: 'track',
              extra: JSON.stringify({
                totalInvalidLinks: invalidLinks.length,
                linkOverLimitBy: length - maxLength,
              }),
            },
          })
        } else if (hasAnchor) {
          setWarningModalType('has-anchor')

          logAction({
            variables: {
              action: 'track-error-anchor-warning',
              functionName: 'addCodes',
              pagePath: '/track/create-links',
              websiteSection: 'track',
              extra: JSON.stringify({
                totalInvalidLinks: invalidLinks.length,
              }),
            },
          })
        } else {
          setWarningModalType('no-url')
        }
      }
    }
  }

  const previewIsDisabled = useMemo(() => {
    const shortLinksToPreview =
      linkType === 'short' || linkType === 'combined'
        ? previewTableShortLinks
        : {}

    const urls = Object.keys(shortLinksToPreview)

    const usePreview =
      previewCodes.length > 0 &&
      (linkType === 'short' || linkType === 'combined')

    const selectedCodes = previewCodes.filter((item) => item.selected)

    const shortLinks = Object.values(previewTableShortLinks)

    return (
      usePreview &&
      (selectedCodes.length === 0 ||
        shortLinkStatus === 'refetching' ||
        urls.length < previewCodes.length ||
        shortLinks.length < selectedCodes.length ||
        shortLinks.findIndex((sL) => sL === '') > -1)
    )
  }, [previewCodes, previewTableShortLinks, linkType, shortLinkStatus])

  const validationMessage = useMemo(() => {
    if (isAdmin) {
      return (
        <>
          Special characters (?=&) are not allowed to avoid users copying
          landing pages with existing parameters.{' '}
          <Link type="arrowForward" href="/track/edit-parameters-and-rules">
            Disable this rule for deep linking
          </Link>
        </>
      )
    }

    return getValidationCheck(
      validationChecksLanding,
      'NO_SPECIAL_CHARS_LANDING_PAGE',
    ).enabled
      ? `Special characters (?=&) are disabled by your admin.`
      : ''
  }, [validationChecksLanding, isAdmin])

  // Tracking
  useEffect(() => {
    if (showWarningModal && previewCodes.length > 0) {
      let desc = {}

      if (
        warningModalType === 'invalid-query-length' ||
        warningModalType === 'invalid-length'
      ) {
        const maxLength =
          warningModalType === 'invalid-length'
            ? landingLengthLimit.value
            : queryLengthLimit.value

        const length =
          warningModalType === 'invalid-length'
            ? previewCodes[0].code.length
            : previewCodes[0].code.replace(previewCodes[0].url, '').length

        desc = {
          maxLength,
          length,
        }
      }

      logAction({
        variables: {
          action: `warning-modal-${warningModalType}`,
          functionName: 'addCodes',
          pagePath: '/track/create-links',
          websiteSection: 'track',
          extra: JSON.stringify(desc),
        },
      })
    }
  }, [showWarningModal, warningModalType])

  // Loading state
  if (
    loading ||
    (dataSource &&
      dataSource.connectionSource !== 'adobe' &&
      !uplifterIdData &&
      !uplifterIdError)
  ) {
    return (
      <OuterBox className={styles.outerBox}>
        <InnerBox className={styles.innerBox}>
          <p className={styles.intro}>
            <span>
              {single
                ? "Enter or select values and then click on 'Create campaign link' button."
                : 'Enter or paste multiple values before approving the link combinations.'}
            </span>
          </p>
          <form>
            {new Array(4).fill(1).map((_, index, arr) => {
              const isLast = index === arr.length - 1

              return (
                <FormRow
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  className={isLast ? styles.lastFormRow : undefined}
                >
                  <LabelSlot>
                    <Loader className={styles.loader} height="35px" />
                  </LabelSlot>
                  <FieldSlot>
                    <Loader
                      className={classNames(styles.loader, {
                        [styles.lastLoader]: isLast,
                      })}
                      height="35px"
                    />
                    {isLast && (
                      <Button isDisabled>Fetching link builder</Button>
                    )}
                  </FieldSlot>
                </FormRow>
              )
            })}
          </form>
        </InnerBox>
      </OuterBox>
    )
  }

  return (
    <>
      <OuterBox className={styles.outerBox} key={formKey}>
        <InnerBox
          className={classNames(styles.innerBox, {
            [styles.singleInnerBox]: single,
          })}
        >
          <p className={styles.intro}>
            <span>
              {single
                ? "Enter or select values and then click on 'Create campaign link' button."
                : 'Enter or paste multiple values before approving the link combinations.'}
            </span>
            {form &&
              ((url.length > 0 && url[0] !== '') ||
                form.paramDefs.find(
                  (param) => param.optionValue && param.optionValue.length > 0,
                )) && (
                <ClearButton
                  onPress={() => {
                    updateForm('url', [''], generatedStructure)
                    setFormKey(nanoid())
                  }}
                >
                  Clear all
                </ClearButton>
              )}
          </p>
          <form onSubmit={onSubmit}>
            {!(
              showLanding !== null &&
              !showLanding.enabled &&
              requireLanding !== null &&
              !requireLanding.enabled
            ) && (
              <InputRow
                id="landing-page"
                name={single ? 'Landing page URL' : 'Landing page URL'}
                className={classNames(styles.rowWrapper, styles.rowWrapperUrl)}
                single={single}
                defaultError={!!(errors.indexOf('landing-page') !== -1)}
                delay={800}
                submitOnEnter={!softDisable}
                optional={requireLanding && !requireLanding.enabled}
                tooltip="This is the page your user will land on once they click on your campaign link. Make sure your link does not already contain a campaign code."
                value={url}
                validation={validationChecksLanding}
                validationMessage={validationMessage}
                onValidation={(valid) =>
                  updateErrorObject('landing-page', valid)
                }
                updateForm={(value: string[]): void => updateForm('url', value)}
                onInputBlur={(currentUrl: string[]) => {
                  if (
                    isValidUrl(currentUrl[0]) &&
                    !Object.prototype.hasOwnProperty.call(
                      validationResults,
                      currentUrl[0],
                    )
                  ) {
                    validateUrls(currentUrl)
                  }
                }}
                // Highlights good links on hover
                onSetTagStatus={(currentUrl) => {
                  const validationDetails = recentlyValidatedLinks[currentUrl]

                  if (!validationDetails) return 'invalid'

                  const { statusCode } = validationDetails

                  if (statusCode === undefined) return 'validating'

                  if (statusCode >= 200 && statusCode <= 299) {
                    return 'valid'
                  }

                  return 'invalid'
                }}
              >
                {url.map((u) => {
                  if (!isValidUrl(u)) return null

                  return (
                    <UrlValidationMessage
                      key={u}
                      url={u}
                      validationDetails={validationResults[u]}
                    />
                  )
                })}
              </InputRow>
            )}
            {form !== null && (
              <GeneratorFormFields
                isAdmin={isAdmin}
                single={single}
                form={form}
                validationChecks={validationChecks}
                errors={errors}
                setErrors={setErrors}
                updateForm={updateForm}
                updateErrorObject={updateErrorObject}
                onRequestNewField={onRequestNewField}
                submitOnEnter={!softDisable}
              />
            )}
            {!hideLongLinkOption && !hideShortLinkOption && (
              <FormRow
                className={classNames(styles.noPadding, {
                  [styles.rowNoBorder]: linkType === 'full',
                })}
              >
                <LabelSlot className={styles.labelSlot}>
                  <Label id="link-type">
                    <Tooltip
                      id="link-type-tooltip"
                      useIcon
                      maxWidth={350}
                      tooltipPosition="right"
                      tooltipMessage={messages.linkTypes}
                    >
                      Link type
                    </Tooltip>
                  </Label>
                </LabelSlot>
                <FieldSlot>
                  <div className={styles.inlineWrap}>
                    <div className={styles.radioPanel}>
                      {!showShortLinkOptionFirst && (
                        <Input
                          type="radio"
                          id="link-type-2"
                          name="link-type"
                          label={
                            <>
                              Basic link{' '}
                              {canUseShortLinks && (
                                <span className={styles.recommended}>
                                  (recommended)
                                </span>
                              )}
                            </>
                          }
                          checked={linkType === 'full'}
                          onClick={() => {
                            const val = 'full'
                            setLinkType(val)
                            saveUserData({
                              linkType: val,
                            })
                          }}
                        />
                      )}
                      <Input
                        type="radio"
                        id="link-type-1"
                        name="link-type"
                        disabled={!canUseShortLinks}
                        label={
                          <>
                            Short link{' '}
                            {(showShortLinkOptionFirst ||
                              !canUseShortLinks) && (
                              <span className={styles.recommended}>
                                (recommended)
                              </span>
                            )}
                          </>
                        }
                        checked={
                          linkType === 'short' || linkType === 'combined'
                        }
                        onClick={() => {
                          if (!canUseShortLinks) {
                            // Disabled for unpaid accounts - show modal
                            // @ts-ignore
                            if (window.dataLayer && window.dataLayer.push) {
                              // @ts-ignore
                              window.dataLayer.push({
                                event: 'click-shortlink-upgrade-blocker',
                              })
                            }

                            logAction({
                              variables: {
                                action: 'click-shortlink-upgrade-blocker',
                                websiteSection: 'track',
                                pagePath: window.location.pathname,
                                functionName: 'clickUpgrade',
                                extra: 'form',
                              },
                            })

                            setShowShortLinkModal(true)

                            return
                          }

                          const val = 'short'
                          setLinkType(val)
                          saveUserData({
                            linkType: val,
                          })
                        }}
                      />
                      {showShortLinkOptionFirst && (
                        <Input
                          type="radio"
                          id="link-type-2"
                          name="link-type"
                          label="Basic link"
                          checked={linkType === 'full'}
                          onClick={() => {
                            const val = 'full'
                            setLinkType(val)
                            saveUserData({
                              linkType: val,
                            })
                          }}
                        />
                      )}
                    </div>
                  </div>
                </FieldSlot>
              </FormRow>
            )}
            <FormRow
              className={classNames(styles.noPadding, styles.lastFormRow)}
            >
              <LabelSlot className={styles.labelSlot}>
                {showShortLinkEdit && (
                  <>
                    <Label id="link-type">
                      <Tooltip
                        id="short-link-edit-tooltip"
                        useIcon
                        tooltipPosition="right"
                        tooltipMessage={
                          single
                            ? messages.customiseShortLink
                            : messages.selectDomain
                        }
                      >
                        {single ? 'Customise short link' : 'Short link domain'}
                      </Tooltip>
                    </Label>
                  </>
                )}
              </LabelSlot>

              <FieldSlot>
                {showShortLinkEdit && (
                  <div className={styles.linkInputWrapper}>
                    <ShortLinkFull
                      shortLinkKey={shortLinkKey}
                      domainSelectorOnly={!single}
                      onDomainChange={(domain) => setShortLinkDomain(domain)}
                      onAliasChange={(alias) => {
                        setShortLink(alias)
                        setCreateShortLinkError(false)
                      }}
                      onStatusChange={(status) => setShortLinkStatus(status)}
                    >
                      <>
                        {single && (
                          <BiLine
                            arrowTop
                            className={styles.editableLinkBiLine}
                          >
                            Make me <strong>memorable</strong>!
                          </BiLine>
                        )}
                      </>
                    </ShortLinkFull>
                  </div>
                )}
                {!single &&
                  combinations > 1 &&
                  combinations <= maxBatchShortLinks &&
                  !softDisable && (
                    <p className={styles.summaryPar}>
                      {numeral(combinations).format('0,0')} valid combinations
                      for you to review.
                    </p>
                  )}
                <Button
                  type="submit"
                  className={classNames({
                    [styles.softDisableButton]:
                      !addingCodes &&
                      !checkingUplifterIDs &&
                      (softDisable || codeCreatedTimeout),
                  })}
                  loading={addingCodes || checkingUplifterIDs}
                  // If shortLink is '', adding the code still works but creates a long link
                  // This causes bugs, so the below disabled state blocks it
                  isDisabled={
                    (!single && combinations === 0) ||
                    combinations >= maxBatchShortLinks ||
                    addingCodes ||
                    checkingUplifterIDs ||
                    !!uplifterIdError ||
                    fetchingAliases ||
                    ['invalid', 'refetching', 'validating'].indexOf(
                      shortLinkStatus,
                    ) > -1 ||
                    ((linkType === 'short' || linkType === 'combined') &&
                      ((single &&
                        (shortLink === '' || shortLinkDomain === '')) ||
                        (!single && shortLinkDomain === '')))
                  }
                  onPress={async () => {
                    if (softDisable) {
                      let newErrors = errors.slice()

                      // Check for empty fields
                      if (
                        requireLanding &&
                        requireLanding.enabled &&
                        (url.join('') === '' ||
                          newErrors.indexOf('landing-page') > -1)
                      ) {
                        newErrors = updateErrorObject(
                          'landing-page',
                          false,
                          newErrors,
                          false,
                        )
                      } else {
                        newErrors = updateErrorObject(
                          'landing-page',
                          true,
                          newErrors,
                          false,
                        )
                      }

                      if (form !== null && form.paramDefs.length > 0) {
                        form.paramDefs.forEach((item) => {
                          const {
                            fieldID,
                            required,
                            fieldAvailable,
                            optionValue,
                          } = item

                          const valueNotSet =
                            typeof optionValue === 'undefined' ||
                            (Array.isArray(optionValue) &&
                              optionValue.join('') === '')

                          if (required && fieldAvailable && valueNotSet) {
                            newErrors = updateErrorObject(
                              fieldID,
                              false,
                              newErrors,
                              false,
                            )
                          } else {
                            newErrors = updateErrorObject(
                              fieldID,
                              true,
                              newErrors,
                              false,
                            )
                          }
                        })
                      }

                      setErrors(newErrors)

                      logAction({
                        variables: {
                          action: 'failed-add-code',
                          functionName: 'addCodes',
                          pagePath: '/track/create-links',
                          extra: JSON.stringify({
                            ...form,
                            url: [...url],
                          }),
                          websiteSection: 'track',
                        },
                      })
                    }
                  }}
                >
                  {single ? 'Create campaign link' : 'Review links'}
                </Button>
                {uplifterIdError && (
                  <p className={styles.footNoteError}>
                    Error generating a unique link ID. Please reload the page
                    and try again, or contact{' '}
                    <Link href={supportEmail}>{supportEmail}</Link>.
                  </p>
                )}
                {createLinkError && (
                  <p className={styles.footNoteError}>
                    Error creating link, please refresh and try again.
                  </p>
                )}
                {submitError && (
                  <p className={styles.footNoteError}>
                    Please have a value for every required parameter.
                  </p>
                )}
                {createShortLinkError && (
                  <p className={styles.footNoteError}>
                    There was an error creating your short link. Please refresh
                    it and try again.
                  </p>
                )}
                {combinations > maxBatchShortLinks && (
                  <p className={styles.footNoteError}>
                    You are trying to create {combinations.toString()} links.
                    Please reduce this to {maxBatchShortLinks.toString()} or
                    less.
                  </p>
                )}
              </FieldSlot>
            </FormRow>
          </form>
        </InnerBox>
      </OuterBox>
      {showShortLinkModal && (
        <RequestShortLinksModal onHideModal={setShowShortLinkModal} />
      )}
      {showQrModal && currentSingleCode !== '' && (
        <QRCodeModal code={currentSingleCode} setShowModal={setShowQrModal} />
      )}
      {showWarningModal && previewCodes.length > 0 && (
        <WarningModal
          url={url}
          single={single}
          type={warningModalType}
          maxLength={
            warningModalType === 'invalid-length'
              ? parseInt(landingLengthLimit.value || '1024', 10)
              : parseInt(queryLengthLimit.value || '255', 10)
          }
          length={
            warningModalType === 'invalid-length'
              ? previewCodes[0].code.length
              : previewCodes[0].code.replace(previewCodes[0].url, '').length
          }
          onYes={() => {
            setShowWarningModal(false)
            if (single) {
              actionAddSingleCode()
            } else {
              setShowPreviewModal(true)
            }
          }}
          onNo={setShowWarningModal}
          uplifterIdData={uplifterIdData}
        />
      )}
      {showPreviewModal && previewCodes.length > 0 && (
        <Modal
          setIsOpen={setShowPreviewModal}
          width="superWide"
          modalHeader="Review campaign links"
          yesButtonDisabled={previewIsDisabled}
          yesButtonLoading={addingCodes || checkingUplifterIDs}
          yesText="Create campaign links"
          onYes={async () => {
            try {
              const shortLinksToPreview =
                linkType === 'short' || linkType === 'combined'
                  ? previewTableShortLinks
                  : {}

              const useCodesUrl: string[] = returnUnique(
                previewCodes.map((i) => i.url),
              )

              const previewCodesWithUplifterIds = await addUplifterIdsToGeneratedCodes(
                previewCodes,
              )

              const lpCodesToAdd = previewCodesWithUplifterIds
                .filter((item) => item !== null && item.selected)
                .map((previewCodeWithUplifterId, codeIndex) => {
                  return {
                    fC: previewCodeWithUplifterId.code, // Full code: URL + params
                    lP:
                      previewCodeWithUplifterId.urlWithHash ||
                      previewCodeWithUplifterId.url ||
                      '',
                    tC: previewCodeWithUplifterId.tC.replaceAll(' ', '%20'),
                    pDfs: previewCodeWithUplifterId.pDfs.map((i) => [
                      i[0],
                      i[1].replaceAll(' ', '%20'),
                    ]),
                    // Ensure object check does not include Uplifter ID
                    // Uplifter ID is added after shortLinksToPreview object is updated
                    // So its keys don't include the Uplifter ID
                    shortLinkID:
                      previewCodes[codeIndex] &&
                      Object.prototype.hasOwnProperty.call(
                        shortLinksToPreview,
                        previewCodes[codeIndex].code,
                      ) &&
                      !!shortLinksToPreview[previewCodes[codeIndex].code] &&
                      previewCodesWithUplifterIds.length < minBatchShortLinks
                        ? (shortLinksToPreview[
                            previewCodes[codeIndex].code
                          ] as string)
                        : '',
                  }
                })

              const countCodes = lpCodesToAdd.length

              await addCode({
                variables: {
                  customDomainID: getCustomDomainID(shortLinkDomain),
                  codeList: lpCodesToAdd,
                  bulkStart:
                    previewCodesWithUplifterIds.length >= minBatchShortLinks
                      ? Object.values(shortLinksToPreview)[0]
                      : undefined,
                },
              })

              // Refetch totals
              // Posible TODO: Update the cached object's value for currentTotal instead of refetching
              if (
                (!dataSource || dataSource.connectionSource !== 'adobe') &&
                useUplifterID
              ) {
                await getUplifterID()
              }

              // GA Tracking
              // @ts-ignore
              if (window.dataLayer && window.dataLayer.push) {
                // @ts-ignore
                window.dataLayer.push({
                  event: 'create_campaign_link',
                  link_creation_type:
                    linkType === 'short' || linkType === 'combined'
                      ? 'add-multiple-codes-short-link'
                      : 'add-multiple-codes',
                  link_count: countCodes,
                })
              }

              logAction({
                variables: {
                  action:
                    linkType === 'short' || linkType === 'combined'
                      ? 'add-multiple-codes-short-link'
                      : 'add-multiple-codes',
                  functionName: 'addCodes',
                  pagePath: '/track/create-links',
                  extra: countCodes.toString(),
                  websiteSection: 'track',
                  getParams: JSON.stringify(useCodesUrl),
                },
              })

              // Updates the backend cache for onboarding state
              if (!hasCreatedCode) triggerCacheRefresh()

              if (linkType === 'short' || linkType === 'combined') {
                const removeShortLinks = [
                  ...new Set(Object.values(shortLinksToPreview)),
                ]

                useAliases(removeShortLinks)

                setPreviewTableShortLinks({})
              }

              const filteredCodes = previewCodesWithUplifterIds
                .map((item) => {
                  if (!item.selected) {
                    return null
                  }
                  return item
                })
                .filter((item) => item !== null)

              if (setNewLinks) {
                setNewLinks(
                  (filteredCodes as GeneratedCode[]).map(({ code }) => code),
                )
              }

              setShowPreviewModal(false)
            } catch {
              setCreateLinkError(true)
            }
          }}
          footerContent={
            createLinkError ? (
              <p className={styles.footNoteError}>
                Error creating links, please refresh and try again.
              </p>
            ) : (
              <CodeSummary previewCodes={previewCodes} />
            )
          }
        >
          <div className={styles.modalBody}>
            <p>
              Untick any combinations you don't need for your campaign and then
              click 'Create campaign links'.
            </p>
            <CampaignCodeCreatePreviewTable
              form={form}
              codes={previewCodes}
              setShowPreviewCodes={setShowPreviewCodes}
              url={url}
              linkType={linkType}
              previewTableShortLinks={previewTableShortLinks}
              setPreviewTableShortLinks={setPreviewTableShortLinks}
              setShortLinkStatus={setShortLinkStatus}
            />
          </div>
        </Modal>
      )}
    </>
  )
}

const CampaignCodeGeneratorForm = React.memo(_CampaignCodeGeneratorForm)

export default CampaignCodeGeneratorForm
