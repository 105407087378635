import React from 'react'
import { useDropzone } from 'react-dropzone'
import classNames from 'classnames'

import Button from './button'
import { ButtonRow } from './button-row'
import { Preloader } from './loader'
import { SuccessText } from './typography'
import styles from '../styles/file-drag-and-drop.module.scss'

interface Props {
  onDrop: (acceptedFiles: File[]) => void
  inProgress: boolean
  success?: string | React.ReactElement
  error?: string | React.ReactElement
  className?: string
  uploadButtonText?: string
  disabled?: boolean
}

export default function FileDragAndDrop({
  onDrop,
  inProgress,
  success = '',
  error = '',
  className,
  uploadButtonText = 'Upload file',
  disabled = false,
}: Props): React.ReactElement {
  const {
    getRootProps,
    getInputProps,
    open,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop, maxFiles: 1, disabled })

  const dropZoneClassNames = classNames(styles.dropZone, {
    [styles.dropZoneActive]: isDragActive,
    [styles.dropZoneAccept]: isDragAccept,
    [styles.dropZoneReject]: isDragReject,
  })

  return (
    <>
      <div className={className}>
        {!inProgress && (
          <div className={classNames(styles.dropZoneWrapper)}>
            <div {...getRootProps({ className: dropZoneClassNames })}>
              <input {...getInputProps()} />
              <ButtonRow className={styles.buttonRow}>
                {!isDragActive && (
                  <>
                    <p>Drag &amp; drop or </p>
                    <Button onPress={open}>{uploadButtonText}</Button>
                  </>
                )}
                {isDragActive && !isDragReject && <p>Drop your file here...</p>}
                {isDragActive && isDragReject && (
                  <p>Sorry, we don't support multiple files...</p>
                )}
              </ButtonRow>
            </div>
          </div>
        )}

        {inProgress && (
          <div className={classNames(styles.dropZoneWrapper)}>
            <div className={styles.dropZone}>
              <Preloader />
            </div>
          </div>
        )}
      </div>
      {success && (
        <SuccessText className={styles.success}>{success}</SuccessText>
      )}
      {error && <p className={styles.error}>{error}</p>}
    </>
  )
}
