import React, { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import { Redirect } from 'react-router-dom'

import {
  handleAdobeOauthResponse,
  handleGoogleOauthResponse,
} from '../api/graphql/oauth-client'
import { LoadingLogo } from '../components/loader'
import { getUrlQuery } from '../helpers'

interface LocationState {
  connectorID: string
  code: string | null
  state: string | null
  credentials?: string
  reconnect?: boolean
  switchToWorkspace?: string
  error?: string
}

interface OAuthRedirectProps {
  connectorID: string
}

export const OAuthRedirect = ({ connectorID }: OAuthRedirectProps) => {
  const params = getUrlQuery()

  const code = params.get('code')
  const state = params.get('state')
  const error = params.get('error')

  const [handleGoogleResponse] = useMutation(handleGoogleOauthResponse)
  const [handleAdobeResponse] = useMutation(handleAdobeOauthResponse)

  // GA and AA require additional handling before being redirected to connect page
  const [resolved, setResolved] = useState(
    ['googleAnalytics', 'adobeAnalytics'].indexOf(connectorID) === -1,
  )
  const [locationState, setLocationState] = useState<LocationState | undefined>(
    code ? { connectorID, code, state } : undefined,
  )

  // If GA or AA, run response handler function
  useEffect(() => {
    if (error) {
      setLocationState({
        connectorID,
        code: null,
        state: null,
        error,
      })

      setResolved(true)

      return
    }

    if (
      !connectorID ||
      !locationState ||
      !code ||
      !state ||
      ['googleAnalytics', 'adobeAnalytics'].indexOf(connectorID) === -1
    ) {
      return
    }

    const handleAnalyticsResponse = async () => {
      const { data } =
        connectorID === 'googleAnalytics'
          ? await handleGoogleResponse({
              variables: {
                code,
                state,
              },
            })
          : await handleAdobeResponse({ variables: { code, state } })

      if (data) {
        const parsedState = JSON.parse(
          data.userAccountSettings[
            connectorID === 'googleAnalytics'
              ? 'googleAnalyticsMutations'
              : 'adobeAnalyticsMutations'
          ][
            connectorID === 'googleAnalytics'
              ? 'handleGAAuthCallback'
              : 'handleAAAuthCallback'
          ].state,
        )
        const currentWorkspace = parsedState.fromId
        const workspaceToConnect = parsedState.connectId

        const { reconnect } = parsedState

        setLocationState({
          ...locationState,
          reconnect,
          credentials:
            data.userAccountSettings[
              connectorID === 'googleAnalytics'
                ? 'googleAnalyticsMutations'
                : 'adobeAnalyticsMutations'
            ][
              connectorID === 'googleAnalytics'
                ? 'handleGAAuthCallback'
                : 'handleAAAuthCallback'
            ].creds,
          switchToWorkspace:
            currentWorkspace !== workspaceToConnect
              ? workspaceToConnect
              : undefined,
        })
      }

      setResolved(true)
    }

    handleAnalyticsResponse()
  }, [])

  if (resolved) {
    return (
      <Redirect
        to={{
          pathname: 'connect',
          state: locationState,
        }}
      />
    )
  }

  return <LoadingLogo fullScreen />
}
