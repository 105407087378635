import React, { useState, useMemo, useCallback } from 'react'
import { useMutation, useQuery, useReactiveVar } from '@apollo/client'
import classNames from 'classnames'

import AddWorkspaceModal from './add-workspace-modal'
import Button from './button'
import { ConnectAnalyticsModal } from './connect-analytics-modal'
import DeleteButtonWithConfirmation from './delete-button-with-confirmation'
import Input from './input'
import Link from './link'
import { Preloader } from './loader'
import Row, { FieldSlot, Slot } from './row'
import Tooltip from './tooltip'
import { RequestMoreWorkspacesModal } from './upgrade-modals'
import { currentUserDetails } from '../api/apollo/variables'
import { getCompanyDetails } from '../api/graphql/company-client'
import { getUserAccounts } from '../api/graphql/user-client'
import {
  deleteWorkspace,
  getCompanyWorkspacesDataSources,
} from '../api/graphql/workspace-client'
import { accountTypes, supportEmail } from '../core/constants'
import { isAdminUser, isSupportUser, setToken } from '../helpers'
import useLogAction from '../hooks/useLogAction'
import useSubscriptionLevel from '../hooks/useSubscriptionLevel'
import integrationsData, { isGenericConnector } from '../integrations/data'
import { teamTierMaxWorkspaces } from '../static-copy/subscription-features'
import styles from '../styles/manage-workspaces.module.scss'

interface ManageWorkspacesProps {
  className?: string
}

export default function ManageWorkspaces({ className }: ManageWorkspacesProps) {
  const { workspaceID, userPermission } = useReactiveVar(currentUserDetails)

  const logAction = useLogAction()

  const {
    loading: loadingSubscriptionLevel,
    isTeam,
    isEnterprise,
  } = useSubscriptionLevel()

  const { data: companyData } = useQuery(getCompanyDetails)
  const { data: dataSourcesData, loading: loadingDataSources } = useQuery(
    getCompanyWorkspacesDataSources,
    {
      notifyOnNetworkStatusChange: true,
    },
  )
  const { data: accountProfilesData } = useQuery(getUserAccounts)

  const [removeWorkspace, { loading: removingWorkspace }] = useMutation(
    deleteWorkspace,
    {
      refetchQueries: [getCompanyWorkspacesDataSources, getUserAccounts],
    },
  )

  const [addNew, setAddNew] = useState(false)
  const [connectId, setConnectId] = useState('')
  const [connectModal, setConnectModal] = useState(false)
  const [showWorkspaceUpgradeModal, setShowWorkspaceUpgradeModal] = useState(
    false,
  )

  const workspaceCount = useMemo(() => {
    return companyData?.currentCompany.accountCount || 0
  }, [companyData])

  const maxWorkspaces = useMemo(() => {
    if (isEnterprise || loadingSubscriptionLevel) return Infinity

    return isTeam ? teamTierMaxWorkspaces : 0
  }, [isTeam, isEnterprise, loadingSubscriptionLevel])

  const canAddWorkspaces = useMemo(() => {
    return isEnterprise || workspaceCount < maxWorkspaces
  }, [isEnterprise, workspaceCount, maxWorkspaces])

  const validWorkspacesDataSources = useMemo(() => {
    if (!dataSourcesData || !accountProfilesData) return []

    // Only show workspaces for which the current user is an admin
    const userAdminWorkspaces = accountProfilesData.currentUser.userAccountProfiles
      .filter((account) => isAdminUser(account.userPermission))
      .map(({ accountID }) => accountID)

    return dataSourcesData.currentCompany.accountList.filter((account) => {
      return userAdminWorkspaces.indexOf(account.accountID) > -1
    })
  }, [accountProfilesData, dataSourcesData])

  const getIconPath = useCallback((homepage: string | null): string => {
    return homepage
      ? `https://www.google.com/s2/favicons?domain=${homepage}`
      : `/favicon.png`
  }, [])

  return (
    <>
      <div className={className}>
        <div className={styles.wrapper}>
          <Row className={styles.header}>
            <Slot align="flex-start" vAlign="center">
              Workspace
            </Slot>
            <Slot align="center" vAlign="center" width={250}>
              Analytics
            </Slot>
            <Slot align="center" vAlign="center" width={224}>
              Actions
            </Slot>
          </Row>

          <div className={styles.body}>
            {loadingDataSources || removingWorkspace ? (
              <>
                <Preloader
                  style={{
                    width: 30,
                    height: 20,
                    margin: '32px auto',
                  }}
                />
              </>
            ) : (
              <>
                {validWorkspacesDataSources.map(
                  ({ accountID, accountName, homepage, dataSource }, index) => {
                    let reportSourceName = ''
                    let reportSourceID = ''

                    if (dataSource && accountTypes[dataSource.kind]) {
                      switch (true) {
                        case !!dataSource.adobeReportSuiteName:
                          reportSourceName = `Report suite: ${dataSource.adobeReportSuiteName}`
                          reportSourceID = dataSource.adobeReportID || ''
                          break
                        case dataSource.kind === 'GA4_PROP' &&
                          !!dataSource.ga4PropertyName:
                          reportSourceName = `Property: ${dataSource.ga4PropertyName}`
                          reportSourceID = dataSource.ga4PropertyID || ''
                          break
                        case !!dataSource.gaPropertyName:
                          reportSourceName = `Property: ${dataSource.gaPropertyName} - View ${dataSource.gaViewName}`
                          reportSourceID = dataSource.gaPropertyID || ''
                          break
                        default:
                          break
                      }
                    }

                    return (
                      <Row
                        key={accountID}
                        className={styles.bodyItem}
                        vAlign="center"
                        align="flex-start"
                      >
                        <Slot
                          align="flex-start"
                          vAlign="center"
                          className={classNames(
                            styles.bodyItemSlot,
                            styles.homepageSlot,
                          )}
                          padding="0 20px 0 0"
                        >
                          <img
                            src={getIconPath(homepage)}
                            alt="favicon"
                            className={styles.icon}
                          />
                          <div>
                            <h3
                              style={{
                                marginBottom: 4,
                              }}
                            >
                              {accountName}{' '}
                              {workspaceID === accountID && (
                                <span className={styles.currentWorkspace}>
                                  (current){' '}
                                </span>
                              )}
                            </h3>
                            {homepage && (
                              <p className={styles.bodyItemP}>{homepage}</p>
                            )}
                          </div>
                        </Slot>
                        <Slot
                          align="flex-start"
                          flex-start
                          vAlign="center"
                          width={250}
                          className={styles.bodyItemSlot}
                        >
                          <p
                            className={styles.bodyItemP}
                            style={{
                              textAlign: 'center',
                            }}
                          >
                            {dataSource && accountTypes[dataSource.kind] ? (
                              <>
                                <p style={{ marginBottom: 4 }}>
                                  {accountTypes[dataSource.kind].short}
                                </p>
                                {reportSourceName && (
                                  <p
                                    style={{
                                      marginBottom: 0,
                                      lineHeight: 'normal',
                                    }}
                                    className={styles.bodyItemP}
                                  >
                                    <Tooltip
                                      className={styles.tooltip}
                                      id={`${accountID}-reportSource`}
                                      tooltipMessage={reportSourceID}
                                    >
                                      {reportSourceName}
                                    </Tooltip>
                                  </p>
                                )}
                              </>
                            ) : (
                              'Not connected'
                            )}
                          </p>
                        </Slot>
                        <Slot
                          className={styles.bodyItemSlot}
                          align="flex-start"
                          vAlign={
                            dataSource &&
                            typeof dataSource.connected !== 'undefined'
                              ? 'center'
                              : 'flex-start'
                          }
                          width={160}
                        >
                          {dataSource?.kind === 'BLANK' ? (
                            <Button
                              onPress={() => {
                                setConnectId(accountID)
                                setConnectModal(true)
                              }}
                            >
                              Connect
                            </Button>
                          ) : (
                            <>
                              {dataSource?.connected !== undefined ? (
                                <button
                                  className={
                                    dataSource.connected
                                      ? styles.connectedActive
                                      : styles.connectedInactive
                                  }
                                  onClick={async () => {
                                    try {
                                      if (!dataSource.connected) {
                                        const connector = integrationsData.find(
                                          (integration) =>
                                            integration.connectorID ===
                                            (dataSource.kind === 'AA_REPORT'
                                              ? 'adobeAnalytics'
                                              : 'googleAnalytics'),
                                        )

                                        if (
                                          !connector ||
                                          !isGenericConnector(
                                            connector.connectButton,
                                          ) ||
                                          !connector.connectButton.reconnectFn
                                        )
                                          throw new Error('Unable to connect')

                                        await connector.connectButton.reconnectFn(
                                          connectId,
                                        )
                                      }
                                    } catch {
                                      console.error(
                                        'Failed to reconnect account',
                                      )
                                    }
                                  }}
                                >
                                  {dataSource.connected
                                    ? 'Connected'
                                    : 'Reconnect'}
                                </button>
                              ) : (
                                <Preloader
                                  style={{
                                    width: 30,
                                    height: 20,
                                    margin: 0,
                                  }}
                                />
                              )}
                            </>
                          )}
                        </Slot>
                        <Slot
                          className={styles.bodyItemSlot}
                          align="center"
                          vAlign="center"
                          width={64}
                        >
                          {/* 
                            There must be >1 workspaces in the company to delete
                            And only support users can delete workspaces in Enterprise companies
                          */}
                          {validWorkspacesDataSources.length > 1 &&
                            (!isEnterprise ||
                              (isEnterprise &&
                                isSupportUser(userPermission))) && (
                              <DeleteButtonWithConfirmation
                                odd={index % 2 === 1}
                                onClick={async () => {
                                  const { data } = await removeWorkspace({
                                    variables: {
                                      workspaceID: accountID,
                                    },
                                  })

                                  if (data) {
                                    const {
                                      newToken,
                                    } = data.userAccountSettings.deleteAccount

                                    // A new token is provided when the user's current workspace is deleted
                                    if (newToken) {
                                      setToken(newToken)
                                      window.location.reload()
                                    }
                                  }
                                }}
                              >
                                <p>
                                  Are you sure you want to delete this
                                  workspace?
                                </p>
                              </DeleteButtonWithConfirmation>
                            )}
                        </Slot>
                      </Row>
                    )
                  },
                )}
              </>
            )}
          </div>
          <Row className={styles.footer} align="flex-end" vAlign="center">
            <Slot align="flex-end" vAlign="center">
              <Button
                onPress={() => {
                  if (canAddWorkspaces) {
                    setAddNew((s) => !s)
                  } else {
                    // @ts-ignore
                    if (window.dataLayer && window.dataLayer.push) {
                      // @ts-ignore
                      window.dataLayer.push({
                        event: 'click-add-workspaces-upgrade-blocker',
                        is_paddle: isTeam,
                      })
                    }

                    logAction({
                      variables: {
                        action: 'click-add-workspaces-upgrade-blocker',
                        websiteSection: 'settings',
                        pagePath: '/settings',
                        functionName: 'clickUpgrade',
                        extra: JSON.stringify({ isTeam }),
                      },
                    })

                    setShowWorkspaceUpgradeModal(true)
                  }
                }}
              >
                Create new workspace
              </Button>
            </Slot>
          </Row>
        </div>
        <Row align="flex-start" className={styles.formRow}>
          <FieldSlot>
            <Input
              type="checkbox"
              name="sync-company-generators"
              id="sync-company-generators"
              className={styles.checkboxItem}
              disabled
              checked={false}
              label="Sync link taxonomy, parameters, dropdowns and rules"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                console.log('TBC')
              }}
            >
              <Tooltip
                id="sync-company-generators"
                useIcon
                tooltipMessage={
                  <p>
                    Email{' '}
                    <Link
                      className={styles.supportLink}
                      href={`mailto:${supportEmail}`}
                    >
                      {supportEmail}
                    </Link>{' '}
                    to use one link generator with the same taxonomy,
                    parameters, dropdowns and rules across workspaces.
                  </p>
                }
              />
            </Input>
          </FieldSlot>
        </Row>
      </div>
      {addNew && <AddWorkspaceModal closeModal={setAddNew} />}
      {connectModal && (
        <ConnectAnalyticsModal
          toggleModal={setConnectModal}
          connectId={connectId}
        />
      )}
      {showWorkspaceUpgradeModal && (
        <RequestMoreWorkspacesModal
          onHideModal={setShowWorkspaceUpgradeModal}
        />
      )}
    </>
  )
}
