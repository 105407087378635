import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { nanoid } from 'nanoid'

import Button from './button'
import ButtonDropdown, { DropdownButtonItem } from './button-dropdown'
import { LoginState } from '../api/apollo/variables'
import {
  OktaDomain,
  createGoogleLoginLink,
  createO365LoginLink,
  getOktaDomainsUnauth,
} from '../api/REST/auth-client'
import GoogleIcon from '../assets/logos/icon-google.svg'
import MicrosoftIcon from '../assets/logos/icon-microsoft.svg'
import OktaIcon from '../assets/logos/icon-okta.png'
import { setLocalItem } from '../helpers/local-client'
import styles from '../styles/sso-buttons.module.scss'

interface SSOProps {
  login: LoginState
  state?: any
  action?: 'login' | 'register' | 'create'
}

export const GoogleSSO = ({
  login,
  state = nanoid(),
  action = 'login',
}: SSOProps) => {
  const { email } = login

  return (
    <Button
      variant="plainBox"
      icon={{
        src: GoogleIcon,
        alt: 'Google',
      }}
      className={styles.loginButton}
      onPress={async () => {
        const nonce = nanoid()

        setLocalItem('ga-nonce', nonce)
        setLocalItem('ga-state', state)
        setLocalItem('ga-action', action)

        const response = await createGoogleLoginLink({
          email,
          nonce,
          state: nanoid(),
        })

        if (response && response.success) {
          const {
            res: { data },
          } = response
          const { authLink } = data

          window.location.assign(authLink)
        }
      }}
    >
      {action === 'login' ? 'Login' : 'Sign up'} with Google
    </Button>
  )
}

export const MicrosoftSSO = ({
  login,
  state = nanoid(),
  action = 'login',
}: SSOProps) => {
  const { email } = login

  return (
    <Button
      variant="plainBox"
      icon={{
        src: MicrosoftIcon,
        alt: 'Microsoft O365',
      }}
      className={styles.loginButton}
      onPress={async () => {
        const nonce = nanoid()

        setLocalItem('ms-nonce', nonce)
        setLocalItem('ms-state', state)
        setLocalItem('ms-action', action)

        const response = await createO365LoginLink({
          email,
          nonce,
          state: nanoid(),
        })

        if (response && response.success) {
          const {
            res: { data },
          } = response

          const { authLink } = data

          window.location.assign(
            authLink.replace(
              'response_mode=form_post',
              'response_mode=fragment',
            ),
          )
        }
      }}
    >
      {action === 'login' ? 'Login' : 'Sign up'} with Microsoft
    </Button>
  )
}

export const OktaSSO = ({
  login,
  state = nanoid(),
  action = 'login',
}: SSOProps) => {
  const { email } = login

  const [oktaDomains, setOktaDomains] = useState<OktaDomain[]>([])

  useEffect(() => {
    const fetchOktaDomains = async () => {
      const emailDomain = email.split('@').pop()

      if (!emailDomain) return

      const oktaDomainList = await getOktaDomainsUnauth(emailDomain)

      setOktaDomains(oktaDomainList)
    }

    fetchOktaDomains()
  }, [email])

  if (oktaDomains.length === 0) return null

  if (oktaDomains.length > 1)
    return (
      <ButtonDropdown
        buttonText={
          action === 'login' ? 'Login with Okta' : 'Sign up with Okta'
        }
        color="white"
        containerClassName={styles.oktaLoginMulti}
      >
        {oktaDomains.map(({ loginLink, tenantName }) => {
          return (
            <DropdownButtonItem
              key={loginLink}
              onPress={() => {
                const linkParams = new URLSearchParams(loginLink)
                const oktaClientID = linkParams?.get('state')
                setLocalItem('okta-client-id', oktaClientID || '')

                setLocalItem('okta-action', action)
                setLocalItem('okta-state', state)

                window.location.assign(loginLink)
              }}
            >
              {tenantName}
            </DropdownButtonItem>
          )
        })}
      </ButtonDropdown>
    )

  return (
    <Button
      variant="plainBox"
      icon={{
        src: OktaIcon,
        alt: 'Okta',
        imgHeight: 20,
      }}
      className={classNames(styles.okta, styles.loginButton)}
      onPress={() => {
        const linkParams = new URLSearchParams(oktaDomains[0].loginLink)
        const oktaClientID = linkParams?.get('state')
        setLocalItem('okta-client-id', oktaClientID || '')

        setLocalItem('okta-action', action)
        setLocalItem('okta-state', state)

        window.location.assign(oktaDomains[0].loginLink)
      }}
    >
      {action === 'login' ? 'Login' : 'Sign up'} with Okta
    </Button>
  )
}
