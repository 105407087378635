import React, { useMemo } from 'react'
import numeral from 'numeraljs'
import moment from 'moment'
import classNames from 'classnames'

import AnomalyDiff from './anomaly-diff'
import { Preloader } from './loader'
import NoDataMessage from './no-data-message'
import {
  dateFormatShort,
  dateFormatShortMonth,
  dateFormatShortWeek,
} from '../core/constants'
import { secondsToHms } from '../helpers/dates'
import styles from '../styles/metric-data.banner.module.scss'
import { GetCampaignLinkDashboardMetricDataClickDataQuery } from '../__gql-types__/graphql'

interface MetricDataBannerProps {
  data:
    | GetCampaignLinkDashboardMetricDataClickDataQuery['campaignLinkDashboardMetricDataClickData']
    | null
  successMetric: string
  // tooltip: string
  dateRange: string
  granularity: string
  loading?: boolean
  error?: boolean
  units?: string
  showDurationUnits?: boolean
}

export default function MetricDataBanner({
  data,
  successMetric,
  dateRange,
  granularity,
  loading = false,
  error = false,
  units,
  showDurationUnits,
}: MetricDataBannerProps) {
  const useDateFormat = useMemo(() => {
    switch (granularity) {
      case 'monthly':
        return dateFormatShortMonth
      case 'weekly':
        return dateFormatShortWeek
      default:
        return dateFormatShort
    }
  }, [granularity])

  const labelByPeriod = useMemo(() => {
    switch (granularity) {
      case 'monthly':
        return 'MoM'
      case 'weekly':
        return 'WoW'
      case 'daily':
        return 'DoD'
      case 'quarterly':
        return 'QoQ'
      default:
        return ''
    }
  }, [granularity])

  const {
    metricLastPeriod,
    metricLastPeriodComparison,
    metricPeriodOnPeriodComparison,
    increasePositive,
    totalMetric,
    lastYearStartDate,
    lastPeriodStartDate,
    prevPeriodStartDate,
  } = data || {}

  let lastYearStartDateF = lastYearStartDate
    ? moment(lastYearStartDate, 'YYYY-MM-DD').format(useDateFormat)
    : ''
  let lastPeriodStartDateF = lastPeriodStartDate
    ? moment(lastPeriodStartDate, 'YYYY-MM-DD').format(useDateFormat)
    : ''
  let prevPeriodStartDateF = prevPeriodStartDate
    ? moment(prevPeriodStartDate, 'YYYY-MM-DD').format(useDateFormat)
    : ''

  const getQ = (dateS) => {
    if (dateS) {
      const d = moment(dateS, 'YYYY-MM-DD')
      const month = d.month() + 1
      const q = Math.floor((month + 3) / 3)
      return `Q${q} ${d.format('YYYY')}`
    }
    return ''
  }

  if (granularity === 'quarterly') {
    if (lastPeriodStartDate) {
      lastYearStartDateF = getQ(lastYearStartDate)
      lastPeriodStartDateF = getQ(lastPeriodStartDate)
      prevPeriodStartDateF = getQ(prevPeriodStartDate)
    }
  }

  const totalValue = useMemo(() => {
    if (showDurationUnits) {
      return secondsToHms(totalMetric?.metricValue || 0)
    }

    return `${numeral(totalMetric?.metricValue || 0).format('0,0.[0]a')}${
      units === 'percentage' ? '%' : ''
    }`
  }, [totalMetric, showDurationUnits])

  // If the difference is ∞%, do not show the callouts
  const { hideLastPeriodDiff, hidePrevPeriodDiff } = useMemo(() => {
    let _hideLastPeriodDiff = false
    let _hidePrevPeriodDiff = false

    if (metricLastPeriodComparison) {
      const {
        metricLastCompleteValue: actual,
        metricPrevValue: expected,
      } = metricLastPeriodComparison
      if (
        (expected === 0 && actual > expected) ||
        (!!expected && expected > actual && actual === 0)
      ) {
        _hideLastPeriodDiff = true
      }
    }

    if (metricPeriodOnPeriodComparison) {
      const {
        metricLastCompleteValue: actual,
        metricPrevValue: expected,
      } = metricPeriodOnPeriodComparison
      if (
        (expected === 0 && actual > expected) ||
        (!!expected && expected > actual && actual === 0)
      ) {
        _hidePrevPeriodDiff = true
      }
    }

    return {
      hideLastPeriodDiff: _hideLastPeriodDiff,
      hidePrevPeriodDiff: _hidePrevPeriodDiff,
    }
  }, [metricLastPeriodComparison, metricPeriodOnPeriodComparison])

  if (error) {
    return (
      <div className={styles.metricDataBannerWrapper}>
        <div className={styles.metricDataBanner}>
          <div className={styles.metricDataBannerBox}>
            <NoDataMessage
              errorMsg="No data"
              grayscale
              className={styles.noData}
            />
          </div>
          <div
            className={classNames(styles.metricDataBannerBox, styles.noData)}
          >
            <NoDataMessage
              errorMsg="No data"
              grayscale
              className={styles.noData}
            />
          </div>
          <div
            className={classNames(styles.metricDataBannerBox, styles.noData)}
          >
            <NoDataMessage
              errorMsg="No data"
              grayscale
              className={styles.noData}
            />
          </div>
        </div>
      </div>
    )
  }

  if (!metricLastPeriod || loading) {
    return (
      <div className={styles.metricDataBannerWrapper}>
        <div className={styles.metricDataBanner}>
          <div
            className={classNames(styles.metricDataBannerBox, styles.isEmpty)}
          >
            <Preloader className={styles.loader} />
          </div>
          <div
            className={classNames(styles.metricDataBannerBox, styles.isEmpty)}
          >
            <Preloader className={styles.loader} />
          </div>
          <div
            className={classNames(styles.metricDataBannerBox, styles.isEmpty)}
          >
            <Preloader className={styles.loader} />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.metricDataBannerWrapper}>
      <div className={styles.metricDataBanner}>
        <div className={styles.metricDataBannerBox}>
          <h2>Total {successMetric}</h2>
          <h3>{totalValue}</h3>
          <h4>{dateRange}</h4>
        </div>
        {!hideLastPeriodDiff && (
          <div className={styles.metricDataBannerBox}>
            <h2>{`${successMetric} ${labelByPeriod}`}</h2>
            <h3 className={styles.paddedTitle}>
              <AnomalyDiff
                className={styles.valueTotal}
                invertPolarity={!increasePositive}
                inline
                roundNumber
                noIcon
                noValueChange
                actual={
                  metricLastPeriodComparison?.metricLastCompleteValue || 0
                }
                expected={metricLastPeriodComparison?.metricPrevValue || 0}
                showDurationUnits={showDurationUnits}
              />
              <AnomalyDiff
                className={styles.valueChange}
                invertPolarity={!increasePositive}
                inline
                roundNumber
                valueChangeOnly
                actual={
                  metricLastPeriodComparison?.metricLastCompleteValue || 0
                }
                expected={metricLastPeriodComparison?.metricPrevValue || 0}
              />
            </h3>
            <h4>
              {granularity === 'weekly' ? (
                <>{metricLastPeriodComparison?.metricTitle}</>
              ) : (
                <>
                  {lastPeriodStartDateF} vs {prevPeriodStartDateF}
                </>
              )}
            </h4>
          </div>
        )}
        {!hidePrevPeriodDiff && (
          <div className={styles.metricDataBannerBox}>
            <h2>{`${successMetric} YoY`}</h2>
            <h3 className={styles.paddedTitle}>
              <AnomalyDiff
                className={styles.valueTotal}
                invertPolarity={!increasePositive}
                inline
                roundNumber
                noIcon
                noValueChange
                actual={
                  metricPeriodOnPeriodComparison?.metricLastCompleteValue || 0
                }
                expected={metricPeriodOnPeriodComparison?.metricPrevValue || 0}
                showDurationUnits={showDurationUnits}
              />
              <AnomalyDiff
                className={styles.valueChange}
                invertPolarity={!increasePositive}
                inline
                roundNumber
                valueChangeOnly
                actual={
                  metricPeriodOnPeriodComparison?.metricLastCompleteValue || 0
                }
                expected={metricPeriodOnPeriodComparison?.metricPrevValue || 0}
              />
            </h3>
            <h4>
              {granularity === 'weekly' ? (
                <>{metricPeriodOnPeriodComparison?.metricTitle}</>
              ) : (
                <>
                  {lastPeriodStartDateF} vs {lastYearStartDateF}
                </>
              )}
            </h4>
          </div>
        )}
      </div>
    </div>
  )
}
