import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client'
import _ from 'lodash'

import useSubscriptionLevel from './useSubscriptionLevel'
import {
  AvailableShortLinkDomain,
  availableShortLinkDomains,
  currentShortLinkDomain,
  currentUserDetails,
  ShortLinkAliasesByDomain,
  shortLinkAliasesByDomain,
} from '../api/apollo/variables'
import {
  getCampaignCodeGenerator,
  getShortLinkCandidates,
  getShortLinkCustomDomains,
} from '../api/graphql/track-create-client'
import { defaultShortLinkDomain } from '../core/constants'
import { getCustomDomainID, getValidationCheck } from '../helpers'
import {
  addGeneratedShortLinkToStorage,
  removeShortLinkFromStorage,
} from '../helpers/short-links'
import { getLocalItem, setLocalItem } from '../helpers/local-client'

export default function useShortLinks() {
  const { workspaceID, isDemoAccount } = useReactiveVar(currentUserDetails)
  const availableDomains = useReactiveVar(availableShortLinkDomains)
  const selectedDomain = useReactiveVar(currentShortLinkDomain)
  const aliasesByDomain = useReactiveVar(shortLinkAliasesByDomain)

  const { isFree } = useSubscriptionLevel()

  const { data: customDomainData } = useQuery(getShortLinkCustomDomains)
  const [fetchShortLinkCandidates] = useLazyQuery(getShortLinkCandidates, {
    fetchPolicy: 'network-only',
  })
  const [getValidationChecks, { data: validationChecksData }] = useLazyQuery(
    getCampaignCodeGenerator,
  )

  const [fetchingAliases, setFetchingAliases] = useState(false)

  // Get rules for short links & custom domains
  useEffect(() => {
    if (!workspaceID) return

    getValidationChecks()
  }, [workspaceID])

  // Initialise available domains
  useEffect(() => {
    if (
      !validationChecksData ||
      !customDomainData ||
      availableDomains.length > 0
    )
      return

    const customDomainsRule = getValidationCheck(
      validationChecksData.campaignCodeGenerator.validationChecks,
      'FORCE_CUSTOM_DOMAIN',
      true,
    )

    let _shortDomainsToUse: AvailableShortLinkDomain[] = []

    if (customDomainsRule.value) {
      _shortDomainsToUse = JSON.parse(customDomainsRule.value)
    } else {
      _shortDomainsToUse = [
        {
          optionName: defaultShortLinkDomain,
          optionValue: defaultShortLinkDomain,
          selected: true,
        },
        ...customDomainData.currentCompany.availableShortLinkCustomDomains.map(
          ({ customDomainID, customDomainName }) => ({
            optionValue: customDomainID,
            optionName: customDomainName.replace('https://', ''),
            selected: true,
          }),
        ),
      ]
    }

    availableShortLinkDomains(_shortDomainsToUse)
  }, [validationChecksData, customDomainData, availableDomains])

  // Initialise aliases by domain
  useEffect(() => {
    // If already initialised
    if (
      availableDomains.length === 0 ||
      Object.keys(aliasesByDomain).length > 0
    )
      return

    const newAliasesByDomain: ShortLinkAliasesByDomain = {}

    availableDomains.forEach((domain) => {
      newAliasesByDomain[domain.optionValue] = { individual: [] }
    })

    shortLinkAliasesByDomain(newAliasesByDomain)
  }, [availableDomains])

  // Initialise selectedDomain based on localStorage
  useEffect(() => {
    if (!workspaceID || availableDomains.length === 0) return

    if (
      selectedDomain !== '' &&
      availableDomains.find((dom) => dom.optionValue === selectedDomain)
        ?.selected
    ) {
      // Value already set and is valid according to FORCE_CUSTOM_DOMAIN rule
      return
    }

    const localData = getLocalItem('track-create') || {}

    if (localData[workspaceID]) {
      const { options } = localData[workspaceID]

      if (
        options &&
        options.shortLinkDomain &&
        availableDomains.find(
          (dom) => dom.optionValue === options.shortLinkDomain,
        )?.selected
      ) {
        // Value in storage is valid according to FORCE_CUSTOM_DOMAIN rule
        currentShortLinkDomain(options.shortLinkDomain)

        return
      }
    }

    // If multiple domains available, do not set to default
    const domainsToUse = availableDomains.filter((dom) => dom.selected)

    if (domainsToUse.length > 1) {
      // Set to first available custom domain
      const firstCustomDomain = domainsToUse.find(
        (dom) => dom.optionValue !== defaultShortLinkDomain,
      )
      currentShortLinkDomain(firstCustomDomain?.optionValue)
    } else {
      currentShortLinkDomain(
        domainsToUse[0].optionValue || defaultShortLinkDomain,
      )
    }
  }, [selectedDomain, workspaceID, availableDomains])

  const updateShortLinkDomain = useCallback(
    (newDomain: string) => {
      currentShortLinkDomain(newDomain)

      const initialData = getLocalItem('track-create')

      const newData = _.cloneDeep(initialData)
      newData[workspaceID].options = {
        ...initialData[workspaceID].options,
        shortLinkDomain: newDomain,
      }

      setLocalItem('track-create', newData)
    },
    [workspaceID],
  )

  const fetchNewAliases = useCallback(
    async (numAliases = 1) => {
      setFetchingAliases(true)

      const newAliasesByDomain = _.cloneDeep(aliasesByDomain)

      const { data } = await fetchShortLinkCandidates({
        variables: {
          nLinks: numAliases,
          customDomainID: getCustomDomainID(selectedDomain),
        },
      })

      if (data) {
        const { shortLinkCandidates } = data

        switch (shortLinkCandidates.__typename) {
          case 'ShortLinkBatchCandidates':
            newAliasesByDomain[selectedDomain].batch =
              shortLinkCandidates.bulkStart
            addGeneratedShortLinkToStorage(
              shortLinkCandidates.bulkStart,
              selectedDomain,
            )
            break
          default:
            shortLinkCandidates.availableLinkIDs.forEach((linkID) => {
              newAliasesByDomain[selectedDomain].individual.push(linkID)
              addGeneratedShortLinkToStorage(linkID, selectedDomain)
            })
            break
        }

        shortLinkAliasesByDomain(newAliasesByDomain)
      }

      setFetchingAliases(false)

      return newAliasesByDomain
    },
    [selectedDomain, aliasesByDomain],
  )

  const replaceAlias = useCallback(
    async (aliasIndex: number) => {
      const newAliasesByDomain = _.cloneDeep(aliasesByDomain)

      const { data } = await fetchShortLinkCandidates({
        variables: {
          nLinks: 1,
          customDomainID: getCustomDomainID(selectedDomain),
        },
      })

      if (data) {
        const { shortLinkCandidates } = data

        switch (shortLinkCandidates.__typename) {
          case 'ShortLinkCandidates':
            // Should only ever be one availableLinkID
            shortLinkCandidates.availableLinkIDs.forEach((linkID) => {
              addGeneratedShortLinkToStorage(linkID, selectedDomain)
            })

            newAliasesByDomain[selectedDomain].individual.splice(
              aliasIndex,
              1,
              ...shortLinkCandidates.availableLinkIDs,
            )
            break
          default:
            // Do nothing
            break
        }

        shortLinkAliasesByDomain(newAliasesByDomain)
      }

      return newAliasesByDomain
    },
    [selectedDomain, aliasesByDomain],
  )

  const replaceBatchAlias = useCallback(
    async (nLinks: number) => {
      const newAliasesByDomain = _.cloneDeep(aliasesByDomain)

      const { data } = await fetchShortLinkCandidates({
        variables: {
          nLinks,
          customDomainID: getCustomDomainID(selectedDomain),
        },
      })

      if (data) {
        const { shortLinkCandidates } = data

        switch (shortLinkCandidates.__typename) {
          case 'ShortLinkBatchCandidates':
            newAliasesByDomain[selectedDomain].batch =
              shortLinkCandidates.bulkStart
            addGeneratedShortLinkToStorage(
              shortLinkCandidates.bulkStart,
              selectedDomain,
            )
            break
          default:
            // Do nothing for non-batch
            break
        }

        shortLinkAliasesByDomain(newAliasesByDomain)
      }

      return newAliasesByDomain
    },
    [selectedDomain, aliasesByDomain],
  )

  const shortLinks = useMemo(() => {
    return aliasesByDomain[selectedDomain]
  }, [aliasesByDomain, selectedDomain])

  const useAliases = useCallback(
    (aliasesToUse: string[]) => {
      if (aliasesToUse.length === 0 || !selectedDomain) return

      aliasesToUse.forEach((aliasToUse) => {
        removeShortLinkFromStorage(aliasToUse, selectedDomain)
      })

      if (!shortLinks) return

      const newAliasesByDomain = _.cloneDeep(aliasesByDomain)

      // Use batch link only
      if (aliasesToUse.length === 1 && aliasesToUse[0] === shortLinks.batch) {
        delete newAliasesByDomain[selectedDomain].batch

        shortLinkAliasesByDomain(newAliasesByDomain)

        return
      }

      if (shortLinks.individual.length === 0) return

      const newShortLinks = shortLinks.individual.filter(
        (shortLink) => aliasesToUse.indexOf(shortLink) === -1,
      )

      newAliasesByDomain[selectedDomain].individual = newShortLinks

      shortLinkAliasesByDomain(newAliasesByDomain)
    },
    [shortLinks, selectedDomain],
  )

  return {
    canUseShortLinks: !isFree || isDemoAccount,
    selectedDomain,
    availableDomains,
    updateShortLinkDomain,
    fetchNewAliases,
    fetchingAliases,
    replaceAlias,
    replaceBatchAlias,
    useAliases,
    shortLinks,
  }
}
