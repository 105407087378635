import React from 'react'

interface ArrowProps {
  className?: string
  style?: object
  fill?: string
  onClick?: (event: any) => void
}

export default function Arrow(
  props: ArrowProps,
): React.ReactElement<React.ReactSVGElement> {
  return (
    <svg
      {...props}
      data-name="Layer 3"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 51.57 86.41"
    >
      <path
        d="M77.63,71.63,86,80l-8.37,8.37L57.09,108.91a8.37,8.37,0,0,0,11.84,11.84l34.83-34.83-.3.29.3-.29.29-.3.11-.13.16-.18.14-.18a1.34,1.34,0,0,1,.11-.14l.14-.21.09-.12.14-.22.08-.13.13-.22s0-.09.07-.14a2.12,2.12,0,0,0,.11-.21s0-.1.08-.16l.09-.19a1.54,1.54,0,0,1,.08-.19,1.22,1.22,0,0,1,.07-.17c0-.07,0-.14.08-.22l0-.15c0-.08,0-.16.08-.24a.65.65,0,0,1,0-.13A2.68,2.68,0,0,1,106,82a.5.5,0,0,0,0-.12,2.29,2.29,0,0,0,.07-.28.5.5,0,0,1,0-.12c0-.09,0-.18,0-.28a.59.59,0,0,0,0-.14,2.3,2.3,0,0,0,0-.26c0-.06,0-.13,0-.19s0-.14,0-.22,0-.23,0-.34v-.13c0-.12,0-.24,0-.35s0-.13,0-.2,0-.14,0-.21a2,2,0,0,0,0-.24,1.11,1.11,0,0,0,0-.17,2.17,2.17,0,0,0,0-.25l0-.15a2.21,2.21,0,0,0,0-.25.83.83,0,0,0,0-.15,2.1,2.1,0,0,0-.06-.24s0-.1,0-.16a1.72,1.72,0,0,1-.07-.21l-.06-.18c0-.06,0-.13-.08-.19a1.67,1.67,0,0,0-.08-.2l-.06-.16a2.14,2.14,0,0,1-.1-.22.83.83,0,0,1-.07-.13l-.12-.24-.06-.11-.15-.25a.41.41,0,0,0-.05-.09c-.06-.08-.11-.17-.16-.25l-.06-.09-.18-.24a.86.86,0,0,0-.07-.1l-.18-.22-.09-.11-.18-.2-.15-.16-.26-.27.13.13L68.93,39.25A8.37,8.37,0,0,0,57.09,51.09Z"
        transform="translate(-54.64 -36.8)"
      />
    </svg>
  )
}
