import React, { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import moment from 'moment'

import { LoadingLabel } from './loader'
import UsageReportCallouts from './usage-report-callouts'
import UsageReportGraph from './usage-report-graph'
import { WorkspaceLogo } from './workspace-logo'
import { getCurrentAccountQRDetails } from '../api/graphql/company-client'
import { NewOptions } from '../api/types'
import uplifter from '../assets/logos/uplogo.png'
import {
  dateFormatShort,
  siteContainerWidth,
  usageReportSuccessMetrics,
} from '../core/constants'
import { getDateRangeLabel } from '../helpers/report-module'
import useResize from '../hooks/useResize'
import styles from '../styles/usage-report-page.module.scss'
import {
  DashboardGraphData,
  ReportFilterSummaryItem,
  UsageReportDataConfig,
} from '../types/report-module'
import { GetAccountUsageDataQuery } from '../__gql-types__/graphql'
import ReportFiltersSummary from './report-filters-summary'

interface UsageReportTabProps {
  loading?: boolean
  dataConfig: UsageReportDataConfig
  availableDimensionsTotals: { [dimensionParameterID: string]: number }
  updateFilters: ({
    accountFilter,
    userFilter,
  }: {
    accountFilter?: NewOptions[]
    userFilter?: NewOptions[]
  }) => Promise<void>
  accountUsageData: GetAccountUsageDataQuery['report'] | null
  graphDataToShow: {
    graphDateRange: string[]
    graphData: DashboardGraphData[]
  } | null
  graphTableTotals: number[] | null
  width: number
}

const UsageReportTab = ({
  loading = false,
  dataConfig,
  availableDimensionsTotals,
  updateFilters,
  accountUsageData,
  graphDataToShow,
  graphTableTotals,
  width,
}: UsageReportTabProps) => {
  const {
    startDate,
    endDate,
    granularity,
    successMetric,
    stackDimension,
  } = dataConfig

  const { data: logoData } = useQuery(getCurrentAccountQRDetails)

  const screenWidth = useResize()
  const isMobile = screenWidth <= 768

  const graphWidth: number = useMemo(() => {
    let res: number
    if (width) {
      res = width
    } else if (screenWidth > 1260) {
      res = 1108
    } else if (screenWidth < siteContainerWidth) {
      res = screenWidth - 40
    } else if (screenWidth <= siteContainerWidth + 150) {
      res = screenWidth - 150
    } else {
      res = 960
    }
    return res - 34 - 40
  }, [width, screenWidth])

  let offset = granularity === 'monthly' || granularity === 'quarterly' ? 0 : 40

  if (isMobile && offset > 0) {
    offset = 20
  }

  const useLogo = logoData?.currentAccount.logoLink || uplifter
  const logoLink =
    useLogo.indexOf('logos/PNG/uplogo.png') !== -1 ? uplifter : useLogo

  const dataBasedDateRange = useMemo(() => {
    if (loading) return ''

    let queryStartDate = ''
    let queryEndDate = ''

    if (
      accountUsageData &&
      accountUsageData[successMetric] &&
      accountUsageData[successMetric]?.queryStartDate
    ) {
      queryStartDate = accountUsageData[successMetric]?.queryStartDate || ''
      queryEndDate = accountUsageData[successMetric]?.queryEndDate || ''
    }

    if (!queryEndDate) {
      return ''
    }

    const start = moment(queryStartDate, 'YYYY-MM-DD').format(dateFormatShort)
    const end = moment(queryEndDate, 'YYYY-MM-DD').format(dateFormatShort)

    return `${start} - ${end}`
  }, [accountUsageData, loading])

  const dateRangeLabel = useMemo(() => {
    return getDateRangeLabel(startDate, endDate)
  }, [startDate, endDate])

  const {
    successMetricDisplayName,
    successMetricDescription,
  } = usageReportSuccessMetrics[successMetric]

  const appliedFilters = useMemo(() => {
    const _appliedFilters: ReportFilterSummaryItem[] = []

    if (dataConfig.accountFilter && dataConfig.accountFilter.length > 0) {
      _appliedFilters.push({
        dimensionParameterID: 'account',
        dimensionName: 'workspace',
        dimensionOptions: dataConfig.accountFilter,
      })
    }

    if (dataConfig.userFilter && dataConfig.userFilter.length > 0) {
      _appliedFilters.push({
        dimensionParameterID: 'user',
        dimensionName: 'user',
        dimensionOptions: dataConfig.userFilter,
      })
    }

    return _appliedFilters
  }, [dataConfig])

  return (
    <div className={styles.paper}>
      <div className={styles.paperHeader}>
        <div className={styles.paperHeaderContent}>
          <div className={styles.editTitleWrapper}>
            <h4>{successMetricDisplayName}</h4>
          </div>
          <p>
            <strong>Date range:</strong>{' '}
            {dataBasedDateRange && !loading ? (
              `${dataBasedDateRange}`
            ) : (
              <LoadingLabel label="Loading" />
            )}
          </p>
          {successMetricDisplayName && (
            <p>
              <strong>Description:</strong> {successMetricDescription}
            </p>
          )}
          <ReportFiltersSummary
            interactionLogReportName="usage-report"
            availableDimensionsTotals={availableDimensionsTotals}
            appliedFilters={appliedFilters}
            onRemoveFilterOption={async ({
              dimensionParameterID,
              optionValue,
            }) => {
              const { accountFilter, userFilter } = dataConfig

              if (accountFilter && dimensionParameterID === 'account') {
                await updateFilters({
                  accountFilter: accountFilter.filter(
                    (item) => item.optionValue !== optionValue,
                  ),
                })
              } else if (userFilter && dimensionParameterID === 'user') {
                await updateFilters({
                  userFilter: userFilter.filter(
                    (item) => item.optionValue !== optionValue,
                  ),
                })
              }
            }}
          />
          {successMetric === 'logins' && (
            <p className={styles.metricNote}>
              Note: Data not available from 16th Feb - 4th Apr 2023
            </p>
          )}
        </div>
        <div className={styles.logoWrapper}>
          <WorkspaceLogo logoLink={logoLink} loading={!logoData} />
        </div>
      </div>
      <div className={styles.metricDataBannerWrapper}>
        <div className={styles.metricDataBanner}>
          <UsageReportCallouts
            successMetric={
              successMetric === 'activeUsers'
                ? 'users'
                : successMetricDisplayName
            }
            dateRange={dataBasedDateRange}
            data={
              accountUsageData && accountUsageData[successMetric]
                ? accountUsageData[
                    successMetric === 'activeUsers'
                      ? 'totalUsers'
                      : successMetric
                  ]?.total
                : null
            }
            loading={loading}
          />
          {successMetric === 'activeUsers' &&
            accountUsageData &&
            accountUsageData.activeUsers &&
            accountUsageData.totalUsers && (
              <>
                {/* Active users callout */}
                <UsageReportCallouts
                  successMetric="active users"
                  dateRange={dataBasedDateRange}
                  data={accountUsageData.activeUsers.total}
                  loading={loading}
                />
                {/* Dormant users callout */}
                <UsageReportCallouts
                  successMetric="dormant users"
                  dateRange={dataBasedDateRange}
                  data={
                    accountUsageData.totalUsers.total -
                    accountUsageData.activeUsers.total
                  }
                  loading={loading}
                />
              </>
            )}
        </div>
      </div>
      <div className={styles.graphWrapper}>
        <UsageReportGraph
          title={stackDimension || 'No breakdown'}
          loading={loading}
          width={width}
          graphWidth={graphWidth}
          offset={offset}
          granularity={granularity}
          isMobile={isMobile}
          graphDataToShow={graphDataToShow}
          graphTableTotals={graphTableTotals}
          stackDimension={stackDimension || ''}
          successMetricDisplayName={successMetricDisplayName}
        >
          {loading ? (
            <></>
          ) : (
            <h1 className={styles.graphTitle}>
              <span>
                {successMetricDisplayName} {dateRangeLabel}{' '}
                {!!stackDimension && <>split by {stackDimension}</>}
              </span>
            </h1>
          )}
        </UsageReportGraph>
      </div>
    </div>
  )
}

export default UsageReportTab
