import React, { useState, useMemo } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import classNames from 'classnames'
import { nanoid } from 'nanoid'
import _ from 'lodash'

import Button, { NavigateButton } from './button'
import { CounterLabel } from './counter'
import Input, { Label } from './input'
import Modal from './modal'
import Row from './row'
import SelectBox from './select-box'
import {
  getCampaignLinkDashboardMeta,
  getDataSourceMetricList,
  setDashboardSettings,
} from '../api/graphql/report-client'
import { getAccountDataSource } from '../api/graphql/workspace-client'
import { sendFeatureRequest } from '../api/graphql/support-client'
import EditIcon from '../assets/edit.svg'
import { detectionMethods, integrationData } from '../core/constants'
import { getItemByKeyValue } from '../helpers'
import styles from '../styles/performance-report-manage-metrics.module.scss'

interface MetricItem {
  isStandard?: boolean
  increasePositive: boolean
  isUplifterClickMetric?: boolean | null
  calculation?: Array<string> | null
  description: string
  displayName: string
  metricID: string
  setupComplete?: boolean
  units: string
  detectionMethod?: 'AE' | 'SD' | 'MANUAL'
}

interface MetricsItem {
  optionValue: string
  optionName: string
  increaseSentimentPositive: boolean
}

interface EditMetricFieldsProps {
  item: any
  onChange: any
}

const EditMetricFields = ({ item, onChange }: EditMetricFieldsProps) => {
  return (
    <>
      <Label modalHeading>Description</Label>
      <Input
        name="description"
        id="description"
        type="text"
        value={item.description}
        onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
          const { value: val } = event.target
          onChange('description', val)
        }}
      />
      <Label modalHeading>An increase in {item.displayName}</Label>
      <div className={styles.goodBadPanel}>
        <Input
          type="radio"
          id="increase-checkbox-1"
          name="increase-checkbox"
          label="Is good"
          checked={item.increasePositive}
          className={styles.radioBox}
          onClick={() => {
            onChange('increasePositive', true)
          }}
        />
        <Input
          type="radio"
          id="increase-checkbox-2"
          name="increase-checkbox"
          label="Is bad"
          checked={!item.increasePositive}
          className={classNames(styles.radioBox, styles.radioBoxBad)}
          onClick={() => {
            onChange('increasePositive', false)
          }}
        />
      </div>
    </>
  )
}

interface EditModalProps {
  editMetricItem: MetricItem | null
  setEditMetricItem: React.Dispatch<React.SetStateAction<MetricItem | null>>
}

function EditMetricsModal({
  editMetricItem,
  setEditMetricItem,
}: EditModalProps) {
  const onChange = (key: string, value, key1 = '', value1 = '') => {
    if (editMetricItem) {
      if (key1 === '') {
        setEditMetricItem({ ...editMetricItem, [key]: value })
      } else {
        setEditMetricItem({
          ...editMetricItem,
          [key]: value,
          [key1]: value1,
        })
      }
    }
  }

  if (!editMetricItem) return null

  const { displayName } = editMetricItem

  return (
    <>
      <Label modalHeading>Metric</Label>
      <Input
        name="displayName"
        id="displayName"
        type="text"
        value={displayName}
        onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
          const { value: val } = event.target
          onChange('displayName', val)
        }}
      />
      <EditMetricFields item={editMetricItem} onChange={onChange} />
    </>
  )
}

const defaultMetricReport = {
  metricID: '',
  increasePositive: true,
  displayName: '',
  description: '',
  units: 'float',
  calculation: [],
}

interface AddMetricModalProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  loading?: boolean
  metricsItems: MetricsItem[] | null
  onYes: (item: MetricItem) => void
  yesButtonLoading?: boolean
  dataSource?: string
}

const AddMetricModal = ({
  setIsOpen,
  loading,
  metricsItems,
  onYes,
  yesButtonLoading,
  dataSource = 'GA4PROP',
}: AddMetricModalProps) => {
  const [newMetricItem, setNewMetricItem] = useState<MetricItem>({
    ...defaultMetricReport,
  })
  const [showFindMore, setShowFindMore] = useState('')

  const [requestFeature] = useMutation(sendFeatureRequest)

  const { displayName } = newMetricItem

  const onChange = (key: string, value) => {
    setNewMetricItem({ ...newMetricItem, [key]: value })
  }

  const groupedMetricOptions = useMemo(() => {
    if (dataSource === 'AA_REPORT') {
      return [{ icon: '/aa-logo.svg', label: 'Adobe Analytics', options: [] }]
    }

    return [
      {
        icon: '/ga-logo.svg',
        label: 'Google Analytics',
        options: metricsItems
          ? _.cloneDeep(metricsItems).sort((a, b) => {
              return a.optionName.toLowerCase() > b.optionValue.toLowerCase()
                ? 1
                : -1
            })
          : [],
      },
    ]
  }, [metricsItems, dataSource])

  if (showFindMore !== '') {
    return (
      <Modal
        setIsOpen={setIsOpen}
        loading={loading}
        modalHeader={`${integrationData[showFindMore].full} integration request`}
        yesText="OK"
        onYes={() => {
          setShowFindMore('')
          setIsOpen(false)
        }}
      >
        <p>Our technical team will contact you to discuss this integration.</p>
      </Modal>
    )
  }

  return (
    <Modal
      className={styles.addmetricModal}
      setIsOpen={setIsOpen}
      modalHeader="Add a metric"
      yesText="Add"
      yesButtonDisabled={displayName === ''}
      yesButtonLoading={yesButtonLoading}
      onYes={() => onYes(newMetricItem)}
    >
      <>
        <Label modalHeading>Metric</Label>
        {metricsItems ? (
          <>
            {metricsItems.length > 0 ? (
              <>
                <SelectBox
                  className={styles.typeahead}
                  labelKey="optionName"
                  valueKey="optionValue"
                  hideOrphanGroupLabel={false}
                  placeholder="Select metric or start typing"
                  value={metricsItems.find((option) => {
                    return newMetricItem.metricID === option.optionValue
                  })}
                  options={groupedMetricOptions}
                  onChange={(newValue) => {
                    if (!newValue) return

                    const { optionValue: value } = newValue

                    const item = getItemByKeyValue(
                      metricsItems,
                      'optionValue',
                      value,
                    )

                    if (item !== -1) {
                      setNewMetricItem({
                        ...newMetricItem,
                        ...{
                          metricID: value,
                          displayName: item.optionName,
                          description: item.description || '',
                          increasePositive: item.increasePositive,
                          calculation: item.calculation,
                        },
                      })
                    } else {
                      setNewMetricItem((curr) => ({
                        ...curr,
                        metricID: value,
                        displayName: '',
                        description: '',
                        calculation: [],
                      }))
                    }
                  }}
                >
                  <>
                    {Object.keys(integrationData).map((key: string): any => {
                      const item = integrationData[key]

                      if (key === 'aa' && dataSource === 'AA_REPORT')
                        return null

                      return (
                        <span key={nanoid()}>
                          <span className={styles.itemLabel}>
                            <span>
                              <img
                                src={item.icon}
                                className={styles.labelIcon}
                                alt="label icon"
                              />
                              <>{item.full}</>
                            </span>
                          </span>
                          <span className={styles.itemLink}>
                            <NavigateButton
                              style={{ padding: '8px 12px' }}
                              onPressStart={async () => {
                                setShowFindMore(key)

                                const message = `${integrationData[key].full} integration request`

                                await requestFeature({
                                  variables: {
                                    page: 'explain',
                                    message,
                                  },
                                })
                              }}
                            >
                              Request integration
                            </NavigateButton>
                          </span>
                        </span>
                      )
                    })}
                  </>
                </SelectBox>
                {displayName !== '' && (
                  <EditMetricFields item={newMetricItem} onChange={onChange} />
                )}
              </>
            ) : (
              <p>No metrics found</p>
            )}
          </>
        ) : (
          <p>Loading metric data, please wait...</p>
        )}
      </>
    </Modal>
  )
}

interface RenderRowProps {
  children?: React.ReactElement
  setupStatus: '' | 'presetup' | 'IN_PROGRESS' | 'failed' | 'completed'
  displayName: string
  increaseSentimentPositive: boolean
  detectionMethod?: null | string
  sendAlert?: boolean | null
  itemIndex: number
}

const RenderRow = ({
  children,
  setupStatus,
  displayName,
  increaseSentimentPositive,
  detectionMethod = null,
  sendAlert = null,
  itemIndex = 0,
}: RenderRowProps) => {
  return (
    <Row align="space-between" className={styles.row} odd={!(itemIndex % 2)}>
      <div className={styles.tableCell}>
        <span className={styles.mobileOnly}>Metric</span>
        <span className={styles.tableCellContent}>
          <span className={styles.tableCellContentPadded}>{displayName}</span>{' '}
          <CounterLabel
            grey
            setupStatus={setupStatus}
            className={styles.counterLabel}
          />
        </span>
      </div>
      <div className={styles.tableCell}>
        <span className={styles.mobileOnly}>An increase</span>
        <span
          className={classNames(styles.tableCellContent, styles.tagWrapper)}
        >
          is{' '}
          <span
            className={classNames(styles.tag, {
              [styles.tagIsBad]: !increaseSentimentPositive,
            })}
          >
            {increaseSentimentPositive ? 'good' : 'bad'}
          </span>
        </span>
      </div>
      {detectionMethod && (
        <div className={styles.tableCell}>
          <span className={styles.mobileOnly}>Method</span>
          <span className={styles.tableCellContent}>
            {detectionMethods[detectionMethod].long}
          </span>
        </div>
      )}
      {sendAlert !== null && (
        <div className={styles.tableCell}>
          <span className={styles.mobileOnly}>Alerts</span>
          <span className={styles.tableCellContent}>
            {sendAlert ? 'Email' : 'None'}
          </span>
        </div>
      )}
      <div className={classNames(styles.tableCell, styles.tableCellButtons)}>
        {children}
      </div>
    </Row>
  )
}

interface PerformanceReportManageMetricsModalProps {
  active: boolean
  toggleActive: React.Dispatch<React.SetStateAction<boolean>>
}

export default function PerformanceReportManageMetricsModal({
  active,
  toggleActive,
}: PerformanceReportManageMetricsModalProps) {
  const {
    data: campaignLinkDashboardMetaData,
    loading: loadingAvailableMetrics,
  } = useQuery(getCampaignLinkDashboardMeta)
  const { data: dataSourceData } = useQuery(getAccountDataSource)
  const {
    data: dataSourceMetricListData,
    loading: loadingMetricList,
  } = useQuery(getDataSourceMetricList)
  const [
    updateMetricSettings,
    { loading: updatingMetricSettings },
  ] = useMutation(setDashboardSettings)

  const [addNewMetric, setAddNewMetric] = useState(false)
  const [editMetric, setEditMetric] = useState(false)
  const [editMetricItem, setEditMetricItem] = useState<MetricItem | null>(null)

  const dataSource = useMemo(() => {
    if (!dataSourceData) return 'GA4PROP'

    return dataSourceData.currentAccount.dataSource?.kind
  }, [dataSourceData])

  const availableMetrics = useMemo(() => {
    if (!campaignLinkDashboardMetaData) return null

    return campaignLinkDashboardMetaData.campaignLinkDashboardMeta
      .availableMetrics
  }, [campaignLinkDashboardMetaData])

  const metricsItems: MetricsItem[] | null = useMemo(() => {
    if (!dataSourceMetricListData) return null

    return dataSourceMetricListData.dataSourceMetricList.dataSourceMetricList
      .filter((item) => {
        const { metricID } = item
        const found = getItemByKeyValue(availableMetrics, 'metricID', metricID)
        return found === -1
      })
      .map((item) => ({
        optionValue: item.metricID,
        optionName: item.displayName,
        increaseSentimentPositive: item.increasePositive || false,
      }))
  }, [dataSourceMetricListData])

  if (!active) return null

  if (editMetric && editMetricItem) {
    return (
      <Modal
        setIsOpen={setEditMetric}
        modalHeader="Edit metrics"
        yesButtonLoading={updatingMetricSettings}
        yesText="Save"
        onYes={async () => {
          const {
            metricID,
            increasePositive,
            displayName,
            description,
            units,
            calculation,
          } = editMetricItem

          await updateMetricSettings({
            variables: {
              additionalMetrics: [
                {
                  metricID,
                  increasePositive,
                  displayName,
                  description,
                  units,
                  calculation,
                },
              ],
            },
            refetchQueries: [getCampaignLinkDashboardMeta],
          })

          setEditMetric(false)
        }}
      >
        <EditMetricsModal
          key={nanoid()}
          editMetricItem={editMetricItem}
          setEditMetricItem={setEditMetricItem}
        />
      </Modal>
    )
  }

  if (addNewMetric) {
    return (
      <AddMetricModal
        setIsOpen={setAddNewMetric}
        loading={loadingMetricList}
        metricsItems={metricsItems}
        yesButtonLoading={updatingMetricSettings}
        onYes={async (metricItemToBeAdded) => {
          await updateMetricSettings({
            variables: {
              additionalMetrics: [
                {
                  ...metricItemToBeAdded,
                  calculation: metricItemToBeAdded.calculation || [],
                },
              ],
            },
            refetchQueries: [getCampaignLinkDashboardMeta],
          })

          setAddNewMetric(false)
        }}
        dataSource={dataSource}
      />
    )
  }

  return (
    <Modal
      setIsOpen={toggleActive}
      className={styles.reportModal}
      modalHeader="Edit metrics"
      loading={loadingAvailableMetrics}
      yesText="Add metric"
      onYes={() => setAddNewMetric(true)}
      yesButtonLoading={loadingAvailableMetrics}
    >
      {availableMetrics && availableMetrics.length === 0 && (
        <p>Please add your first metric.</p>
      )}
      {availableMetrics && availableMetrics.length > 0 && (
        <>
          <div
            className={classNames(styles.wrapper, {
              [styles.actionPanelActive]: addNewMetric,
            })}
          >
            <div className={styles.body}>
              <div className={styles.metricsList}>
                <div className={styles.table}>
                  <div className={styles.tableHeader}>
                    <div className={styles.tableCell}>
                      <span className={styles.tableCellContent}>Metric</span>
                    </div>
                    <div className={styles.tableCell}>
                      <span className={styles.tableCellContent}>
                        An increase
                      </span>
                    </div>
                    <div className={styles.tableCell}>
                      <span className={styles.tableCellContent}>Actions</span>
                    </div>
                  </div>
                  {availableMetrics.map((item, index) => {
                    return (
                      <RenderRow
                        key={nanoid()}
                        setupStatus={
                          item.setupComplete ? 'completed' : 'IN_PROGRESS'
                        }
                        displayName={item.displayName}
                        increaseSentimentPositive={item.increasePositive}
                        itemIndex={index}
                      >
                        <>
                          {!item.isStandard && (
                            <Button
                              variant="text"
                              icon={{
                                src: EditIcon,
                                alt: 'Edit',
                                iconAfter: true,
                                imgHeight: 16,
                              }}
                              onPress={() => {
                                setEditMetricItem({ ...item })
                                setEditMetric(true)
                              }}
                            >
                              Edit
                            </Button>
                          )}
                        </>
                      </RenderRow>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Modal>
  )
}
