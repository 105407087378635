import React from 'react'
import { useHistory } from 'react-router-dom'

import Intro from '../components/intro'
import Layout from '../components/layout'
import SiteWrapper from '../components/site-wrapper'
import SubscriptionComparison from '../components/subscription-comparison'
import useSubscriptionLevel from '../hooks/useSubscriptionLevel'

export default function UpgradePage() {
  const history = useHistory()

  const { isEnterprise } = useSubscriptionLevel()

  // Enterprise users should not see this page
  if (isEnterprise) history.replace('/welcome')

  return (
    <SiteWrapper>
      <Layout>
        <Intro title="Upgrade to save time and money" showUpgrade={false}>
          <p>
            Let your team self-serve consistent links, short links, QR codes,
            reports and insights.
          </p>
        </Intro>
        <SubscriptionComparison showIcons showFeatures showCurrent />
      </Layout>
    </SiteWrapper>
  )
}
