import { gql } from '../../__gql-types__/gql'

export const sendFeatureRequest = gql(`
	mutation SendFeatureRequest (
		$message: String!,
		$page: String!
	) {
		sendFeatureRequest (
			message: $message,
			page: $page,
		)
	}
`)

export const createEnterpriseCompany = gql(`
	mutation CreateEnterpriseCompany(
		$accountManagerList: [GenericEmailInput!]!
		$auditAvailable: Boolean!
		$autoRenew: Boolean!
		$breakClauseDate: String
		$businessDivision: String!
		$companyName: String!
		$companyNotes: String!
		$contractEndDate: String!
		$contractStartDate: String!
		$customTandC: Boolean!
		$dataSourceType: String!
		$domainLimit: Int!
		$domains: String!
		$explainAvailable: Boolean!
		$legalEntityName: String!
		$reportAvailable: Boolean!
		$revenuePerMonth: Float!
		$revenuePerMonthPostBreakClause: Float
		$revenuePerMonthPreBreakClause: Float
		$sponsorList: [GenericEmailInput!]!
		$totalContractValue: Float!
		$trackAvailable: Boolean!
		$userLimit: Int!
		$whitelabel: String
	) {
		userAccountSettings {
			updateUplifterAdminSettings {
				createEnterpriseCompany(
					accountManagerList: $accountManagerList
					auditAvailable: $auditAvailable
					autoRenew: $autoRenew
					businessDivision: $businessDivision
					companyName: $companyName
					companyNotes: $companyNotes
					contractEndDate: $contractEndDate
					contractStartDate: $contractStartDate
					customTandC: $customTandC
					dataSourceType: $dataSourceType
					domainLimit: $domainLimit
					domains: $domains
					explainAvailable: $explainAvailable
					legalEntityName: $legalEntityName
					reportAvailable: $reportAvailable
					revenuePerMonth: $revenuePerMonth
					sponsorList: $sponsorList
					totalContractValue: $totalContractValue
					trackAvailable: $trackAvailable
					userLimit: $userLimit
					breakClauseDate: $breakClauseDate
					revenuePerMonthPostBreakClause: $revenuePerMonthPostBreakClause
					revenuePerMonthPreBreakClause: $revenuePerMonthPreBreakClause
					whitelabel: $whitelabel
				) {
					accountCount
					accountManagerList
					activeState
					auditAvailable
					autoRenew
					billingUserList
					billingNotes
					breakClause
					breakClauseDate
					breakClauseExists
					businessDivision
					companyID
					contractEndDate
					companyNotes
					contractLink
					contractStartDate
					created
					customTandC
					mezzoAccountManager
					revenuePerMonth
					reportAvailable
					totalContractValue
					userCount
					userLimit
				}
			}
		}
	}
`)

export const addSupportUser = gql(`
	mutation AddSupportUser (
		$email: String!
		$whitelabel: String
		$permissionLevel: String!
	) {
		userAccountSettings {
			makeUserSupportUser(
				email: $email
				whitelabel: $whitelabel
				permissionLevel: $permissionLevel
			) {
					userID
					email
					inviteOpen
					userAccountProfiles {
						userEmail
						accountID
						accountName
						userPermission
						companyID
						inviteOpen
					}
			}
		}
	}
`)
