import React, { useState, useEffect } from 'react'
import classNames from 'classnames'

import { CopyButton } from './button'
import { Preloader } from './loader'
import Tooltip from './tooltip'
import styles from '../styles/change-input.module.scss'

interface Props {
  onClick?: (
    value: any,
  ) => void | Promise<
    'Updated' | 'Failed' | 'Change' | 'Not valid' | 'Already in use' | void
  >
  label?: string
  name: string
  id?: string
  initialValue?: string
  type?: string | 'picture'
  autoComplete?: string
  tooltip?: null | string
  readOnly?: boolean
  copyButton?: boolean
  copyText?: null | string
  prefix?: any
  onBeforeChange?: (val: string) => string
  small?: boolean
}

export default function ChangeInput({
  onClick,
  label,
  name,
  id,
  initialValue = '',
  type = 'text',
  autoComplete,
  tooltip = null,
  readOnly = false,
  copyButton = false,
  copyText = null,
  prefix,
  onBeforeChange,
  small,
  ...rest
}: Props) {
  const [value, setValue] = useState<any>(initialValue)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [changed, setChanged] = useState('Change')

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  return (
    <label htmlFor={name} className={styles.wrapper}>
      <span
        className={classNames(styles.label, {
          [styles.smallInput]: small,
        })}
      >
        {label}
        {tooltip && (
          <Tooltip
            id={`change-input-tooltip-${name}`}
            useIcon
            maxWidth={250}
            tooltipMessage={tooltip}
          />
        )}
      </span>
      <div
        className={classNames({
          [styles.inputContainerNoBorder]: type === 'picture',
          [styles.inputContainer]: type !== 'picture',
          [styles.smallInput]: small,
        })}
      >
        {prefix && <span className={styles.prefix}>{prefix}</span>}
        <div
          className={classNames(styles.inputWrapper, {
            [styles.smallInput]: small,
            [styles.fixedHeight]: type !== 'picture',
          })}
        >
          {type === 'picture' ? (
            <div className={styles.imgContainer}>
              {value ? (
                <img
                  className={styles.img}
                  src={
                    typeof value !== 'string'
                      ? URL.createObjectURL(value)
                      : value
                  }
                  alt="User profile"
                />
              ) : null}

              <input
                type="file"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const { files } = e.target as HTMLInputElement
                  if (files && files.length > 0) {
                    setValue(files[0])
                  }
                }}
              />
            </div>
          ) : (
            <>
              <input
                className={classNames(styles.input)}
                name={name}
                id={id || name}
                value={value}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setError(false)
                  const { value: val } = e.target as HTMLInputElement
                  const targetInputValue = onBeforeChange
                    ? onBeforeChange(val)
                    : val
                  setValue(targetInputValue)
                  if (initialValue !== targetInputValue) {
                    setChanged('Change')
                  }
                }}
                onKeyUp={async (event) => {
                  if (event.key === 'Enter' && onClick) {
                    try {
                      setLoading(true)

                      const res = await onClick(value)
                      if (res) {
                        setChanged(res)
                      }
                    } catch {
                      setError(true)
                    } finally {
                      setLoading(false)
                    }
                  }
                }}
                type={type}
                autoComplete={autoComplete}
                readOnly={readOnly}
                {...rest}
              />
              {copyButton && (
                <CopyButton
                  value={copyText || value}
                  className={styles.copyButton}
                />
              )}
            </>
          )}
          {loading && (
            <Preloader
              style={{
                margin: '0 8px',
                alignSelf: 'center',
                height: 25,
                width: 40,
              }}
            />
          )}

          {!loading && (initialValue !== value || changed !== 'Change') && (
            <button
              className={classNames(styles.button, {
                [styles.updated]: changed === 'Updated',
                [styles.change]: changed !== 'Updated',
                [styles.error]: error,
              })}
              disabled={error}
              onClick={async () => {
                if (changed === 'Updated' || !onClick) return
                try {
                  setLoading(true)

                  const res = await onClick(value)
                  if (res) {
                    setChanged(res)
                  }
                } catch {
                  setError(true)
                } finally {
                  setLoading(false)
                }
              }}
            >
              {error ? 'Error' : changed}
            </button>
          )}
        </div>
      </div>
    </label>
  )
}
