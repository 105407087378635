import { useCallback } from 'react'
import { useLazyQuery, useReactiveVar } from '@apollo/client'
import axios from 'axios'
import _ from 'lodash'
import { nanoid } from 'nanoid'

import useLogAction from './useLogAction'
import {
  loggedInState,
  loginForm,
  onboardingState,
  passwordState,
  registerForm,
} from '../api/apollo/variables'
import { getOktaDomains } from '../api/graphql/integrations-client'
import {
  CreateClient,
  createClient,
  registerNewUser,
  RegisterUser,
} from '../api/REST/account-client'
import {
  fetchPolicy,
  GoogleData,
  loginGoogleSSO,
  loginOktaSSO,
  loginMicrosoftSSO,
  loginWithPassword,
  MicrosoftData,
  PasswordData,
  requestPasswordReset,
  resetPassword as newPassword,
  GoogleReauth,
  MicrosoftReauth,
  OktaData,
  OktaReauth,
  createO365LoginLink,
  createGoogleLoginLink,
} from '../api/REST/auth-client'
import {
  messages,
  registrationErrorMessageLookup,
  supportEmail,
} from '../core/constants'
import { getToken, setToken } from '../helpers'
import { removeLocalItem, setLocalItem } from '../helpers/local-client'

const isGoogleReauth = (data: GoogleData): data is GoogleReauth => {
  return Object.prototype.hasOwnProperty.call(data, 'googleKey')
}

const isMSReauth = (data: MicrosoftData): data is MicrosoftReauth => {
  return Object.prototype.hasOwnProperty.call(data, 'microsoftKey')
}

const isOktaReauth = (data: OktaData): data is OktaReauth => {
  return Object.prototype.hasOwnProperty.call(data, 'oktaKey')
}

export default function useAuthenticate() {
  const currLoggedInState = useReactiveVar(loggedInState)
  const currLoginForm = useReactiveVar(loginForm)
  const currRegisterForm = useReactiveVar(registerForm)
  const currOnboardingState = useReactiveVar(onboardingState)
  const currentPasswordState = useReactiveVar(passwordState)

  const [fetchOktaDomains] = useLazyQuery(getOktaDomains)

  const logAction = useLogAction()

  const getPolicy = useCallback(
    async (username: string) => {
      const policyRes = await fetchPolicy(username)

      loggedInState({
        ...currLoggedInState,
        policy: {
          lastMethod: policyRes.res?.data?.lastMethod || '',
          notFound:
            policyRes.res?.data?.notFound ||
            policyRes.res?.error?.response?.data?.notFound ||
            false,
          domainExists:
            policyRes.res?.data?.domainExists ||
            policyRes.res?.error?.response?.data?.domainExists ||
            false,
        },
      })
    },
    [currLoggedInState],
  )

  const authPassword = useCallback(
    async ({ username, password }: PasswordData) => {
      const loginRes = await loginWithPassword({ username, password })

      // Needed to compare to a string
      if (loginRes.success && loginRes.res.status === 200) {
        let newLoggedInState = _.cloneDeep(currLoggedInState)

        // @ts-ignore
        if (window.dataLayer && window.dataLayer.push) {
          const token = getToken()

          // @ts-ignore
          window.dataLayer.push({ event: 'login', token })
        }

        await logAction({
          variables: {
            action: 'login',
            functionName: 'loginWithPassword',
            pagePath: window.location.pathname,
            websiteSection: 'auth',
            extra: 'password',
          },
        })

        // Try to get the version of the local  and compare it with the version file
        try {
          const req = await axios({
            method: 'get',
            url: '/version.json',
          })

          if (req && req.status === 200 && req.data) {
            const appVersion = process.env.REACT_APP_VERSION

            const { version: fetchedVersion } = req.data

            if (appVersion && appVersion !== fetchedVersion) {
              // This data object is often changed between releases
              removeLocalItem(['user-session-data'])

              window.location.reload()
            }
          }
        } catch {
          console.error('error fetching version')
        }

        // Update login state to authenticated and checked
        newLoggedInState = {
          ...newLoggedInState,
          runningDebug:
            loginRes.res.data?.runningDebug || newLoggedInState.runningDebug,
          isNewlyCreated: loginRes.res.data?.isNewlyCreated || undefined,
          authenticated: true,
          checked: true,
        }

        loggedInState(newLoggedInState)

        return
      }

      let errorMessage = messages.sorry

      if (loginRes && loginRes.res) {
        const { message, status, error } = loginRes.res

        if (status === 403 || status === 400) {
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.detail
          ) {
            errorMessage = error.response.data.detail
          } else if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            switch (error.response.data.error) {
              case 'BAD_BILLING':
                errorMessage = `This account has been suspended due to not having a valid licence. Please email ${supportEmail} to buy a licence and reactivate your account.`
                break

              default:
                errorMessage = error.response.data.error
                break
            }
          }
        } else {
          errorMessage = message || errorMessage
        }
      }

      const currentAttempts = currLoginForm.onFail.attempts

      // TODO: Send this via non-auth method
      // await logAction({
      //   variables: {
      //     action: 'failed-login',
      //     functionName: 'loginWithPassword',
      //     pagePath: window.location.pathname,
      //     websiteSection: 'auth',
      //     extra: JSON.stringify({
      //       method: 'password',
      //       errorMessage,
      //       attempts: currentAttempts + 1,
      //     }),
      //   },
      // })

      loginForm({
        ...currLoginForm,
        onFail: {
          message: errorMessage,
          attempts: currentAttempts + 1,
        },
      })
    },
    [currLoginForm, currLoggedInState, window.location],
  )

  const authGoogle = useCallback(
    async (loginData: GoogleData) => {
      const loginRes = await loginGoogleSSO(loginData)

      if (loginRes) {
        const {
          success,
          res: { data },
        } = loginRes

        if (success && data) {
          const { googleKey: newGoogleKey, token, runningDebug } = data

          if (newGoogleKey && token) {
            setLocalItem('ga-googleKey', newGoogleKey)
            setToken(token)

            // @ts-ignore
            if (window.dataLayer && window.dataLayer.push) {
              // @ts-ignore
              window.dataLayer.push({ event: 'login', token })
            }

            await logAction({
              variables: {
                action: 'login',
                functionName: 'loginWithGoogleSSO',
                pagePath: window.location.pathname,
                websiteSection: 'auth',
                extra: 'google',
              },
            })

            let newLoggedInState = _.cloneDeep(currLoggedInState)

            // Update login state to authenticated and checked
            newLoggedInState = {
              ...newLoggedInState,
              runningDebug: runningDebug || newLoggedInState.runningDebug,
              authenticated: true,
              checked: true,
            }

            loggedInState(newLoggedInState)
          }

          return
        }

        if (!isGoogleReauth(loginData)) {
          // Only add a failed attempt if login attempt was manual

          const currentAttempts = currLoginForm.onFail.attempts

          loginForm({
            ...currLoginForm,
            onFail: {
              message: messages.sorry,
              attempts: currentAttempts + 1,
            },
          })
        }
      }
    },
    [currLoginForm, currLoggedInState, window.location],
  )

  const authMicrosoft = useCallback(
    async (loginData: MicrosoftData) => {
      const loginRes = await loginMicrosoftSSO(loginData)

      if (loginRes) {
        const {
          success,
          res: { data },
        } = loginRes

        if (success && data) {
          const { microsoftKey: newMSKey, token, runningDebug } = data

          if (newMSKey && token) {
            setLocalItem('ms-microsoftKey', newMSKey)
            setToken(token)

            // @ts-ignore
            if (window.dataLayer && window.dataLayer.push) {
              // @ts-ignore
              window.dataLayer.push({ event: 'login', token })
            }

            await logAction({
              variables: {
                action: 'login',
                functionName: 'loginWithMicrosoftSSO',
                pagePath: window.location.pathname,
                websiteSection: 'auth',
                extra: 'microsoft',
              },
            })

            let newLoggedInState = _.cloneDeep(currLoggedInState)

            // Update login state to authenticated and checked
            newLoggedInState = {
              ...newLoggedInState,
              runningDebug: runningDebug || newLoggedInState.runningDebug,
              authenticated: true,
              checked: true,
              token,
            }

            loggedInState(newLoggedInState)
          }

          return
        }

        if (!isMSReauth(loginData)) {
          // Only add a failed attempt if login attempt was manual
          const currentAttempts = currLoginForm.onFail.attempts

          loginForm({
            ...currLoginForm,
            onFail: {
              message: messages.sorry,
              attempts: currentAttempts + 1,
            },
          })
        }
      }
    },
    [currLoginForm, currLoggedInState, window.location],
  )

  const authOkta = useCallback(
    async (loginData: OktaData) => {
      const loginRes = await loginOktaSSO(loginData)

      if (loginRes) {
        const {
          success,
          res: { data },
        } = loginRes

        if (success && data) {
          const { oktaKey, token, runningDebug } = data

          if (oktaKey && token) {
            setLocalItem('okta-oktaKey', oktaKey)
            setToken(token)

            // @ts-ignore
            if (window.dataLayer && window.dataLayer.push) {
              // @ts-ignore
              window.dataLayer.push({ event: 'login', token })
            }

            await logAction({
              variables: {
                action: 'login',
                functionName: 'loginWithOktaSSO',
                pagePath: window.location.pathname,
                websiteSection: 'auth',
                extra: 'okta',
              },
            })

            let newLoggedInState = _.cloneDeep(currLoggedInState)

            // Update login state to authenticated and checked
            newLoggedInState = {
              ...newLoggedInState,
              runningDebug: runningDebug || newLoggedInState.runningDebug,
              authenticated: true,
              checked: true,
            }

            loggedInState(newLoggedInState)
          }

          return
        }

        if (!isOktaReauth(loginData)) {
          const currentAttempts = currLoginForm.onFail.attempts

          loginForm({
            ...currLoginForm,
            onFail: {
              message: messages.sorry,
              attempts: currentAttempts + 1,
            },
          })
        }
      }
    },
    [currLoginForm, currLoggedInState, window.location],
  )

  const registerUser = useCallback(
    async (registrationData: RegisterUser) => {
      const registerRes = await registerNewUser(registrationData)

      const { success, res } = registerRes

      if (success && res.data) {
        const { token, runningDebug, isNewlyCreated } = res.data

        if (token) {
          setToken(token)

          // @ts-ignore
          if (window.dataLayer && window.dataLayer.push) {
            // @ts-ignore
            window.dataLayer.push({
              event: 'register-user',
              token,
            })
          }

          logAction({
            variables: {
              action: 'user-registered',
              functionName: 'registerUser',
              pagePath: window.location.pathname,
              websiteSection: 'auth',
              extra: registrationData.signUpMethod,
            },
          })

          // Set MS Clarity tag for first session
          if (window.clarity) {
            window.clarity('set', 'isFirstSession', 'true')
          }

          let newLoggedInState = _.cloneDeep(currLoggedInState)

          // Update login state to authenticated and checked
          newLoggedInState = {
            ...newLoggedInState,
            runningDebug: runningDebug || newLoggedInState.runningDebug,
            isNewlyCreated: isNewlyCreated || undefined,
            authenticated: true,
            checked: true,
          }

          loggedInState(newLoggedInState)

          registerForm({
            ...currRegisterForm,
            success: true,
          })

          return
        }
      }

      const { status, message, error, data } = res
      const { message: dataMessage } = data || { message: '' }

      let errorMessage = messages.sorry

      if (status === 403) {
        errorMessage =
          'This link has expired, please contact your admin to resend the invitation.'
      } else if (dataMessage) {
        errorMessage = dataMessage
      } else if (message) {
        errorMessage = message
      } else if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.detail
      ) {
        errorMessage = error.response.data.detail
      }

      onboardingState({
        ...currOnboardingState,
        onError: errorMessage,
      })

      registerForm({
        success: false,
        onFail: {
          message:
            Object.keys(registrationErrorMessageLookup).indexOf(errorMessage) >
            -1
              ? registrationErrorMessageLookup[errorMessage]
              : errorMessage,
          attempts: currRegisterForm.onFail.attempts + 1,
        },
      })

      loginForm({
        ...currLoginForm,
        onFail: {
          message:
            Object.keys(registrationErrorMessageLookup).indexOf(errorMessage) >
            -1
              ? registrationErrorMessageLookup[errorMessage]
              : errorMessage,
          attempts: currLoginForm.onFail.attempts + 1,
        },
      })
    },
    [
      currLoggedInState,
      currRegisterForm,
      currOnboardingState,
      currLoginForm,
      window.location,
    ],
  )

  const createNewClient = useCallback(
    async (createClientData: CreateClient) => {
      const fullCreateClientData = { ...createClientData }

      const savedUtmParams = window.sessionStorage.getItem('utmParams')

      if (savedUtmParams) {
        const {
          utm_source,
          utm_medium,
          utm_campaign,
          utm_term,
          utm_content,
        } = JSON.parse(savedUtmParams)

        fullCreateClientData.utm_source = utm_source || ''
        fullCreateClientData.utm_medium = utm_medium || ''
        fullCreateClientData.utm_campaign = utm_campaign || ''
        fullCreateClientData.utm_term = utm_term || ''
        fullCreateClientData.utm_content = utm_content || ''
      }

      const createClientRes = await createClient(fullCreateClientData)

      if (createClientRes && createClientRes.status === 200) {
        // Update login state to newlyCreated
        loggedInState({
          ...currLoggedInState,
          isNewlyCreated: true,
          authenticated: true,
          checked: true,
        })

        // This triggers a new login in the get-started.tsx component
        // Applies to password method only
        onboardingState({
          ...currOnboardingState,
          createdBillingUser: true,
        })

        // Set session storage variable so UTM sheet uploader modal can be displayed on Welcome page
        window.sessionStorage.setItem('showSessionStorageModal', 'true')

        // @ts-ignore
        if (window.dataLayer && window.dataLayer.push) {
          // @ts-ignore
          window.dataLayer.push({
            event: 'create-billing-account',
          })
        }

        if (window.location.search.indexOf('utm_source=champion_page') !== -1) {
          logAction({
            variables: {
              action: 'new-champion',
              functionName: 'createNewClient',
              pagePath: window.location.pathname,
              websiteSection: 'setup',
              extra: window.location.search,
            },
          })
        }

        const logActionExtra = {
          planInterest: createClientData.planInterest || 'Unknown',
          signUpMethod: createClientData.signUpMethod,
          searchParams: window.location.search,
        }

        logAction({
          variables: {
            action: 'create-billing-account',
            functionName: 'createNewClient',
            pagePath: window.location.pathname,
            websiteSection: 'setup',
            extra: JSON.stringify(logActionExtra),
          },
        })

        // Set MS Clarity tag for first session
        if (window.clarity) {
          window.clarity('set', 'isFirstSession', 'true')
          window.clarity('set', 'isAccountCreator', 'true')
        }

        return
      }

      let errorMessage = messages.sorry

      if (createClientRes) {
        const { status } = createClientRes

        if (status === 403 || status === 400) {
          errorMessage = messages.alreadyHaveAccount
        }
      }

      onboardingState({
        ...currOnboardingState,
        onError: errorMessage,
      })

      loginForm({
        ...currLoginForm,
        onFail: {
          message:
            Object.keys(registrationErrorMessageLookup).indexOf(errorMessage) >
            -1
              ? registrationErrorMessageLookup[errorMessage]
              : errorMessage,
          attempts: currLoginForm.onFail.attempts + 1,
        },
      })
    },
    [currOnboardingState, currLoggedInState, window.location],
  )

  const resetPasswordRequest = useCallback(
    async (email: string) => {
      const passwordResetRes = await requestPasswordReset(email)

      passwordState({
        ...currentPasswordState,
        forgotPassword: {
          requested: true,
          success: passwordResetRes,
        },
      })
    },
    [currentPasswordState],
  )

  const resetPassword = useCallback(
    async (token: string, email: string) => {
      const res = await newPassword(token, email)

      passwordState({
        ...currentPasswordState,
        resetPassword: {
          requested: true,
          success: res,
        },
      })
    },
    [currentPasswordState],
  )

  // When user tries to change email address and used SSO
  // This should be called to force them to reauth with that platform
  const ssoReauth = async (
    type: 'ms' | 'ga' | 'okta',
    currEmail: string,
    newEmail: string,
  ) => {
    const nonce = nanoid()

    setLocalItem(
      'oauth-return-url',
      `/settings?show=profile&update_email=${type}`,
    )
    setLocalItem('force-reauth', {
      reauth: true,
      type,
    })
    setLocalItem('email-to-update', newEmail)

    let oktaLoginLink = ''

    if (type === 'okta') {
      const { data } = await fetchOktaDomains()

      if (data) {
        const {
          currentCompany: {
            oktaDomainList: { oktaDomains },
          },
        } = data

        if (oktaDomains.length > 0) {
          oktaLoginLink = oktaDomains[0].loginLink

          const linkParams = new URLSearchParams(oktaLoginLink)
          const oktaClientID = linkParams?.get('state')
          setLocalItem('okta-client-id', oktaClientID || '')
        }
      }
    }

    let response: any

    // Force a new login
    switch (type) {
      case 'ms':
        removeLocalItem('ms-microsoftKey')
        setLocalItem('ms-nonce', nonce)

        response = await createO365LoginLink({
          email: currEmail,
          nonce,
          state: nanoid(),
        })
        break
      case 'ga':
        removeLocalItem('ga-googleKey')
        removeLocalItem('ga-action')
        setLocalItem('ga-nonce', nonce)

        response = await createGoogleLoginLink({
          email: currEmail,
          nonce,
          state: nanoid(),
        })
        break
      case 'okta':
        removeLocalItem('okta-oktaKey')
        removeLocalItem('okta-action')

        if (oktaLoginLink === '') {
          throw new Error('No Okta domains found')
        }

        response = { success: true, res: { data: { authLink: oktaLoginLink } } }
        break
      default:
        response = false
        break
    }

    // Use the generated auth link for SSO journey
    if (response && response.success) {
      const {
        res: { data },
      } = response

      const { authLink } = data

      let _authLink = authLink

      // GA auto-logs in when 'login_hint parameter is provided
      if (type === 'ga') {
        const searchQuery = _authLink.split('?')

        if (searchQuery[1]) {
          const params = new URLSearchParams(searchQuery[1])
          if (params.get('login_hint')) {
            params.delete('login_hint')

            _authLink = `${authLink.split('?')[0]}?${params.toString()}`
          }
        }
      }

      // Use prompt=login to force a new SSO login
      window.location.assign(
        `${_authLink.replace(
          'response_mode=form_post',
          'response_mode=fragment',
        )}&prompt=login`,
      )
    } else {
      throw new Error('Unable to login with auth provider')
    }
  }

  return {
    getPolicy,
    authPassword,
    authGoogle,
    authOkta,
    authMicrosoft,
    registerUser,
    createNewClient,
    resetPasswordRequest,
    resetPassword,
    ssoReauth,
  }
}
