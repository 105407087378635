import { CampaignCodeGeneratorStructure } from '../api/types'

type Result = { [key: string]: any } | null | string

export function getLocalItem(storageKey = ''): any {
  let res: any = null

  try {
    res = window.localStorage.getItem(storageKey)
    if (res) {
      return JSON.parse(res)
    }
  } catch (e) {
    console.error(e)
  }

  return res
}

export function setLocalItem(
  storageKey: string,
  storageObject: Result,
): boolean {
  let res = true

  try {
    window.localStorage.setItem(storageKey, JSON.stringify(storageObject))
  } catch (e) {
    res = false
    console.error(e)
  }

  return res
}

export function removeLocalItem(storageKey: string | string[]): boolean {
  try {
    const iterate = typeof storageKey === 'string' ? [storageKey] : storageKey

    iterate.forEach((key) => {
      window.localStorage.removeItem(key)
    })
  } catch (e) {
    console.error(e)
    return false
  }

  return true
}

export function returnUnique(arr: any[]) {
  const seen = {}
  return arr.filter((item) => {
    const hasSeen = Object.prototype.hasOwnProperty.call(
      seen,
      JSON.stringify(item),
    )
    if (!hasSeen) {
      seen[JSON.stringify(item)] = true
    }
    return !hasSeen
  })
}

export function saveUserData(
  data: object,
  currentAccount = 'user',
  doMerge = true,
): void {
  // eslint-disable-next-line
  // debugger

  const savedData = getLocalItem('user-session-data') || {}
  const useData = Object.prototype.hasOwnProperty.call(
    savedData,
    currentAccount,
  )
    ? savedData
    : {
        [currentAccount]: Array.isArray(data) ? [] : {},
      }

  if (Array.isArray(data) && Array.isArray(useData[currentAccount])) {
    useData[currentAccount] = returnUnique([
      ...(doMerge ? useData[currentAccount] : []),
      ...data,
    ])
  } else {
    useData[currentAccount] = {
      ...(doMerge ? useData[currentAccount] : []),
      ...data,
    }
  }

  setLocalItem('user-session-data', { ...savedData, ...useData })
}

export function getUserData(currentAccount = 'user'): any {
  const savedData = getLocalItem('user-session-data') || {}
  if (Object.prototype.hasOwnProperty.call(savedData, currentAccount)) {
    return savedData[currentAccount]
  }
  return null
}

export const defaultFormData = {
  single: { url: [], data: {} },
  multi: { url: [], data: {} },
  email: { emailHtml: '', data: {} },
  options: {
    active: 'single',
    shortLinkDomain: '',
  },
}

export function getFormValues(form): any {
  const result = {}

  if (!form || !Object.prototype.hasOwnProperty.call(form, 'paramDefs')) {
    return form
  }

  form.paramDefs.forEach((item) => {
    const { fieldID, optionValue } = item
    result[fieldID] = {
      optionValue,
    }
  })
  return result
}

interface SaveFormDataProps {
  url?: string[]
  emailHtml?: string
  generatedEmailHtml?: string
  form?: any
  currentAccount: string
  formType: 'single' | 'multi' | 'email'
  shortLinkDomain?: string | null
}

export function saveFormData({
  url,
  emailHtml,
  generatedEmailHtml,
  form,
  currentAccount,
  formType,
  shortLinkDomain = null,
}: SaveFormDataProps): void {
  const useFormData = getFormValues(form)

  const savedData = getLocalItem('track-create') || {}

  if (!currentAccount) return

  const useData = Object.prototype.hasOwnProperty.call(
    savedData,
    currentAccount,
  )
    ? {
        ...savedData,
        [currentAccount]: { ...defaultFormData, ...savedData[currentAccount] },
      }
    : {
        [currentAccount]: { ...defaultFormData },
      }

  const options = { ...useData[currentAccount].options }

  if (formType === 'single') {
    if (url) {
      useData[currentAccount].single.url = url
    }
    options.active = 'single'
  } else if (formType === 'email') {
    if (emailHtml) {
      useData[currentAccount].email.emailHtml = emailHtml
    }
    if (generatedEmailHtml) {
      useData[currentAccount].email.generatedEmailHtml = generatedEmailHtml
    }
    options.active = 'email'
  } else {
    if (url) {
      useData[currentAccount].multi.url = url
    }
    options.active = 'multi'
  }

  if (useFormData) {
    useData[currentAccount][formType].data = useFormData
  }

  if (shortLinkDomain !== null) {
    options.shortLinkDomain = shortLinkDomain
  }

  useData[currentAccount].options = { ...options }

  setLocalItem('track-create', { ...savedData, ...useData })
}

export function getFormData(
  form: CampaignCodeGeneratorStructure,
  urlOrEmail: string | string[],
  currentAccount,
  formType: 'single' | 'multi' | 'email',
): any {
  const savedData = getLocalItem('track-create') || {}

  if (Object.prototype.hasOwnProperty.call(savedData, currentAccount)) {
    const data = savedData[currentAccount][formType]

    if (data) {
      const newParamDefs = form.paramDefs.map((item) => {
        const { fieldID } = item

        if (Object.prototype.hasOwnProperty.call(data.data, fieldID)) {
          const { optionValue } = data.data[fieldID]
          return {
            ...item,
            optionValue,
          }
        }
        return {
          ...item,
        }
      })

      const output = {
        ...defaultFormData,
        ...savedData[currentAccount],
        form: {
          ...form,
          paramDefs: newParamDefs,
        },
      }

      if (formType === 'email') {
        output.emailHtml = data.emailHtml
      } else {
        output.url = data.url
      }

      return output
    }
  }

  const output: any = {
    ...defaultFormData,
    form: {
      ...form,
    },
  }

  if (formType === 'email') {
    output.email = urlOrEmail
  } else {
    output.url = urlOrEmail
  }

  return output
}
