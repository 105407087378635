import React, { useState, useEffect, useMemo } from 'react'
import { useMutation, useQuery, useReactiveVar } from '@apollo/client'
import { nanoid } from 'nanoid'

import ChangeInput from './change-input'
import { ImgUploader } from './img-uploader'
import Input, { Label } from './input'
import Tooltip from './tooltip'
import { currentUserDetails } from '../api/apollo/variables'
import {
  deleteQrLogo,
  getCurrentAccountQRDetails,
  updateAccountDescription,
  updateQrSettings,
} from '../api/graphql/company-client'
import { getUserInfo } from '../api/graphql/user-client'
import { uploadAccountLogo, uploadQrLogo } from '../api/REST/account-client'
import { makeHexString, getDefaultOption } from '../helpers'
import useLogAction from '../hooks/useLogAction'
import styles from '../styles/workspace-details.module.scss'

interface WorkspaceDetailsProps {
  className?: string
}

export default function WorkspaceDetails({ className }: WorkspaceDetailsProps) {
  const { workspaceID, workspaceName } = useReactiveVar(currentUserDetails)

  const logAction = useLogAction()

  const { data: homepageData } = useQuery(getUserInfo)
  const { data: qrData, refetch: refetchQrData } = useQuery(
    getCurrentAccountQRDetails,
  )

  const [updateWorkspaceDescription] = useMutation(updateAccountDescription)
  const [deleteQrCodeLogo] = useMutation(deleteQrLogo)
  const [updateQrCodeSettings] = useMutation(updateQrSettings)

  const homepage = useMemo(() => {
    if (!homepageData) return ''

    return homepageData.currentAccount.homepage
  }, [homepageData])

  const logoLink = `${qrData?.currentAccount.logoLink || ''}`.replace(
    '/static/logos/PNG/uplogo.png',
    '',
  )

  const showShape = false
  const showTransparency = false

  const defaultQrStyle = 'squares'
  const [qrStyle, setQrStyle] = useState(defaultQrStyle)

  useEffect(() => {
    if (qrData) {
      setQrStyle(
        getDefaultOption(
          qrData.currentAccount,
          'qrSettings',
          'qrStyle',
          defaultQrStyle,
        ),
      )
    }
  }, [qrData])

  const { fgColour, bgColour, qrTransparentLogo } = useMemo(() => {
    if (qrData) {
      return {
        fgColour: getDefaultOption(
          qrData.currentAccount,
          'qrSettings',
          'fgColour',
          '000000',
        ).replace('#', ''),
        qrTransparentLogo: getDefaultOption(
          qrData.currentAccount,
          'qrSettings',
          'transparentLogo',
          'false',
        ),
        bgColour: getDefaultOption(
          qrData.currentAccount,
          'qrSettings',
          'bgColour',
          'FFFFFF',
        ).replace('#', ''),
      }
    }

    return {
      fgColour: '#000000',
      bgColour: '#FFFFFF',
      qrTransparentLogo: false,
    }
  }, [qrData])

  const qrCodeLogoList = useMemo(() => {
    return qrData ? qrData.currentAccount.qrCodeLogoList : []
  }, [qrData])

  return (
    <div className={className}>
      <h3>Details</h3>
      <div className={styles.subsection}>
        <div className={styles.fieldRow}>
          <ChangeInput
            onClick={async (nextWorkspacename) => {
              const response = nextWorkspacename !== ''

              if (!response || workspaceID === '') return 'Not valid'

              const { data } = await updateWorkspaceDescription({
                variables: {
                  accountID: workspaceID,
                  updatedAccountName: nextWorkspacename,
                },
              })

              if (data) {
                return 'Updated'
              }
              return 'Failed'
            }}
            tooltip="This is the name of your workspace."
            label="Workspace name"
            type="text"
            name="workspace"
            initialValue={workspaceName || ''}
          />
          <ChangeInput
            tooltip="This is your homepage (use full domain name, e.q. https://www.mezzolabs.com)."
            onClick={async (nextHomepage) => {
              const response = nextHomepage !== ''

              if (!response || workspaceID === '') return 'Not valid'

              const { data } = await updateWorkspaceDescription({
                variables: {
                  accountID: workspaceID,
                  updatedAccountDomain: nextHomepage,
                },
              })

              if (data) {
                return 'Updated'
              }
              return 'Failed'
            }}
            label="Homepage"
            type="text"
            name="homepage"
            initialValue={homepage || ''}
          />
        </div>
      </div>

      <span className={styles.spacer} />

      <div className={styles.fieldRow}>
        <div>
          <Label id="logo">
            <Tooltip
              id="company-logo-tooltip"
              useIcon
              tooltipMessage="This logo will be displayed on the top left of reports. We support png, gif or jpeg files up to 1MB."
            >
              Workspace logo
            </Tooltip>
          </Label>
          <ImgUploader
            logoLink={logoLink}
            imgType="logo"
            uploadFn={async (file: File) => {
              const res = await uploadAccountLogo({ file })

              if (res === true) {
                logAction({
                  variables: {
                    action: 'company-logo-changed',
                    extra: '',
                    websiteSection: 'settings',
                    pagePath: '/settings',
                    functionName: 'uploadAccountLogo',
                  },
                })

                refetchQrData()

                return true
              }
              return false
            }}
          />
        </div>
      </div>

      <span className={styles.spacer} />

      <h3>QR code</h3>
      <Label id="qrLogo" className={styles.byline}>
        <Tooltip
          id="qr-code-logo-tooltip"
          useIcon
          tooltipMessage="Simple versions of your logo work best, ideal size 300px x 300px &amp; less than 1MB."
        >
          Default logo used in the centre of QR codes.
        </Tooltip>
      </Label>
      <div className={styles.subsection}>
        {qrCodeLogoList.length > 0 &&
          qrCodeLogoList.map((logoLinkQR) => {
            return (
              <ImgUploader
                key={logoLinkQR}
                logoLink={logoLinkQR}
                imgType="qrLogo"
                uploadFn={async (file: File) => {
                  const res = await uploadQrLogo({ file })

                  if (res === true) {
                    logAction({
                      variables: {
                        action: 'company-qr-logo-changed',
                        extra: '',
                        websiteSection: 'settings',
                        pagePath: '/settings',
                        functionName: 'uploadQrLogo',
                      },
                    })

                    await refetchQrData()

                    return true
                  }
                  return false
                }}
                deleteFn={
                  qrCodeLogoList.length > 1
                    ? async () => {
                        await deleteQrCodeLogo({
                          variables: { blobName: logoLinkQR },
                        })

                        logAction({
                          variables: {
                            action: 'company-qr-logo-deleted',
                            extra: '',
                            websiteSection: 'settings',
                            pagePath: '/settings',
                            functionName: 'deleteQrLogo',
                          },
                        })
                      }
                    : undefined
                }
              />
            )
          })}
        {qrCodeLogoList.length === 0 && (
          <ImgUploader
            logoLink=""
            imgType="qrLogo"
            uploadFn={async (file: File) => {
              const res = await uploadQrLogo({ file })

              if (res === true) {
                logAction({
                  variables: {
                    action: 'company-qr-logo-changed',
                    extra: '',
                    websiteSection: 'settings',
                    pagePath: '/settings',
                    functionName: 'uploadQrLogo',
                  },
                })

                await refetchQrData()

                return true
              }
              return false
            }}
          />
        )}
        {showTransparency && (
          <>
            <Label modalHeading id="backgroundBehind" className={styles.label}>
              Background behind logo
            </Label>
            <Input
              name="type"
              id="backgroundBehind"
              label="Transparent"
              type="checkbox"
              checked={qrTransparentLogo === 'true'}
              onChange={(e) => {
                e.preventDefault()

                setQrStyle('dots')

                updateQrCodeSettings({
                  variables: {
                    qrSettings: [
                      {
                        name: 'transparentLogo',
                        enabled: true,
                        value: e.target.checked ? 'true' : 'false',
                      },
                    ],
                  },
                })
              }}
            />
          </>
        )}
        <div className={styles.fieldRow}>
          <ChangeInput
            label="Default foreground colour"
            tooltip="Use RGB hex value, e.g. #000000 for black."
            type="text"
            name="fgColour"
            initialValue={fgColour}
            prefix="#"
            onBeforeChange={(val) => {
              return makeHexString(val)
            }}
            onClick={(newValue) => {
              if (newValue !== '') {
                updateQrCodeSettings({
                  variables: {
                    qrSettings: [
                      {
                        name: 'fgColour',
                        enabled: true,
                        value: `#${newValue}`,
                      },
                    ],
                  },
                })
              }
            }}
          />
          <ChangeInput
            label="Default background colour"
            tooltip="Use RGB hex value, e.g. #FFFFFF for white."
            type="text"
            name="bgColour"
            initialValue={bgColour}
            prefix="#"
            onBeforeChange={(val) => {
              return makeHexString(val)
            }}
            onClick={(newValue) => {
              if (newValue !== '') {
                updateQrCodeSettings({
                  variables: {
                    qrSettings: [
                      {
                        name: 'bgColour',
                        enabled: true,
                        value: `#${newValue}`,
                      },
                    ],
                  },
                })
              }
            }}
          />
        </div>
        {showShape && (
          <div className={styles.radioRow}>
            <Label modalHeading id="type" className={styles.label}>
              Shape of QR modules
            </Label>
            <div className={styles.alignRow}>
              <Input
                name="type"
                id={nanoid()}
                label="Squares"
                type="radio"
                value="squares"
                checked={qrStyle !== 'dots'}
                onClick={(e): any => {
                  e.preventDefault()

                  setQrStyle('squares')

                  updateQrCodeSettings({
                    variables: {
                      qrSettings: [
                        {
                          name: 'qrStyle',
                          enabled: true,
                          value: 'squares',
                        },
                      ],
                    },
                  })
                }}
              />
              <Input
                name="type"
                id={nanoid()}
                label="Dots"
                type="radio"
                value="dots"
                checked={qrStyle === 'dots'}
                onClick={(e): any => {
                  e.preventDefault()

                  setQrStyle('dots')

                  updateQrCodeSettings({
                    variables: {
                      qrSettings: [
                        {
                          name: 'qrStyle',
                          enabled: true,
                          value: 'dots',
                        },
                      ],
                    },
                  })
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
