import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLazyQuery, useReactiveVar } from '@apollo/client'

import { currentUserDetails } from '../api/apollo/variables'
import { getCompanyAccountsAndUsers } from '../api/graphql/company-client'
import { getAccountUsageData } from '../api/graphql/report-client'
import { NewOptions } from '../api/types'
import ButtonTabs from '../components/button-tabs'
import Intro from '../components/intro'
import Layout from '../components/layout'
import ReportControls from '../components/report-controls'
import { SelectBoxChecklist } from '../components/select-box'
import SiteWrapper from '../components/site-wrapper'
import UsageReportTab from '../components/usage-report-tab'
import { brandName } from '../core/constants'
import useLogAction from '../hooks/useLogAction'
import styles from '../styles/usage-report-page.module.scss'
import {
  DashboardGraphData,
  ReportFilterSummaryItem,
  UsageReportDataConfig,
  UsageSuccessMetrics,
  successMetrics,
} from '../types/report-module'

interface UsageReportFilterProps {
  dimension: {
    dimensionParameterID: string
    dimensionName: string
    dimensionOptions: {
      optionName: string
      optionValue: string
    }[]
  }
  selectedValues: NewOptions[]
  onChange: (
    newValue: NewOptions[],
    blockApplyFilter?: boolean,
  ) => Promise<void>
}

const UsageReportFilter = ({
  dimension,
  selectedValues,
  onChange,
}: UsageReportFilterProps) => {
  const { dimensionName, dimensionOptions } = dimension

  const [savedOptions, setSavedOptions] = useState<NewOptions[] | null>(null)

  return (
    <SelectBoxChecklist
      variant="grey"
      id="groupByLandingPage"
      className={styles.controlsSelector}
      isSearchable
      excludeAny
      excludeNone
      allLabel=""
      labelKey="optionName"
      valueKey="optionValue"
      controlLabel={
        selectedValues.length === 0 ||
        selectedValues.length === dimensionOptions.length
          ? `all ${dimensionName}s`
          : dimensionName
      }
      placeholder={
        selectedValues.length === 0 ||
        selectedValues.length === dimensionOptions.length
          ? `all ${dimensionName}s`
          : dimensionName
      }
      value={selectedValues}
      options={dimensionOptions}
      onChange={async (newValue) => {
        await onChange(
          [...newValue],
          newValue.length > 0 && newValue.length !== dimensionOptions.length,
        )

        setSavedOptions(
          newValue.length > 0 && newValue.length !== dimensionOptions.length
            ? [...newValue]
            : null,
        )
      }}
      onBlur={async () => {
        if (savedOptions) {
          onChange(savedOptions)

          setSavedOptions(null)
        }
      }}
    />
  )
}

export const initialDataConfig: UsageReportDataConfig = {
  startDate: 'thisYear',
  endDate: '',
  granularity: 'monthly',
  successMetric: 'badCodesBlocked',
}

const UsageReportPage = () => {
  const { companyID } = useReactiveVar(currentUserDetails)

  const logAction = useLogAction()

  const [
    getUsageData,
    {
      data: usageData,
      loading: loadingUsageData,
      error: errorFetchingUsageData,
    },
  ] = useLazyQuery(getAccountUsageData, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })

  const [
    getAccountsAndUsers,
    { data: accountListData, loading: loadingAccountList },
  ] = useLazyQuery(getCompanyAccountsAndUsers)

  const [width, setWidth] = useState(0)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [dataConfig, setDataConfig] = useState<UsageReportDataConfig>(
    initialDataConfig,
  )

  useEffect(() => {
    if (companyID) getAccountsAndUsers()
  }, [companyID])

  const accountList = useMemo(() => {
    if (!accountListData) return []

    return accountListData.currentCompany.accountList
  }, [accountListData])

  const accountUsageData = useMemo(() => {
    if (!usageData) return null

    return usageData.report
  }, [usageData])

  // Get filterable items
  const {
    availableDimensions,
    availableDimensionsTotals,
  }: {
    availableDimensions: ReportFilterSummaryItem[]
    availableDimensionsTotals: { [dimensionParameterID: string]: number }
  } = useMemo(() => {
    if (accountList.length === 0) {
      return {
        availableDimensions: [] as ReportFilterSummaryItem[],
        availableDimensionsTotals: {},
      }
    }

    const accountOptions = accountList.map((account) => ({
      optionName: account.accountName,
      optionValue: account.accountID,
    }))

    const uniqueUsersObj: {
      [name: string]: { optionName: string; optionValue: string }
    } = {}

    accountList.forEach((account) => {
      account.userAccountProfiles.forEach((profile) => {
        if (
          profile.userID &&
          !Object.prototype.hasOwnProperty.call(uniqueUsersObj, profile.userID)
        ) {
          uniqueUsersObj[profile.userEmail] = {
            optionName: profile.userEmail,
            optionValue: profile.userID,
          }
        }
      })
    })

    return {
      availableDimensions: [
        {
          dimensionParameterID: 'account',
          dimensionName: 'workspace',
          dimensionOptions: accountOptions,
        },
        {
          dimensionParameterID: 'user',
          dimensionName: 'user',
          dimensionOptions: Object.values(uniqueUsersObj),
        },
      ],
      availableDimensionsTotals: {
        account: accountOptions.length,
        user: Object.values(uniqueUsersObj).length,
      } as { [dimensionParameterID: string]: number },
    }
  }, [accountList])

  const [graphDataToShow, setGraphDataToShow] = useState<{
    graphDateRange: string[]
    graphData: DashboardGraphData[]
  } | null>(null)
  const [graphTableTotals, setGraphTableTotals] = useState<number[] | null>(
    null,
  )

  // Set graph info
  useEffect(() => {
    const { successMetric } = dataConfig

    if (
      accountUsageData &&
      accountUsageData[successMetric] &&
      accountUsageData[successMetric]?.graphData
    ) {
      const graphDateRange = accountUsageData[successMetric]?.dateRange || []
      let graphData: DashboardGraphData[] = []

      setGraphTableTotals(
        accountUsageData[successMetric]?.dateTotalRows || null,
      )
      // Active users should show a split with total users
      if (
        successMetric === 'activeUsers' &&
        accountUsageData.activeUsers?.graphData &&
        accountUsageData.totalUsers?.graphData
      ) {
        const combinedGraphData = [...accountUsageData.activeUsers.graphData]

        const {
          dimensionValues: activeUsersValues,
        } = accountUsageData.activeUsers.graphData[0]

        const {
          dimensionValues: totalUsersValues,
        } = accountUsageData.totalUsers.graphData[0]

        const totalUsersData = {
          dimensionName: 'Dormant users',
          dimensionValues: totalUsersValues.map((val, index) =>
            Math.max(0, val - activeUsersValues[index]),
          ),
        }

        combinedGraphData.push(totalUsersData)

        graphData = combinedGraphData
      } else {
        graphData = accountUsageData[successMetric]?.graphData || []
      }

      setGraphDataToShow({
        graphDateRange,
        graphData,
      })
    }
  }, [accountUsageData, dataConfig.successMetric])

  // Fetch initial data: badCodesBlocked
  useEffect(() => {
    const { startDate, granularity, successMetric } = initialDataConfig

    getUsageData({
      variables: {
        startDate,
        granularity,
        badCodesBlocked: successMetric === 'badCodesBlocked',
        invalidCodesBreakdown: successMetric === 'invalidCodesBreakdown',
        codesCreated: successMetric === 'codesCreated',
        logins: successMetric === 'logins',
        activeUsers: successMetric === 'activeUsers',
        totalUsers: false,
      },
    })
  }, [])

  const refetchData = useCallback(async (data: UsageReportDataConfig) => {
    const {
      startDate,
      endDate,
      granularity,
      successMetric,
      accountFilter,
      userFilter,
    } = data

    const dimensionFilters = accountFilter
      ? [
          {
            dimensionParameterID: 'account',
            dimensionName: 'account',
            dimensionOptions: accountFilter.map(
              ({ optionValue }) => optionValue,
            ),
          },
        ]
      : []

    if (userFilter) {
      dimensionFilters.push({
        dimensionParameterID: 'user',
        dimensionName: 'user',
        dimensionOptions: userFilter.map(({ optionValue }) => optionValue),
      })
    }

    getUsageData({
      variables: {
        startDate,
        endDate,
        granularity,
        badCodesBlocked: successMetric === 'badCodesBlocked',
        invalidCodesBreakdown: successMetric === 'invalidCodesBreakdown',
        codesCreated: successMetric === 'codesCreated',
        logins: successMetric === 'logins',
        activeUsers: successMetric === 'activeUsers',
        totalUsers: successMetric === 'activeUsers',
        filterList:
          dimensionFilters.length > 0 ? { dimensionFilters } : undefined,
      },
    })
  }, [])

  return (
    <SiteWrapper>
      <Layout width={1600}>
        <div
          ref={(element) => {
            if (element) {
              const container = element.getBoundingClientRect()
              setWidth(container.width - 42)
            }
          }}
        >
          {errorFetchingUsageData ? (
            <Intro title="Value and usage">
              <p>We are having trouble loading your dashboard data.</p>
            </Intro>
          ) : (
            <>
              <Intro title="Value and usage" className={styles.intro}>
                <p>
                  Create reports which show who is using {brandName} and how it
                  is adding value.
                </p>
              </Intro>
              <ReportControls
                interactionLogReportName="usage-report"
                loadingData={loadingUsageData || loadingAccountList}
                controlsPrefix={
                  !loadingAccountList &&
                  availableDimensions.length > 0 &&
                  availableDimensions.some(
                    (dim) =>
                      dim.dimensionOptions && dim.dimensionOptions.length > 1,
                  ) && (
                    <>
                      <span className={styles.reportControlCopy}>
                        Show data&nbsp;
                      </span>
                      <span className={styles.reportControlCopy}> for </span>
                      {availableDimensions.map((dimension, index) => {
                        const {
                          dimensionParameterID,
                          dimensionOptions,
                        } = dimension

                        if (dimensionOptions && dimensionOptions.length < 2) {
                          return null
                        }

                        const selectedValues =
                          (dataConfig[
                            `${dimensionParameterID}Filter`
                          ] as NewOptions[]) || []

                        return (
                          <React.Fragment key={JSON.stringify(dimension)}>
                            <UsageReportFilter
                              dimension={dimension}
                              selectedValues={selectedValues}
                              onChange={async (newValue, blockApplyFilter) => {
                                const newDataConfig = {
                                  ...dataConfig,
                                  [`${dimensionParameterID}Filter`]:
                                    newValue.length > 0 ? newValue : undefined,
                                }

                                setDataConfig(newDataConfig)

                                // Prevents filters being applied immediately for multiselect
                                if (!blockApplyFilter) {
                                  await refetchData(newDataConfig)

                                  logAction({
                                    variables: {
                                      action: 'update-filters-usage-report',
                                      extra: JSON.stringify(newDataConfig),
                                      websiteSection: 'report',
                                      functionName: 'updateFilters',
                                      pagePath: window.location.pathname,
                                    },
                                  })
                                }
                              }}
                            />
                            {index < availableDimensions.length - 1 ? (
                              <span className={styles.reportControlCopy}>
                                ,{' '}
                              </span>
                            ) : (
                              <span className={styles.reportControlCopy}>
                                {' '}
                                over{' '}
                              </span>
                            )}
                          </React.Fragment>
                        )
                      })}
                    </>
                  )
                }
                currentDataConfig={dataConfig}
                setCurrentDataConfig={setDataConfig}
                onChange={async (newDataConfig) => {
                  await refetchData(newDataConfig)
                }}
              />
              <ButtonTabs
                className={styles.buttonTabs}
                selected={currentIndex}
                isTopOfBox
                tabsLabels={[
                  'Invalid links prevented',
                  'Link errors breakdown',
                  'Links created',
                  'Logins',
                  'Active users',
                ]}
                type="tabs"
                onChange={(index: number) => {
                  setCurrentIndex(index)

                  const newDataConfig = {
                    ...dataConfig,
                    successMetric: successMetrics[index] as UsageSuccessMetrics,
                    stackDimension: index === 1 ? 'creation type' : null,
                  }

                  setDataConfig(newDataConfig)
                  refetchData(newDataConfig)
                }}
              >
                {successMetrics.map((currMetric) => {
                  return (
                    <UsageReportTab
                      key={currMetric}
                      loading={loadingUsageData}
                      dataConfig={dataConfig}
                      availableDimensionsTotals={availableDimensionsTotals}
                      updateFilters={async ({ accountFilter, userFilter }) => {
                        const newDataConfig = {
                          ...dataConfig,
                          accountFilter:
                            accountFilter || dataConfig.accountFilter,
                          userFilter: userFilter || dataConfig.userFilter,
                        }

                        setDataConfig(newDataConfig)
                        await refetchData(newDataConfig)
                      }}
                      accountUsageData={accountUsageData}
                      graphDataToShow={graphDataToShow}
                      graphTableTotals={graphTableTotals}
                      width={width}
                    />
                  )
                })}
              </ButtonTabs>
            </>
          )}
        </div>
      </Layout>
    </SiteWrapper>
  )
}

export default UsageReportPage
