import React, { useState, useMemo } from 'react'
import classNames from 'classnames'
import numeral from 'numeraljs'
import moment from 'moment'
import {
  XYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  VerticalGridLines,
  DiscreteColorLegend,
  VerticalBarSeries,
  LineSeries,
  Hint,
  Crosshair,
} from 'react-vis'
import { nanoid } from 'nanoid'
import _ from 'lodash'

import NoDataMessage from './no-data-message'
import Input from './input'
import { Preloader } from './loader'
import Table from './table'
import Tooltip from './tooltip'
import graphIcon from '../assets/icon-graph.svg'
import linesIcon from '../assets/icon-table.svg'
import {
  dateFormatShort,
  dateFormatShortMonth,
  dateFormatShortWeek,
  graphColours,
} from '../core/constants'
import { formatValueForDisplay } from '../helpers'
import { secondsToHms } from '../helpers/dates'
import { saveUserData, getUserData } from '../helpers/local-client'
import styles from '../styles/performance-report-graph.module.scss'
import { DashboardGraphData, Granularity } from '../types/report-module'
import { AvailableDimension } from '../__gql-types__/graphql'

const getColour = (index: number) => {
  return graphColours[index % graphColours.length]
}

export interface GraphDataTableProps {
  width: number
  granularity: string
  isMobile: boolean
  totalsData?: number[] | null
  units?: string
  title: string
  showDurationUnits?: boolean
  graphDataToShow: {
    graphDateRange: string[]
    graphData: DashboardGraphData[]
  } | null
}

export function GraphDataTable({
  totalsData,
  width,
  isMobile,
  granularity,
  units,
  title,
  showDurationUnits,
  graphDataToShow,
}: GraphDataTableProps): React.ReactElement | null {
  if (!graphDataToShow) {
    return null
  }

  const { graphDateRange, graphData } = graphDataToShow

  return (
    <div className={styles.tableWrapper}>
      <div className={styles.tableContainer}>
        <Table className={classNames(styles.table)}>
          <thead>
            <tr>
              <th>{title === 'No breakdown' ? '' : title}</th>
              {graphDateRange.map((item) => {
                const d = moment(item, 'YYYY-MM-DD')
                let showDate = `${d.format('MMM YY')}`
                if (width && width < 768) {
                  showDate = d.format('MMM YY')
                }
                if (isMobile) {
                  showDate = `${d.format('DD/MM')}`
                }
                if (granularity === 'daily' || granularity === 'weekly') {
                  showDate = `${d.format('DD MMM YY')}`
                }
                if (granularity === 'quarterly') {
                  const month = d.month() + 1
                  const q = Math.floor((month + 3) / 3)
                  showDate = `Q${q} ${d.format('YYYY')}`
                }

                return <th key={nanoid()}>{showDate}</th>
              })}
            </tr>
          </thead>
          <tbody className={classNames(styles.tableBody)}>
            {(graphData.length > 1 ||
              (graphData[0] && graphData[0].dimensionName !== 'NOT_STACKED')) &&
              graphData.map((item) => {
                return (
                  <tr key={nanoid()}>
                    <td>
                      <span>
                        {item.dimensionName === ''
                          ? '(empty)'
                          : item.dimensionName.replace('NOT_STACKED', 'All')}
                      </span>
                    </td>
                    {item.dimensionValues.map((val) => {
                      const displayValue = showDurationUnits
                        ? secondsToHms(val) || '0s'
                        : formatValueForDisplay(val, units)
                      return <td key={nanoid()}>{displayValue}</td>
                    })}
                  </tr>
                )
              })}
            {totalsData && (
              <tr className={styles.totalsRow}>
                <td>
                  <span>Total</span>
                </td>
                {totalsData.map((item) => {
                  const displayValue = showDurationUnits
                    ? secondsToHms(item) || '0s'
                    : formatValueForDisplay(item, units)
                  return <td key={nanoid()}>{displayValue}</td>
                })}
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  )
}

export interface PerformanceReportGraphProps {
  width: number
  graphWidth: number
  offset: number
  granularity: Granularity
  isMobile: boolean
  totalsData?: number[] | null
  graphType: string
  availableDimensions: AvailableDimension[] | null
  breakdownDimension: string
  successMetricDisplayName: string
  children: React.ReactChild
  units: string
  loading?: boolean
  title: string
  showDurationUnits?: boolean
  graphDataToShow: {
    graphDateRange: string[]
    graphData: DashboardGraphData[]
  } | null
  condensedGraphData: {
    graphDateRange: string[]
    graphData: DashboardGraphData[]
  } | null
}

export default function PerformanceReportGraph({
  width,
  graphWidth,
  offset,
  granularity,
  isMobile,
  totalsData,
  graphType = 'bar',
  availableDimensions,
  breakdownDimension,
  successMetricDisplayName,
  children,
  units,
  loading = false,
  title,
  showDurationUnits,
  graphDataToShow,
  condensedGraphData,
}: PerformanceReportGraphProps): React.ReactElement {
  const savedState = getUserData()
  const initialState =
    savedState && savedState.toggleGraphDataTable
      ? savedState.toggleGraphDataTable
      : false
  const [expanded, setExpanded] = useState(initialState)
  const [heighligtedIndex, setHeighligtedIndex] = useState<null | number>(null)
  const [crosshairData, setCrosshairData] = useState<
    { x: number; y: number }[]
  >([])
  const [hintData, setHintData] = useState<
    {
      position: {
        x: number
        y: number
      }
      date: string
      right: boolean
      colour: string
      title: string
      value: number
    }[]
  >([])

  const graphHeight = (graphWidth >= 800 ? 320 : 200) + offset

  const desktopMargin = {
    bottom: 30 + offset,
    left: 60,
    right: 20,
    top: 20,
  }
  const mobileMargin = {
    bottom: 30 + offset,
    left: 40,
    right: 10,
    top: 20,
  }

  const graphDataGrouped = useMemo(() => {
    if (
      !condensedGraphData ||
      !condensedGraphData.graphData ||
      !condensedGraphData.graphDateRange
    ) {
      return []
    }

    const result = {}

    const graphDataHasOther = condensedGraphData.graphData.find(
      (item) => item.dimensionName === '(Other)',
    )

    if (
      ['int', 'float'].indexOf(units) === -1 ||
      graphDataHasOther ||
      condensedGraphData.graphData.length <= 20
    ) {
      condensedGraphData.graphData.forEach((item) => {
        const { dimensionName, dimensionValues } = item
        result[dimensionName] = dimensionValues
      })
    } else {
      // Sort data by combined size and show top 20 labels
      const sortedGraphData = _.cloneDeep(condensedGraphData.graphData).sort(
        (a, b) => {
          const aTotal = a.dimensionValues.reduce((acc, curr) => acc + curr, 0)
          const bTotal = b.dimensionValues.reduce((acc, curr) => acc + curr, 0)

          return bTotal - aTotal
        },
      )

      const itemsToShow = sortedGraphData.splice(0, 20)

      itemsToShow.forEach((item) => {
        const { dimensionName, dimensionValues } = item
        result[dimensionName] = dimensionValues
      })

      // Graph data is now the remaining ones
      // Aggregate them and add as a separate
      if (sortedGraphData.length > 0) {
        result['(Other)'] = sortedGraphData[0].dimensionValues.reduce<number[]>(
          (acc, curr, index) => {
            let total = 0

            sortedGraphData.forEach((item) => {
              total += item.dimensionValues[index]
            })

            acc.push(total)

            return acc
          },
          [],
        )
      }
    }

    const mergedResult: any[] = []
    const keys = Object.keys(result)

    keys.forEach((key, index) => {
      mergedResult.push({
        title: key,
        colour: getColour(index),
        data: result[key].map((val, valIndex) => {
          return {
            x: valIndex,
            y: val,
            y0: 0,
            yearMonth: condensedGraphData.graphDateRange
              ? condensedGraphData.graphDateRange[valIndex]
              : '',
          }
        }),
      })
    })

    return mergedResult
  }, [condensedGraphData, availableDimensions])

  const tickValues = useMemo(() => {
    if (
      condensedGraphData &&
      condensedGraphData.graphData.length > 0 &&
      condensedGraphData.graphDateRange.length > 0
    ) {
      if (granularity === 'daily') {
        const starts: string[] = []
        const startString =
          isMobile && condensedGraphData.graphDateRange.length > 35
            ? 'month'
            : 'week'
        condensedGraphData.graphDateRange.forEach((date) => {
          const item = moment(date, 'YYYY-MM-DD')
          const weekStart = item
            .startOf(startString)
            .add(1, 'day')
            .format('YYYY-MM-DD')
          if (starts.indexOf(weekStart) === -1) {
            starts.push(weekStart)
          }
        })
        return starts
          .map((item) => {
            // @ts-ignore
            return condensedGraphData.graphDateRange.indexOf(item)
          })
          .filter((item) => item !== -1)
      }
      return undefined
    }
    return undefined
  }, [condensedGraphData, granularity, isMobile])

  const xAxisLabel = useMemo(() => {
    const longAxis =
      condensedGraphData && condensedGraphData.graphDateRange.length > 35
    if (
      granularity === 'monthly' ||
      (isMobile && granularity === 'daily' && longAxis)
    ) {
      return 'Month'
    }
    if (granularity === 'quarterly') {
      return 'Quarter'
    }
    return 'Week commencing Monday'
  }, [granularity, isMobile, condensedGraphData])

  if (loading) {
    return (
      <div
        className={styles.graphPaper}
        style={{
          height: `${graphHeight}px`,
          width: `auto`,
        }}
      >
        {children}
        <div className={styles.graphContainer}>
          <Preloader
            style={{
              width: 60,
              height: 50,
              marginTop: graphHeight / 2 - 30,
              marginBottom: graphHeight / 2 - 30,
            }}
          />
        </div>
      </div>
    )
  }

  if (
    !loading &&
    (condensedGraphData === null ||
      condensedGraphData.graphData.length === 0 ||
      condensedGraphData.graphDateRange.length === 0)
  ) {
    return (
      <div
        className={styles.graphPaper}
        style={{
          height: `${graphHeight}px`,
          width: `auto`,
        }}
      >
        {children}
        <div className={styles.graphContainer}>
          <NoDataMessage />
        </div>
      </div>
    )
  }

  return (
    <div className={styles.graphPaper}>
      <div className={styles.titleRow}>
        <div>{children}</div>
        <div>
          <div className={styles.tabsWrapper}>
            <Input
              className={styles.tabs}
              name="expand"
              id={nanoid()}
              label="&nbsp;"
              type="radio"
              value="expand"
              checked={expanded}
              onClick={(e): any => {
                e.preventDefault()
                setExpanded(false)
                saveUserData({ toggleGraphDataTable: false })
              }}
            >
              <img src={graphIcon} alt="graph icon" />
            </Input>
            <Input
              className={styles.tabs}
              name="expand"
              id={nanoid()}
              label="&nbsp;"
              type="radio"
              value="collapse"
              checked={!expanded}
              onClick={(e): any => {
                e.preventDefault()
                setExpanded(true)
                saveUserData({ toggleGraphDataTable: true })
              }}
            >
              <img src={linesIcon} alt="lines icon" />
            </Input>
          </div>
        </div>
      </div>
      {expanded ? (
        <div>
          <GraphDataTable
            title={title}
            width={width}
            granularity={granularity}
            isMobile={isMobile}
            // Table data should include zero-data
            graphDataToShow={graphDataToShow}
            totalsData={totalsData}
            units={units}
            showDurationUnits={showDurationUnits}
          />
        </div>
      ) : (
        <div className={styles.graphContainer}>
          {graphDataGrouped.length > 1 && (
            <div className={styles.colorLegendWrapper}>
              <DiscreteColorLegend
                orientation="horizontal"
                items={graphDataGrouped.map((item, index) => {
                  const { title: itemTitle } = item
                  const isOther = index === 0 && itemTitle === '(Other)'
                  const useTitle = itemTitle
                  const colour = getColour(index)
                  return (
                    <div
                      className={styles.colorLegendDot}
                      onMouseEnter={() => {
                        setHeighligtedIndex(index)
                      }}
                      onMouseLeave={() => {
                        setHeighligtedIndex(null)
                      }}
                    >
                      {isOther && (
                        <Tooltip
                          id="other-tooltip"
                          className={styles.tooltip}
                          maxWidth={500}
                          tooltipPosition="right"
                          tooltipMessage={`Other (non top 20 ${title.toLowerCase()}(s))`}
                        >
                          <>
                            <div
                              className={styles.dot}
                              style={{
                                backgroundColor: colour,
                                color: colour,
                              }}
                            />
                            <span className={styles.dotTitle}>
                              {itemTitle === '' ? '(empty)' : useTitle}
                            </span>
                          </>
                        </Tooltip>
                      )}
                      {!isOther && (
                        <>
                          <div
                            className={styles.dot}
                            style={{
                              backgroundColor: colour,
                              color: colour,
                            }}
                          />
                          <span className={styles.dotTitle}>
                            {itemTitle === '' ? '(empty)' : useTitle}
                          </span>
                        </>
                      )}
                    </div>
                  )
                })}
              />
            </div>
          )}
          <XYPlot
            onMouseLeave={() => {
              setCrosshairData([])
              setHintData([])
            }}
            className={styles.graphWrapper}
            height={graphHeight}
            width={graphWidth}
            margin={isMobile ? mobileMargin : desktopMargin}
            stackBy={graphType === 'line' ? undefined : 'y'}
          >
            <HorizontalGridLines />
            <VerticalGridLines />

            {graphType === 'bar' &&
              graphDataGrouped.map((item, index) => {
                let colour = getColour(index)
                let opacity = 1

                if (heighligtedIndex !== null && heighligtedIndex !== index) {
                  opacity = 0.4
                  colour = '#718096'
                }

                return (
                  // @ts-ignore
                  <VerticalBarSeries
                    key={item.title}
                    onSeriesMouseOut={() => setHintData([])}
                    onValueMouseOver={(datapoint) => {
                      const { x, y0 } = datapoint
                      const dim = graphDataGrouped[index]
                      const value = dim.data[x].y
                      setHintData([
                        {
                          position: {
                            // @ts-ignore
                            x,
                            y: y0 + Math.round(value / 2),
                          },
                          title: `${dim.title || '(empty)'}`,
                          colour: getColour(index),
                          right: dim.data.length * 0.8 >= (x as number),
                          // @ts-ignore
                          date: condensedGraphData.graphDateRange[x],
                          value,
                        },
                      ])
                    }}
                    opacity={opacity}
                    data={item.data}
                    color={colour}
                  />
                )
              })}
            {graphType === 'line' && (
              <Crosshair values={crosshairData} className={styles.crossHair}>
                <></>
              </Crosshair>
            )}
            {graphType === 'line' &&
              graphDataGrouped.map((item, index) => {
                return (
                  <LineSeries
                    key={item.title}
                    // curve="curveMonotoneX"
                    onSeriesMouseOut={() => {
                      setHintData([])
                    }}
                    onNearestX={(datapoint) => {
                      const { x } = datapoint
                      const hintDataTemp: any[] = []
                      graphDataGrouped.forEach((data, dataIndex) => {
                        const value = data.data[x].y
                        hintDataTemp.push({
                          position: { x, y: 0 },
                          title: data.title,
                          colour: getColour(dataIndex),
                          right: data.data.length * 0.8 >= x,
                          // @ts-ignore
                          date: condensedGraphData.graphDateRange[x],
                          value,
                        })
                      })
                      setHintData(hintDataTemp)
                      setCrosshairData([{ x, y: 0 }])
                    }}
                    data={item.data}
                    color={getColour(index)}
                  />
                )
              })}
            {hintData.length > 0 && (
              <Hint
                align={{
                  horizontal: hintData[0].right ? 'right' : 'left',
                  vertical: 'top',
                }}
                key={nanoid()}
                value={hintData[0].position}
                className={classNames(styles.hint, {
                  [styles.hintLineChart]: graphType === 'line',
                })}
              >
                <div className={styles.hintBox}>
                  {hintData.map((item, itemIndex) => {
                    const d = moment(item.date)

                    let showDate = d.format(dateFormatShort)

                    switch (granularity) {
                      case 'quarterly':
                        showDate = `Q${Math.floor(
                          (d.month() + 1 + 3) / 3,
                        )} ${d.format('YYYY')}`
                        break
                      case 'monthly':
                        showDate = d.format(dateFormatShortMonth)
                        break
                      case 'weekly':
                        showDate = d.format(dateFormatShortWeek)
                        break
                      default:
                        break
                    }

                    return (
                      <div key={nanoid()}>
                        <h3 className={styles.hintTitle}>
                          <div
                            className={styles.hintColour}
                            style={{
                              backgroundColor: item.colour,
                            }}
                          />
                          <span>
                            {breakdownDimension !== ''
                              ? `${breakdownDimension}, `
                              : ''}
                            {item.title === 'NOT_STACKED' ? 'All' : item.title}:{' '}
                            {numeral(item.value).format('0,0.[0]a')}
                          </span>
                        </h3>
                        {hintData.length - 1 === itemIndex && (
                          <h4 className={styles.hintDate}>{showDate}</h4>
                        )}
                      </div>
                    )
                  })}
                </div>
              </Hint>
            )}
            <XAxis
              tickSizeOuter={10}
              tickLabelAngle={
                granularity === 'monthly' || granularity === 'quarterly'
                  ? 0
                  : -45
              }
              tickValues={tickValues}
              // @ts-ignore
              tickFormat={(i: number, index: number): string => {
                // check if number is decimal
                if (
                  i % 1 !== 0 ||
                  i === -1 ||
                  graphDataGrouped[0].data.length <= i
                ) {
                  return ''
                }
                const d = moment(
                  graphDataGrouped[0].data[i].yearMonth,
                  'YYYY-MM-DD',
                )
                // leave out every second number
                if (width && width < 768) {
                  return index % 2 ? '' : d.format('MMM YY')
                }
                if (isMobile) {
                  return `${d.format('DD/MM')}`
                }
                if (granularity === 'daily' || granularity === 'weekly') {
                  return `${d.format('DD MMM YY')}`
                }
                if (granularity === 'quarterly') {
                  const month = d.month() + 1
                  const q = Math.floor((month + 3) / 3)
                  return `Q${q} ${d.format('YYYY')}`
                }
                return `${d.format('MMM YY')}`
              }}
            />
            <YAxis
              title={`${successMetricDisplayName}${
                units === 'percentage' ? ' (%)' : ''
              }${showDurationUnits ? ' (s)' : ''}`}
              tickFormat={(i: string): string => {
                return numeral(i).format('0,0.[0]a')
              }}
            />
          </XYPlot>
          <p className={styles.xAxislabel}>{xAxisLabel}</p>
        </div>
      )}
    </div>
  )
}
