import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { nanoid } from 'nanoid'

import { GRAPHQL_API_BASE_DOMAIN } from '../api/constants'
import Button from '../components/button'
import Link from '../components/link'
import SiteLogo, { LogoTagline } from '../components/site-logo'
import { supportEmail } from '../core/constants'
import { setToken } from '../helpers'
import useLogAction from '../hooks/useLogAction'
import styles from '../styles/generic-page.module.scss'

interface Props {
  match: any
}

export function SetToken({ token }: { token: string }): void {
  setToken(token)
  // @ts-ignore
  if (window.dataLayer && window.dataLayer.push) {
    // @ts-ignore
    window.dataLayer.push({ event: 'csv-download', token })
  }
}

export default function DirectDownload({ match }: Props): React.ReactElement {
  const logAction = useLogAction()

  const history = useHistory()

  const { downloadId } = match.params

  // This will error if not logged in
  // TODO: Fix
  useEffect(() => {
    logAction({
      variables: {
        action: 'direct-download',
        extra: '',
        websiteSection: 'track',
        functionName: 'directDownload',
        pagePath: window.location.pathname,
      },
    })
  }, [])

  return (
    <div className={styles.ssoWrapperOuter}>
      <section className={styles.ssoWrapper}>
        <SiteLogo link className={styles.svg} />
        <LogoTagline />
        <div className={styles.box}>
          <p>
            The campaign links that have been shared with you will soon
            download. Please check your downloads folder.
          </p>
          <p>
            If you need support please contact:{' '}
            <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>
          </p>
          <div style={{ display: 'none' }}>
            <iframe
              title="download"
              src={`${GRAPHQL_API_BASE_DOMAIN}track/direct-download/${downloadId}`}
            />
          </div>
        </div>
        <Button
          onPress={() =>
            history.push({
              pathname: '/login',
              key: nanoid(),
              state: {
                applied: true,
              },
            })
          }
        >
          Login
        </Button>
      </section>
    </div>
  )
}
