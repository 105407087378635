import React, { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { useLocation } from 'react-router-dom'

import { getAccountDataSource } from '../api/graphql/workspace-client'
import { getCompanyDetails } from '../api/graphql/company-client'
import Stars from '../assets/star-cluster-yellow.svg'
import CampaignIntroContent from '../components/campaign-intro-content'
import Intro from '../components/intro'
import Layout from '../components/layout'
import Link from '../components/link'
import { LoadingLabel } from '../components/loader'
import SiteWrapper from '../components/site-wrapper'
import Tag from '../components/tag'
import TrackViewTable from '../components/track-view-table'
import { NoteText } from '../components/typography'
import { getDataSourceDescription } from '../helpers/report-module'

interface LocationState {
  fromBulk?: boolean
}

export default function TrackView() {
  const { state } = useLocation<LocationState>()

  const bulkMessageType = useMemo(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const bulkEditParam = searchParams.get('bulkEdit')

    if (bulkEditParam) return 'Edit'

    return state && state.fromBulk ? 'New' : false
  }, [])

  const { data: companyData } = useQuery(getCompanyDetails)
  const { data: dataSourceData } = useQuery(getAccountDataSource)

  const dataSourceDescription = useMemo(() => {
    return getDataSourceDescription(dataSourceData, false)
  }, [dataSourceData])

  const isConnected = useMemo(() => {
    if (!companyData && !dataSourceData) return false

    if (
      dataSourceData &&
      dataSourceData.currentAccount.blankAccount &&
      dataSourceData.currentAccount.dataSource?.kind !== 'AA_REPORT'
    )
      return false

    if (
      companyData &&
      companyData.currentCompany.subscriptionLevel === 'preSetup'
    )
      return false

    return (
      dataSourceData && !!dataSourceData.currentAccount.dataSource?.connected
    )
  }, [companyData, dataSourceData])

  return (
    <SiteWrapper>
      <Layout width={2560}>
        <Intro title="View links">
          <CampaignIntroContent
            tagLine={
              <p>
                Review, share and edit campaign links.
                {isConnected && (
                  <>
                    {dataSourceDescription ? (
                      ` ${dataSourceDescription}`
                    ) : (
                      <>
                        {' '}
                        Metrics from: <LoadingLabel label="Loading" />
                      </>
                    )}
                  </>
                )}
              </p>
            }
          >
            <>
              {!isConnected && (
                <Tag inline icon={Stars}>
                  <p>
                    <Link href="/connect">Connect your analytics platform</Link>{' '}
                    to compare metrics for each campaign link.
                  </p>
                </Tag>
              )}
              {!!bulkMessageType && (
                <NoteText>
                  <>
                    Your {bulkMessageType === 'New' ? 'new ' : ''}links may take
                    up to a minute to be{' '}
                    {bulkMessageType === 'New' ? 'created' : 'updated'}. Please
                    reload the page if you do not see them here yet.
                  </>
                </NoteText>
              )}
            </>
          </CampaignIntroContent>
        </Intro>

        <TrackViewTable />
      </Layout>
    </SiteWrapper>
  )
}
