import React, { useCallback, useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import { motion, useAnimate } from 'framer-motion'
import classNames from 'classnames'

import Button, { NavigateButton } from './button'
import { Preloader } from './loader'
import { Heading } from './typography'
import CloseIcon from '../assets/icon-close-grey.svg'
import CloseIconWhite from '../assets/icon-close-white.svg'
import styles from '../styles/modal.module.scss'

interface ModalProps {
  /** The back/close buttons will always close the modal but can also run custom functionality beforehand */
  beforeClose?: () => void | Promise<void>
  /** Best used when closing modal involves navigation. In this case the modal animation will have no target so must happen before the navigation */
  afterClose?: () => void | Promise<void>
  /** State setter for opening/closing the modal */
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  /** Should the modal have an 'x' button in the top-right */
  hideCloseButton?: boolean
  /** Adds a warning icon to the modal header. Ignored if modalHeader not present */
  isWarning?: boolean
  /** If true, replace all modal body content with loading animation */
  loading?: boolean
  /** If text, uses h3 */
  modalHeader?: React.ReactNode
  /** Color of the header banner */
  headerColor?: 'pink' | 'green'
  /** Should the main site content be readable or blurred? */
  blurredBackground?: boolean
  /** Width of the modal. */
  width?: 'superNarrow' | 'normal' | 'wide' | 'superWide'
  /** Text for the back button in the footer */
  noText?: string
  /** Text for the main CTA. Only used if 'onYes' is defined */
  yesText?: React.ReactNode
  /** Functionality on click of primary action button. Leave undefined to hide button. This can include 'close' state setter if required */
  onYes?: () => void | Promise<void>
  yesButtonDisabled?: boolean
  yesButtonLoading?: boolean
  /** React node used between the back button and primary action button */
  footerContent?: React.ReactNode
  /** Set if the modal should not have a 'Back' button in the bottom-left corner */
  hideFooter?: boolean
  /** ID of HTML form that should be submitted on click of Yes button */
  form?: string
  className?: string
  children?: React.ReactNode
}

const Modal = ({
  beforeClose,
  afterClose,
  setIsOpen,
  hideCloseButton,
  isWarning = false,
  loading = false,
  modalHeader,
  headerColor,
  blurredBackground = false,
  width = 'normal',
  noText,
  yesText,
  onYes,
  yesButtonDisabled,
  yesButtonLoading,
  footerContent,
  hideFooter,
  form,
  className,
  children,
}: ModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null)

  const [scope, animate] = useAnimate()

  // Animate closing the modal
  const closeModal = useCallback(async () => {
    if (beforeClose) await beforeClose()

    await animate(scope.current, { opacity: 0 }, { duration: 0.2 })

    setIsOpen(false)

    if (afterClose) await afterClose()
  }, [scope, beforeClose, afterClose])

  // Focus trapping to modal
  // https://medium.com/cstech/achieving-focus-trapping-in-a-react-modal-component-3f28f596f35b
  useEffect(() => {
    const modalElement = modalRef.current

    if (!modalElement) return

    // Get all elements in the modal that are focusable
    const focusableElements = modalElement.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
    )

    const firstElement = focusableElements[0] as HTMLElement
    const lastElement = focusableElements[
      focusableElements.length - 1
    ] as HTMLElement

    const handleTabKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Tab') {
        if (modalElement.contains(document.activeElement)) {
          // Tab backwards
          if (event.shiftKey && document.activeElement === firstElement) {
            event.preventDefault()
            lastElement.focus()
          }
          // Tab forwards
          else if (!event.shiftKey && document.activeElement === lastElement) {
            event.preventDefault()
            firstElement.focus()
          }
        } else {
          // Tab to the first element in the modal
          firstElement.focus()
        }
      }
    }

    // Close modal on press of Escape key
    const handleEscapeKeyPress = async (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        await closeModal()
      }
    }

    document.addEventListener('keydown', handleTabKeyPress)
    document.addEventListener('keydown', handleEscapeKeyPress)

    // eslint-disable-next-line consistent-return
    return () => {
      document.removeEventListener('keydown', handleTabKeyPress)
      document.removeEventListener('keydown', handleEscapeKeyPress)
    }
  }, [modalRef, closeModal])

  const modalRoot = document.getElementById('modals')

  if (!modalRoot) return null

  return createPortal(
    <>
      <style>
        {`
					#root, body {
						overflow: hidden !important;
					}
					`}
      </style>
      {/* Background */}
      <motion.div
        ref={scope}
        className={classNames(styles.modalBackground, {
          [styles.blurredBackground]: blurredBackground,
        })}
        initial="hidden"
        animate="show"
        variants={{
          hidden: { opacity: 0 },
          show: {
            opacity: 1,
            transition: {
              ease: 'easeInOut',
              duration: 0.2,
            },
          },
        }}
      >
        {/* Modal */}
        <motion.div
          ref={modalRef}
          className={classNames(className, styles.modalContainer, [
            styles[width],
          ])}
          variants={{
            hidden: { marginTop: 40 },
            show: {
              marginTop: 0,
              transition: {
                ease: 'easeInOut',
                duration: 0.2,
              },
            },
          }}
        >
          {/* Header */}
          {modalHeader && (
            <div
              className={classNames(styles.modalHeader, {
                [styles.warning]: isWarning,
                [styles[headerColor || '']]: !!headerColor,
              })}
            >
              <div>
                {typeof modalHeader === 'string' ? (
                  <Heading type={3} align="left">
                    {modalHeader}
                  </Heading>
                ) : (
                  modalHeader
                )}
              </div>
              {!hideCloseButton && (
                <Button
                  variant="iconOnly"
                  icon={{
                    src:
                      modalHeader &&
                      headerColor &&
                      !(headerColor === 'pink' && isWarning)
                        ? CloseIconWhite
                        : CloseIcon,
                    alt: 'Close',
                    imgHeight: 15,
                  }}
                  onPress={closeModal}
                />
              )}
            </div>
          )}
          {!modalHeader && !hideCloseButton && (
            <Button
              className={styles.closeButton}
              variant="iconOnly"
              icon={{
                src:
                  modalHeader &&
                  headerColor &&
                  !(headerColor === 'pink' && isWarning)
                    ? CloseIconWhite
                    : CloseIcon,
                alt: 'Close',
                imgHeight: 15,
              }}
              onPress={closeModal}
            />
          )}
          {/* Body */}
          <div
            className={classNames(styles.modalBody, {
              [styles.modalLoading]: loading,
            })}
          >
            {loading ? (
              <Preloader
                className={styles.loading}
                style={{ height: 50, width: 70 }}
              />
            ) : (
              children
            )}
          </div>
          {/* Footer */}
          {!hideFooter && (
            <div
              className={classNames(styles.modalFooter, {
                [styles.hasButton]: !!onYes,
              })}
            >
              <NavigateButton back onPress={closeModal}>
                {noText || 'Back'}
              </NavigateButton>
              {footerContent && (
                <div className={styles.footerInner}>{footerContent}</div>
              )}
              {onYes && (
                <Button
                  loading={yesButtonLoading}
                  isDisabled={yesButtonDisabled}
                  onPress={onYes}
                  color={headerColor}
                  type={form ? 'submit' : undefined}
                  form={form}
                >
                  {yesText || 'Continue'}
                </Button>
              )}
            </div>
          )}
        </motion.div>
      </motion.div>
    </>,
    modalRoot,
  )
}

export default Modal
