import React, { useMemo } from 'react'
import { LazyQueryExecFunction, useLazyQuery, useQuery } from '@apollo/client'
import _ from 'lodash'

import { Label } from './input'
import Link from './link'
import { LoadingLabel } from './loader'
import { FieldSlot, FormRow, LabelSlot } from './row'
import SelectBox from './select-box'
import Tooltip from './tooltip'
import {
  getIntegrationsStatus,
  getSalesforceMCTemplateList,
} from '../api/graphql/integrations-client'
import { htmlHasLinks } from '../helpers/track-module'
import styles from '../styles/email-code-generator-form.module.scss'
import {
  Exact,
  GetSalesforceMcEmailTemplateDetailQuery,
} from '../__gql-types__/graphql'

export interface EmailTemplateSlot {
  slotID: string
  hasLinks: boolean
  content: string
  blocks: {
    blockID: string
    content: string
  }[]
}

type TemplateType = 'template' | 'templatebasedemail'

export interface SalesforceMCEmailDetails {
  emailSource: 'salesforceMC'
  businessUnitId: string | null
  businessUnitName: string | null
  emailHtml: string
  templateID: string | null
  templateType: TemplateType | null
  /** template only requires updating the HTML, templatebasedemail needs slotMap updated too */
  slotMap: EmailTemplateSlot[] | null
  /** templatebasedemail needs textContent updated */
  textContent: string | null
  updateSuccess: boolean
}

export interface SalesforceMCEmailFieldsProps {
  emailDetails: SalesforceMCEmailDetails
  setEmailDetails: React.Dispatch<React.SetStateAction<any>>
  fetchSFMCEmailHTML: LazyQueryExecFunction<
    GetSalesforceMcEmailTemplateDetailQuery,
    Exact<{
      mid: string
      templateID: string
    }>
  >
  errorFetchingSFMCEmailHtml: boolean
}

const SalesforceMCEmailFields = ({
  emailDetails,
  setEmailDetails,
  fetchSFMCEmailHTML,
  errorFetchingSFMCEmailHtml,
}: SalesforceMCEmailFieldsProps) => {
  const {
    data: salesforceMCIntegrationData,
    loading: loadingSFBusinessUnits,
  } = useQuery(getIntegrationsStatus)

  const [
    getSFMCEmailTemplates,
    {
      data: sfEmailTemplatesData,
      loading: loadingSFTemplates,
      error: errorFetchingSFMCTemplates,
    },
  ] = useLazyQuery(getSalesforceMCTemplateList)

  // Business units are only required for classic template
  const sfBusinessUnits = useMemo(() => {
    if (!salesforceMCIntegrationData) return []

    const isConnected =
      salesforceMCIntegrationData.currentCompany
        .salesforceMcIntegrationStatus === 'active'

    if (!isConnected) return []

    const businessUnits =
      salesforceMCIntegrationData.currentCompany.salesforceMcIntegrationList

    // Set business unit to only available option
    // And request templates for that business unit
    if (businessUnits.length === 1) {
      setEmailDetails({
        ...emailDetails,
        businessUnitName: businessUnits[0].businessUnitName,
        businessUnitId: businessUnits[0].mid,
      })

      getSFMCEmailTemplates({
        variables: {
          mid: businessUnits[0].mid,
        },
      })
    }

    return businessUnits
  }, [salesforceMCIntegrationData])

  const sfEmailTemplates = useMemo(() => {
    if (!sfEmailTemplatesData) return []

    return sfEmailTemplatesData.track.salesforceMarketingCloudQueries
      .getTemplateList
  }, [sfEmailTemplatesData])

  const { slotMapHasNoLinks, textContentHasNoLinks } = useMemo(() => {
    // 'template' type does not use slotMap - no need to check
    if (
      emailDetails.templateType === 'template' ||
      !emailDetails.slotMap ||
      !emailDetails.textContent
    )
      return { slotMapHasNoLinks: false, textContentHasNoLinks: false }

    // Short sharp check for links in any of the slots
    // String concatenation is fine here - only checking, not replacing
    return {
      slotMapHasNoLinks: !emailDetails.slotMap.find((slot) => {
        return slot.hasLinks
      }),
      textContentHasNoLinks: !htmlHasLinks(emailDetails.textContent, true),
    }
  }, [
    emailDetails.templateType,
    emailDetails.slotMap,
    emailDetails.textContent,
  ])

  return (
    <>
      {sfBusinessUnits.length > 1 && (
        <FormRow>
          <LabelSlot className={styles.labelSlot}>
            <Label id="salesforceBusinessUnit">
              <Tooltip
                id="salesforce-business-unit-tooltip"
                useIcon
                tooltipPosition="right"
                tooltipMessage={
                  <>
                    <p>
                      The name of the Salesforce Marketing Cloud business unit
                      that has the email template you want to find and replace
                      links in.
                    </p>
                  </>
                }
              >
                Salesforce MC business unit
              </Tooltip>
            </Label>
          </LabelSlot>
          <FieldSlot>
            <SelectBox
              id="salesforceBusinessUnit"
              isClearable
              labelKey="pardotName"
              valueKey="pardotID"
              placeholder="Select business unit or start typing"
              isLoading={loadingSFBusinessUnits}
              loadingMessage={() => (
                <LoadingLabel label="Checking integrations" />
              )}
              value={sfBusinessUnits.find(
                (option) => option.mid === emailDetails.businessUnitId,
              )}
              options={sfBusinessUnits}
              onChange={async (newValue) => {
                setEmailDetails({
                  ...emailDetails,
                  businessUnitId: newValue?.mid || '',
                  emailHtml: '',
                  templateID: null,
                  templateType: null,
                  slotMap: null,
                  textContent: null,
                  updateSuccess: false,
                })

                if (newValue) {
                  getSFMCEmailTemplates({
                    variables: {
                      mid: newValue.mid,
                    },
                  })
                }
              }}
            />
          </FieldSlot>
        </FormRow>
      )}
      <FormRow>
        <LabelSlot className={styles.labelSlot}>
          <Label id="salesforceEmailTemplate">
            <Tooltip
              id="salesforce-email-template-tooltip"
              useIcon
              tooltipPosition="right"
              tooltipMessage={
                <>
                  <p>The name of the email in Salesforce.</p>
                  <p>
                    <strong>Your Salesforce Account:</strong>{' '}
                    {emailDetails.businessUnitName} (
                    {emailDetails.businessUnitId})
                  </p>
                </>
              }
            >
              Email template
            </Tooltip>
          </Label>
        </LabelSlot>
        <FieldSlot>
          <SelectBox
            id="salesforceEmailTemplate"
            isClearable
            labelKey="templateName"
            valueKey="templateID"
            placeholder="Select email or start typing"
            isDisabled={!loadingSFTemplates && sfEmailTemplates.length === 0}
            isLoading={loadingSFTemplates}
            loadingMessage={() => <LoadingLabel label="Fetching templates" />}
            error={!!emailDetails.templateID && !!errorFetchingSFMCEmailHtml}
            aria-errormessage="email-template-error"
            value={sfEmailTemplates.find(
              (template) => template.templateID === emailDetails.templateID,
            )}
            options={sfEmailTemplates}
            onChange={async (newValue) => {
              const newEmailDetails: SalesforceMCEmailDetails = {
                ...emailDetails,
                templateID: newValue?.templateID || null,
                templateType: (newValue?.templateType as TemplateType) || null,
                slotMap: null,
                textContent: null,
                emailHtml: '',
                updateSuccess: false,
              }

              if (!newValue || !emailDetails.businessUnitId) return

              const { templateID } = newValue

              const { data: htmlData } = await fetchSFMCEmailHTML({
                variables: {
                  mid: emailDetails.businessUnitId,
                  templateID,
                },
              })

              if (
                !htmlData ||
                !htmlData.track.salesforceMarketingCloudQueries
                  .getTemplateDetail.templateContent
              ) {
                setEmailDetails(newEmailDetails)
                return
              }

              const {
                templateContent,
                templateType,
                slotMap,
                textContent,
              } = htmlData.track.salesforceMarketingCloudQueries.getTemplateDetail

              // Check if each slot in slotMap has a link
              // If yes, flag the slot for updating
              // Only need to do for templatebasedemail
              const flaggedSlotMap =
                templateType === 'templatebasedemail' &&
                Array.isArray(slotMap) &&
                slotMap.length > 0
                  ? slotMap.map((slot) => {
                      let hasLinks = htmlHasLinks(slot.content)

                      // For efficiency, only run this check if main content does not have links
                      if (!hasLinks) {
                        hasLinks = !!slot.blocks.find((block) =>
                          htmlHasLinks(block.content),
                        )
                      }

                      return {
                        ...slot,
                        hasLinks,
                      }
                    })
                  : null

              newEmailDetails.templateID = templateID
              newEmailDetails.templateType = templateType as TemplateType
              newEmailDetails.emailHtml = templateContent as string
              newEmailDetails.slotMap = flaggedSlotMap
              newEmailDetails.textContent = textContent

              setEmailDetails(newEmailDetails)
            }}
          />
          {!errorFetchingSFMCTemplates &&
            !errorFetchingSFMCEmailHtml &&
            !loadingSFTemplates &&
            emailDetails.businessUnitId &&
            sfEmailTemplates.length === 0 && (
              <p className={styles.footNoteError} id="email-template-error">
                No email templates found for the selected business unit.
              </p>
            )}
          {!errorFetchingSFMCTemplates &&
            !!emailDetails.templateID &&
            !!errorFetchingSFMCEmailHtml && (
              <p className={styles.footNoteError} id="email-template-error">
                Error fetching email HTML. Please reload the page or contact
                support (
                <Link href="mailto:support@uplifter.ai">
                  support@uplifter.ai
                </Link>
                ).
              </p>
            )}
          {errorFetchingSFMCTemplates && (
            <p className={styles.footNoteError} id="email-template-error">
              Error fetching email templates. Please reload the page or contact
              support (
              <Link href="mailto:support@uplifter.ai">support@uplifter.ai</Link>
              ).
            </p>
          )}
          {!errorFetchingSFMCTemplates &&
            !errorFetchingSFMCEmailHtml &&
            emailDetails.emailHtml &&
            !htmlHasLinks(emailDetails.emailHtml) &&
            slotMapHasNoLinks &&
            textContentHasNoLinks && (
              <p className={styles.footNoteError} id="email-template-error">
                No links found in this email template.
              </p>
            )}
        </FieldSlot>
      </FormRow>
    </>
  )
}

export default SalesforceMCEmailFields
