import { LogActionMutationVariables } from '../__gql-types__/graphql'
import {
  CampaignCodeGeneratorStructure,
  CodeType,
  DateFormats,
  ValidationChecks,
} from '../api/types'

const hostname = window.location.hostname.replace(/^www\./, '')

// Define mappings between hostnames and variables based on whitelabel
const whitelabelHostnameMappings: { [hostname: string]: string } = {
  'uplifter.uptimal.tech': 'uptimal',
  // Add more mappings as needed
}

/** The ID of the Google Analytics property to use for tracking */
export const gaMeasurementID = 'G-K357EMES7R'

// Extract the hostname from window.location and find the corresponding variable
export const whitelabelDomain =
  whitelabelHostnameMappings[hostname] || 'uplifter'

// Define the values based on the variable
const supportEmailMap = {
  uptimal: 'support@uplifter.ai',
  // TODO: Move to this when required
  // uptimal: 'accounts@uptimal.com',
  uplifter: 'support@uplifter.ai',
}

export const supportEmail = supportEmailMap[whitelabelDomain]

const brandNameMap = {
  uptimal: 'Uptimal builder',
  uplifter: 'Uplifter',
}

export const brandName = brandNameMap[whitelabelDomain]

export const usePaddleSandboxAccount = !(
  process.env.REACT_APP_PROD === 'TRUE' ||
  process.env.REACT_APP_STAGING_OVERRIDE
)
export const paddleVendorID = usePaddleSandboxAccount ? 12541 : 106860
// The subscription we use in Paddle, NOT the Paddle vendor ID
export const paddleSubscriptionID = usePaddleSandboxAccount ? 52360 : 580851

export const defaultShortLinkDomain = 'upl.inc'
export const minBatchShortLinks = 11
export const maxBatchShortLinks = 2500
export const siteContainerWidth = 1200
export const deleteWord = 'GOODBYE'
export const dateFormatShort = 'Do MMM YY'
export const dateFormatShortMonth = 'MMM YY'
export const dateFormatShortWeek = '[wk.] ww YYYY'
export const defaultAnomalyDateRange = 90
export const defaultAnomalyDateRangeLabel = 'Last 90days'
export const defaultCampaignTableRange = 30
export const defaultCampaignTableRangeLabel = 'Last 30days'
export const maxRowsExplainTable = 3

export const trackCreateTabs = {
  single: 'One at a time',
  multi: 'Multiple',
  cloneAndEdit: 'Clone and edit',
  bulkCSV: 'Bulk CSV',
  email: 'Email',
}

export const dateOptions: { name: string; value: DateFormats }[] = [
  {
    name: 'Day (UK, DD-MM-YYYY)',
    value: 'DD-MM-YYYY',
  },
  {
    name: 'Day (UK, DD/MM/YYYY)',
    value: 'DD/MM/YYYY',
  },
  {
    name: 'Day (UK, DDMMYYYY)',
    value: 'DDMMYYYY',
  },
  {
    name: 'Day (UK, DDMMYY)',
    value: 'DDMMYY',
  },
  {
    name: 'Day (US, MM-DD-YYYY)',
    value: 'MM-DD-YYYY',
  },
  {
    name: 'Day (US, MM/DD/YYYY)',
    value: 'MM/DD/YYYY',
  },
  {
    name: 'Day (US, MMDDYYYY)',
    value: 'MMDDYYYY',
  },
  {
    name: 'Quarter (YYYYq#)',
    value: 'YYYY[q]Q',
  },
  {
    name: 'Month (YYYYMM)',
    value: 'YYYYMM',
  },
  {
    name: 'Year (YYYY)',
    value: 'YYYY',
  },
]

export interface FullValidationCheck extends ValidationChecks {
  showCheckbox?: boolean
  ruleTitle?: string
  helpText?: string
  requireUpgrade?: boolean
  logAction?: string
  fieldType?: 'input' | 'textarea' | 'select' | 'multiselect'
}

type ValidationCategories =
  | 'Landing page URL'
  | 'Landing page validation'
  | 'Validation options'
  | 'Short link preferences'
  | 'Email preferences'
  | 'Advanced options'

export interface ValidationChecksCategory {
  category: ValidationCategories
  validationChecks: FullValidationCheck[]
}

export const defaultEnabled = {
  REQUIRE_LANDING_PAGE: true,
  SHOW_LANDING_PAGE: true,
  NO_SPECIAL_CHARS_LANDING_PAGE: false,
  CHECK_URL_EXISTS: true,
  CHECK_URL_SPEED: true,
  CHECK_URL_REDIRECT: true,
  CHECK_URL_ANALYTICS: false,
  ALL_LOWER_CASE: false,
  NO_SPECIAL_CHARS: true,
  NO_SPACES: true,
  REPLACE_SPACES_WITH: true,
  LIMIT_URL_LENGTH: true,
  LIMIT_QUERY_LENGTH: true,
  FORCE_SHORT_LINK: false,
  FORCE_CUSTOM_DOMAIN: false,
  INCLUDE_PREFIX_WITH_COPY_FROM: false,
  COPY_FROM_SEPARATOR: true,
  INCLUDE_EMPTY_VALUES: false,
}

export const defaultValidationChecksFull: ValidationChecksCategory[] = [
  {
    category: 'Landing page URL',
    validationChecks: [
      {
        showCheckbox: true,
        enabled: true,
        ruleTitle: 'Require',
        name: 'REQUIRE_LANDING_PAGE',
        logAction: 'update-generator-rules-landing-page-required',
        value: null,
        helpText:
          'If checked, users will have to enter a landing page URL to create a campaign code link.',
      },
      {
        showCheckbox: true,
        enabled: true,
        ruleTitle: 'Show this field',
        name: 'SHOW_LANDING_PAGE',
        logAction: 'update-generator-rules-landing-page-show-field',
        value: null,
        helpText:
          'If unchecked, the landing page URL input box will be removed (not recommended).',
      },
      {
        showCheckbox: true,
        enabled: true,
        ruleTitle:
          'No special characters or existing query string parameters (?=&)',
        name: 'NO_SPECIAL_CHARS_LANDING_PAGE',
        logAction: 'update-generator-rules-landing-page-no-special-characters',
        value: null,
        helpText:
          'If checked, users will not be able to enter a URL with ?=&amp;, including existing query string parameters. Turn this off if deep linking uses extra query string parameters instead of hashes (#).',
      },
    ],
  },
  {
    category: 'Landing page validation',
    validationChecks: [
      {
        showCheckbox: true,
        enabled: true,
        ruleTitle: 'Check it exists',
        name: 'CHECK_URL_EXISTS',
        logAction: 'update-generator-rules-landing-page-exists',
        value: null,
        helpText: 'If checked the validation will check if the page exists.',
      },
      {
        showCheckbox: true,
        enabled: true,
        ruleTitle: 'Check it loads fast',
        name: 'CHECK_URL_SPEED',
        logAction: 'update-generator-rules-landing-page-loads-fast',
        value: null,
        helpText:
          'If checked the validation will check if the page loads under 5 seconds.',
      },
      {
        showCheckbox: true,
        enabled: true,
        ruleTitle: 'Check it redirects',
        name: 'CHECK_URL_REDIRECT',
        logAction: 'update-generator-rules-landing-page-redirects',
        value: null,
        helpText: 'If checked the validation will check for page redirects.',
      },
      {
        showCheckbox: true,
        enabled: false,
        ruleTitle: 'Check it has analytics',
        name: 'CHECK_URL_ANALYTICS',
        logAction: 'update-generator-rules-landing-page-analytics',
        value: null,
        helpText:
          'If checked the validation will check if the page has Google or Adobe analytics.',
      },
    ],
  },
  {
    category: 'Validation options',
    validationChecks: [
      {
        showCheckbox: true,
        enabled: true,
        ruleTitle: 'All parameters lowercase only',
        name: 'ALL_LOWER_CASE',
        logAction: 'update-generator-rules-lowercase',
        value: null,
        helpText:
          'If checked, all text in links will be automatically lowercased. This makes data easier to read in analytics platforms.\n\n**If unchecked, lowercase validation is controlled individually above for each parameter.**',
      },
      {
        showCheckbox: true,
        enabled: true,
        ruleTitle: 'No special characters (?=&)',
        name: 'NO_SPECIAL_CHARS',
        logAction: 'update-generator-rules-no-special-characters',
        value: null,
        helpText:
          "If checked, users' text input cannot contain special characters. This stops users creating campaign codes which interfere with campaign code tracking.",
      },
      {
        showCheckbox: true,
        enabled: true,
        ruleTitle: 'No spaces',
        name: 'NO_SPACES',
        logAction: 'update-generator-rules-no-spaces',
        value: null,
        helpText:
          'If checked, users will not be allowed to enter spaces into free text parameters or dropdown codes. This stops ugly %20 characters from showing in your analytics tools.',
      },
      {
        showCheckbox: true,
        enabled: true,
        ruleTitle: 'Replace spaces with',
        name: 'REPLACE_SPACES_WITH',
        logAction: 'update-generator-rules-replace-spaces-with',
        value: '_',
        helpText:
          'If checked, spaces will be replaced with this character when typing into free text parameters or dropdown codes. The default recommended value is underscore _.',
      },
      {
        showCheckbox: true,
        enabled: true,
        ruleTitle: 'Max link length',
        name: 'LIMIT_URL_LENGTH',
        logAction: 'update-generator-rules-max-link-length',
        value: '1024',
        helpText:
          'Maximum number of characters in the landing page and generated tracking code. Recommended value is 1024 characters.',
      },
      {
        showCheckbox: true,
        enabled: true,
        ruleTitle: 'Max query length',
        name: 'LIMIT_QUERY_LENGTH',
        logAction: 'update-generator-rules-max-query-length',
        value: '255',
        helpText:
          'Maximum number of generated tracking code. Recommended value is 255 characters.',
      },
    ],
  },
  {
    category: 'Short link preferences',
    validationChecks: [
      {
        showCheckbox: false,
        enabled: true,
        ruleTitle: 'Link type preferences',
        name: 'FORCE_SHORT_LINK',
        logAction: 'update-generator-short-link-force-short-link',
        value: `[
          {
            "optionName": "Recommend basic links",
            "optionValue": "recommend-long-links",
            "selected": true
          },
          {
            "optionName": "Recommend short links",
            "optionValue": "recommend-short-links",
            "selected": false
          },
          {
            "optionName": "Force basic links",
            "optionValue": "force-long-links",
            "selected": false
          },
          {
            "optionName": "Force short links",
            "optionValue": "force-short-links",
            "selected": false
          }
        ]`,
        helpText:
          'Shows/hides the short or basic link options or determines which one is pre-selected',
        fieldType: 'select',
      },
      {
        showCheckbox: false,
        enabled: true,
        ruleTitle: 'Only show domains',
        name: 'FORCE_CUSTOM_DOMAIN',
        logAction: 'update-short-link-custom-domain',
        value: `[
          {
            "optionName": "${defaultShortLinkDomain}",
            "optionValue": "${defaultShortLinkDomain}",
            "selected": true
          }
        ]`,
        helpText:
          'Pick which of your short link domains your users will be able to select',
        requireUpgrade: true,
        fieldType: 'multiselect',
      },
    ],
  },
  {
    category: 'Email preferences',
    validationChecks: [
      {
        showCheckbox: true,
        enabled: true,
        ruleTitle: 'Only replace email links which contain:',
        name: 'EMAIL_DOMAIN_LIST',
        logAction: 'update-generator-rules-email-default',
        value: '',
        fieldType: 'textarea',
        helpText:
          "When creating links in the 'Email' tab, we will only find and replace links which contain these domains.",
      },
    ],
  },
  {
    category: 'Advanced options',
    validationChecks: [
      {
        showCheckbox: true,
        enabled: false,
        ruleTitle: "Include prefix with 'copy from' fields",
        name: 'INCLUDE_PREFIX_WITH_COPY_FROM',
        logAction: 'update-generator-rules-copy-from-include-prefix',
        value: null,
        helpText:
          'If checked, any parameters copied from another parameter will include both the parameter prefix and the value.',
      },
      {
        showCheckbox: false,
        enabled: true,
        ruleTitle: 'Copy from separator',
        name: 'COPY_FROM_SEPARATOR',
        logAction: 'update-generator-rules-copy-from-separator',
        value: '|',
        helpText:
          "If a parameters copies from multiple other parameters, they will be separated by this value. The default value is a pipe '|'.",
      },
      {
        showCheckbox: true,
        enabled: false,
        ruleTitle: 'Include empty optional values',
        name: 'INCLUDE_EMPTY_VALUES',
        logAction: 'update-generator-rules-include-empty',
        value: null,
        helpText:
          'If checked, empty input values will still be shown in the tool with their prefix.',
      },
    ],
  },
]

export const defaultValidationChecksArray: ValidationChecks[] = defaultValidationChecksFull.flatMap(
  (category) =>
    category.validationChecks.map(({ enabled, name, value }) => ({
      enabled,
      name,
      value,
    })),
)

export const defaultGeneratorObject: CampaignCodeGeneratorStructure = {
  masterPrefix: '?cid=',
  paramDefs: [
    {
      fieldAvailable: true,
      fieldID: '9cde81e8',
      fieldName: 'Channels',
      fieldOrder: 0,
      fieldType: 'select',
      helpText: '',
      prefix: '',
      required: true,
      selectFields: [],
    },
    {
      fieldAvailable: true,
      fieldID: 'd6fa96cf',
      fieldName: 'Post Type',
      fieldOrder: 1,
      fieldType: 'select',
      helpText: '',
      prefix: '',
      required: true,
      selectFields: [],
    },
    {
      fieldAvailable: true,
      fieldID: '40a1c419',
      fieldName: 'Network',
      fieldOrder: 2,
      fieldType: 'select',
      helpText: '',
      prefix: '',
      required: true,
      selectFields: [],
    },
    {
      fieldAvailable: true,
      fieldID: '0fe4215d',
      fieldName: 'Targeting Strategy',
      fieldOrder: 3,
      fieldType: 'select',
      helpText: '',
      prefix: '',
      required: true,
      selectFields: [],
    },
    {
      fieldAvailable: true,
      fieldID: '43cc0115',
      fieldName: 'Campaign Name',
      fieldOrder: 4,
      fieldType: 'select',
      helpText: '',
      prefix: '',
      required: true,
      selectFields: [],
    },
    {
      fieldAvailable: true,
      fieldID: 'a9a48915',
      fieldName: 'Creative Type',
      fieldOrder: 5,
      fieldType: 'select',
      helpText: '',
      prefix: '',
      required: true,
      selectFields: [],
    },
    {
      fieldAvailable: true,
      fieldID: '879726f3',
      fieldName: 'Creative Name',
      fieldOrder: 6,
      fieldType: 'input',
      helpText: '',
      prefix: '',
      required: true,
    },
  ],
  paramSeparator: '&',
  validationChecks: defaultValidationChecksArray,
}

export const sharingPermissionTypes = {
  private: {
    short: 'Private',
    long: `Only ${brandName} users can view (private)`,
  },
  public: {
    short: 'Public',
    long: 'Anyone with the link below can view (public)',
  },
}

export const matchTypesDropDown: MatchTypeProps[] = [
  {
    name: 'Campaign links',
    shortName: 'links',
    value: 'full',
    tooltip:
      'Compares metrics of landing pages with campaign codes. The best way to compare performance if **users correctly enter landing page URLs** when creating campaign links.',
  },
  {
    name: 'Campaign codes',
    shortName: 'codes',
    value: 'partial',
    tooltip:
      "Compares metrics of campaign codes, ignoring the landing page URLs. The best way to compare performance if your **users don't enter landing page URLs** when creating campaign links.",
  },
  {
    name: 'Landing pages',
    shortName: 'pages',
    value: 'lpag',
    tooltip: 'Compares metrics of landing pages.',
  },
  {
    name: 'Short links',
    shortName: 'short links',
    value: 'shortuplcode',
    tooltip: 'Compares metrics of short links.',
  },
]

export const availableToShareOptions = {
  company: {
    value: 'company',
    long: 'Company',
    desc: 'Everyone at the company can see this report.',
  },
  account: {
    value: 'account',
    long: 'Workspace',
    desc: 'Only people who have access to this workspace can see this report.',
  },
  user: {
    value: 'user',
    long: 'Private',
    desc: 'Only you can see this report.',
  },
}

export const calendarBookingLink = 'https://calendly.com/uplifterai'

export const googleDataSources = ['GA_VIEW', 'GA4_PROP']
export const adobeDataSource = 'AA_REPORT'

export const accountTypes = {
  '': { short: '', long: '' },
  GA_VIEW: {
    short: 'Google (UA)',
    long: 'Google Analytics: Universal Analytics',
  },
  GA4_PROP: { short: 'Google (GA4)', long: 'Google Analytics: GA4' },
  AA_REPORT: { short: 'Adobe', long: 'Adobe Analytics' },
}

export const messages = {
  defaultReportTitle_performance: 'Links performance report',
  defaultReportTitle_lostLinks: 'Other links report',
  defaultReportTitle_marketingJourney: 'Marketing journey',
  notSet:
    'This row contains all campaign traffic where the campaign parameter was blank.',
  notValidEmail: 'Sorry, but this does not seem to be a valid email.',
  sorry: 'Sorry, we are having a few issues right now. Please try again later.',
  fileUploadError: 'We are having trouble uploading your file.',
  fileDeleteError: 'We are having trouble deleting your file.',
  fileUploadErrorImageOnly:
    'You can only upload png, gif or jpeg. Max file size 1MB.',
  alreadyHaveAccount: `It looks like you've already got an account associated with this email. If you are still experiencing issues, please contact [${supportEmail}](mailto:${supportEmail}).`,
  partialMatch:
    'We can only find aggregated data for this campaign code, not specifically the landing page URL with campaign code.',
  linkStatus: {
    'N/A': `Please connect your analytics or email ${supportEmail}`,
    Error: `Please connect your analytics or email ${supportEmail}`,
    Ended: 'Had data but has not received any in the last 30 days.',
    Live: 'Has had data within the last 30 days.',
    New: 'Was created in the last 30 days but does not yet have data.',
    Unused: 'Was created more than 30 days ago and has no data.',
  },
  validateUrlMessage: {
    noUrl:
      'This campaign code does not have a landing page URL. You can add one by editing here.',
    goodUrl:
      'This landing page is live, has a Google or Adobe analytics tag and loads in under 4 seconds. [Info](https://support.uplifter.ai/hc/en-us/articles/360020525658-What-does-landing-page-validation-do-).',
    noData:
      'An analytics tag did not fire when we visited the page, this could be due to cookie consent. [Learn more](https://support.uplifter.ai/hc/en-us/articles/360020525658-What-does-landing-page-validation-do-).',
    unknownUrl:
      "We couldn't scan and validate your URL, contact support. [Learn more](https://support.uplifter.ai/hc/en-us/articles/360020525658-What-does-landing-page-validation-do-).",
    validating: 'This is a new URL which will be validated over night.',
    badUrl:
      'This landing page URL is not currently live and users will go to an error page. [Learn more](https://support.uplifter.ai/hc/en-us/articles/360020525658-What-does-landing-page-validation-do-).',
    slowLandingPage:
      'This page takes over 4 seconds to load, which is above the recommended speed time. [Learn more](https://support.uplifter.ai/hc/en-us/articles/360020525658-What-does-landing-page-validation-do-).',
    noAnalyticsTag:
      'This landing page does not have a Google / Adobe analytics tag on it.',
    redirectedLandingPage:
      'This landing page redirects to another page, which can strip out campaign tracking and make the page load slow. [Learn more](https://support.uplifter.ai/hc/en-us/articles/360020525658-What-does-landing-page-validation-do-).',
  },
  urlStatus: {
    redirectedLandingPage: `This page redirects to another page. [Learn more](https://support.uplifter.ai/hc/en-us/articles/360020525658-What-does-landing-page-validation-do-).`,
    badUrl:
      'This page is not live. [Learn more](https://support.uplifter.ai/hc/en-us/articles/360020525658-What-does-landing-page-validation-do-)',
    intensiveValidationFailed:
      '(full scan timed out - contact [support@uplifter.ai](mailto:support@uplifter.ai))',
  },
  customiseShortLink: 'You can change short link **alias** if available.',
  selectDomain: 'Choose your domain to create your short links.',
  batchShortLinkAlias:
    'All short links from your file will follow the pattern shown, e.g. abcdef-0x1, abcdef-0x2',
  linkTypes: `**Short link (Recommended):** Create a short link that redirects to the landing page with a campaign code, **capturing** valuable clickthrough data.

**Basic link:** Create a landing page with campaign code, does not go through a short link, we **will not capture** valuable clickthrough data.
`,
}

export const registrationErrorMessageLookup = {
  'user attempted to register twice': `This email address has already registered. Please [login](/login) or [change password](/forgot-password).`,
  'user has been removed':
    'Email address not found. Please contact your account owner.',
  'token expired':
    'This link has expired, please contact your admin to resend the invitation.',
  'user attempted to register google twice': `This email address has already registered. Please [login](/login) or [change password](/forgot-password).`,
  'user attempted to register microsoft twice': `This email address has already registered. Please [login](/login) or [change password](/forgot-password).`,
  'blocked by server':
    'Email address not found. Please contact your account owner.',
}

export const fieldTypes = {
  '': { short: 'Unknown', long: 'Unknown' },
  input: { short: 'Input', long: 'Free text' },
  select: { short: 'Dropdown', long: 'Dropdown' },
  date: { short: 'Date', long: 'Date picker' },
}

interface CodeStatus {
  na: CodeType
  ended: CodeType
  live: CodeType
  new: CodeType
  unused: CodeType
  error: CodeType
}

export const codeStatus: CodeStatus = {
  new: 'New',
  live: 'Live',
  ended: 'Ended',
  unused: 'Unused',
  na: 'N/A',
  error: 'Error',
}

export const detectionMethods = {
  '': { short: 'Unknown', long: 'Unknown', desc: '' },
  AE: {
    short: 'AI',
    long: 'AI anomaly detection',
    desc: 'Best for high volume metrics like Users and Revenue',
  },
  MANUAL: {
    short: 'None',
    long: 'No anomaly detection',
    desc: 'Best for low volume, highly volatile metrics like Leads',
  },
  SD: {
    short: 'SD',
    long: 'Standard deviation',
    desc:
      'Best for secondary metrics which are highly unpredictable like Bounce rate',
  },
}

export const metricSettingUp = `
This metric is still being setup, please return in an hour to breakdown anomalies and find insights. 
 
If you keep seeing this message, please email [${supportEmail}](mailto:${supportEmail}).
`

export const markdownHelp = `
We use Markdown for formatting. Here are the basics.

First Level Header
\`\`\`
Making pancakes
===============
\`\`\`

Second Level Header
\`\`\`
Method
------
\`\`\`

Paragraphs
\`\`\`
Add two new lines to start a new paragraph. Put flour, eggs, milk, oil and a pinch of salt into a bowl.
\`\`\`


Bold
\`\`\`
**Whisk** to a smooth batter.
\`\`\`

Emphasis
\`\`\`
Set a medium frying pan over a *medium heat*.
\`\`\`

Lists
\`\`\`
Ingredients:  
- flour
- eggs
- *optional:* milk or oat milk
- oil
\`\`\`

`

export default {
  siteContainerWidth,
}

export const presetColors = [
  '#FFFFFF',
  '#000000',
  '#A0AEC0',
  '#E61969',
  '#0FC3F0',
  '#007D9B',
  '#FFD70F',
]

export const linkPreviewColours = [
  '#007d9b',
  '#e61969',
  '#0fc3f0',
  '#4285f4',
  '#34a853',
  '#fbbc05',
  '#ea4335',
  '#EB4787',
  '#3FCFF3',
  '#3397AF',
  '#FFDF3F',
]
export const initialPaginationConfig: PaginationConfig = {
  limit: 10,
  offset: 1,
  sortDirection: 'DESC',
}

export const graphColours = [
  '#007d9b',
  '#e61969',
  '#0fc3f0',
  '#ffd70f',
  '#718096',
  '#4285f4', // Google colours
  '#34a853',
  '#fbbc05',
  '#ea4335',
  '#EB4787', // light colours
  '#3FCFF3',
  '#3397AF',
  '#FFDF3F',
]

export const graphColoursMedium = ['#EB4787', '#3FCFF3', '#3397AF', '#FFDF3F']

export const graphColoursMuted = ['#F075a5', '#6fdbf6', '#66b1c3', '#ffe76f']

export const integrationData = {
  aa: {
    full: 'Adobe Analytics',
    icon: '/aa-logo.svg',
  },
  sf: {
    full: 'Salesforce',
    icon: '/salesforce-logo.svg',
  },
  fb: {
    full: 'Facebook',
    icon: '/fb-logo.svg',
  },
  t: {
    full: 'Twitter',
    icon: '/twitter-logo.svg',
  },
  li: {
    full: 'LinkedIn',
    icon: '/linkedin-logo.svg',
  },
  ftc: {
    full: 'Google Marketing Platform',
    icon: '/gmp-logo.svg',
  },
}

export const defaultHomepageSlugs = {
  welcome: '/welcome',
  // explain: '/explain',
  report: '/report/performance',
  track: '/track/create-links',
}

export const onboardingSections = [
  'learnUplifter',
  'editTaxonomy',
  'createCampaignLink',
  'learnCampaignLinks',
  'inviteUsers',
  'connectAnalytics',
  'completeProfile',
] as const

interface OnboardingSectionData {
  title: string
  link: string
  linkCopy: string
  content: string | false
  isAdmin: boolean
  regularOnly?: boolean
  position: number
  actionData?: Omit<LogActionMutationVariables, 'pagePath'> | null
  skipAction: Omit<LogActionMutationVariables, 'pagePath'>
  unskipAction: Omit<LogActionMutationVariables, 'pagePath'>
}

export type ObjectFromList<T extends ReadonlyArray<string>, U = boolean> = {
  // eslint-disable-next-line
  [K in T extends ReadonlyArray<infer U> ? U : never]: U
}

export const onboardingSectionData: ObjectFromList<
  typeof onboardingSections,
  OnboardingSectionData
> = {
  learnUplifter: {
    title: `Learn about ${brandName}`,
    link: '/welcome',
    linkCopy: 'Next section',
    content: false,
    isAdmin: false,
    regularOnly: false,
    position: 0,
    actionData: {
      action: 'learn-uplifter-opened',
      extra: "User has opened the 'Learn about Uplifter' section",
      websiteSection: 'welcome',
      functionName: '',
    },
    skipAction: {
      action: 'learn-uplifter-skipped',
      extra: 'User has skipped Learn Uplifter onboarding step',
      websiteSection: 'welcome',
      functionName: '',
    },
    unskipAction: {
      action: 'learn-uplifter-unskipped',
      extra: 'User has unskipped Learn Uplifter onboarding step',
      websiteSection: 'welcome',
      functionName: '',
    },
  },
  editTaxonomy: {
    title: 'Set up parameters and rules',
    link: '/track/edit-parameters-and-rules',
    linkCopy: 'Edit parameters and rules',
    content: false,
    isAdmin: true,
    regularOnly: false,
    position: 1,
    actionData: null,
    skipAction: {
      action: 'edit-taxonomy-skipped',
      extra: 'User has skipped Edit Taxonomy step',
      websiteSection: 'welcome',
      functionName: '',
    },
    unskipAction: {
      action: 'edit-taxonomy-unskipped',
      extra: 'User has unskipped Edit Taxonomy step',
      websiteSection: 'welcome',
      functionName: '',
    },
  },
  createCampaignLink: {
    title: 'Create first link',
    link: '/track/create-links',
    linkCopy: 'Create links',
    content: false,
    isAdmin: false,
    regularOnly: false,
    position: 2,
    skipAction: {
      action: 'create-campaign-link-skipped',
      extra: "User has skipped 'Create campaign link' onboarding step",
      websiteSection: 'welcome',
      functionName: '',
    },
    unskipAction: {
      action: 'create-campaign-link-unskipped',
      extra: "User has unskipped 'Create campaign link' onboarding step",
      websiteSection: 'welcome',
      functionName: '',
    },
  },
  learnCampaignLinks: {
    title: 'Become a certified expert',
    link: '/track/learn',
    linkCopy: 'Get certified',
    actionData: {
      action: 'track-learn-completed',
      extra: 'User has completed Track>Learn certification',
      websiteSection: 'track-learn',
      functionName: '',
    },
    content:
      "\n\nCampaign links (with UTM codes or CID codes) help you understand what made visitors come to your website.\n\nIf you run a campaign across paid media, social and email, you'll want to know which channel, which variant, which message, was the most effective - and which was the least.\n\nCreating a different campaign link for each type of ad will help your analytics platform identify exactly what your visitors clicked on.\n\nBut the structure and accuracy of those links is critical - one mistake and your analytics platform won't get the right data.\n\nComplete our 15 minute training module to learn best practice and get a Campaign Tracking Expert LinkedIn certificate.",
    isAdmin: false,
    regularOnly: false,
    position: 3,
    skipAction: {
      action: 'track-learn-skipped',
      extra: 'User has skipped Track>Learn onboarding step',
      websiteSection: 'welcome',
      functionName: '',
    },
    unskipAction: {
      action: 'track-learn-unskipped',
      extra: 'User has unskipped Track>Learn onboarding step',
      websiteSection: 'welcome',
      functionName: '',
    },
  },
  inviteUsers: {
    title: 'Add users',
    link: '/settings?show=team',
    linkCopy: 'Add users',
    content: false,
    isAdmin: true,
    regularOnly: false,
    position: 4,
    skipAction: {
      action: 'invite-users-skipped',
      extra: 'User has skipped Invite Users onboarding step',
      websiteSection: 'welcome',
      functionName: '',
    },
    unskipAction: {
      action: 'invite-users-unskipped',
      extra: 'User has unskipped Invite Users onboarding step',
      websiteSection: 'welcome',
      functionName: '',
    },
  },
  connectAnalytics: {
    title: 'Connect to analytics',
    link: '/report/performance',
    linkCopy: 'Connect analytics',
    content: false,
    isAdmin: true,
    regularOnly: false,
    position: 5,
    skipAction: {
      action: 'connect-analytics-skipped',
      extra: "User has skipped 'Connect analytics' onboarding step",
      websiteSection: 'welcome',
      functionName: '',
    },
    unskipAction: {
      action: 'connect-analytics-unskipped',
      extra: "User has unskipped 'Connect analytics' onboarding step",
      websiteSection: 'welcome',
      functionName: '',
    },
  },
  completeProfile: {
    title: 'Complete profile',
    link: '/settings?show=profile',
    linkCopy: 'Update profile',
    actionData: {
      action: 'completed-profile',
      extra: 'User has filled in all sections of their profile',
      websiteSection: 'welcome',
      functionName: '',
    },
    content:
      '\n\nLet other users know more about you by completing your profile.',
    isAdmin: false,
    regularOnly: false,
    position: 6,
    skipAction: {
      action: 'complete-profile-skipped',
      extra: "User has skipped 'Complete Profile' onboarding step",
      websiteSection: 'welcome',
      functionName: '',
    },
    unskipAction: {
      action: 'complete-profile-unskipped',
      extra: "User has unskipped 'Complete Profile' onboarding step",
      websiteSection: 'welcome',
      functionName: '',
    },
  },
}

export const usageReportSuccessMetrics = {
  badCodesBlocked: {
    successMetricDisplayName: 'Invalid links prevented',
    successMetricDescription:
      'The number of links blocked to stop broken customer journeys and/or tracking.',
  },
  invalidCodesBreakdown: {
    successMetricDisplayName: 'Link errors breakdown',
    successMetricDescription:
      'What sort of errors were triggered by users when trying to create new links.',
  },
  codesCreated: {
    successMetricDisplayName: 'Campaign links created',
    successMetricDescription:
      'The number of unique campaign links you have created.',
  },
  activeUsers: {
    successMetricDisplayName: 'Active users',
    successMetricDescription:
      'The number of users who have performed an action.',
  },
  logins: {
    successMetricDisplayName: 'Logins',
    successMetricDescription:
      'The number of times users of your account(s) have logged in.',
  },
}
