import React from 'react'
import classNames from 'classnames'
import { useHistory } from 'react-router-dom'

import Button from './button'
import { InnerBox, OuterBox } from './two-columns'
import { BoxedText } from './typography'
import styles from '../styles/campaign-code-generator-module-clone-tab.module.scss'

export default function CampaignCodeGeneratorCloneTab(): React.ReactElement {
  const history = useHistory()

  return (
    <OuterBox className={styles.outerBox}>
      <InnerBox className={classNames(styles.innerBox, styles.innerBoxContent)}>
        <p className={styles.intro}>
          <span>
            Clone existing links and edit parameters to make new links quickly.
          </span>
        </p>
        <div className={styles.cloneContent}>
          <ol>
            <li>
              In the left-hand navigation bar, click{' '}
              <BoxedText>Track &gt; View links</BoxedText>
            </li>
            <li>
              In the left-hand column of the table, tick the box next to the
              link(s) you want to clone
            </li>
            <li>
              Click on the pink <BoxedText>Action</BoxedText> button and select{' '}
              <BoxedText>Clone and edit</BoxedText>
            </li>
            <li>
              You will be taken to the{' '}
              <BoxedText>Track &gt; Create links</BoxedText> page with the
              parameters pre-selected
            </li>
            <li>Edit the parameters you want to change</li>
            <li>
              <span className={styles.optional}>(Multiple only)</span> Review
              the campaign link combinations you want
            </li>
            <li>
              Click <BoxedText>Create campaign link(s)</BoxedText> button to
              create your campaign link(s)
            </li>
          </ol>
          <img
            alt="Clone &amp; Edit single codes"
            src="/clone-edit-multiple.gif"
          />
          <Button
            onPress={() => history.push('/track/view-links')}
            className={styles.cloneButton}
          >
            Clone existing links
          </Button>
        </div>
      </InnerBox>
    </OuterBox>
  )
}
