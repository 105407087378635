import { gql as apolloGql } from '@apollo/client'
import { customAlphabet } from 'nanoid'

import client from '../apollo'
import { nanoIdCharacters } from '../constants'
import { forceCacheRefresh } from './onboarding-client'

/**
 * Aliases can't be used in type gen or hooks.
 * Must mutate directly on the client
 */
export const addUsersToAccountsBulk = async ({
  users,
  accountIDs,
  permissionLevelList,
}: {
  users: string[]
  accountIDs: string[]
  permissionLevelList: PermissionLevel[]
}) => {
  let addMutation = ``
  const alias = customAlphabet(nanoIdCharacters, 10)

  users.forEach((user) => {
    addMutation += `
      ${alias()}:addUserToAccountBulk(
        accountIDList: ${JSON.stringify(accountIDs)},
        email: "${user}",
        permissionLevelList: ${JSON.stringify(permissionLevelList)},
      ) {
          userID
          email
          inviteOpen
          userAccountProfiles {
            userEmail
            accountID
            accountName
            userPermission
            companyID
            inviteOpen
          }
      }
    `
  })

  return client.mutate({
    mutation: apolloGql`
        mutation AddUsersToAccountsBulk {
          userAccountSettings {
            ${addMutation}
          }
        }
      `,
  })
}

/**
 * For updating onboarding status, mutations are the same but field names differ slightly
 */
export const updateCachedOnboardingSectionsProgress = async (
  accountID: string,
  type: 'user' | 'account',
  data: {
    onboardingSectionID: string
    sectionCompleted: boolean
    sectionSkipped: boolean
    sectionSkippedBefore: boolean
  }[],
) => {
  const typeName =
    type === 'user' ? 'userOnboardingProgress' : 'accountOnboardingProgress'

  client.writeQuery({
    query: apolloGql`
        mutation UpdateOnboardingSections {
          ${typeName} {
            ${`${typeName}List`} {
              onboardingSectionID
              sectionCompleted
              sectionSkipped
              sectionSkippedBefore
            }
          }
        }
      `,
    data: {
      [typeName]: {
        __typename: type === 'user' ? 'UserOnboarding' : 'AccountOnboarding',
        [`${typeName}List`]: data,
      },
    },
  })

  await forceCacheRefresh(accountID, type === 'account')
}
