import React, { forwardRef, useEffect, useState } from 'react'
import classNames from 'classnames'

import styles from '../styles/top-scrollbar.module.scss'

interface TopScrollbarProps {
  className?: string
  // TODO: Fix this any
  children: any
  scrollHandle?: (e: any) => void
}

const TopScrollbar = forwardRef<HTMLDivElement, TopScrollbarProps>(
  ({ className, children, scrollHandle }, ref) => {
    const [childWidth, setChildWidth] = useState(1198)

    useEffect(() => {
      if (children && Array.isArray(children)) {
        const refChild = children.find((child) => child && child.ref)

        if (refChild && refChild.ref.current) {
          setChildWidth(refChild.ref.current.offsetWidth)
        }
      }

      if (typeof children === 'object' && children !== null && children.ref) {
        setChildWidth(children.ref.current.offsetWidth)
      }
    }, [children])

    const scrollTop = (e) => {
      e.target.nextElementSibling.scrollLeft = e.target.scrollLeft
    }

    const scrollMain = (e) => {
      e.target.previousElementSibling.scrollLeft = e.target.scrollLeft
    }

    return (
      <>
        <div className={styles.doubleScroll} onScroll={scrollTop}>
          <div
            className={styles.topScroll}
            style={{ width: `${childWidth}px` }}
          >
            &nbsp;
          </div>
        </div>
        <div
          className={classNames(styles.doubleScroll, className)}
          ref={ref}
          onScroll={(e) => {
            scrollMain(e)

            if (scrollHandle) scrollHandle(e)
          }}
        >
          {children}
        </div>
      </>
    )
  },
)

export default TopScrollbar
