import React, { useMemo } from 'react'
import { useMutation, useQuery, useReactiveVar } from '@apollo/client'
import moment from 'moment'
import ReactMarkdown from 'react-markdown'

import { ButtonRow } from './button-row'
import { ImgUploader } from './img-uploader'
import { ClickEditInput } from './input'
import { LoadingLabel } from './loader'
import ReportFiltersSummary, { LinkFilter } from './report-filters-summary'
import Tag from './tag'
import { WorkspaceLogo } from './workspace-logo'
import { currentUserDetails } from '../api/apollo/variables'
import { getCurrentAccountQRDetails } from '../api/graphql/company-client'
import {
  listSavedLinkPerformanceReportsGQL,
  updateSavedLinkPerformanceReport,
} from '../api/graphql/report-client'
import { uploadAccountLogo } from '../api/REST/account-client'
import uplifter from '../assets/logos/uplogo.png'
import warning from '../assets/icon-check-orange.svg'
import { messages } from '../core/constants'
import { isAdminUser } from '../helpers'
import { getDataSourceDescription } from '../helpers/report-module'
import useLogAction from '../hooks/useLogAction'
import styles from '../styles/performance-report-header.module.scss'
import {
  PerformanceReportDataConfig,
  SavedPerformanceReportDataConfig,
} from '../types/report-module'
import {
  AvailableDimension,
  GetAccountDataSourceQuery,
} from '../__gql-types__/graphql'

interface PerformanceReportHeaderProps {
  urlParamsFilter: {
    checked: boolean
    filter: LinkFilter | null
    error: boolean
  }
  onRemoveUrlParam: () => void
  setShowCreateModal: React.Dispatch<React.SetStateAction<boolean>>
  dataBasedDateRange: string
  dataSourceData?: GetAccountDataSourceQuery
  isMobile: boolean
  successMetricDisplayName: string
  successMetricTooltip?: string
  availableDimensions: AvailableDimension[]
  dataConfig: PerformanceReportDataConfig
  updateFilters: ({
    dimensionParameterID,
    optionValue,
  }: {
    dimensionParameterID: string
    optionValue: string
  }) => Promise<void>
  currentReport: SavedPerformanceReportDataConfig | null
  error?: boolean
}

export default function PerformanceReportHeader({
  urlParamsFilter,
  onRemoveUrlParam,
  setShowCreateModal,
  dataBasedDateRange,
  dataSourceData,
  isMobile,
  successMetricDisplayName,
  successMetricTooltip,
  availableDimensions,
  dataConfig,
  updateFilters,
  currentReport,
  error,
}: PerformanceReportHeaderProps) {
  const { userPermission } = useReactiveVar(currentUserDetails)

  const logAction = useLogAction()

  const { data: logoData, refetch: refetchLogoDetails } = useQuery(
    getCurrentAccountQRDetails,
  )

  const [updateSavedReport] = useMutation(updateSavedLinkPerformanceReport, {
    refetchQueries: [listSavedLinkPerformanceReportsGQL],
  })

  const currentReportTitle =
    currentReport?.reportTitle || messages.defaultReportTitle_performance

  // UI display of data source details
  const dataSourceDescription = useMemo(() => {
    return getDataSourceDescription(dataSourceData)
  }, [dataSourceData])

  const logoLink = useMemo(() => {
    if (!logoData || !logoData.currentAccount) return uplifter

    const useLogo = logoData.currentAccount.logoLink || uplifter

    return useLogo.indexOf('logos/PNG/uplogo.png') !== -1 ? uplifter : useLogo
  }, [logoData])

  const availableDimensionsTotals = useMemo(() => {
    const _availableDimensionsTotals: {
      [dimensionParameterID: string]: number
    } = {}

    availableDimensions.forEach(
      ({ dimensionParameterID, dimensionOptions }) => {
        _availableDimensionsTotals[dimensionParameterID] =
          dimensionOptions?.length || 0
      },
    )

    return _availableDimensionsTotals
  }, [availableDimensions])

  const appliedFilters = useMemo(() => {
    return (
      dataConfig.applyFilters?.map(
        ({ dimensionParameterID, dimensionName, dimensionOptions }) => ({
          dimensionParameterID,
          dimensionName,
          dimensionOptions: dimensionOptions.map((option) => ({
            optionName: option,
            optionValue: option,
          })),
        }),
      ) || []
    )
  }, [dataConfig])

  /** Short link clicks had a data outage in July 2024 - show this if metric is selected and date range is relevant */
  const showWarningMessage = useMemo(() => {
    if (dataConfig.selectedMetric !== 'shortLinkClicks') {
      return false
    }

    const [start, end] = dataBasedDateRange.split(' - ')

    // 17/6/2024 - 7/7/2024
    const dataStart = moment(start, 'Do/MMM/YYYY')
    const dataEnd = moment(end, 'Do/MMM/YYYY')
    const errorStart = moment('17/06/2024', 'DD/MM/YYYY')
    const errorEnd = moment('07/07/2024', 'DD/MM/YYYY')

    return (
      errorStart.isBetween(dataStart, dataEnd, 'date', '[]') ||
      errorEnd.isBetween(dataStart, dataEnd, 'date', '[]') ||
      dataStart.isBetween(errorStart, errorEnd, 'date', '[]') ||
      dataEnd.isBetween(errorStart, errorEnd, 'date', '[]')
    )
  }, [dataBasedDateRange, dataConfig.selectedMetric])

  return (
    <div className={styles.paperHeader}>
      {isMobile && (
        <div className={styles.logoWrapper}>
          {/* Mobile version deliberately excludes logo uploader - avoid showing 'replace' button */}
          <WorkspaceLogo logoLink={logoLink} loading={!logoData} />
        </div>
      )}
      <div className={styles.paperHeaderContent}>
        <ButtonRow className={styles.headerButtonRow}>
          <div className={styles.editTitleWrapper}>
            <ClickEditInput
              key={currentReportTitle}
              id="title"
              name="title"
              value={currentReportTitle}
              className={styles.editTitle}
              onChange={(newValue: string) => {
                if (newValue !== currentReportTitle) {
                  if (currentReport !== null) {
                    updateSavedReport({
                      variables: {
                        savedReportID: currentReport.savedReportID,
                        reportName: newValue,
                        reportTitle: newValue,
                      },
                    })
                  } else {
                    setShowCreateModal(true)
                  }

                  logAction({
                    variables: {
                      action: 'link-performance-title-updated',
                      extra: newValue,
                      websiteSection: 'report',
                      functionName: 'updateLinkPerformanceTitle',
                      pagePath: '/report/performance',
                    },
                  })
                }
              }}
            />
          </div>
        </ButtonRow>
        {!error && (
          <p>
            <strong>Date range:</strong>{' '}
            {dataBasedDateRange ? (
              `${dataBasedDateRange}`
            ) : (
              <LoadingLabel label="Loading" />
            )}
          </p>
        )}
        {dataSourceDescription ? (
          <ReactMarkdown source={dataSourceDescription} />
        ) : (
          <p>
            <strong>Metrics from:</strong> <LoadingLabel label="Loading" />
          </p>
        )}
        {!error && (
          <>
            {!(successMetricDisplayName && successMetricTooltip) && (
              <p>
                <strong>Key metric:</strong> <LoadingLabel label="Loading" />
              </p>
            )}
            {successMetricDisplayName && successMetricTooltip && (
              <>
                <p>
                  <strong>{successMetricDisplayName}:</strong>{' '}
                  <span className={styles.metricDescription}>
                    <ReactMarkdown source={successMetricTooltip} />
                  </span>
                </p>
              </>
            )}
          </>
        )}
        <ReportFiltersSummary
          interactionLogReportName="performance-report"
          availableDimensionsTotals={availableDimensionsTotals}
          appliedFilters={appliedFilters}
          onRemoveFilterOption={async ({
            dimensionParameterID,
            optionValue,
          }) => {
            await updateFilters({
              dimensionParameterID,
              optionValue,
            })
          }}
          urlParamsFilter={urlParamsFilter}
          onRemoveUrlParam={onRemoveUrlParam}
        />
        {successMetricDisplayName && showWarningMessage && (
          <Tag inline icon={warning} className={styles.metricDescription}>
            <p>
              Apologies: Due to an internal error, no short link clickthrough
              data was collected between 5pm 17th Jun 2024 and 4pm 2nd July
              2024.
            </p>
          </Tag>
        )}
      </div>
      {!isMobile && (
        <div className={styles.logoWrapper}>
          {isAdminUser(userPermission) && (
            <ImgUploader
              logoLink={logoLink}
              imgType="logo"
              uploadFn={async (file: File) => {
                const res = await uploadAccountLogo({
                  file,
                })

                if (res === true) {
                  logAction({
                    variables: {
                      action: 'company-logo-changed',
                      extra: '',
                      websiteSection: 'settings',
                      functionName: 'updateCompanyLogo',
                      pagePath: '/report/performance',
                    },
                  })

                  refetchLogoDetails()

                  return true
                }
                return false
              }}
              className={styles.reportLogo}
            />
          )}
          {!isAdminUser(userPermission) && (
            <WorkspaceLogo logoLink={logoLink} loading={!logoData} />
          )}
        </div>
      )}
    </div>
  )
}
