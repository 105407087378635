import React, {
  forwardRef,
  TableHTMLAttributes,
  useEffect,
  useRef,
  useState,
} from 'react'
import classNames from 'classnames'
import _ from 'lodash'

import { ButtonRow } from './button-row'
import NoDataMessage from './no-data-message'
import Loader from './loader'
import Pagination from './pagination'
import TopScrollbar from './top-scrollbar'
import useMobile from '../hooks/useMobile'
import useResize from '../hooks/useResize'
import styles from '../styles/table.module.scss'

interface TableProps extends TableHTMLAttributes<HTMLTableElement> {
  className?: string
  children: React.ReactNode
}

const Table = forwardRef<HTMLTableElement, TableProps>(
  ({ className, children }, ref) => {
    return (
      <table className={classNames(styles.table, className)} ref={ref}>
        {children}
      </table>
    )
  },
)

interface TableLoadingRowsProps {
  colCount?: number
}

export const TableLoadingRows = ({ colCount = 4 }: TableLoadingRowsProps) => {
  const placeholderRow = (key: string) => {
    return (
      <tr key={key} className={styles.placeholderRow}>
        {Array.from(new Array(colCount), (col, index) => index).map((item) => {
          return (
            <td key={`loadingCol-${item}`}>
              <Loader className={styles.loadingText} />
            </td>
          )
        })}
      </tr>
    )
  }

  return (
    <>
      {[0, 1, 2, 3, 4].map((item) => {
        return placeholderRow(`loadingRow-${item}`)
      })}
    </>
  )
}

interface PaginationData {
  totalRows: number
  rowsPerPage: number
  pages: number
  activePage: number
}

interface FullPageTableProps {
  tableClassName?: string
  headerRow: React.ReactElement<HTMLTableRowElement>
  loadingStatus?: boolean
  loadingColCount?: number
  noDataFound?: boolean
  noDataMsg?: string | React.ReactElement
  paginationData?: PaginationData
  onPageChange?: (newPage: number) => void
  onRowsChange?: (newRows: number) => void
  children: React.ReactElement<HTMLTableElement>
}

export const FullPageTable = ({
  tableClassName,
  headerRow,
  loadingStatus = false,
  loadingColCount,
  noDataFound = false,
  noDataMsg,
  paginationData,
  onPageChange,
  onRowsChange,
  children,
}: FullPageTableProps) => {
  const hasMobileHeader = useMobile()
  const windowHeight = useResize('height')

  const tableContainerRef = useRef<HTMLDivElement>(null)
  const tableRef = useRef<HTMLTableElement>(null)
  const tableHeaderRef = useRef<HTMLTableSectionElement>(null)
  const fixedScrollRef = useRef<HTMLDivElement>(null)

  const [tableWidth, setTableWidth] = useState(0)
  const [showFixedFooter, setShowFixedFooter] = useState(false)
  const [isFixedScroll, setIsFixedScroll] = useState(false)

  const fixedScroll = (e) => {
    if (isFixedScroll && tableContainerRef.current) {
      const bitToScroll = tableContainerRef.current.querySelector(
        'div[class*="doubleScroll"]',
      )

      if (bitToScroll) {
        bitToScroll.scrollLeft = e.target.scrollLeft
      }
    }
  }

  const mainScroll = (e) => {
    if (!isFixedScroll && fixedScrollRef.current && fixedScrollRef.current) {
      fixedScrollRef.current.scrollLeft = e.target.scrollLeft
    }
  }

  // Show the fixed footer only if the table runs off the bottom of the screen
  useEffect(() => {
    if (!tableContainerRef.current) return

    const { offsetHeight, offsetTop } = tableContainerRef.current

    setShowFixedFooter(offsetTop + offsetHeight > window.innerHeight - 100)

    // Set width of fixed scroll bar
    if (tableRef.current) {
      setTableWidth(tableRef.current.offsetWidth)
    }
  }, [tableRef.current?.offsetHeight, loadingStatus, windowHeight])

  // Fix table header to top of screen on scroll
  useEffect(() => {
    const onScroll = () => {
      if (
        tableContainerRef.current &&
        tableRef.current &&
        tableHeaderRef.current
      ) {
        const fromTop = tableContainerRef.current.getBoundingClientRect().top

        let offset = fromTop

        if (hasMobileHeader) offset -= 60

        const topScrollbar = tableContainerRef.current.querySelector(
          "div[class*='doubleScroll']",
        )

        if (
          tableRef.current.offsetWidth > tableContainerRef.current.offsetWidth
        ) {
          if (topScrollbar) {
            // eslint-disable-next-line @typescript-eslint/no-extra-semi
            ;(topScrollbar as HTMLElement).style.setProperty(
              'transform',
              `translateY(${-offset}px)`,
            )
          }
        }

        if (fromTop < 0) {
          tableHeaderRef.current.style.setProperty(
            'transform',
            `translateY(${-offset}px)`,
          )
        } else {
          tableHeaderRef.current.style.removeProperty('transform')

          if (topScrollbar) {
            // eslint-disable-next-line @typescript-eslint/no-extra-semi
            ;(topScrollbar as HTMLElement).style.removeProperty('transform')
          }
        }
      }
    }

    // clean up listeners
    window.removeEventListener('scroll', onScroll)
    window.addEventListener('scroll', onScroll, {
      passive: true,
    })
    return () => window.removeEventListener('scroll', onScroll)
  }, [
    hasMobileHeader,
    tableContainerRef.current,
    tableRef.current,
    tableHeaderRef.current,
  ])

  return (
    <div
      className={classNames(styles.fullPageTable, {
        [styles.showFixedFooter]: showFixedFooter,
      })}
    >
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div
        className={classNames(styles.tableContainer, {
          [styles.noScroll]: noDataFound,
        })}
        ref={tableContainerRef}
        onMouseDown={() => setIsFixedScroll(false)}
      >
        {!loadingStatus && noDataFound ? (
          <Table ref={tableRef} className={tableClassName}>
            {headerRow && <thead ref={tableHeaderRef}>{headerRow}</thead>}
            <tbody>
              <tr>
                <td colSpan={100}>
                  <div
                    style={{
                      margin: '16px 0',
                      maxWidth: tableContainerRef.current?.offsetWidth,
                    }}
                  >
                    <NoDataMessage errorMsg={noDataMsg} />
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        ) : (
          <TopScrollbar
            className={styles.tableContainerInner}
            scrollHandle={mainScroll}
          >
            <Table ref={tableRef} className={tableClassName}>
              {headerRow && <thead ref={tableHeaderRef}>{headerRow}</thead>}
              {loadingStatus ? (
                <tbody>
                  <TableLoadingRows colCount={loadingColCount} />
                </tbody>
              ) : (
                <>{children}</>
              )}
            </Table>
          </TopScrollbar>
        )}
      </div>
      {paginationData && (
        <ButtonRow className={styles.paginationRow}>
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <div
            className={styles.stickyScroll}
            ref={fixedScrollRef}
            onScroll={fixedScroll}
            onMouseDown={() => setIsFixedScroll(true)}
          >
            <div
              className={styles.topScroll}
              style={{ width: `${tableWidth}px` }}
            />
          </div>
          <Pagination
            pages={paginationData.pages}
            activePage={paginationData.activePage}
            onChange={(index) => {
              if (onPageChange) onPageChange(index)
            }}
            rowsPerPageData={{
              rowsPerPage: paginationData.rowsPerPage,
              totalRows: paginationData.totalRows,
              onChange: (newRowsPerPage) => {
                if (onRowsChange) onRowsChange(newRowsPerPage)
              },
            }}
          />
        </ButtonRow>
      )}
    </div>
  )
}

export default Table
