import React, { useState, useEffect } from 'react'
import classNames from 'classnames'

import styles from '../styles/toggle-box.module.scss'
import Arrow from '../assets/svgs/arrow'
import Tick from '../assets/svgs/tick'
import Input from './input'

interface ToggleBoxProps {
  children: React.ReactNode
  heading: string | React.ReactNode
  checked?: boolean
  checkedLeft?: boolean
  setChecked?: (self: any) => void
  open?: boolean
  onToggle?: (state: boolean) => void
  fwdRef?: (self: any) => void
  optional?: string | React.ReactNode
  icon?: React.ReactNode
  className?: string
  type?: 'white'
  disableToggle?: boolean
  strikethrough?: boolean
  noPadding?: boolean
}

const _ToggleBox = ({
  children,
  heading,
  checked,
  checkedLeft = false,
  setChecked,
  onToggle,
  open = false,
  fwdRef,
  optional,
  icon = null,
  className,
  type,
  disableToggle = false,
  strikethrough = false,
  noPadding = false,
}: ToggleBoxProps) => {
  const [active, setActive] = useState(open)

  useEffect(() => {
    setActive(open)
  }, [open])

  const wrapperClassNames = classNames(className, styles.heading, {
    [styles.headingOpen]: active,
    [styles.headingClosed]: !active,
    [styles.hasIcon]: icon !== null,
    [styles.hasCheckbox]: checkedLeft,
  })
  const containerStyles = classNames(styles.container, {
    [styles.open]: active,
    [styles.white]: type === 'white',
    [styles.disableToggle]: disableToggle,
  })
  const titleClassNames = classNames(styles.titleText, {
    [styles.strikethrough]: strikethrough && checked,
  })

  const optionalElement = (opt) => {
    if (opt) {
      if (typeof opt === 'string') {
        return (
          <>
            {' '}
            <b className={styles.optional}>{opt}</b>
          </>
        )
      }
      return <>{opt}</>
    }
    return <></>
  }

  return (
    <div
      className={containerStyles}
      ref={(self) => {
        if (fwdRef) fwdRef(self)
      }}
    >
      <div
        role="button"
        tabIndex={-1}
        data-type="on-click-button-toggle"
        className={wrapperClassNames}
        onClick={(e) => {
          const tag = e.target as HTMLElement
          if (checkedLeft && tag.tagName === 'B') {
            return
          }
          if (disableToggle) {
            return
          }
          const s = !active
          if (onToggle) {
            onToggle(s)
          }
          setActive(s)
        }}
        onKeyDown={(): void => {
          //
        }}
      >
        {icon}
        {checkedLeft && (
          <Input
            label={<></>}
            type="checkbox"
            name={typeof heading === 'string' ? heading : 'toggleCheckbox'}
            checked={checked}
            className={styles.checkboxItem}
            onChange={() => {
              if (!setChecked) {
                return
              }
              setChecked(!checked)
            }}
            onClick={(e) => {
              e.stopPropagation()
            }}
          />
        )}
        <span className={titleClassNames}>{heading}</span>
        {optionalElement(optional)}

        {!disableToggle && checked && !checkedLeft && (
          <Tick className={styles.tick} />
        )}
        {!disableToggle && (!checked || checkedLeft) && (
          <Arrow className={active ? styles.arrow : styles.arrowClosed} />
        )}
      </div>
      <div
        className={classNames({
          [styles.box]: active,
          [styles.boxHidden]: !active,
          [styles.padding]: noPadding,
        })}
      >
        <div
          className={classNames(styles.boxInner, {
            [styles.padding]: !noPadding,
          })}
          aria-label={active ? 'visible' : 'hidden'}
        >
          {active && children}
        </div>
      </div>
    </div>
  )
}

const ToggleBox = React.memo(_ToggleBox)

export default ToggleBox
