import React, { useEffect, useState, useMemo, useCallback } from 'react'
import {
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import _ from 'lodash'

import { currentUserDetails } from '../api/apollo/variables'
import {
  getLostLinksFilterOptions,
  getLostLinksGraphData,
  getLostLinksTableData,
  listSavedLostLinksReports,
  removeSavedLostLinkReport,
  saveNewLostLinkReport,
  updateSavedLostLinkReport,
  updateSavedLostLinkReportPrefs,
} from '../api/graphql/report-client'
import { getAccountDataSource } from '../api/graphql/workspace-client'
import { getLostLinksRawData } from '../api/REST/report-client'
import Intro from '../components/intro'
import Layout from '../components/layout'
import LostLinksReportHeader from '../components/lost-links-report-header'
import LostLinksDataCallout from '../components/lost-links-data-callout'
import LostLinksGraph from '../components/lost-links-graph'
import LostLinksSearchBox from '../components/lost-links-search-box'
import LostLinksTable, { PaginationData } from '../components/lost-links-table'
import ReportControls from '../components/report-controls'
import ReportSavedTabs, {
  ManageSavedReportsModal,
  SaveEditReportModal,
} from '../components/report-save'
import SelectBox, { SelectBoxChecklist } from '../components/select-box'
import SiteWrapper from '../components/site-wrapper'
import { dateFormatShort, initialPaginationConfig } from '../core/constants'
import { getUrlQuery } from '../helpers'
import {
  getDateRangeLabel,
  metricNameMapper,
  paramNameMapper,
  reportDates,
} from '../helpers/report-module'
import useLogAction from '../hooks/useLogAction'
import useResize from '../hooks/useResize'
import styles from '../styles/lost-links-report.module.scss'
import {
  Granularity,
  LostLinksReportDataConfig,
  LostLinksReportRefetchOptions,
  SavedLostLinksReportDataConfig,
} from '../types/report-module'
import { AvailableDimension } from '../__gql-types__/graphql'

const groupByDropdownValues = [
  {
    label: 'Landing pages with non-utm parameters',
    value: 'landingPageWithParameters',
    tooltip:
      'Show all landing pages with other parameters not recognised or created in Uplifter.',
  },
  {
    label: 'Landing pages',
    value: 'landingPage',
    tooltip:
      'Aggregate links with utm values not recognised by Uplifter, but ignore non utm parameters.',
  },
]

export const initialDataConfig: LostLinksReportDataConfig = {
  startDate: 'thisYear',
  endDate: '',
  selectedMetric: 'sessions',
  granularity: 'monthly',
  applyFilters: null,
  searchFilter: undefined,
}

export default function LostLinksReportPage() {
  const { workspaceID } = useReactiveVar(currentUserDetails)

  const logAction = useLogAction()

  const history = useHistory()

  const [width, setWidth] = useState(0)
  const [dataConfig, setDataConfig] = useState<LostLinksReportDataConfig>(
    initialDataConfig,
  )
  const [
    currentReport,
    setCurrentReport,
  ] = useState<SavedLostLinksReportDataConfig | null>(null)
  const [
    reportToEdit,
    setReportToEdit,
  ] = useState<SavedLostLinksReportDataConfig | null>(null)
  const [urlReportIDCheckComplete, setUrlReportIDCheckComplete] = useState<
    'default' | 'saved' | false
  >(false)
  const [filtersHaveChanged, setFiltersHaveChanged] = useState(false)

  const [paginationData, setPaginationData] = useState<PaginationData | null>(
    null,
  )
  const [tempTables, setTempTables] = useState<{
    [tempTableID: string]: LostLinksReportDataConfig
  }>({})
  const [currentTempTable, setCurrentTempTable] = useState<string | null>(null)
  const [totalTableRows, setTotalTableRows] = useState(0)
  const [showSaveReportModal, setShowSaveReportModal] = useState(false)
  const [showManageReportsModal, setShowManageReportsModal] = useState(false)
  const [exporting, setExporting] = useState(false)
  const [tableParameters, setTableParameters] = useState<
    {
      paramHeadingValue: string
      paramHeadingName: string
      hidden: boolean
    }[]
  >([])

  const screenWidth = useResize()

  const isMobile = screenWidth <= 768
  let offset =
    dataConfig.granularity === 'monthly' ||
    dataConfig.granularity === 'quarterly'
      ? 0
      : 40
  if (isMobile && offset > 0) offset = 20

  const { data: dataSourceData } = useQuery(getAccountDataSource)

  const {
    data: savedReportsData,
    loading: loadingSavedReports,
    error: errorFetchingSavedReports,
  } = useQuery(listSavedLostLinksReports, { fetchPolicy: 'cache-first' })
  const [
    getGraphData,
    { data: fullGraphData, loading: graphDataLoading, error: graphDataError },
  ] = useLazyQuery(getLostLinksGraphData, {
    notifyOnNetworkStatusChange: true,
  })
  const [
    getTableData,
    { data: tableData, loading: tableDataLoading, error: tableDataError },
  ] = useLazyQuery(getLostLinksTableData, {
    notifyOnNetworkStatusChange: true,
  })
  const [
    getFilterOptions,
    { data: filtersData, loading: loadingFilters },
  ] = useLazyQuery(getLostLinksFilterOptions, {
    notifyOnNetworkStatusChange: true,
  })

  const [saveReport, { loading: savingNewReport }] = useMutation(
    saveNewLostLinkReport,
    {
      refetchQueries: [listSavedLostLinksReports],
    },
  )
  const [updateSavedReport, { loading: updatingReport }] = useMutation(
    updateSavedLostLinkReport,
    {
      refetchQueries: [listSavedLostLinksReports],
    },
  )
  const [updateReportsOrder] = useMutation(updateSavedLostLinkReportPrefs, {
    refetchQueries: [listSavedLostLinksReports],
  })
  const [deleteSavedLostLinkReport] = useMutation(removeSavedLostLinkReport, {
    refetchQueries: [listSavedLostLinksReports],
  })

  const isFilteredReport = !(currentReport === null && !dataConfig.applyFilters)

  // Only GA accounts should see this page
  useEffect(() => {
    if (!dataSourceData || !dataSourceData.currentAccount.dataSource) return

    if (
      ['GA_VIEW', 'GA4_PROP'].indexOf(
        dataSourceData.currentAccount.dataSource.kind,
      ) === -1
    ) {
      history.push('/connect')
    }
  }, [dataSourceData])

  const {
    graphDataToShow,
    dateTotalRows: graphTableTotals,
    queryStartDate,
    queryEndDate,
    total,
  } = useMemo(() => {
    if (!fullGraphData) {
      return {
        graphDataToShow: null,
        dateTotalRows: null,
        queryStartDate: null,
        queryEndDate: null,
        total: null,
      }
    }

    const {
      dateRange,
      graphData: _graphData,
      dateTotalRows,
      queryStartDate: _queryStartDate,
      queryEndDate: _queryEndDate,
      total: _total,
    } = fullGraphData.report.linkAudit.linkAuditGraph

    if (!isFilteredReport) {
      return {
        graphDataToShow: {
          graphDateRange: dateRange,
          graphData: _graphData,
        },
        dateTotalRows,
        queryStartDate: _queryStartDate,
        queryEndDate: _queryEndDate,
        total: _total,
      }
    }

    const nonUplifterLinks = _graphData.find(
      (data) => data.dimensionName === 'Total not in uplifter',
    )

    // The 'Other links sessions'/'Uplifter link sessions' breakdown should not show when the report is filtered
    return {
      graphDataToShow: {
        graphDateRange: dateRange,
        graphData: nonUplifterLinks ? [nonUplifterLinks] : [],
      },
      dateTotalRows,
      queryStartDate: _queryStartDate,
      queryEndDate: _queryEndDate,
      total: _total,
    }
  }, [fullGraphData, currentReport, dataConfig.applyFilters])

  const uplifterLinksTotal = useMemo(() => {
    if (
      !graphDataToShow ||
      currentReport !== null ||
      (!!dataConfig.applyFilters && dataConfig.applyFilters.length > 0)
    )
      return null

    return graphDataToShow.graphData[1].dimensionValues.reduce(
      (acc, curr) => acc + curr,
      0,
    )
  }, [graphDataToShow, currentReport, dataConfig.applyFilters])

  const dataBasedDateRange = useMemo(() => {
    if (graphDataLoading || !queryStartDate || !queryEndDate) return ''

    const start = moment(queryStartDate, 'YYYY-MM-DD').format(dateFormatShort)
    const end = moment(queryEndDate, 'YYYY-MM-DD').format(dateFormatShort)

    return `${start} - ${end}`
  }, [graphDataLoading, queryStartDate, queryEndDate])

  const dateRangeLabel = useMemo(() => {
    const { startDate, endDate } = dataConfig

    getDateRangeLabel(startDate, endDate)
  }, [dataConfig.startDate, dataConfig.endDate])

  // Update table row count
  useEffect(() => {
    if (
      currentTempTable &&
      tempTables[currentTempTable] &&
      typeof tempTables[currentTempTable].totalRows === 'number'
    ) {
      setTotalTableRows(tempTables[currentTempTable].totalRows as number)
      return
    }

    if (!tableData || !tableData.report.linkAudit.linkAuditTable.pageData)
      return

    setTotalTableRows(tableData.report.linkAudit.linkAuditTable.pageData.total)
  }, [tableData, tempTables, currentTempTable])

  // Get saved reports
  const { savedReports, userPreferredOrder } = useMemo(() => {
    if (!savedReportsData) {
      return {
        savedReports: [] as SavedLostLinksReportDataConfig[],
        userPreferredOrder: [] as string[],
      }
    }

    return {
      ...savedReportsData.report.listSavedLinkAuditReports,
      savedReports: savedReportsData.report.listSavedLinkAuditReports.savedReports.map(
        (report) => {
          return {
            ...report,
            applyFilters:
              report.applyFilters?.filter(
                (aF) => aF.dimensionOptions.length > 0,
              ) || [],
          } as SavedLostLinksReportDataConfig
        },
      ),
    }
  }, [savedReportsData])

  // Check if URL has a report ID that we should filter to
  useEffect(() => {
    if (!workspaceID) return

    const urlParams = getUrlQuery()

    const reportID = urlParams.get('reportID')

    // No need to set current report - allow data to load
    if (!urlParams || !reportID) {
      setUrlReportIDCheckComplete('default')
      return
    }

    // Only allow report data to be fetched once saved reports are found
    if (savedReportsData || errorFetchingSavedReports) {
      const foundSavedReport = savedReportsData?.report.listSavedLinkAuditReports.savedReports.find(
        ({ savedReportID }) => reportID === savedReportID,
      )

      if (foundSavedReport) {
        const {
          applyFilters,
          startDate,
          endDate,
          selectedMetric,
          granularity,
          groupByLandingPage,
        } = foundSavedReport

        setDataConfig({
          startDate,
          endDate,
          selectedMetric,
          granularity: (granularity as Granularity) || 'monthly',
          applyFilters,
          searchFilter: undefined,
          groupByLandingPage: groupByLandingPage || false,
        })

        setCurrentReport(foundSavedReport as SavedLostLinksReportDataConfig)
      }

      setUrlReportIDCheckComplete(foundSavedReport ? 'saved' : 'default')
    }
  }, [workspaceID, savedReportsData, errorFetchingSavedReports])

  // Initial fetch
  useEffect(() => {
    const initialFetch = async (useDefault = true) => {
      if (
        !workspaceID ||
        !dataSourceData ||
        !dataSourceData.currentAccount.dataSource
      ) {
        return
      }

      // dataConfig uses applyFilters (from savedReports response), but data query requires filterList
      const transformedData = { ...dataConfig }

      if (dataConfig.applyFilters) {
        // @ts-ignore
        transformedData.filterList = {
          dimensionFilters: dataConfig.applyFilters.map(
            ({ dimensionParameterID, dimensionOptions }) => ({
              dimensionName: dimensionParameterID,
              dimensionParameterID,
              dimensionOptions,
            }),
          ),
        }
      }

      getGraphData({
        variables: {
          ...transformedData,
          selectedMetric: `${
            dataSourceData?.currentAccount.dataSource?.kind === 'GA_VIEW'
              ? 'ga:'
              : ''
          }${transformedData.selectedMetric}`,
        },
      })

      const { data: newTableData } = await getTableData({
        variables: {
          ...transformedData,
          ...initialPaginationConfig,
          selectedMetric: `${
            dataSourceData.currentAccount.dataSource.kind === 'GA_VIEW'
              ? 'ga:'
              : ''
          }${transformedData.selectedMetric}`,
          tempTable: undefined,
        },
        fetchPolicy: 'network-only',
      })

      if (
        newTableData &&
        newTableData.report.linkAudit.linkAuditTable.pageData?.destination
      ) {
        // Refetch filters when tempTable changes
        getFilterOptions({
          variables: {
            tempTable: useDefault
              ? newTableData.report.linkAudit.linkAuditTable.pageData
                  .destination
              : undefined,
          },
        })
      }
    }

    // If URL has a reportID, mustn't fetch data till saved reports are available
    if (urlReportIDCheckComplete) {
      initialFetch(urlReportIDCheckComplete === 'default')
    }
  }, [workspaceID, dataSourceData, urlReportIDCheckComplete])

  const refetchData = useCallback(
    async (
      data: LostLinksReportDataConfig,
      {
        tempTable,
        pagination,
        refetchTable,
        refetchGraph,
        refetchFilters,
      }: LostLinksReportRefetchOptions = { refetchFilters: true },
    ) => {
      const transformedData = { ...data }

      // dataConfig uses applyFilters (from savedReports response), but data query requires filterList
      if (data.applyFilters) {
        transformedData.applyFilters = undefined
        // @ts-ignore
        transformedData.filterList = {
          dimensionFilters: data.applyFilters.map(
            ({ dimensionParameterID, dimensionOptions }) => ({
              dimensionName: dimensionParameterID,
              dimensionParameterID,
              dimensionOptions,
            }),
          ),
        }
      }

      if (refetchGraph !== false) {
        getGraphData({
          variables: {
            ...transformedData,
            selectedMetric: `${
              dataSourceData?.currentAccount.dataSource?.kind === 'GA_VIEW'
                ? 'ga:'
                : ''
            }${dataConfig.selectedMetric}`,
          },
        })
      }

      if (refetchTable !== false) {
        // Only use tempTable if pagination is changing
        if (pagination) {
          let tempTableToUse: string | null = tempTable || null

          if (!tempTableToUse) {
            // Check if requested dataConfig exists on existing tempTables
            // Must also include totalRows, since pageData object is not returned on tempTables
            Object.entries(tempTables).forEach(([key, value]) => {
              if (
                _.isEqual(value, {
                  ...data,
                  totalRows: value.totalRows,
                })
              ) {
                tempTableToUse = key
              }
            })
          }

          // If requested dataConfig matches existing tempTable config
          if (tempTableToUse) {
            await getTableData({
              variables: {
                tempTable: tempTableToUse,
                ...pagination,
              },
            })

            return
          }
        }

        const { data: newTableData } = await getTableData({
          variables: {
            ...transformedData,
            ...initialPaginationConfig,
            selectedMetric: `${
              dataSourceData?.currentAccount.dataSource?.kind === 'GA_VIEW'
                ? 'ga:'
                : ''
            }${transformedData.selectedMetric}`,
            tempTable: undefined,
          },
        })

        if (
          refetchFilters &&
          newTableData &&
          newTableData.report.linkAudit.linkAuditTable.pageData?.destination
        ) {
          // Refetch filters when tempTable changes
          getFilterOptions({
            variables: {
              tempTable:
                newTableData.report.linkAudit.linkAuditTable.pageData
                  .destination,
            },
          })
        }
      }
    },
    [workspaceID, tempTables, dataSourceData, currentReport, dataConfig],
  )

  // Rerun the report when a new one is fetched while being on the same page
  useEffect(() => {
    // Should only run if URL has changed but page is the same
    if (!urlReportIDCheckComplete) return

    const urlParams = getUrlQuery()

    const reportID = urlParams.get('reportID')

    // No need to set current report - allow data to load
    if (!urlParams || !reportID) {
      setCurrentReport(null)
      setDataConfig(initialDataConfig)

      refetchData(initialDataConfig, { refetchFilters: false })
      return
    }

    const foundSavedReport = savedReportsData?.report.listSavedLinkAuditReports.savedReports.find(
      ({ savedReportID }) => reportID === savedReportID,
    )

    if (foundSavedReport) {
      const {
        applyFilters,
        startDate,
        endDate,
        selectedMetric,
        granularity,
        groupByLandingPage,
      } = foundSavedReport

      const newDataConfig = {
        startDate,
        endDate,
        selectedMetric,
        granularity: (granularity as Granularity) || 'monthly',
        filterList: applyFilters
          ? {
              dimensionFilters: applyFilters.map(
                ({ dimensionOptions, dimensionParameterID }) => ({
                  dimensionName: dimensionParameterID,
                  dimensionOptions,
                  dimensionParameterID,
                }),
              ),
            }
          : undefined,
        searchFilter: undefined,
        groupByLandingPage: groupByLandingPage || false,
      }

      setDataConfig(newDataConfig)

      refetchData(newDataConfig, { refetchFilters: false })

      setCurrentReport(foundSavedReport as SavedLostLinksReportDataConfig)
    }
  }, [window.location.search])

  // Update tempTables whenever dataConfig is changed
  useEffect(() => {
    if (!tableData || !tableData.report.linkAudit.linkAuditTable.pageData)
      return

    const newTempTable =
      tableData.report.linkAudit.linkAuditTable.pageData.destination
    const tempTableTotalRows =
      tableData.report.linkAudit.linkAuditTable.pageData.total

    if (newTempTable === currentTempTable) return

    setCurrentTempTable(newTempTable)

    setTempTables({
      ...tempTables,
      [newTempTable]: {
        ...dataConfig,
        totalRows: tempTableTotalRows,
      },
    })
  }, [tableData, currentTempTable, dataConfig])

  // Get dimensions for filter
  const {
    availableDimensions,
    availableDimensionsTotals,
  }: {
    availableDimensions: AvailableDimension[]
    availableDimensionsTotals: { [dimensionParameterID: string]: number }
  } = useMemo(() => {
    if (!filtersData) {
      return { availableDimensions: [], availableDimensionsTotals: {} }
    }

    const {
      report: {
        linkAudit: {
          linkAuditFilters: { parameterFilters },
        },
      },
    } = filtersData

    const _availableDimensions: AvailableDimension[] = []
    const _availableDimensionsTotals: {
      [dimensionParameterID: string]: number
    } = {}

    parameterFilters.forEach(({ parameterID, parameterOptions }) => {
      _availableDimensions.push({
        dimensionParameterID: parameterID,
        dimensionName: paramNameMapper[parameterID],
        dimensionOptions: parameterOptions,
        parameterType: 'select',
      })

      _availableDimensionsTotals[parameterID] = parameterOptions.length
    })

    return {
      availableDimensions: _availableDimensions,
      availableDimensionsTotals: _availableDimensionsTotals,
    }
  }, [filtersData])

  // Some parameters must be hidden from table columns
  useEffect(() => {
    if (!tableData) return

    const _tableParameters: {
      paramHeadingValue: string
      paramHeadingName: string
      hidden: boolean
    }[] = []

    tableData.report.linkAudit.linkAuditTable.parameterHeadings.forEach(
      (paramHeading, index) => {
        _tableParameters.push({
          paramHeadingValue: paramHeading,
          paramHeadingName: paramNameMapper[paramHeading],
          hidden: !!currentReport?.hideColumnIndexList?.includes(index),
        })
      },
    )

    setTableParameters(_tableParameters)
  }, [tableData, currentReport])

  const switchCurrentReport = useCallback(
    (resetToReport: SavedLostLinksReportDataConfig | null) => {
      setCurrentReport(resetToReport)

      if (resetToReport !== null) {
        const {
          startDate,
          endDate,
          selectedMetric,
          applyFilters,
        } = resetToReport

        const newDataConfig: LostLinksReportDataConfig = {
          ...dataConfig,
          startDate,
          endDate,
          selectedMetric,
          granularity: resetToReport.granularity || 'monthly',
          applyFilters,
          groupByLandingPage: resetToReport.groupByLandingPage || false,
        }

        setDataConfig(newDataConfig)

        refetchData(newDataConfig, { refetchFilters: false })
      } else {
        const newDataConfig: LostLinksReportDataConfig = {
          startDate: 'thisYear',
          endDate: '',
          selectedMetric: 'sessions',
          granularity: 'monthly' as Granularity,
          applyFilters: undefined,
          searchFilter: undefined,
        }

        setDataConfig(newDataConfig)
        refetchData(newDataConfig, { refetchFilters: false })
      }

      setFiltersHaveChanged(false)
    },
    [dataConfig],
  )

  return (
    <>
      <SiteWrapper>
        <Layout width={1600}>
          <div
            ref={(element) => {
              if (element) {
                const container = element.getBoundingClientRect()
                setWidth(container.width - 42)
              }
            }}
          >
            <Intro title="Other links" className={styles.intro}>
              <p>
                Discover landing pages with UTMs created by other platforms /
                users to plan integrations and additional training.
              </p>
              {errorFetchingSavedReports && (
                <p className={styles.errorMsg}>
                  Error retrieving saved reports.
                </p>
              )}
            </Intro>
            <ReportControls
              interactionLogReportName="link-audit"
              currentDataConfig={dataConfig}
              setCurrentDataConfig={setDataConfig}
              controlsPrefix={
                <>
                  <span className={styles.reportControlCopy}>Show </span>
                  <SelectBox
                    variant="grey"
                    id="groupByLandingPage"
                    className={styles.controlsSelector}
                    isLoading={tableDataLoading}
                    placeholder="All campaigns"
                    value={
                      dataConfig.groupByLandingPage
                        ? groupByDropdownValues[1]
                        : groupByDropdownValues[0]
                    }
                    options={groupByDropdownValues}
                    onChange={async (newValue) => {
                      const groupByLandingPage =
                        newValue?.value === 'landingPage'

                      setDataConfig((curr) => ({
                        ...curr,
                        groupByLandingPage,
                      }))

                      await refetchData(
                        {
                          ...dataConfig,
                          groupByLandingPage,
                          tempTable: undefined,
                        },
                        {
                          pagination: initialPaginationConfig,
                          refetchGraph: false,
                          refetchFilters: false,
                          refetchTable: true,
                        },
                      )

                      setFiltersHaveChanged(true)
                    }}
                  />
                  <span className={styles.reportControlCopy}> for </span>
                </>
              }
              includeFilter
              loadingFilters={
                loadingFilters || availableDimensions.length === 0
              }
              filterError={!!tableDataError}
              filterData={availableDimensions}
              onChange={async (newDataConfig, changeType) => {
                await refetchData(newDataConfig, {
                  refetchTable: changeType !== 'granularity',
                  refetchFilters: changeType !== 'filters',
                })

                setFiltersHaveChanged(true)
              }}
            />
            <ReportSavedTabs
              reportType="lostLinks"
              reportIDKey="savedReportID"
              reportNameKey="reportTitle"
              currentDataConfig={dataConfig}
              currentSavedReport={currentReport}
              setShowSaveReportModal={setShowSaveReportModal}
              showSaveButton={filtersHaveChanged}
              savingReport={updatingReport || savingNewReport}
              savedReports={savedReports}
              onResetReport={switchCurrentReport}
              onSaveReport={async (newReport) => {
                await updateSavedReport({
                  variables: {
                    ...newReport,
                    // FilterList is different to applyFilters in mutation
                    filterList: newReport.applyFilters
                      ? newReport.applyFilters.map(
                          ({ dimensionParameterID, dimensionOptions }) => ({
                            dimensionName: dimensionParameterID,
                            dimensionParameterID,
                            dimensionOptions,
                          }),
                        )
                      : undefined,
                  },
                })

                setFiltersHaveChanged(false)
              }}
              actionButtons={[
                {
                  key: 'manageReports',
                  isDisabled: savedReports.length === 0,
                  onPress: () => setShowManageReportsModal(true),
                  buttonText: 'Manage reports',
                },
                {
                  key: 'save',
                  isDisabled: !filtersHaveChanged,
                  onPress: async () => {
                    if (currentReport === null) {
                      setShowSaveReportModal(true)
                    } else {
                      await updateSavedReport({
                        variables: {
                          ...currentReport,
                          ...dataConfig,
                          // FilterList is different to applyFilters in mutation
                          filterList: dataConfig.applyFilters
                            ? dataConfig.applyFilters.map(
                                ({
                                  dimensionParameterID,
                                  dimensionOptions,
                                }) => ({
                                  dimensionName: dimensionParameterID,
                                  dimensionParameterID,
                                  dimensionOptions,
                                }),
                              )
                            : undefined,
                        },
                      })

                      setFiltersHaveChanged(false)
                    }
                  },
                  buttonText:
                    currentReport === null ? 'Save report' : 'Save changes',
                  logAction: {
                    action: 'saved-lost-link-reports-update-existing',
                    extra: JSON.stringify({
                      ...currentReport,
                      ...dataConfig,
                    }),
                    functionName: 'updateSavedReport',
                  },
                },
                {
                  key: 'downloadPDF',
                  isDisabled: true,
                  onPress: () => null,
                  buttonText: 'Download as PDF',
                },
                {
                  key: 'downloadExcel',
                  isDisabled: !dataConfig?.startDate || !currentTempTable,
                  loading: exporting,
                  onPress: async () => {
                    if (
                      !dataConfig ||
                      !dataConfig.startDate ||
                      !currentTempTable
                    )
                      return

                    const {
                      startDate,
                      endDate,
                      granularity,
                      selectedMetric,
                      applyFilters,
                    } = dataConfig

                    setExporting(true)

                    const from = reportDates.find(
                      (date) => date.metricValue === startDate,
                    )
                      ? startDate
                      : moment(startDate).format('YYYY-MM-DD')

                    const rawExcelData: LostLinksReportDataConfig = {
                      startDate: from,
                      endDate: endDate
                        ? moment(endDate).format('YYYY-MM-DD')
                        : '',
                      granularity: granularity || 'monthly',
                      selectedMetric,
                      applyFilters,
                      offset: paginationData
                        ? (paginationData.activePage - 1) *
                            paginationData.rowsPerPage +
                          1
                        : 1,
                      limit: paginationData?.rowsPerPage || 25,
                      sortDirection: paginationData?.sortDirection || 'DESC',
                      tempTable: currentTempTable,
                    }

                    await getLostLinksRawData(rawExcelData)

                    logAction({
                      variables: {
                        action: 'download-link-audit-data-excel',
                        extra: JSON.stringify(rawExcelData),
                        websiteSection: 'report',
                        functionName: 'getLostLinksRawData',
                        pagePath: window.location.pathname,
                      },
                    })

                    setExporting(false)
                  },
                  buttonText: 'Download to Excel',
                },
              ]}
            />
            <div className={styles.paper}>
              <LostLinksReportHeader
                isMobile={isMobile}
                dateRange={dataBasedDateRange}
                dataSourceData={dataSourceData}
                dataConfig={dataConfig}
                availableDimensionsTotals={availableDimensionsTotals}
                updateFilters={async ({
                  dimensionParameterID,
                  optionValue,
                }) => {
                  // Shouldn't be possible - this function only removes existing filters
                  if (!dataConfig.applyFilters) return

                  const newApplyFilters = [...dataConfig.applyFilters]

                  const filterListIndex = dataConfig.applyFilters.findIndex(
                    (filter) =>
                      filter.dimensionParameterID === dimensionParameterID,
                  )

                  if (filterListIndex === -1) return

                  const dimensionToEdit =
                    dataConfig.applyFilters[filterListIndex]

                  const newDimensionOptions = dimensionToEdit.dimensionOptions.filter(
                    (option) => option !== optionValue,
                  )

                  if (newDimensionOptions.length > 0) {
                    newApplyFilters.splice(filterListIndex, 1, {
                      ...dimensionToEdit,
                      dimensionOptions: dimensionToEdit.dimensionOptions.filter(
                        (option) => option !== optionValue,
                      ),
                    })
                  } else {
                    newApplyFilters.splice(filterListIndex, 1)
                  }

                  const newDataConfig = {
                    ...dataConfig,
                    applyFilters:
                      newApplyFilters.length > 0 ? newApplyFilters : undefined,
                  }

                  setDataConfig(newDataConfig)
                  setFiltersHaveChanged(true)

                  await refetchData(newDataConfig, { refetchFilters: false })
                }}
                currentReport={currentReport}
                error={!!tableDataError || !!graphDataError}
              />
              <div className={styles.metricDataBannerWrapper}>
                <div className={styles.metricDataBanner}>
                  <LostLinksDataCallout
                    loading={!tableData || tableDataLoading}
                    error={!!tableDataError}
                    successMetric={isFilteredReport ? 'Links' : 'Other links'}
                    tooltip="Landing pages with UTMs found in Google Analytics which were not created in this platform."
                    metricValue={totalTableRows}
                    dateRange={dataBasedDateRange}
                  />
                  <LostLinksDataCallout
                    loading={!graphDataToShow || graphDataLoading}
                    error={!!graphDataError}
                    successMetric={
                      isFilteredReport ? 'Sessions' : 'Other link sessions'
                    }
                    tooltip="A group of user interactions that take place within a limited time frame (ends after 30mins, if this has not been adjusted in settings), from lost links."
                    metricValue={total || 0}
                    dateRange={dataBasedDateRange}
                  />
                  {/* Only show this callout if current report is not a saved one and no filters have been applied */}
                  {!isFilteredReport && (
                    <LostLinksDataCallout
                      loading={!graphDataToShow || graphDataLoading}
                      error={!!graphDataError}
                      successMetric="% sessions from other links"
                      tooltip="Other link sessions divided by all link sessions found in Google Analytics."
                      metricValue={
                        total !== null && uplifterLinksTotal !== null
                          ? (total / (total + uplifterLinksTotal)) * 100
                          : 0
                      }
                      percentage
                      dateRange={dataBasedDateRange}
                    />
                  )}
                </div>
              </div>
              <div className={styles.graphWrapper}>
                <LostLinksGraph
                  width={width}
                  screenWidth={screenWidth}
                  offset={offset}
                  granularity={dataConfig.granularity || 'monthly'}
                  isMobile={isMobile}
                  graphDataToShow={graphDataToShow}
                  graphTableTotals={graphTableTotals}
                  stackDimension={!isFilteredReport ? 'sessions' : ''}
                  successMetricDisplayName={
                    metricNameMapper[dataConfig.selectedMetric] ||
                    dataConfig.selectedMetric
                  }
                  loading={graphDataLoading}
                  error={!!graphDataError}
                  title={`Links${
                    !isFilteredReport ? 'by creation source' : ''
                  }`}
                >
                  {graphDataLoading ? (
                    <></>
                  ) : (
                    <h1 className={styles.graphTitle}>
                      <span>
                        {metricNameMapper[dataConfig.selectedMetric] ||
                          dataConfig.selectedMetric}
                        {dateRangeLabel}
                        {!isFilteredReport ? ' split by creation source' : ''}
                      </span>
                    </h1>
                  )}
                </LostLinksGraph>
              </div>
              <div className={styles.tableWrapper}>
                <div className={styles.tableControls}>
                  <LostLinksSearchBox
                    dataConfig={dataConfig}
                    totalRows={totalTableRows}
                    loading={!tableData || tableDataLoading}
                    error={!!tableDataError}
                    searchFields={[
                      { name: 'All', value: 'all' },
                      { name: 'Landing page', value: 'landingPage' },
                      ...availableDimensions.map((dim) => ({
                        name: dim.dimensionName,
                        value: dim.dimensionParameterID,
                      })),
                    ]}
                    refetchData={(data) => {
                      setDataConfig(data)

                      refetchData(data)
                    }}
                  />
                  <SelectBoxChecklist
                    id="tableParameters"
                    className={styles.tableParametersSelector}
                    isClearable={false}
                    allLabel="All"
                    excludeNone
                    controlLabel="Parameters"
                    placeholder="Parameters"
                    labelKey="paramHeadingName"
                    valueKey="paramHeadingValue"
                    isDisabled={tableParameters.length === 0}
                    value={tableParameters.filter((param) => !param.hidden)}
                    options={tableParameters}
                    onChange={(newValue) => {
                      const newHideIndex: number[] = []

                      tableParameters.forEach(
                        ({ paramHeadingValue }, index) => {
                          if (
                            !newValue.find(
                              (val) =>
                                val.paramHeadingValue === paramHeadingValue,
                            )
                          ) {
                            newHideIndex.push(index)
                          }
                        },
                      )

                      setDataConfig((curr) => ({
                        ...curr,
                        hideColumnIndexList: newHideIndex,
                      }))

                      setTableParameters((curr) => {
                        const newTableParams: {
                          paramHeadingValue: string
                          paramHeadingName: string
                          hidden: boolean
                        }[] = []

                        curr.forEach((param) => {
                          newTableParams.push({
                            ...param,
                            hidden: !newValue.find(
                              (val) =>
                                val.paramHeadingValue ===
                                param.paramHeadingValue,
                            ),
                          })
                        })

                        return newTableParams
                      })

                      // If on a saved report, update that report
                      if (currentReport) {
                        updateSavedReport({
                          variables: {
                            savedReportID: currentReport.savedReportID,
                            hideColumnIndexList: newHideIndex,
                          },
                        })
                      }
                    }}
                  />
                </div>
                <LostLinksTable
                  savedReportID={currentReport?.savedReportID}
                  savedReportColumnOrder={
                    currentReport?.updatedTableColumnIndexOrderList
                  }
                  data={tableData}
                  paramsToShow={tableParameters}
                  groupByLandingPage={dataConfig.groupByLandingPage}
                  loading={!tableData || tableDataLoading}
                  error={!!tableDataError}
                  totalRows={totalTableRows}
                  paginationData={paginationData}
                  setPaginationData={setPaginationData}
                  refetchData={(pagination) => {
                    refetchData(dataConfig, {
                      pagination: {
                        ...initialPaginationConfig,
                        ...pagination,
                      },
                      refetchGraph: false,
                      refetchFilters: false,
                    })
                  }}
                  showEmptyColumns={!isFilteredReport}
                />
              </div>
            </div>
          </div>
        </Layout>
      </SiteWrapper>
      {showSaveReportModal && (
        <SaveEditReportModal
          reportType="lostLinks"
          reportIDKey="savedReportID"
          reportNameKey="reportTitle"
          toggleActive={setShowSaveReportModal}
          reportToEdit={reportToEdit}
          setReportToEdit={setReportToEdit}
          loading={savingNewReport}
          onSuccess={async (newReport) => {
            let newReportID = newReport.savedReportID || ''

            if (reportToEdit === null) {
              const { data: newReportData } = await saveReport({
                variables: {
                  ...dataConfig,
                  // FilterList is different to applyFilters in mutation
                  filterList: dataConfig.applyFilters
                    ? dataConfig.applyFilters.map(
                        ({ dimensionParameterID, dimensionOptions }) => ({
                          dimensionName: dimensionParameterID,
                          dimensionParameterID,
                          dimensionOptions,
                        }),
                      )
                    : undefined,
                  ...newReport,
                  reportName: newReport.reportTitle as string,
                },
              })

              newReportID =
                newReportData?.report.saveLinkAuditReport.savedReportID || ''
            } else {
              updateSavedReport({
                variables: newReport,
              })
            }

            setCurrentReport({
              // @ts-ignore
              savedReportID: newReportID,
              ...dataConfig,
              ...newReport,
            })
            setFiltersHaveChanged(false)
            setShowSaveReportModal(false)
          }}
        />
      )}
      {showManageReportsModal && (
        <ManageSavedReportsModal
          toggleActive={setShowManageReportsModal}
          reportType="lostLinks"
          reportIDKey="savedReportID"
          reportNameKey="reportTitle"
          loading={loadingSavedReports || savingNewReport || updatingReport}
          savedReports={savedReports}
          setReportToEdit={setReportToEdit}
          setShowSaveReportModal={setShowSaveReportModal}
          userPreferredOrder={userPreferredOrder}
          onUpdateOrder={async (newOrder) => {
            await updateReportsOrder({
              variables: {
                newOrder,
              },
            })
          }}
          /**
           * Hidden reports are provided as a separate list.
           * This is different to ReportMarketingJourneysPage.
           * TODO: Need Tom to update the response object to use a `reportHidden` field on each report instead of a separate array
           */
          // reportHiddenKey="boardHidden"
          // onHideReport={async (reportsOrder, hiddenReports) => {
          //   await updateBoardOrder({
          //     variables: {
          //       newOrder: reportsOrder,
          //       hideReports: hiddenReports,
          //     },
          //   })
          // }}
          onDeleteReport={async (reportIdToDelete) => {
            await deleteSavedLostLinkReport({
              variables: {
                savedReportID: reportIdToDelete,
              },
            })

            // If current report is deleted, switch back to default report
            if (reportIdToDelete === currentReport?.savedReportID) {
              switchCurrentReport(null)
            }
          }}
        />
      )}
    </>
  )
}
