import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useLazyQuery, useReactiveVar } from '@apollo/client'
import classNames from 'classnames'
import ReactMarkdown from 'react-markdown'
import moment from 'moment'
import { nanoid } from 'nanoid'

import Button from './button'
import Input from './input'
import { LoadingLabel } from './loader'
import SelectBox from './select-box'
import Tooltip from './tooltip'
import { RequestBrandedDomainModal } from './upgrade-modals'
import {
  currentUserDetails,
  ShortLinkAliasesByDomain,
} from '../api/apollo/variables'
import { validateShortLinkCandidate } from '../api/graphql/track-create-client'
import { maxShortLinkAliasLength } from '../api/constants'
import RefreshIcon from '../assets/icon-refresh.svg'
import { defaultShortLinkDomain, minBatchShortLinks } from '../core/constants'
import {
  getCustomDomainID,
  getItemByKeyValue,
  replaceShortLinkCharacters,
} from '../helpers'
import { removeShortLinkFromStorage } from '../helpers/short-links'
import useShortLinks from '../hooks/useShortLinks'
import useSubscriptionLevel from '../hooks/useSubscriptionLevel'
import { getUserData, saveUserData } from '../helpers/local-client'
import styles from '../styles/short-link.module.scss'

interface ShortLinkDomainSelectorProps {
  onDomainChange?: (domain: string) => void
  className?: string
}

export const ShortLinkDomainSelector = ({
  onDomainChange,
  className,
}: ShortLinkDomainSelectorProps) => {
  const { workspaceID } = useReactiveVar(currentUserDetails)

  const {
    selectedDomain,
    availableDomains,
    updateShortLinkDomain,
  } = useShortLinks()

  const [showBrandedDomainModal, setShowBrandedDomainModal] = useState(false)

  useEffect(() => {
    if (onDomainChange) onDomainChange(selectedDomain)
  }, [selectedDomain])

  return (
    <>
      <SelectBox
        id="domain-selector"
        className={classNames(className, styles.domainSelectorContainer)}
        labelKey="optionName"
        valueKey="optionValue"
        value={availableDomains.find(
          (option) => option.optionValue === selectedDomain,
        )}
        options={availableDomains.filter((dom) => dom.selected)}
        onChange={(newValue) => {
          if (newValue?.optionValue) {
            updateShortLinkDomain(newValue.optionValue)
          }
        }}
      >
        {workspaceID && (
          <Button
            variant="text"
            className={styles.addButton}
            onPressStart={() => setShowBrandedDomainModal(true)}
          >
            Get a branded short link +
          </Button>
        )}
      </SelectBox>
      {showBrandedDomainModal && (
        <RequestBrandedDomainModal onHideModal={setShowBrandedDomainModal} />
      )}
    </>
  )
}

interface ShortLinkAliasMainProps {
  domainID: string
  fetchingAliases: boolean
  alias: string
  onAliasChange?: (alias: string) => void
  error?: boolean
  className?: string
  setShortLinkStatus?: Dispatch<SetStateAction<UrlStatus>>
}

interface ShortLinkSingleAliasProps extends ShortLinkAliasMainProps {
  index: number
  canUseCustom?: boolean
  replaceAlias: (indexOrNLinks: number) => Promise<ShortLinkAliasesByDomain>
  setShortLinkMessage?: Dispatch<SetStateAction<string>>
}

interface ShortLinkBatchAliasProps extends ShortLinkAliasMainProps {
  nLinks: number
  replaceBatchAlias?: (nLinks: number) => Promise<ShortLinkAliasesByDomain>
}

const isSingleAlias = (
  props: ShortLinkSingleAliasProps | ShortLinkBatchAliasProps,
): props is ShortLinkSingleAliasProps =>
  Object.prototype.hasOwnProperty.call(props, 'index')

export const ShortLinkAlias = (
  props: ShortLinkSingleAliasProps | ShortLinkBatchAliasProps,
) => {
  const { isDemoAccount } = useReactiveVar(currentUserDetails)

  const { isFree } = useSubscriptionLevel()

  // Shared props
  const {
    domainID,
    fetchingAliases,
    alias,
    onAliasChange,
    error,
    className,
    setShortLinkStatus,
  } = props

  // Single props
  const {
    index,
    canUseCustom,
    replaceAlias,
    setShortLinkMessage,
  } = isSingleAlias(props)
    ? props
    : {
        index: null,
        canUseCustom: null,
        replaceAlias: null,
        setShortLinkMessage: null,
      }

  // Batch props
  const { nLinks, replaceBatchAlias } = !isSingleAlias(props)
    ? props
    : { nLinks: null, replaceBatchAlias: null }

  const [validateCustomShortLink] = useLazyQuery(validateShortLinkCandidate, {
    fetchPolicy: 'network-only',
  })

  const [shortLink, setShortLink] = useState(alias)
  const [shortLinkIsCustom, setShortLinkIsCustom] = useState(false)
  const [refreshingShortLink, setRefreshingShortLink] = useState(false)

  useEffect(() => {
    setShortLinkIsCustom(false)
  }, [domainID])

  // Reassign shortLink to fetched alias when it is reset
  useEffect(() => {
    setShortLink(alias)

    if (setShortLinkMessage) setShortLinkMessage('')
    if (setShortLinkStatus) setShortLinkStatus('')
    if (onAliasChange) onAliasChange(alias)
  }, [alias])

  if (isFree && !isDemoAccount) return null

  return (
    <Input
      name="short-link"
      value={refreshingShortLink ? '' : shortLink}
      readOnly={!canUseCustom}
      placeholder="Creating short link..."
      delay={600}
      className={classNames(className, styles.shortLinkInput, {
        [styles.shortLinkInputAnimated]: fetchingAliases || refreshingShortLink,
        [styles.editable]: canUseCustom,
        [styles.error]: error,
      })}
      beforeChange={(v) => replaceShortLinkCharacters(v)}
      onBlur={() => {
        // Reset alias to randomly generated one if left empty
        if (shortLink === '' && shortLinkIsCustom) {
          setShortLinkIsCustom(false)
          setShortLink(alias)

          if (onAliasChange) onAliasChange(alias)
          if (setShortLinkMessage) setShortLinkMessage('')
          if (setShortLinkStatus) setShortLinkStatus('')
        }
      }}
      onValueChange={async (value) => {
        if (!canUseCustom || !setShortLinkMessage || !setShortLinkStatus) return

        setShortLinkIsCustom(true)
        setShortLink(value)

        // No need to validate empty string
        if (value === '') {
          setShortLinkMessage('')
          setShortLinkStatus('invalid')
          return
        }

        // If matches batch alias pattern, do not allow
        if (/-0x(.*)/.test(value)) {
          setShortLinkMessage('This short link is not valid')
          setShortLinkStatus('invalid')
          return
        }

        const isMaxLength = shortLink.length > maxShortLinkAliasLength

        if (isMaxLength) {
          setShortLinkMessage(
            'Short link cannot be more than **1024 characters**.',
          )
          setShortLinkStatus('invalid')
          return
        }

        setShortLinkStatus('validating')

        let found: any = -1
        let isAvailable = false

        // Check if alias has already been validated for domain
        const savedData = getUserData(domainID)

        if (savedData && Array.isArray(savedData)) {
          found = getItemByKeyValue(savedData, 'shortLinkID', value)

          if (found && found.isAvailable) {
            const { availableUntil } = found

            const now = new Date(Date.now())

            const diff = moment(availableUntil).utc().diff(moment(now).utc())
            const isExpired = diff < 0

            if (!isExpired) {
              // Can be used
              isAvailable = true
            } else {
              // Needs to be rechecked
              found = -1
              removeShortLinkFromStorage(value, domainID)
            }
          }
        }

        if (found === -1) {
          const { data: validateShortLinkData } = await validateCustomShortLink(
            {
              variables: {
                testCandidate: value,
                customDomainID: getCustomDomainID(domainID),
              },
            },
          )

          if (validateShortLinkData) {
            saveUserData(
              [validateShortLinkData.track.validateShortLinkCandidate],
              domainID,
            )

            if (
              validateShortLinkData.track.validateShortLinkCandidate.isAvailable
            )
              isAvailable = true
          }
        }

        if (isAvailable) {
          setShortLinkMessage('Short link alias **available**.')
          setShortLinkStatus('valid')
        } else {
          setShortLinkMessage(
            `Short link **is already in use**.${
              domainID === '' || domainID === defaultShortLinkDomain
                ? ' Upgrade to branded domain or enter a different alias.'
                : ''
            }`,
          )
          setShortLinkStatus('invalid')
        }

        if (onAliasChange) onAliasChange(value)
      }}
      suffix={
        <>
          <Tooltip
            id="refresh-alias-tooltip"
            tooltipMessage={
              fetchingAliases || refreshingShortLink
                ? ''
                : 'Generate random alias'
            }
            tooltipPosition="left"
          >
            <Button
              variant="iconOnly"
              className={styles.shortLinkInputButton}
              isDisabled={fetchingAliases || refreshingShortLink}
              icon={{
                src: RefreshIcon,
                alt: 'Refresh alias',
                imgHeight: 16,
              }}
              onPress={async () => {
                setRefreshingShortLink(true)
                if (setShortLinkStatus) setShortLinkStatus('refetching')

                if (nLinks !== null && replaceBatchAlias) {
                  await replaceBatchAlias(nLinks)
                } else if (index !== null && replaceAlias) {
                  await replaceAlias(index)
                }

                setRefreshingShortLink(false)
                setShortLinkIsCustom(false)
                if (setShortLinkStatus) setShortLinkStatus('')
              }}
            />
          </Tooltip>
        </>
      }
    />
  )
}

interface ShortLinkFullProps {
  fetchBatchAlias?: boolean
  shortLinkKey?: string
  onAliasChange?: (alias: string) => void
  onDomainChange?: (domain: string) => void
  onStatusChange?: (status: UrlStatus) => void
  domainSelectorOnly?: boolean
  className?: string
  domainSelectorClassName?: string
  aliasClassName?: string
  children?: React.ReactElement
}

export const ShortLinkFull = ({
  fetchBatchAlias = false,
  shortLinkKey = '',
  onAliasChange,
  onDomainChange,
  onStatusChange,
  domainSelectorOnly = false,
  className,
  domainSelectorClassName,
  aliasClassName,
  children,
}: ShortLinkFullProps) => {
  const {
    canUseShortLinks,
    selectedDomain,
    fetchNewAliases,
    fetchingAliases,
    replaceAlias,
    replaceBatchAlias,
    shortLinks,
  } = useShortLinks()

  const [shortLinkMessage, setShortLinkMessage] = useState<string>('')
  const [shortLinkStatus, setShortLinkStatus] = useState<UrlStatus>('')

  // Fetch a single alias
  useEffect(() => {
    if (
      !shortLinks ||
      domainSelectorOnly ||
      !selectedDomain ||
      (!fetchBatchAlias &&
        (!shortLinks.individual || shortLinks.individual.length > 0)) ||
      (fetchBatchAlias && shortLinks.batch)
    )
      return

    const fetchAliases = async () => {
      await fetchNewAliases(fetchBatchAlias ? minBatchShortLinks : 1)
    }

    fetchAliases()
  }, [shortLinks, selectedDomain])

  useEffect(() => {
    if (onStatusChange) onStatusChange(shortLinkStatus)
  }, [shortLinkStatus])

  if (!canUseShortLinks) return null

  return (
    <>
      <div className={classNames(className, styles.fullShortLinkWrapper)}>
        <ShortLinkDomainSelector
          onDomainChange={onDomainChange}
          className={domainSelectorClassName}
        />
        {!domainSelectorOnly && (
          <>
            <p className={styles.shortLinkInputPrefixDiv}>/</p>
            {fetchBatchAlias ? (
              <ShortLinkAlias
                key={shortLinkKey}
                className={styles.jointAlias}
                domainID={selectedDomain}
                onAliasChange={onAliasChange}
                fetchingAliases={fetchingAliases}
                alias={shortLinks && shortLinks.batch ? shortLinks.batch : ''}
                nLinks={minBatchShortLinks}
                replaceBatchAlias={replaceBatchAlias}
                setShortLinkStatus={setShortLinkStatus}
                error={shortLinkStatus !== '' && shortLinkStatus !== 'valid'}
              />
            ) : (
              <ShortLinkAlias
                key={shortLinkKey}
                className={classNames(aliasClassName, styles.jointAlias)}
                domainID={selectedDomain}
                onAliasChange={onAliasChange}
                fetchingAliases={fetchingAliases}
                replaceAlias={replaceAlias}
                alias={
                  shortLinks &&
                  shortLinks.individual &&
                  shortLinks.individual.length > 0
                    ? shortLinks.individual[0]
                    : ''
                }
                index={0}
                canUseCustom={!fetchBatchAlias}
                setShortLinkMessage={setShortLinkMessage}
                setShortLinkStatus={setShortLinkStatus}
                error={shortLinkStatus !== '' && shortLinkStatus !== 'valid'}
              />
            )}
          </>
        )}
      </div>
      {shortLinkStatus === 'validating' && (
        <div
          key={nanoid()}
          className={classNames(
            styles.validationCheckShortLink,
            styles.validationCheck,
          )}
        >
          <LoadingLabel label="Checking availability" />
        </div>
      )}
      {shortLinkStatus !== 'validating' && shortLinkMessage !== '' ? (
        <div
          key={nanoid()}
          className={classNames(
            styles.validationCheckShortLink,
            styles.validationCheck,
            {
              [styles.urlIsBad]: shortLinkStatus === 'invalid',
              [styles.urlIsGood]: shortLinkStatus === 'valid',
            },
          )}
        >
          <div
            className={classNames(styles.validationCheckItem, {
              [styles.urlIsBad]: shortLinkStatus === 'invalid',
              [styles.urlIsGood]: shortLinkStatus === 'valid',
            })}
          >
            <ReactMarkdown linkTarget="_blank">
              {shortLinkMessage}
            </ReactMarkdown>
          </div>
        </div>
      ) : (
        <>{children}</>
      )}
    </>
  )
}
