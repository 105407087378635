import React, { useEffect, useMemo, useRef, useState } from 'react'
import classNames from 'classnames'
import ReactMarkdown from 'react-markdown'
import _ from 'lodash'

import { NavigateButton } from './button'
import { LoadingLabel } from './loader'
import ObservepointModal from './observepoint-modal'
import Tooltip from './tooltip'
import { RecentlyValidatedUrl } from '../api/apollo/variables'
import { messages } from '../core/constants'
import { getUrlMessages } from '../helpers/track-module'
import useLogAction from '../hooks/useLogAction'
import useUrlValidation from '../hooks/useUrlValidation'
import styles from '../styles/campaign-code-url-validation.module.scss'

interface UrlValidationMessageProps {
  url: string
  validationDetails?: RecentlyValidatedUrl
  className?: string
}

export const UrlValidationMessage = ({
  url,
  validationDetails,
  className,
}: UrlValidationMessageProps) => {
  const {
    statusCode,
    intensiveStatusCode,
    badUrl,
    noAnalyticsTag,
    redirectedLandingPage,
    slowLandingPage,
    validatorReturned,
  } = validationDetails || {}

  const logAction = useLogAction()

  const {
    quick,
    intensive,
    checkSpeed,
    checkRedirect,
    checkAnalytics,
  } = useUrlValidation()

  const messageRef = useRef<HTMLDivElement>(null)

  const [showObservepointModal, setShowObservepointModal] = useState(false)
  const [scanForIssuesMessagesShown, setScanForIssuesMessagesShown] = useState(
    false,
  )

  const quickValidationStatus = useMemo(() => {
    if (!quick) return ''

    if (statusCode === undefined) return 'validating'

    if (statusCode === 0 || (statusCode >= 400 && statusCode <= 599)) {
      return 'invalid'
    }

    if (statusCode >= 300 && statusCode <= 399) {
      return 'warning'
    }

    if (statusCode >= 200 && statusCode <= 299) {
      return 'valid'
    }

    return ''
  }, [quick, statusCode, intensiveStatusCode])

  const validatingIntensive = useMemo(() => {
    if (
      !intensive ||
      ['invalid', 'warning'].indexOf(quickValidationStatus) > -1
    )
      return false

    return intensiveStatusCode === undefined
  }, [intensive, intensiveStatusCode])

  const quickValidationMessage = useMemo(() => {
    if (!quick) return null

    if (badUrl) return messages.urlStatus.badUrl

    switch (quickValidationStatus) {
      case 'valid':
        return 'This page exists'
      case 'warning':
        return messages.urlStatus.redirectedLandingPage
      case 'invalid':
        return messages.urlStatus.badUrl
      default:
        return null
    }
  }, [quick, quickValidationStatus, badUrl])

  const intensiveValidationMessage = useMemo(() => {
    if (!intensive) return null

    if (intensiveStatusCode === undefined) return null

    // Quick validation message is enough in this case
    if (quickValidationStatus === 'invalid') return null

    // Intensive validation failed - do not show message
    if (
      ['valid', 'warning'].indexOf(quickValidationStatus) > -1 &&
      intensiveStatusCode === -1
    )
      return null

    if (!validatorReturned) return messages.urlStatus.intensiveValidationFailed

    if (badUrl) return null

    const msgArray: string[] = []

    // Page speed
    if (checkSpeed && !slowLandingPage) {
      msgArray.push('is fast')
    }

    // Analytics
    if (checkAnalytics && !noAnalyticsTag) {
      msgArray.push('has analytics')
    }

    // Redirect
    if (checkRedirect && !redirectedLandingPage) {
      msgArray.push('has no redirects')
    }

    let butUsed = false

    // Page speed
    if (checkSpeed && slowLandingPage) {
      msgArray.push(
        `${
          !quickValidationMessage && msgArray.length === 0 ? '' : 'but'
        } is slow (loads in over 4 seconds)`,
      )

      if (quickValidationMessage) {
        butUsed = true
      }
    }

    if (checkAnalytics && noAnalyticsTag) {
      msgArray.push(
        `${
          butUsed || (!quickValidationMessage && msgArray.length === 0)
            ? ''
            : 'but'
        } may not have analytics`,
      )

      if (quickValidationMessage || msgArray.length > 0) {
        butUsed = true
      }
    }

    if (checkRedirect && redirectedLandingPage) {
      msgArray.push(
        `${
          butUsed || (!quickValidationMessage && msgArray.length === 0)
            ? ''
            : 'but'
        } redirects to another page`,
      )

      if (quickValidationMessage || msgArray.length > 0) {
        butUsed = true
      }
    }

    const msgStarter = quickValidationMessage ? ', ' : ' '
    const msgEnder = msgArray.pop()
    const msgEndSeparator = `${
      butUsed && msgEnder?.startsWith('but') ? ', ' : ' and '
    }`

    return `${msgArray.length > 0 ? msgStarter : ''}${msgArray.join(', ')}${
      quickValidationMessage || msgArray.length > 0 ? msgEndSeparator : ' '
    }${msgEnder}.${
      butUsed
        ? ' [Learn more](https://support.uplifter.ai/hc/en-us/articles/360020525658-What-does-landing-page-validation-do-).'
        : ''
    }`
  }, [
    intensive,
    quickValidationMessage,
    quickValidationStatus,
    intensiveStatusCode,
    badUrl,
    noAnalyticsTag,
    redirectedLandingPage,
    slowLandingPage,
    validatorReturned,
    checkSpeed,
    checkRedirect,
    checkAnalytics,
  ])

  const fullValidationStatus = useMemo(() => {
    if (
      !intensive ||
      ['invalid', 'warning'].indexOf(quickValidationStatus) > -1
    )
      return quickValidationStatus

    if (validatingIntensive) return 'validating'

    switch (true) {
      case checkSpeed && slowLandingPage:
      case checkAnalytics && noAnalyticsTag:
      case checkRedirect && redirectedLandingPage:
        return 'warning'
      default:
        return 'valid'
    }
  }, [
    quickValidationStatus,
    intensive,
    validatingIntensive,
    noAnalyticsTag,
    redirectedLandingPage,
    slowLandingPage,
    checkSpeed,
    checkRedirect,
    checkAnalytics,
  ])

  // Ensure 'Observepoint message shown' action is only logged once
  useEffect(() => {
    if (
      !scanForIssuesMessagesShown &&
      (['invalid', 'warning'].indexOf(quickValidationStatus) > -1 ||
        slowLandingPage ||
        redirectedLandingPage ||
        noAnalyticsTag)
    ) {
      logAction({
        variables: {
          action: 'scan-page-for-issues-cta-shown',
          functionName: 'observePointScan',
          pagePath: window.location.pathname,
          websiteSection: 'track',
          extra: `{"url": "${url}", "validationResults": "${JSON.stringify(
            validationDetails,
          )}"}`,
        },
      })
      setScanForIssuesMessagesShown(true)
    }
  }, [
    quickValidationStatus,
    slowLandingPage,
    redirectedLandingPage,
    noAnalyticsTag,
    scanForIssuesMessagesShown,
  ])

  // In markdown, skip tabbing through URL validation links
  useEffect(() => {
    if (messageRef.current) {
      const links = Array.from(messageRef.current.getElementsByTagName('a'))

      links.forEach((link) => {
        link.setAttribute('tabindex', '-1')
      })
    }
  }, [messageRef.current])

  if (url === '' || (!quick && !intensive)) return null

  return (
    <>
      <div
        className={classNames(className, styles.validationCheck, {
          [styles.urlIsBad]: fullValidationStatus === 'invalid',
          [styles.urlIsGood]: fullValidationStatus === 'valid',
          [styles.urlValidating]: fullValidationStatus === 'validating',
          [styles.urlWarning]: fullValidationStatus === 'warning',
        })}
      >
        <div
          ref={messageRef}
          className={classNames(styles.validationCheckItem, {
            [styles.urlIsBad]: fullValidationStatus === 'invalid',
            [styles.urlIsGood]: fullValidationStatus === 'valid',
            [styles.urlValidating]: fullValidationStatus === 'validating',
            [styles.urlWarning]: fullValidationStatus === 'warning',
          })}
        >
          <ReactMarkdown
            source={`${
              quickValidationMessage ||
              `${fullValidationStatus === 'validating' ? '' : 'This page'}`
            }${
              quickValidationStatus !== 'validating' &&
              !validatingIntensive &&
              !intensiveValidationMessage
                ? '.'
                : ''
            }${intensiveValidationMessage || ''}`}
            linkTarget="_blank"
          />

          {fullValidationStatus === 'validating' && (
            <LoadingLabel
              label={quickValidationMessage ? '' : 'Validating URL'}
            />
          )}
          {/* Observepoint scan */}
          {['invalid', 'warning'].indexOf(fullValidationStatus) > -1 && (
            <>
              {' '}
              <NavigateButton
                className={styles.observepointLink}
                excludeFromTabOrder
                onPress={() => {
                  setShowObservepointModal(true)

                  logAction({
                    variables: {
                      action: 'open-observepoint-scan-modal',
                      functionName: 'observePointScan',
                      pagePath: window.location.pathname,
                      websiteSection: 'track',
                      extra: url,
                    },
                  })
                }}
              >
                Scan for issues
              </NavigateButton>
            </>
          )}
        </div>
      </div>
      {showObservepointModal && (
        <ObservepointModal setShowModal={setShowObservepointModal} link={url} />
      )}
    </>
  )
}

interface UrlStatusProps {
  url: string
  validatedUrl?: Pick<
    RecentlyValidatedUrl,
    'badUrl' | 'noAnalyticsTag' | 'redirectedLandingPage' | 'slowLandingPage'
  > | null
  createdToday: boolean
  hasMetricData: boolean
  hasNoLandingPage: boolean
}

export function UrlStatus({
  url,
  validatedUrl,
  createdToday,
  hasMetricData,
  hasNoLandingPage,
}: UrlStatusProps) {
  const logAction = useLogAction()

  const noData = !validatedUrl
  const isBadUrl = validatedUrl && validatedUrl.badUrl
  const isGoodUrl = !!(
    !isBadUrl &&
    validatedUrl &&
    !validatedUrl.slowLandingPage &&
    !validatedUrl.noAnalyticsTag &&
    !validatedUrl.redirectedLandingPage
  )

  const urlStatusMessage = getUrlMessages(
    validatedUrl,
    createdToday,
    hasMetricData,
    isGoodUrl,
    hasNoLandingPage,
  )

  const [showObservepointModal, setShowObservepointModal] = useState(false)

  if (hasNoLandingPage || noData || isGoodUrl || (validatedUrl && !isGoodUrl)) {
    return (
      <>
        <div
          className={classNames(styles.badge, {
            [styles.noUrl]: hasNoLandingPage,
            [styles.goodUrl]: isGoodUrl && !hasNoLandingPage,
            [styles.badUrl]: isBadUrl && !hasNoLandingPage,
            [styles.slowLandingPage]:
              !isBadUrl &&
              validatedUrl &&
              validatedUrl.slowLandingPage &&
              !hasNoLandingPage,
            [styles.noAnalyticsTag]:
              !isBadUrl &&
              validatedUrl &&
              validatedUrl.noAnalyticsTag &&
              !hasNoLandingPage,
            [styles.redirectedLandingPage]:
              !isBadUrl &&
              validatedUrl &&
              validatedUrl.redirectedLandingPage &&
              !hasNoLandingPage,
          })}
        >
          <Tooltip
            id={`scan-for-issues-tooltip-${url}`}
            clickable
            maxWidth={350}
            tooltipPosition="left"
            tooltipMessage={
              <>
                <ReactMarkdown
                  source={urlStatusMessage}
                  className={styles.urlStatusMessage}
                  linkTarget="_blank"
                />
                {validatedUrl && !isGoodUrl && (
                  <>
                    {' '}
                    <NavigateButton
                      className={styles.observepointLink}
                      onPress={() => {
                        setShowObservepointModal(true)

                        logAction({
                          variables: {
                            action: 'open-observepoint-scan-modal',
                            functionName: 'observePointScan',
                            pagePath: window.location.pathname,
                            websiteSection: 'track',
                            extra: url,
                          },
                        })
                      }}
                    >
                      Scan page for issues
                    </NavigateButton>
                  </>
                )}
              </>
            }
          >
            <span className={styles.blank} />
          </Tooltip>
        </div>
        {showObservepointModal && (
          <ObservepointModal
            setShowModal={setShowObservepointModal}
            link={url}
          />
        )}
      </>
    )
  }

  return null
}
