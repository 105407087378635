import React from 'react'

interface TickProps {
  className?: string
  style?: object
}

export default function Tick(
  props: TickProps,
): React.ReactElement<React.ReactSVGElement> {
  return (
    <svg
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 148.17 112.09"
      {...props}
    >
      <path
        d="M145.7,28.74a17.34,17.34,0,0,0-24.45,1.85L82,76.24,66,94.84l-18.61-16V79L32.22,65.89A17.35,17.35,0,0,0,9.61,92.2L57,132.77l.29.23.27.2c.14.1.27.21.41.3l.39.26.31.21.45.27.27.16.47.26.28.14.47.23.3.14.45.18.34.14.41.15.41.14.36.12.46.13.32.08.52.13.28,0,.55.11.26,0,.58.08.26,0,.58,0,.3,0,.54,0h.85l.71,0,.26,0,.73-.07.41-.06.43-.07.49-.09.34-.07.52-.13.3-.08.51-.15.3-.1.49-.17.32-.12.43-.17.36-.16.38-.17.4-.2.32-.16.44-.25.26-.15.48-.29.21-.14.49-.34.18-.13.5-.38.17-.13.48-.4.18-.16.44-.41h0l.2-.2.39-.39.3-.34c.18-.19.35-.39.51-.59l-.25.3,66.52-77.4A17.34,17.34,0,0,0,145.7,28.74Z"
        transform="translate(-3.57 -24.55)"
      />
    </svg>
  )
}
