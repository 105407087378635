import React, { useEffect, useMemo, useState } from 'react'
import { useReactiveVar } from '@apollo/client'

import ManageWorkspaces from './manage-workspaces'
import ToggleBox from './toggle-box'
import { Heading } from './typography'
import WorkspaceDetails from './workspace-details'
import WorkspacePreferences from './workspace-preferences'
import { currentUserDetails } from '../api/apollo/variables'
import { getUrlQuery, isAdminUser } from '../helpers'
import useLogAction from '../hooks/useLogAction'
import styles from '../styles/workspace-settings.module.scss'

export default function WorkspaceSettings() {
  const { userPermission } = useReactiveVar(currentUserDetails)

  const logAction = useLogAction()

  const [openSection, setOpenSection] = useState('')

  // Open section according to URL query
  const intialOpen = useMemo(() => {
    const query = getUrlQuery()

    return query?.get('show')
  }, [])

  useEffect(() => {
    if (intialOpen) setOpenSection(intialOpen)
  }, [intialOpen])

  if (!userPermission || !isAdminUser(userPermission)) return null

  return (
    <div className={styles.container}>
      <>
        <ToggleBox
          noPadding
          className={styles.settingsToggleBox}
          heading={
            <div className={styles.settingHeader}>
              <div className={styles.emoji}>
                <span role="img" aria-label="Workspace details">
                  🖥️
                </span>
              </div>
              <div className={styles.headerText}>
                <Heading
                  type={2}
                  align="left"
                  className={styles.settingsToggleHeader}
                >
                  Workspace details
                </Heading>
                <span>
                  Update workspace name, homepage, report and QR code settings.
                </span>
              </div>
            </div>
          }
          open={openSection === 'workspaceDetails'}
          onToggle={() => {
            if (openSection === 'workspaceDetails') {
              setOpenSection('')
            } else {
              setOpenSection('workspaceDetails')

              logAction({
                variables: {
                  action: 'open-settings-section',
                  extra: '{"sectionOpened": "workspaceDetails"}',
                  websiteSection: 'Settings',
                  pagePath: '/settings',
                  functionName: 'toggleSection',
                },
              })
            }
          }}
        >
          <WorkspaceDetails className={styles.settingsToggleBoxInner} />
        </ToggleBox>
        <ToggleBox
          noPadding
          className={styles.settingsToggleBox}
          heading={
            <div className={styles.settingHeader}>
              <div className={styles.emoji}>
                <span role="img" aria-label="Workspace preferences">
                  ✅
                </span>
              </div>
              <div className={styles.headerText}>
                <Heading
                  type={2}
                  align="left"
                  className={styles.settingsToggleHeader}
                >
                  Workspace preferences
                </Heading>
                <span>Manage access to features and notifications.</span>
              </div>
            </div>
          }
          open={openSection === 'workspacePreferences'}
          onToggle={() => {
            if (openSection === 'workspacePreferences') {
              setOpenSection('')
            } else {
              setOpenSection('workspacePreferences')

              logAction({
                variables: {
                  action: 'open-settings-section',
                  extra: '{"sectionOpened": "workspacePreferences"}',
                  websiteSection: 'Settings',
                  pagePath: '/settings',
                  functionName: 'toggleSection',
                },
              })
            }
          }}
        >
          <WorkspacePreferences className={styles.settingsToggleBoxInner} />
        </ToggleBox>
        <ToggleBox
          noPadding
          className={styles.settingsToggleBox}
          heading={
            <div className={styles.settingHeader}>
              <div className={styles.emoji}>
                <span
                  role="img"
                  aria-label="Link builders and analytics integrations"
                >
                  📡
                </span>
              </div>
              <div className={styles.headerText}>
                <Heading
                  type={2}
                  align="left"
                  className={styles.settingsToggleHeader}
                >
                  Analytics connections
                </Heading>
                <span>
                  Create new workspaces and connect website analytics.
                </span>
              </div>
            </div>
          }
          open={openSection === 'workspaceConnections'}
          onToggle={() => {
            if (openSection === 'workspaceConnections') {
              setOpenSection('')
            } else {
              setOpenSection('workspaceConnections')

              logAction({
                variables: {
                  action: 'open-settings-section',
                  extra: '{"sectionOpened": "workspaceConnections"}',
                  websiteSection: 'Settings',
                  pagePath: '/settings',
                  functionName: 'toggleSection',
                },
              })
            }
          }}
        >
          <ManageWorkspaces className={styles.settingsToggleBoxInner} />
        </ToggleBox>
      </>
    </div>
  )
}
