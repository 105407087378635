import { gql } from '../../__gql-types__/gql'

export const shareCodesFromList = gql(`
	mutation ShareCodes(
      $codeList: [String!]!
      $emailList: [String!]!
      $note: String!
      $subject: String!
    ) {
      track {
        shareCodes(
          codeList: $codeList
          emailList: $emailList
          note: $note
          subject: $subject
        ) {
          account
          cid
          codeShareData
          requester
          sent
          shortID
        }
      }
    }
`)

export const deleteBatchCodes = gql(`
  mutation DeleteBatchCodes (
    $codeIDList: [String!]!
    $customDomainID: String
  ) {
    track {
      deleteCodes(
        codeIDList: $codeIDList,
        customDomainID: $customDomainID
      )
    }
  }
`)
