import React, { useState, useEffect, useMemo } from 'react'
import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client'
import classNames from 'classnames'

import { BetaLabel, BulletCounter } from './counter'
import Link from './link'
import SiteLogo from './site-logo'
import { Heading } from './typography'
import { currentUserDetails, dataSourceReactive } from '../api/apollo/variables'
import { listSavedLostLinksReports } from '../api/graphql/report-client'
import { getCampaignCodeGenerator } from '../api/graphql/track-create-client'
import { getUpdateRequestList } from '../api/graphql/track-edit-client'
import { getUserAccounts } from '../api/graphql/user-client'
import { getAvailableModules } from '../api/graphql/workspace-client'
import MenuSvg from '../assets/svgs/menu'
import {
  ConnectIcon,
  ReportIcon,
  SettingsIcon,
  TrackIcon,
} from '../assets/svgs/menu/module-icons'
import Arrow from '../assets/svgs/arrow'
import { brandName, matchTypesDropDown } from '../core/constants'
import { isAdminUser, returnUnique } from '../helpers'
import useShortLinks from '../hooks/useShortLinks'
import styles from '../styles/mobile-nav.module.scss'

interface MobileNavProps {
  justLogo?: boolean
  onboardingNotifications?: number
}

export default function MobileNav({
  justLogo,
  onboardingNotifications,
}: MobileNavProps) {
  const { workspaceID, userPermission, companyID } = useReactiveVar(
    currentUserDetails,
  )
  const dataSource = useReactiveVar(dataSourceReactive)

  const { canUseShortLinks } = useShortLinks()

  const { data: accountData } = useQuery(getAvailableModules)
  const [fetchUpdateRequestList, { data: updateRequestData }] = useLazyQuery(
    getUpdateRequestList,
    {
      fetchPolicy: 'cache-first',
    },
  )
  const [
    fetchGeneratorFieldTypes,
    { data: generatorFieldsData },
  ] = useLazyQuery(getCampaignCodeGenerator, {
    fetchPolicy: 'cache-first',
  })
  const [getUserAccountProfiles] = useLazyQuery(getUserAccounts)
  const [
    getSavedOtherLinksReports,
    { data: savedOtherLinksReportsData },
  ] = useLazyQuery(listSavedLostLinksReports)

  const [requestCounter, setRequestCounter] = useState<number | null>(null)

  // Only fetch requests if user is an admin user
  useEffect(() => {
    if (!workspaceID) return

    const fetchTrackData = async () => {
      await fetchGeneratorFieldTypes()
      await fetchUpdateRequestList()
    }

    if (isAdminUser(userPermission)) {
      fetchTrackData()
    }
  }, [workspaceID, userPermission])

  const { trackAvailable, reportAvailable } = useMemo(() => {
    // Track and Report should be seen straight away
    if (!accountData)
      return {
        trackAvailable: true,
        reportAvailable: true,
      }

    return accountData.currentAccount
  }, [accountData])

  const dataSourceIsGoogle = useMemo(() => {
    return dataSource && dataSource.connectionSource === 'google'
  }, [dataSource])

  const showDropdowns = useMemo(() => {
    if (!generatorFieldsData) return false

    return generatorFieldsData.campaignCodeGenerator.paramDefs.some(
      ({ fieldType }) => fieldType === 'select',
    )
  }, [generatorFieldsData])

  const [active, setActive] = useState(false)
  const [subNavModule, setSubNavModule] = useState<'track' | 'report' | null>(
    null,
  )
  const [secondarySubNavModule, setSecondarySubNavModule] = useState<
    'report-performance' | 'report-other-links' | null
  >(null)

  // Get valid requests for the user
  useEffect(() => {
    if (!updateRequestData || !isAdminUser(userPermission) || !companyID) return

    const getRequests = async () => {
      const allPendingRequests =
        updateRequestData.track.updateRequestList.pendingRequests

      if (allPendingRequests.length === 0) return

      const { data: userAccountsData } = await getUserAccountProfiles()

      if (userAccountsData) {
        const { userAccountProfiles } = userAccountsData.currentUser

        if (userAccountProfiles.length > 0) {
          const selectedAccounts: {
            accountName: string
            accountID: string
          }[] = returnUnique(userAccountProfiles, 'accountID')
            .filter(
              (account: AccountInfo) =>
                account.companyID === companyID &&
                (account.userPermission === 'admin' ||
                  account.userPermission === 'support'),
            )
            .map((account: AccountInfo) => {
              return {
                accountName: account.accountName,
                accountID: account.accountID,
              }
            })

          const availableAccountIDs = selectedAccounts.map(
            (account) => account.accountID,
          )

          const validPendingRequests = allPendingRequests.filter((request) => {
            return (
              availableAccountIDs.indexOf(request.updateRequestAccountID) >
                -1 &&
              request.changeRequests &&
              !request.changeRequests.every((changeRequest) => {
                return (
                  changeRequest.newOptions &&
                  changeRequest.newOptions.every(
                    ({ approved, rejected }) => approved || rejected,
                  )
                )
              })
            )
          })

          setRequestCounter(validPendingRequests.length || null)
        }
      }
    }

    getRequests()
  }, [updateRequestData, userPermission, companyID])

  // Allows report page to auto-filter to workspace's parameters on load
  const reportPerformanceFilterOptions = useMemo(() => {
    const initialValues = matchTypesDropDown
      .filter(
        (option) =>
          option.value === 'full' ||
          (canUseShortLinks && option.value === 'shortuplcode'),
      )
      .map(({ name, value }) => ({ name, value }))

    if (!generatorFieldsData) {
      return initialValues
    }

    return [
      ...initialValues,
      ...generatorFieldsData.campaignCodeGenerator.paramDefs
        .filter(({ metaParameter, required }) => !metaParameter && required)
        .map(({ fieldName, fieldID }) => ({
          name: fieldName,
          value: fieldID,
        })),
    ]
  }, [generatorFieldsData, canUseShortLinks])

  useEffect(() => {
    if (!dataSourceIsGoogle || !workspaceID) return

    getSavedOtherLinksReports()
  }, [dataSourceIsGoogle, workspaceID])

  const savedOtherLinksReports = useMemo(() => {
    if (!savedOtherLinksReportsData) return []

    return savedOtherLinksReportsData.report.listSavedLinkAuditReports.savedReports.map(
      ({ savedReportID, reportName }) => {
        return {
          savedReportID,
          reportName,
        }
      },
    )
  }, [savedOtherLinksReportsData])

  return (
    <nav className={styles.nav}>
      <section className={styles.navHeader}>
        <Link newTab={false} href="/welcome" className={styles.logoContainer}>
          <SiteLogo className={styles.logo} />
          {!!onboardingNotifications && (
            <BulletCounter
              count={onboardingNotifications}
              className={styles.onboardingCounter}
            />
          )}
        </Link>
        {!justLogo && (
          <MenuSvg
            active={active}
            onClick={() => {
              if (active && subNavModule) {
                setSubNavModule(null)

                setTimeout(() => {
                  setActive(false)
                }, 700)
              } else {
                setActive(!active)
              }
            }}
          />
        )}
      </section>
      {!justLogo && (
        <>
          <section
            className={classNames(styles.navMenu, {
              [styles.active]: active && !subNavModule,
            })}
          >
            {trackAvailable && (
              <div
                className={styles.navItem}
                onClick={() => setSubNavModule('track')}
                onKeyDown={() => setSubNavModule('track')}
                data-name="track"
                role="button"
                tabIndex={-1}
              >
                <TrackIcon />
                <Heading type={3}>Track</Heading>
                <Arrow className={styles.arrow} />
                <BulletCounter
                  count={requestCounter}
                  className={styles.counter}
                />
              </div>
            )}
            {reportAvailable && (
              <div
                className={styles.navItem}
                onClick={() => setSubNavModule('report')}
                onKeyDown={() => setSubNavModule('report')}
                data-name="report"
                role="button"
                tabIndex={-1}
              >
                <ReportIcon />
                <Heading type={3}>Report</Heading>
                <Arrow className={styles.arrow} />
              </div>
            )}
            {/* Explain module should be hidden for all users */}
            {/* {explainAvailable && (
              <Link newTab={false}
                className={styles.navItem}
                href="/explain"
                data-name="explain"
              >
                <ExplainIcon />
                <Heading type={3}>Explain</Heading>
                <Arrow className={styles.arrow} />
              </Link>
            )} */}
            {isAdminUser(userPermission) && (
              <Link
                newTab={false}
                className={styles.navItem}
                href="/connect"
                data-name="connect"
              >
                <ConnectIcon />
                <Heading type={3}>Connect</Heading>
                <Arrow className={styles.arrow} />
              </Link>
            )}
            <Link
              newTab={false}
              className={styles.navItem}
              href="/settings"
              data-name="settings"
            >
              <SettingsIcon />
              <Heading type={3}>Settings</Heading>
              <Arrow className={styles.arrow} />
            </Link>
          </section>
          <div
            className={classNames(styles.subNav, {
              [styles.active]: !!subNavModule && !secondarySubNavModule,
            })}
          >
            <Arrow
              className={styles.arrow}
              onClick={() => setSubNavModule(null)}
            />
            {subNavModule === 'track' && (
              <>
                <Link
                  newTab={false}
                  className={styles.navItem}
                  href="/track/learn"
                >
                  <Heading type={3}>Learn</Heading>
                </Link>
                <Link
                  newTab={false}
                  className={styles.navItem}
                  href="/track/create"
                >
                  <Heading type={3}>Create links</Heading>
                </Link>
                <Link
                  newTab={false}
                  className={styles.navItem}
                  href="/track/view"
                >
                  <Heading type={3}>View links</Heading>
                </Link>
                {isAdminUser(userPermission) && showDropdowns && (
                  <Link
                    newTab={false}
                    className={styles.navItem}
                    href="/track/edit-dropdowns"
                  >
                    <Heading type={3}>Edit dropdowns</Heading>

                    <BulletCounter
                      count={requestCounter}
                      className={styles.subCounter}
                    />
                  </Link>
                )}
                {isAdminUser(userPermission) && (
                  <Link
                    newTab={false}
                    className={styles.navItem}
                    href="/track/edit-parameters-and-rules"
                  >
                    <Heading type={3}>Edit parameters</Heading>
                  </Link>
                )}
              </>
            )}
            {subNavModule === 'report' && (
              <>
                <div className={styles.subNavDrilldown}>
                  <Link
                    newTab={false}
                    className={styles.navItem}
                    href="/report/performance"
                  >
                    <Heading type={3}>{brandName} links</Heading>
                  </Link>
                  <Arrow
                    className={styles.subNavArrow}
                    onClick={() =>
                      setSecondarySubNavModule('report-performance')
                    }
                  />
                </div>
                {dataSource && dataSource.connectionSource !== 'adobe' && (
                  <Link
                    newTab={false}
                    className={styles.navItem}
                    href="/report/marketing-journeys"
                  >
                    <Heading type={3}>
                      Marketing journeys
                      <BetaLabel />
                    </Heading>
                  </Link>
                )}
                {dataSourceIsGoogle && (
                  <>
                    {savedOtherLinksReports.length > 0 &&
                      savedOtherLinksReports.map(
                        ({ savedReportID, reportName }) => {
                          return (
                            <Link
                              newTab={false}
                              className={styles.navItem}
                              href={`/report/other-links-audit?reportID=${savedReportID}`}
                            >
                              <Heading type={3}>{reportName}</Heading>
                            </Link>
                          )
                        },
                      )}
                    <Link
                      newTab={false}
                      className={styles.navItem}
                      href="/report/other-links-audit"
                    >
                      <Heading type={3}>Other links</Heading>
                    </Link>
                  </>
                )}
                <Link
                  newTab={false}
                  className={styles.navItem}
                  href="/report/usage"
                >
                  <Heading type={3}>Value and usage</Heading>
                </Link>
                <Link
                  newTab={false}
                  className={styles.navItem}
                  href="/report/dashboards"
                >
                  <Heading type={3}>Custom embedded</Heading>
                </Link>
              </>
            )}
          </div>
          <div
            className={classNames(styles.secondarySubNav, {
              [styles.active]: !!secondarySubNavModule,
            })}
          >
            <Arrow
              className={styles.arrow}
              onClick={() => setSecondarySubNavModule(null)}
            />
            {secondarySubNavModule === 'report-performance' &&
              reportPerformanceFilterOptions.length > 1 &&
              reportPerformanceFilterOptions.map(({ name, value }) => {
                return (
                  <Link
                    newTab={false}
                    className={styles.navItem}
                    href={`/report/performance?compare=${value}`}
                  >
                    <Heading type={3}>{name}</Heading>
                  </Link>
                )
              })}
          </div>
        </>
      )}
    </nav>
  )
}
