import React, { useState, useEffect, useMemo } from 'react'
import { useQuery, useReactiveVar } from '@apollo/client'
import classNames from 'classnames'
import { nanoid } from 'nanoid'

import Input, { Label } from './input'
import Modal from './modal'
import { LabelSlot } from './row'
import { SelectBoxSimple } from './select-box'
import Tooltip from './tooltip'
import { BoxedText } from './typography'
import { currentUserDetails } from '../api/apollo/variables'
import { getCampaignCodeGenerator } from '../api/graphql/track-create-client'
import { GeneratorFields } from '../api/types'
import { dateOptions, supportEmail } from '../core/constants'
import { checkParamNameIsInvalid } from '../helpers/track-module'
import styles from '../styles/campaign-codes-administration.module.scss'

const paramDefTemplate: GeneratorFields = {
  fieldID: 'NEW_ID',
  fieldAvailable: true,
  fieldName: '',
  fieldType: 'select',
  helpText: '',
  prefix: '',
  required: true,
  metaParameter: false,
  selectFields: [],
  forceLowerCase: true,
}

interface Props {
  onSubmit: (newParamDef: GeneratorFields) => void
  active: boolean
  toggleActive: React.Dispatch<React.SetStateAction<boolean>>
}

export default function CampaignCodeAdministrationAddNew({
  onSubmit,
  active,
  toggleActive,
}: Props) {
  const { workspaceID } = useReactiveVar(currentUserDetails)

  const { data: campaignCodeGeneratorData } = useQuery(getCampaignCodeGenerator)

  const generatedStructure = useMemo(() => {
    if (!campaignCodeGeneratorData) return null

    return campaignCodeGeneratorData.campaignCodeGenerator
  }, [campaignCodeGeneratorData])

  const [loading, setLoading] = useState(false)
  const [dateFormat, setDateFormat] = useState('')

  const [newParamDef, setNewParamDef] = useState<GeneratorFields>({
    ...paramDefTemplate,
  })

  useEffect(() => {
    setDateFormat(dateOptions[0].value)
  }, [])

  const [fieldNameError, setFieldNameError] = useState(false)

  if (!active || !generatedStructure) return <></>

  return (
    <Modal
      setIsOpen={toggleActive}
      modalHeader="Add parameter"
      yesText="Add parameter"
      yesButtonDisabled={
        fieldNameError ||
        newParamDef.fieldName === '' ||
        (newParamDef.fieldType === 'fixed' && newParamDef.fixedValue === '')
      }
      yesButtonLoading={loading}
      onYes={async () => {
        const data = {
          ...paramDefTemplate,
          ...newParamDef,
          forceLowerCase: true,
          accountID: workspaceID,
        }

        if (newParamDef.fieldType === 'select') {
          data.selectFields = [
            {
              hide: false,
              optionName: 'Blank',
              optionValue: 'blank',
              optionID: 'blank-id',
            },
          ]
        }

        setLoading(true)

        await onSubmit(data)

        setLoading(false)

        setNewParamDef({ ...paramDefTemplate })
        toggleActive(false)
      }}
    >
      <LabelSlot column>
        <Label modalHeading id="parameter-name">
          <Tooltip
            id="parameter-name-tooltip"
            useIcon
            tooltipMessage="The name of each parameter input, visible to your users in the Track>Create links page. These should be easy for your other users to understand."
          >
            Parameter name
          </Tooltip>
        </Label>
      </LabelSlot>
      <Input
        id="parameter-name"
        name="parameter-name"
        label="Parameter name"
        value={newParamDef.fieldName}
        error={fieldNameError}
        className={classNames({
          [styles.hasError]: fieldNameError,
        })}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const { value: val } = e.target as HTMLInputElement

          if (
            generatedStructure.paramDefs &&
            Array.isArray(generatedStructure.paramDefs) &&
            !checkParamNameIsInvalid(
              val,
              generatedStructure.paramDefs.map(
                (paramDef) => paramDef.fieldName,
              ),
              setFieldNameError,
            )
          ) {
            setNewParamDef({
              ...newParamDef,
              fieldID: 'NEW_ID',
              fieldName: val,
            })
          }
        }}
      />
      {fieldNameError && (
        <p className={styles.footNoteError}>
          A parameter with that name already exists.
        </p>
      )}
      <Input
        type="checkbox"
        id="required"
        name="required"
        label="Required"
        checked={newParamDef.required}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const { checked } = e.target as HTMLInputElement
          setNewParamDef({
            ...newParamDef,
            required: checked,
          })
        }}
      >
        <Tooltip
          id="parameter-required-tooltip"
          useIcon
          tooltipMessage="If checked, users will have to complete this field to create a campaign link."
        />
      </Input>
      <Input
        type="checkbox"
        id="meta"
        name="meta"
        label="Meta parameter"
        checked={!!newParamDef.metaParameter}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const { checked } = e.target as HTMLInputElement
          setNewParamDef({
            ...newParamDef,
            metaParameter: checked,
          })
        }}
      >
        <Tooltip
          id="parameter-meta-tooltip"
          useIcon
          tooltipMessage="If checked, this parameter data will only be stored here and will not be visible in your campaign link (or other analytics tools). Designed for campaign link notes."
        />
      </Input>

      <LabelSlot column>
        <Label modalHeading id="prefix">
          <Tooltip
            id="parameter-prefix-tooltip"
            useIcon
            tooltipMessage="The query string parameter name which appears before the typed or selected value. Example: 'utm_campaign=' is the prefix for the Google Analytics campaign parameter. This may be a symbol when concatenating multiple inputs into one parameter."
          >
            Prefix
          </Tooltip>
        </Label>
      </LabelSlot>
      <Input
        id="prefix"
        name="prefix"
        label="New parameter prefix"
        value={newParamDef.prefix}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const { value: val } = e.target as HTMLInputElement
          setNewParamDef({
            ...newParamDef,
            prefix: val,
          })
        }}
      />
      <LabelSlot column>
        <Label modalHeading id="help-text">
          <Tooltip
            id="parameter-description-tooltip"
            useIcon
            tooltipMessage="Text designed to help users enter the right values on the Track>Create links page. Text will show when the users hovers over a tooltip next to each parameter name."
          >
            Description
          </Tooltip>
        </Label>
      </LabelSlot>
      <Input
        id="help-text"
        name="help-text"
        label="New parameter description"
        value={newParamDef.helpText}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const { value: val } = e.target as HTMLInputElement
          setNewParamDef({
            ...newParamDef,
            helpText: val,
          })
        }}
      />
      <Label modalHeading id="type">
        <Tooltip
          id="parameter-input-type-tooltip"
          useIcon
          maxWidth={300}
          tooltipMessage={
            <p>
              Set what values users can input on the{' '}
              <BoxedText>Track &gt; Create links</BoxedText> page.
            </p>
          }
        >
          Input type
        </Tooltip>
      </Label>
      <Input
        className={styles.iconDropDown}
        name="select-type"
        label="Dropdown"
        type="radio"
        value={newParamDef.fieldType}
        checked={newParamDef.fieldType === 'select'}
        onClick={() =>
          setNewParamDef({
            ...newParamDef,
            fieldType: 'select',
          })
        }
      >
        <Tooltip
          id="parameter-dropdown-tooltip"
          useIcon
          tooltipMessage="Users select a value from a list of options. Dropdown options are maintained by your admins on the **Track > Edit dropdowns** page."
        />
      </Input>
      <Input
        className={styles.iconInput}
        name="text-type"
        label="Free text"
        type="radio"
        value={newParamDef.fieldType}
        checked={newParamDef.fieldType === 'input'}
        onClick={() =>
          setNewParamDef({
            ...newParamDef,
            fieldType: 'input',
          })
        }
      >
        <Tooltip
          id="parameter-input-type-tooltip"
          useIcon
          tooltipMessage="Users can type or paste values, with data validation rules selected by your admins on the **Track > Edit parameters** page."
        />
      </Input>
      <div className={styles.inline} style={{ marginBottom: 16 }}>
        <Input
          className={styles.iconUKDate}
          name="date-type"
          label="Date picker"
          type="radio"
          value={newParamDef.fieldType}
          checked={newParamDef.fieldType === 'date'}
          onClick={() =>
            setNewParamDef({
              ...newParamDef,
              fieldType: 'date',
              dateFormat: dateOptions[0].value,
            })
          }
        />
        <Tooltip
          id="parameter-date-type-tooltip"
          useIcon
          tooltipMessage={`Users can select a date, month, year or quarter from a calendar visualisation. You can choose the date format (how it will appear in the query string parameter). If you require another date format, contact [${supportEmail}](mailto:${supportEmail})`}
        />
        <SelectBoxSimple
          className={styles.selectBox}
          name="date"
          value={dateFormat}
          onChange={(val) => {
            setDateFormat(val)
            setNewParamDef({
              ...newParamDef,
              fieldType: 'date',
              dateFormat: val,
            })
          }}
        >
          {dateOptions.map((i) => (
            <option key={nanoid()} value={i.value}>
              {i.name}
            </option>
          ))}
        </SelectBoxSimple>
      </div>
      <div className={styles.inline} style={{ marginBottom: 16 }}>
        <Input
          className={styles.fixedInput}
          name="fixed-type"
          label="No input"
          type="radio"
          value={newParamDef.fieldType}
          checked={newParamDef.fieldType === 'fixed'}
          onClick={() =>
            setNewParamDef({
              ...newParamDef,
              fieldType: 'fixed',
              fixedValue: '',
              forceLowerCase: false,
              required: false,
            })
          }
        />
        <Tooltip
          id="parameter-fixed-value-tooltip"
          useIcon
          tooltipMessage={
            <p>
              Add a fixed value to every link without users entering a value on
              the <BoxedText>Track &gt; Create links</BoxedText> page. Used to
              add permanent, non changing parameters.
            </p>
          }
        />
        <Input
          className={styles.fixedValueField}
          name="fixed-value"
          label="Fixed value text"
          value={newParamDef.fixedValue || ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const { value: val } = e.target as HTMLInputElement

            setNewParamDef({
              ...newParamDef,
              fieldType: 'fixed',
              fixedValue: val,
              forceLowerCase: false,
              required: false,
            })
          }}
        />
      </div>
    </Modal>
  )
}
