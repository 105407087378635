import React from 'react'
import classNames from 'classnames'

import noDataGrey from '../assets/no-data-light.svg'
import noData from '../assets/no-data-colour.svg'
import Link from './link'
import styles from '../styles/no-data-message.module.scss'

interface NoDataMessageProps {
  className?: string
  grayscale?: boolean
  excludeCopy?: boolean
  errorMsg?: string | React.ReactElement
}

const NoDataMessage = ({
  className,
  grayscale = true,
  excludeCopy = false,
  errorMsg,
}: NoDataMessageProps) => {
  return (
    <div className={classNames(styles.errorContainer, className)}>
      <div>
        <img
          className={styles.noDataImg}
          src={grayscale ? noDataGrey : noData}
          alt="error"
        />
        {!excludeCopy && (
          <>
            {errorMsg ? (
              <>{typeof errorMsg === 'string' ? <p>{errorMsg}</p> : errorMsg}</>
            ) : (
              <>
                <p>No data for your dates and filters.</p>
                <p>
                  <Link
                    type="arrowForward"
                    href="https://support.uplifter.ai/hc/en-us/articles/19342815392029-Why-can-t-I-see-any-data-in-my-reports-graphs-tables"
                  >
                    Get support
                  </Link>
                </p>
              </>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default NoDataMessage
