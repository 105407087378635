import React, { useState, useEffect, useCallback } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import classNames from 'classnames'

import Button, { ClearButton, DeleteButton } from './button'
import FileDragAndDrop from './file-drag-and-drop'
import { getQrLogoImage, deleteQrLogo } from '../api/graphql/company-client'
import { messages } from '../core/constants'
import useMobile from '../hooks/useMobile'
import styles from '../styles/img-uploader.module.scss'

interface ImgUploaderProps {
  logoLink: string
  uploadFn: (file: File) => Promise<boolean>
  deleteFn?: (link: string) => Promise<void>
  imgType: 'logo' | 'qrLogo' | 'profileImg'
  compact?: boolean
  uploadButtonText?: string
  defaultShowUploader?: boolean
  onSetShowUploader?: (val: boolean) => void
  className?: string
}

export function ImgUploader({
  logoLink,
  uploadFn,
  deleteFn,
  imgType,
  compact = false,
  uploadButtonText,
  defaultShowUploader = true,
  onSetShowUploader,
  className,
}: ImgUploaderProps) {
  const [getQrCodeLogoImage] = useLazyQuery(getQrLogoImage)
  const [deleteQrCodeLogo] = useMutation(deleteQrLogo)

  const [showUploader, setShowUploader] = useState(defaultShowUploader)
  const [error, setError] = useState('')
  const [uploadInProgress, setUploadInProgress] = useState(false)
  const [imageFile, setImageFile] = useState('')

  const isMobile = useMobile(769)

  useEffect(() => {
    if (logoLink === '') {
      setShowUploader(true)
      if (onSetShowUploader) {
        onSetShowUploader(true)
      }
    } else {
      setShowUploader(false)
      if (onSetShowUploader) {
        onSetShowUploader(false)
      }
    }
  }, [logoLink])

  useEffect(() => {
    if (logoLink === '') {
      setShowUploader(defaultShowUploader)
    }
  }, [defaultShowUploader])

  const onDrop = useCallback(async (acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setUploadInProgress(true)

      const file = acceptedFiles.pop()

      const { type, size } = file

      if (type && size && type.indexOf('image/') !== -1 && size < 1038383) {
        // Delete existing: Remove when allowing multiple logos
        if (imgType === 'qrLogo' && logoLink)
          await deleteQrCodeLogo({ variables: { blobName: logoLink } })

        const res = await uploadFn(file)

        if (res === false) {
          setError(messages.fileUploadError)
        }
      } else {
        setError(messages.fileUploadErrorImageOnly)
      }
    } else {
      setError(messages.fileUploadError)
    }
    setUploadInProgress(false)
  }, [])

  useEffect(() => {
    if (!showUploader) {
      setError('')
      setUploadInProgress(false)
    }
  }, [showUploader])

  useEffect(() => {
    if (imgType === 'qrLogo') {
      const loadImage = async () => {
        const { data } = await getQrCodeLogoImage({
          variables: { blobName: logoLink },
        })

        if (data) setImageFile(data.currentAccount.qrCodeLogoSrc)
      }

      if (logoLink !== '') {
        loadImage()
      }
    }
  }, [logoLink])

  return (
    <div
      className={classNames(styles.companyLogoComponent, className, {
        [styles.noButtons]:
          !logoLink || (!isMobile && !((logoLink || compact) && showUploader)),
        [styles.compact]: compact,
        [styles.showUploader]: showUploader,
        [styles.uploaderWithError]: showUploader && error,
      })}
    >
      <div className={styles.companyLogoWrapper}>
        {logoLink && (
          <div className={styles.logoWrapper}>
            {imgType !== 'qrLogo' && <img src={logoLink} alt="logo" />}
            {imgType === 'qrLogo' && imageFile !== '' && (
              <img src={imageFile} alt="logo" />
            )}
            {!isMobile && !showUploader && (
              <div className={styles.hoverButtons}>
                <Button
                  onPress={() => setShowUploader(true)}
                  variant="secondary"
                >
                  Replace
                  {!compact && (imgType === 'logo' || imgType === 'qrLogo')
                    ? ' logo'
                    : ''}
                </Button>
                {!!deleteFn && (
                  <DeleteButton
                    onPress={async () => {
                      setShowUploader(true)
                      setUploadInProgress(true)
                      await deleteFn(logoLink)
                    }}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </div>
      {error && <p className={styles.error}>{error}</p>}
      <div className={styles.buttons}>
        {(isMobile || compact) && !showUploader && (
          <div className={styles.row}>
            <Button onPress={() => setShowUploader(true)} variant="secondary">
              Replace logo
            </Button>
            {!!deleteFn && (
              <DeleteButton
                onPress={async () => {
                  setShowUploader(true)
                  setUploadInProgress(true)
                  await deleteFn(logoLink)
                }}
              />
            )}
          </div>
        )}
        {(logoLink || compact) && showUploader && (
          <ClearButton
            className={styles.cancelButton}
            onPress={() => setShowUploader(false)}
          >
            Cancel
          </ClearButton>
        )}
      </div>
      <span className={error ? styles.largeSpacer : styles.spacer} />
      {showUploader && (
        <FileDragAndDrop
          uploadButtonText={uploadButtonText}
          className={styles.dragAndDrop}
          onDrop={onDrop}
          inProgress={uploadInProgress}
        />
      )}
    </div>
  )
}
