import React, { useMemo, useState } from 'react'
import { useMutation, useReactiveVar } from '@apollo/client'
import numeral from 'numeraljs'

import Modal from './modal'
import { SuccessText } from './typography'
import { SelectedCode } from '../api/types'
import { currentUserDetails } from '../api/apollo/variables'
import { deleteBatchCodes } from '../api/graphql/track-actions-client'
import { defaultShortLinkDomain } from '../core/constants'
import {
  getItemByKeyValue,
  isAdminUser,
  getDomain,
  getCustomDomainID,
} from '../helpers'
import useLogAction from '../hooks/useLogAction'
import useShortLinks from '../hooks/useShortLinks'

export interface BulkDeleteModalProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
  selectedCodes: SelectedCode[]
  refetchData: () => Promise<void>
}

export default function BulkDeleteModal({
  setShowModal,
  selectedCodes,
  refetchData,
}: BulkDeleteModalProps) {
  const logAction = useLogAction()

  const { availableDomains } = useShortLinks()

  const { userPermission, userEmail } = useReactiveVar(currentUserDetails)

  const [deleteCodes] = useMutation(deleteBatchCodes)

  const isAdmin = isAdminUser(userPermission)

  const [deletingLinks, setDeletingLinks] = useState(false)
  const [allLinksDeleted, setAllLinksDeleted] = useState(false)
  const [deleteError, setDeleteError] = useState(false)

  const loading = useMemo(() => {
    return availableDomains.length === 0
  }, [availableDomains])

  const selectedCodesCanDelete = useMemo(() => {
    if (!userEmail) return []

    if (isAdminUser(userPermission)) return selectedCodes

    return selectedCodes.filter((item) => {
      const found = getItemByKeyValue(selectedCodes, 'cID', item.cID)

      return found !== -1 && found.e === userEmail
    })
  }, [userPermission, selectedCodes, userEmail])

  const message = useMemo(() => {
    if (!isAdmin && selectedCodes.length !== selectedCodesCanDelete.length) {
      if (selectedCodesCanDelete.length === 0)
        return 'You can only delete campaign links that you have created. Contact an admin user to delete campaign links created by other users.'

      return `You can only delete campaign links that you have created. Would you like to delete your ${numeral(
        selectedCodesCanDelete.length,
      ).format('0,0')} link${selectedCodesCanDelete.length > 1 ? 's' : ''}?`
    }

    return `Are you sure you want to delete the ${numeral(
      selectedCodes.length,
    ).format('0,0')} selected campaign link${
      selectedCodes.length > 1 ? 's' : ''
    }?`
  }, [isAdmin, selectedCodes, selectedCodesCanDelete])

  const isDisabled = !isAdmin && selectedCodesCanDelete.length === 0

  return (
    <Modal
      beforeClose={
        allLinksDeleted
          ? () => {
              refetchData()
            }
          : undefined
      }
      setIsOpen={setShowModal}
      modalHeader={
        allLinksDeleted
          ? `Link${selectedCodes.length === 1 ? '' : 's'} deleted`
          : 'Confirm deletion'
      }
      yesText="Delete"
      yesButtonDisabled={
        isDisabled || loading || allLinksDeleted || deleteError
      }
      yesButtonLoading={deletingLinks}
      onYes={
        allLinksDeleted
          ? undefined
          : async () => {
              setDeletingLinks(true)

              const codesToDeleteByID = selectedCodesCanDelete.map(
                ({ sL, cID }) => ({
                  cID,
                  sLDomainName: getCustomDomainID(
                    getDomain(sL).replace('https://', ''),
                  ),
                }),
              )

              const slDomainsToDelete = [
                ...new Set(
                  codesToDeleteByID.map(({ sLDomainName }) => sLDomainName),
                ),
              ]

              const deleteObjects: {
                codeIDList: string[]
                customDomainID: string | null
              }[] = []

              slDomainsToDelete.forEach((customDomainName) => {
                const found = getItemByKeyValue(
                  availableDomains,
                  'optionName',
                  customDomainName || defaultShortLinkDomain,
                )

                if (found === -1) return

                deleteObjects.push({
                  codeIDList: codesToDeleteByID
                    .filter(
                      ({ sLDomainName }) => sLDomainName === customDomainName,
                    )
                    .map(({ cID }) => cID),
                  customDomainID: getCustomDomainID(found.optionValue),
                })
              })

              try {
                // eslint-disable-next-line no-restricted-syntax
                for (const deleteObject of deleteObjects) {
                  // eslint-disable-next-line no-await-in-loop
                  const { errors } = await deleteCodes({
                    variables: deleteObject,
                  })

                  if (errors && errors.length > 0) throw new Error()
                }
              } catch {
                setDeleteError(true)
                setDeletingLinks(false)
              }

              logAction({
                variables: {
                  action: 'bulk-delete-codes',
                  functionName: 'bulkDelete',
                  pagePath: '/track/view-links',
                  websiteSection: 'track',
                  extra: JSON.stringify(selectedCodesCanDelete),
                },
              })

              setDeletingLinks(false)
              setAllLinksDeleted(true)
            }
      }
      noText={allLinksDeleted ? 'Close' : 'Back'}
      footerContent={
        <>{deleteError && <span>There was an issue deleting the codes</span>}</>
      }
    >
      {allLinksDeleted ? (
        <SuccessText color="black">
          Your link{selectedCodes.length === 1 ? ' has' : 's have'} been
          successfully deleted.
        </SuccessText>
      ) : (
        <p style={{ marginBottom: 0 }}>{message}</p>
      )}
    </Modal>
  )
}
