import React, { useEffect, useMemo, useState } from 'react'
import { LazyQueryExecFunction, useLazyQuery, useQuery } from '@apollo/client'

import { Label } from './input'
import Link from './link'
import { LoadingLabel } from './loader'
import { FieldSlot, FormRow, LabelSlot } from './row'
import SelectBox from './select-box'
import Tooltip from './tooltip'
import {
  getAvailableEloquaEmailGroups,
  getAvailableEloquaTemplates,
  getEloquaOrgList,
} from '../api/graphql/integrations-client'
import { supportEmail } from '../core/constants'
import { htmlHasLinks } from '../helpers/track-module'
import styles from '../styles/email-code-generator-form.module.scss'
import {
  Exact,
  GetAvailableEloquaEmailGroupsQuery,
  GetAvailableEloquaTemplatesQuery,
  GetEloquaTemplateHtmlQuery,
} from '../__gql-types__/graphql'

export interface EloquaEmailDetails {
  emailSource: 'eloqua'
  emailHtml: string
  eloquaOrg: {
    eloquaOrgID: string
    eloquaOrgName: string
    eloquaOrgDisplayName: string
  } | null
  emailGroup:
    | GetAvailableEloquaEmailGroupsQuery['track']['eloquaQueries']['getAvailableEloquaEmailGroups']['eloquaEmailGroupList'][0]
    | {
        groupID: null
        name: '[Ungrouped - Search templates manually]'
      }
    | null
  emailTemplate:
    | GetAvailableEloquaTemplatesQuery['track']['eloquaQueries']['getAvailableEloquaTemplateList']['eloquaTemplateList'][0]
    | null
  updateSuccess: boolean
}

export interface EloquaEmailFieldsProps {
  emailDetails: EloquaEmailDetails
  setEmailDetails: React.Dispatch<React.SetStateAction<any>>
  fetchEloquaEmailHtml: LazyQueryExecFunction<
    GetEloquaTemplateHtmlQuery,
    Exact<{
      eloquaOrgID: string
      templateID: string
    }>
  >
  errorFetchingEloquaEmailHtml: boolean
}

const EloquaEmailFields = ({
  emailDetails,
  setEmailDetails,
  fetchEloquaEmailHtml,
  errorFetchingEloquaEmailHtml,
}: EloquaEmailFieldsProps) => {
  const [templateSearch, setTemplateSearch] = useState('')

  const {
    data: orgListData,
    loading: loadingOrgListData,
    error: orgListError,
  } = useQuery(getEloquaOrgList)

  const [
    getEloquaEmailGroups,
    {
      data: emailGroupsData,
      loading: loadingEmailGroups,
      error: errorFetchingEmailGroups,
    },
  ] = useLazyQuery(getAvailableEloquaEmailGroups)

  const [
    getEmailTemplates,
    {
      data: emailTemplatesData,
      loading: loadingEmailTemplates,
      error: errorFetchingEmailTemplates,
    },
  ] = useLazyQuery(getAvailableEloquaTemplates)

  const orgList = useMemo(() => {
    if (!orgListData) return []

    const _orgList =
      orgListData.track.eloquaQueries.getConnectedEloquaOrgs.eloquaOrgList

    if (_orgList.length === 1) {
      const { eloquaOrgDisplayName, eloquaOrgID, eloquaOrgName } = _orgList[0]

      setEmailDetails({
        ...emailDetails,
        eloquaOrg: { eloquaOrgDisplayName, eloquaOrgID, eloquaOrgName },
        emailGroup: null,
        emailTemplate: null,
      })

      getEloquaEmailGroups({
        variables: {
          eloquaOrgID,
        },
      })
    }

    return _orgList
  }, [orgListData])

  const emailGroups = useMemo(() => {
    if (!emailGroupsData) return []

    return [
      { groupID: null, name: '[Ungrouped - Search templates manually]' },
      ...emailGroupsData.track.eloquaQueries.getAvailableEloquaEmailGroups
        .eloquaEmailGroupList,
    ]
  }, [emailGroupsData])

  const emailTemplates = useMemo(() => {
    if (
      !emailTemplatesData ||
      (emailDetails.emailGroup?.groupID === null && templateSearch.length < 4)
    ) {
      return []
    }

    if (
      emailDetails.emailGroup?.groupID === null &&
      templateSearch.length > 4
    ) {
      return emailTemplatesData.track.eloquaQueries.getAvailableEloquaTemplateList.eloquaTemplateList.filter(
        (template) =>
          template.name.toLowerCase().indexOf(templateSearch.toLowerCase()) >
          -1,
      )
    }

    return emailTemplatesData.track.eloquaQueries.getAvailableEloquaTemplateList
      .eloquaTemplateList
  }, [emailTemplatesData, emailDetails.emailGroup, templateSearch])

  useEffect(() => {
    const searchTemplates = async () => {
      // Only fetch templates asynchronously if user is searching ungrouped templates
      if (
        emailDetails.eloquaOrg &&
        emailDetails.emailGroup &&
        emailDetails.emailGroup.groupID === null &&
        templateSearch.length === 4
      ) {
        await getEmailTemplates({
          variables: {
            eloquaOrgID: emailDetails.eloquaOrg.eloquaOrgID,
            groupID: undefined,
            search: templateSearch,
          },
        })
      }
    }

    searchTemplates()
  }, [emailDetails.emailGroup, templateSearch])

  return (
    <>
      {orgList.length > 1 && (
        <FormRow>
          <LabelSlot className={styles.labelSlot}>
            <Label id="eloquaOrgList">
              <Tooltip
                id="eloqua-org-tooltip"
                useIcon
                tooltipPosition="right"
                tooltipMessage="The Eloqua organisation to use."
              >
                Organisation
              </Tooltip>
            </Label>
          </LabelSlot>
          <FieldSlot>
            <SelectBox
              id="eloquaOrgList"
              labelKey="eloquaOrgDisplayName"
              valueKey="eloquaOrgID"
              placeholder="Select organisation or start typing"
              isLoading={loadingOrgListData}
              loadingMessage={() => (
                <LoadingLabel label="Fetching connected Eloqua organisations" />
              )}
              error={!!orgListError}
              aria-errormessage="org-list-error"
              value={emailDetails.eloquaOrg}
              options={orgList}
              onChange={async (newValue) => {
                if (!newValue) return

                setEmailDetails({
                  ...emailDetails,
                  eloquaOrg: newValue,
                })

                getEloquaEmailGroups({
                  variables: {
                    eloquaOrgID: newValue.eloquaOrgID,
                  },
                })
              }}
            />
            {!!orgListError && (
              <p className={styles.footNoteError} id="org-list-error">
                Error fetching organisations. Please reload the page or contact
                support (
                <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>
                ).
              </p>
            )}
          </FieldSlot>
        </FormRow>
      )}
      <FormRow>
        <LabelSlot className={styles.labelSlot}>
          <Label id="eloquaEmailGroup">
            <Tooltip
              id="eloqua-email-group-tooltip"
              useIcon
              tooltipPosition="right"
              tooltipMessage="The email group to use from Eloqua."
            >
              Email group
            </Tooltip>
          </Label>
        </LabelSlot>
        <FieldSlot>
          <SelectBox
            id="eloquaEmailGroup"
            labelKey="name"
            valueKey="groupID"
            placeholder="Select email group or start typing"
            isLoading={loadingEmailGroups}
            isDisabled={
              !emailDetails.eloquaOrg ||
              (!loadingOrgListData &&
                emailGroups.length === 0 &&
                emailDetails.eloquaOrg !== null)
            }
            loadingMessage={() => (
              <LoadingLabel label="Fetching email groups" />
            )}
            error={!!errorFetchingEmailGroups}
            aria-errormessage="email-group-error"
            value={emailDetails.emailGroup}
            options={emailGroups}
            onChange={async (newValue) => {
              if (!newValue) return

              setEmailDetails({
                ...emailDetails,
                emailGroup: newValue,
              })

              // Ungrouped: Should use search field for templates instead
              if (newValue.groupID === null) {
                return
              }

              if (emailDetails.eloquaOrg) {
                await getEmailTemplates({
                  variables: {
                    eloquaOrgID: emailDetails.eloquaOrg.eloquaOrgID,
                    groupID: newValue.groupID,
                    search: undefined,
                  },
                })
              }
            }}
          />
          {!loadingEmailGroups &&
            emailGroups.length === 0 &&
            emailDetails.eloquaOrg !== null && (
              <p className={styles.footNoteError} id="email-group-error">
                No email groups found for the selected organisation.
              </p>
            )}
          {!!errorFetchingEmailGroups && (
            <p className={styles.footNoteError} id="email-group-error">
              Error fetching email groups. Please reload the page or contact
              support (
              <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>
              ).
            </p>
          )}
        </FieldSlot>
      </FormRow>
      <FormRow>
        <LabelSlot className={styles.labelSlot}>
          <Label id="eloquaEmailTemplate">
            <Tooltip
              id="eloqua-email-template-tooltip"
              useIcon
              tooltipPosition="right"
              tooltipMessage="The email template to use from the selected group."
            >
              Email template
            </Tooltip>
          </Label>
        </LabelSlot>
        <FieldSlot>
          <SelectBox
            id="eloquaEmailTemplate"
            labelKey="name"
            valueKey="eloquaID"
            placeholder="Select email template or start typing"
            isLoading={loadingEmailTemplates}
            isDisabled={
              !emailDetails.eloquaOrg ||
              !emailDetails.emailGroup ||
              (!loadingEmailTemplates &&
                emailTemplates.length === 0 &&
                emailDetails.emailGroup.groupID !== null)
            }
            loadingMessage={() => (
              <LoadingLabel label="Fetching email templates" />
            )}
            error={!!errorFetchingEmailTemplates}
            aria-errormessage="email-template-error"
            value={emailDetails.emailTemplate}
            options={emailTemplates}
            inputValue={templateSearch}
            onInputChange={async (newValue) => setTemplateSearch(newValue)}
            onChange={async (newValue) => {
              if (!newValue) return

              setEmailDetails({
                ...emailDetails,
                emailTemplate: newValue,
              })

              if (emailDetails.eloquaOrg) {
                const { data: htmlData } = await fetchEloquaEmailHtml({
                  variables: {
                    eloquaOrgID: emailDetails.eloquaOrg.eloquaOrgID,
                    templateID: newValue.eloquaID,
                  },
                })

                if (
                  !htmlData ||
                  !htmlData.track.eloquaQueries.getEloquaEmailTemplateDetail
                    .emailHtml
                ) {
                  return
                }

                setEmailDetails({
                  ...emailDetails,
                  emailTemplate: newValue,
                  emailHtml:
                    htmlData.track.eloquaQueries.getEloquaEmailTemplateDetail
                      .emailHtml,
                })
              }
            }}
          />
          {!loadingEmailTemplates &&
            emailTemplates.length === 0 &&
            emailDetails.emailGroup !== null &&
            emailDetails.emailGroup.groupID !== null && (
              <p className={styles.footNoteError} id="email-template-error">
                No email templates found for the selected organisation.
              </p>
            )}
          {!!errorFetchingEmailTemplates && (
            <p className={styles.footNoteError} id="email-template-error">
              Error fetching email template. Please reload the page or contact
              support (
              <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>
              ).
            </p>
          )}
          {!!emailDetails.emailTemplate && !!errorFetchingEloquaEmailHtml && (
            <p className={styles.footNoteError} id="email-template-error">
              Error fetching email HTML. Please reload the page or contact
              support (
              <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>
              ).
            </p>
          )}
          {!errorFetchingEmailTemplates &&
            !errorFetchingEloquaEmailHtml &&
            emailDetails.emailHtml &&
            !htmlHasLinks(emailDetails.emailHtml) && (
              <p className={styles.footNoteError} id="email-template-error">
                No links found in this email template.
              </p>
            )}
        </FieldSlot>
      </FormRow>
    </>
  )
}

export default EloquaEmailFields
