import React, { useState } from 'react'
import classNames from 'classnames'

import Button, { CopyButton } from './button'
import { BiLine } from './counter'
import { Label, PassiveInput } from './input'
import QRCodePreview from './qr-code'
import QRCodeModal from './qr-code-modal'
import { FormRow, LabelSlot, FieldSlot } from './row'
import Tooltip from './tooltip'
import { Box } from './two-columns'
import CogIcon from '../assets/icon-cog.svg'
import DownloadIcon from '../assets/icon-download.svg'
import styles from '../styles/campaign-code-generator.module.scss'

interface ShowLatestCodeProps {
  newLink: string
}

export function ShowLatestCode({ newLink }: ShowLatestCodeProps) {
  const [showQrModal, setShowQrModal] = useState(false)

  return (
    <Box className={styles.noPaddingBox}>
      <FormRow className={classNames(styles.noPadding)}>
        <LabelSlot className={styles.labelSlot}>
          <Label id="link" className={styles.bold}>
            Your last created link
          </Label>
        </LabelSlot>
        <FieldSlot>
          <div className={styles.inlineRow}>
            <PassiveInput id="generated-code" value={newLink} />
            <CopyButton value={newLink}>Copy</CopyButton>
          </div>
        </FieldSlot>
      </FormRow>
      <FormRow className={classNames(styles.noPadding)}>
        <LabelSlot className={styles.labelSlot}>
          <Label id="qr">
            <Tooltip
              id="qr-code-tooltip"
              useIcon
              tooltipPosition="right"
              tooltipMessage="An optional QR code which can be scanned by a mobile phone camera to access the landing page with campaign codes."
            >
              QR code
            </Tooltip>
          </Label>
        </LabelSlot>
        <FieldSlot>
          <QRCodePreview
            className={styles.qrCodePreview}
            url={newLink}
            showLogo
            disableCopy
          >
            <Button
              variant="text"
              color="grey"
              className={styles.customiseButton}
              icon={{
                src: CogIcon,
                alt: 'Customise',
                imgHeight: 20,
              }}
              style={{ fontWeight: 600 }}
              onPress={() => setShowQrModal(true)}
            >
              Customise
            </Button>
            <Button
              variant="text"
              color="grey"
              className={styles.downloadButton}
              icon={{
                src: DownloadIcon,
                alt: 'Download',
                imgHeight: 20,
              }}
              style={{ fontWeight: 600 }}
              onPress={() => setShowQrModal(true)}
            >
              Download
            </Button>
          </QRCodePreview>
          <BiLine arrowTop className={styles.qrBiLine}>
            Always test your <strong>QR code</strong> before use!
          </BiLine>
        </FieldSlot>
      </FormRow>
      {showQrModal && (
        <QRCodeModal code={newLink} setShowModal={setShowQrModal} />
      )}
    </Box>
  )
}
