import React, { useState, useEffect, useMemo } from 'react'
import { useMutation, useQuery, useReactiveVar } from '@apollo/client'

import FeaturesSettingsCard from './features-settings-card'
import { SelectBoxSimple } from './select-box'
import { currentUserDetails } from '../api/apollo/variables'
import {
  getCampaignLinkDashboardMeta,
  setDashboardSettings,
} from '../api/graphql/report-client'
import { matchTypesDropDown } from '../core/constants'
import {
  getDefaultOption,
  getItemByKeyValue,
  sortData,
  isAdminUser,
} from '../helpers'
import styles from '../styles/workspace-preferences.module.scss'

interface WorkspaceDetailsProps {
  className?: string
}

export default function WorkspacePreferences({
  className,
}: WorkspaceDetailsProps) {
  const { userPermission } = useReactiveVar(currentUserDetails)

  const { data: reportData } = useQuery(getCampaignLinkDashboardMeta)

  const campaignLinkDashboardMeta = useMemo(() => {
    if (!reportData) return null

    return reportData.campaignLinkDashboardMeta
  }, [reportData])

  const [updateDashboardSettings] = useMutation(setDashboardSettings)

  const [performance, setPerfomance] = useState('full')

  const isAdmin = isAdminUser(userPermission)

  const matchTypeMerged: MatchTypeProps[] = useMemo(() => {
    if (
      !campaignLinkDashboardMeta ||
      !campaignLinkDashboardMeta.availableDimensions ||
      campaignLinkDashboardMeta.availableDimensions.length === 0
    )
      return [...matchTypesDropDown]

    return [
      ...matchTypesDropDown,
      ...sortData(
        campaignLinkDashboardMeta.availableDimensions.map((item) => {
          const { dimensionParameterID, dimensionName, helpText } = item
          return {
            name: dimensionName,
            shortName: dimensionName,
            value: dimensionName,
            tooltip: helpText,
            id: dimensionParameterID,
          }
        }),
        'name',
        true,
        null,
        true,
      ),
    ]
  }, [campaignLinkDashboardMeta])

  useEffect(() => {
    const mT = getDefaultOption(
      campaignLinkDashboardMeta,
      'onloadDefaultOptions',
      'matchType',
      'full',
    )

    if (
      mT !== 'full' &&
      mT !== 'partial' &&
      mT !== 'lpag' &&
      mT !== 'shortuplcode'
    ) {
      // Set performance to the value from the ID
      if (matchTypeMerged && matchTypeMerged.length > 0) {
        const res = getItemByKeyValue(matchTypeMerged, 'id', mT)

        if (res !== -1) {
          setPerfomance(res.value)
        }

        return
      }
    }

    const res = getItemByKeyValue(matchTypesDropDown, 'value', mT)
    if (res !== -1) {
      setPerfomance(res.value)
      return
    }

    setPerfomance(mT)
  }, [campaignLinkDashboardMeta, matchTypeMerged])

  return (
    <div className={className}>
      <h3>Features</h3>
      <p className={styles.byline}>
        Change which features your users can access.
      </p>
      <div className={styles.subsection}>
        <FeaturesSettingsCard />
      </div>

      <h3>Report preferences</h3>
      <p className={styles.byline}>Set your default performance report view.</p>
      <div className={styles.subsection} style={{ marginBottom: 16 }}>
        <SelectBoxSimple
          disabled={!isAdmin}
          key={performance}
          name="Performance"
          value={performance}
          onChange={(performanceType: string) => {
            const useValue = matchTypeMerged.find(
              (item) => item.value === performanceType,
            )

            setPerfomance(performanceType)

            if (useValue)
              updateDashboardSettings({
                variables: {
                  onloadDefaultOptions: [
                    {
                      name: 'matchType',
                      enabled: false,
                      value: useValue.id || useValue.value,
                    },
                  ],
                },
              })
          }}
        >
          {matchTypeMerged.map((item) => {
            return (
              <option value={item.value} key={item.value}>
                {item.name}
              </option>
            )
          })}
        </SelectBoxSimple>
      </div>
    </div>
  )
}
