import React from 'react'

import { Preloader } from './loader'
import styles from '../styles/workspace-logo.module.scss'

interface WorkspaceLogoProps {
  logoLink: string
  loading: boolean
}

export function WorkspaceLogo({ logoLink, loading }: WorkspaceLogoProps) {
  if (loading) {
    return (
      <div className={styles.logoWrapper}>
        <Preloader />
      </div>
    )
  }
  return (
    <div className={styles.logoWrapper}>
      <img src={logoLink} alt="Account logo" />
    </div>
  )
}
