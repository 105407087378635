import React, { useRef, useMemo, useState, useEffect } from 'react'
import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client'
import { useLocation } from 'react-router-dom'

import { currentUserDetails } from '../api/apollo/variables'
import { updateCachedOnboardingSectionsProgress } from '../api/graphql/custom-queries'
import {
  getMicrosoftSubscriptionData,
  getPaddleSubscriptionDetails,
} from '../api/graphql/company-client'
import { getUserInfo } from '../api/graphql/user-client'
import amazon from '../assets/amazon-voucher.png'
import quicklink from '../assets/svgs/quicklink.svg'
import Button from '../components/button'
import Modal from '../components/modal'
import Intro from '../components/intro'
import Layout from '../components/layout'
import Link from '../components/link'
import ProgressBar from '../components/progress-bar'
import SiteWrapper from '../components/site-wrapper'
import ToggleBox from '../components/toggle-box'
import TwoColumns, { Column } from '../components/two-columns'
import { Heading } from '../components/typography'
import { UploadExistingUtmSpreadsheetModal } from '../components/upload-existing-utm-spreadsheet-modal'
import WelcomeChecklist from '../components/welcome-checklist'
import WelcomeFeedbackForm from '../components/welcome-feedback'
import WelcomeRightModule from '../components/welcome-rhs'
import {
  brandName,
  onboardingSectionData,
  whitelabelDomain,
} from '../core/constants'
import { timeOfDayGreeting } from '../helpers/dates'
import useLogAction from '../hooks/useLogAction'
import useOnboarding from '../hooks/useOnboarding'
import styles from '../styles/welcome-page.module.scss'

export const QuickAccess = () => {
  const { workspaceID } = useReactiveVar(currentUserDetails)

  const [quickLinksOpenState, setQuickLinksOpenState] = useState(true)

  return (
    <div className={styles.containerInner}>
      <ToggleBox
        noPadding
        className={styles.quickAccess}
        disableToggle={!workspaceID}
        heading={
          <div className={styles.boxHeader}>
            <img src={quicklink} alt="Quick access" />
            <h2>Quick access</h2>
          </div>
        }
        open={quickLinksOpenState}
        onToggle={(state: boolean) => {
          setQuickLinksOpenState(state)
        }}
      >
        {workspaceID && (
          <div className={styles.quickAccessButtons}>
            <Link
              type="arrowForward"
              href="/track/create-links"
              newTab={false}
              className={styles.quickAccessButton}
            >
              Create campaign links
            </Link>
            <Link
              type="arrowForward"
              href="/track/view-links"
              newTab={false}
              className={styles.quickAccessButton}
            >
              View all campaign links
            </Link>
            <Link
              type="arrowForward"
              href="/report/performance"
              newTab={false}
              className={styles.quickAccessButton}
            >
              Create a report
            </Link>
          </div>
        )}
      </ToggleBox>
    </div>
  )
}

export const ReferralVoucher = () => {
  const [voucherOpenState, setVoucherOpenState] = useState(false)

  return (
    <div className={styles.containerInner}>
      <ToggleBox
        noPadding
        className={styles.onboardingToggleBox}
        heading={
          <>
            <div className={styles.boxHeader}>
              <div className={styles.emoji}>
                <span role="img" aria-label="Referrals">
                  💰
                </span>
              </div>
              <h2>Earn a £100 voucher</h2>
            </div>
            {voucherOpenState && (
              <p style={{ marginBottom: 0 }}>
                Recommend us and earn up to £100 of Amazon vouchers*
              </p>
            )}
          </>
        }
        open={voucherOpenState}
        onToggle={(state: boolean) => {
          setVoucherOpenState(state)
        }}
      >
        <div className={styles.voucherContainer}>
          <img src={amazon} alt="Amazon voucher" className={styles.amazon} />
          <div className={styles.voucherText}>
            <Button
              style={{ display: 'block', marginBottom: 8 }}
              onPress={() =>
                window.open('https://uplifter.ai/referral/', '_blank')
              }
            >
              Recommend us
            </Button>
            <span>*terms and conditions apply</span>
          </div>
        </div>
      </ToggleBox>
    </div>
  )
}

export const WelcomeFeedback = () => {
  const [feedbackOpenState, setFeedbackOpenState] = useState(false)

  return (
    <div className={styles.containerInner}>
      <ToggleBox
        noPadding
        className={styles.onboardingToggleBox}
        heading={
          <div className={styles.boxHeader}>
            <div className={styles.emoji}>
              <span role="img" aria-label="Onboarding checklist">
                💬
              </span>
            </div>

            <h2>Give Feedback</h2>
          </div>
        }
        open={feedbackOpenState}
        onToggle={(state: boolean) => {
          setFeedbackOpenState(state)
        }}
      >
        <div className={styles.feedbackContainer}>
          <WelcomeFeedbackForm />
        </div>
      </ToggleBox>
    </div>
  )
}

export const WelcomeChecklistContainer = () => {
  const { workspaceID } = useReactiveVar(currentUserDetails)

  const logAction = useLogAction()

  const checklistRef = useRef<HTMLDivElement>(null)

  const [loading, setLoading] = useState(true)
  const [onboardingOpenState, setOnboardingOpenState] = useState(false)

  const {
    mergedOnboardingSections,
    setFullOnboardingSections,
  } = useOnboarding()

  const [completedSections, completedPercent] = useMemo(() => {
    if (mergedOnboardingSections && mergedOnboardingSections.length > 0) {
      const _completedSections = mergedOnboardingSections.filter((section) => {
        return (
          (section.sectionCompleted &&
            (section.sectionSkipped || !section.sectionSkippedBefore)) ||
          section.sectionSkipped
        )
      }).length

      const _completedPercent = Math.ceil(
        (_completedSections / mergedOnboardingSections.length) * 100,
      )

      if (_completedPercent < 100) {
        setOnboardingOpenState(true)
      }

      setLoading(false)

      return [_completedSections, _completedPercent]
    }
    return [null, 0]
  }, [mergedOnboardingSections])

  return (
    <div className={styles.containerInner}>
      <ToggleBox
        noPadding
        className={styles.onboardingToggleBox}
        heading={
          <>
            <div className={styles.boxHeader}>
              <div className={styles.emoji}>
                <span role="img" aria-label="Onboarding checklist">
                  📝
                </span>
              </div>

              <h2>Onboarding checklist</h2>
              {!loading &&
                !onboardingOpenState &&
                mergedOnboardingSections &&
                completedSections !== null && (
                  <div className={styles.collapsedProgressBar}>
                    <ProgressBar
                      percentage={completedPercent}
                      showPercent={false}
                      thin
                      superThin
                      green
                    />
                    <p>{completedPercent}%</p>
                  </div>
                )}
            </div>
            {onboardingOpenState &&
              mergedOnboardingSections &&
              completedSections !== null && (
                <>
                  {completedPercent < 100 && (
                    <p style={{ margin: '8px 0' }}>
                      This is your personal guide for making the most of{' '}
                      {brandName}. Let's get you set up and creating links!
                    </p>
                  )}
                  <p>
                    You've completed{' '}
                    {completedPercent === 100 ? (
                      'all'
                    ) : (
                      <>
                        <strong>{completedSections.toString()}</strong> out of{' '}
                        <strong>
                          {mergedOnboardingSections.length.toString()}
                        </strong>
                      </>
                    )}{' '}
                    steps
                    {completedPercent === 100 ? ' - congrats!' : '.'}
                  </p>
                  <ProgressBar
                    percentage={completedPercent}
                    showPercent={false}
                    thin
                    green
                  />
                </>
              )}
          </>
        }
        open={onboardingOpenState}
        onToggle={(state: boolean) => {
          setOnboardingOpenState(state)
        }}
      >
        {mergedOnboardingSections && completedSections !== null && (
          <>
            <WelcomeChecklist fwdRef={checklistRef} />
            {completedPercent < 100 && (
              <Button
                variant="text"
                color="grey"
                className={styles.skipAll}
                onPress={() => {
                  const copy: typeof mergedOnboardingSections = JSON.parse(
                    JSON.stringify(mergedOnboardingSections),
                  )

                  copy.forEach(async (_, index) => {
                    if (!copy[index].sectionSkipped) {
                      copy[index].sectionSkipped = true
                      copy[index].sectionSkippedBefore = true

                      await logAction({
                        variables: {
                          ...onboardingSectionData[
                            copy[index].onboardingSectionID
                          ].skipAction,
                          pagePath: '/welcome',
                        },
                      })
                    }
                  })

                  setFullOnboardingSections((curr) => {
                    const newFull: typeof curr = JSON.parse(
                      JSON.stringify(curr),
                    )

                    newFull.user.forEach((_, sectionIndex) => {
                      newFull.user[sectionIndex].sectionSkipped = true
                      newFull.user[sectionIndex].sectionSkippedBefore = true
                    })

                    newFull.account.forEach((_, sectionIndex) => {
                      newFull.account[sectionIndex].sectionSkipped = true
                      newFull.account[sectionIndex].sectionSkippedBefore = true
                    })

                    // Update local state
                    updateCachedOnboardingSectionsProgress(
                      workspaceID,
                      'user',
                      newFull.user,
                    )
                    updateCachedOnboardingSectionsProgress(
                      workspaceID,
                      'account',
                      newFull.account,
                    )

                    return newFull
                  })

                  setOnboardingOpenState(false)
                }}
              >
                <em>I'm an expert - don't show me these steps</em>
              </Button>
            )}
          </>
        )}
      </ToggleBox>
    </div>
  )
}

interface UpgradeModalProps {
  onHideModal: React.Dispatch<React.SetStateAction<boolean>>
}

export function UpgradeSuccessModal({ onHideModal }: UpgradeModalProps) {
  return (
    <Modal
      setIsOpen={onHideModal}
      modalHeader={
        <Heading type={3} align="left">
          Congratulations for upgrading to Teams{' '}
          <span role="img" aria-label="Upgrade successful">
            🎉
          </span>
        </Heading>
      }
      noText="Close"
      yesText="Start using teams"
      onYes={() => onHideModal(false)}
    >
      <p>Thank you for your purchase! Below are some new actions you can do:</p>
      <Link
        type="arrowForward"
        className={styles.actionButton}
        href="/settings?show=users"
        newTab={false}
      >
        Add new users
      </Link>
      <Link
        type="arrowForward"
        className={styles.actionButton}
        href="/track/create"
        newTab={false}
      >
        Create a short link
      </Link>
      <Link
        type="arrowForward"
        className={styles.actionButton}
        href="/settings?show=workspaces"
        newTab={false}
      >
        Add a new workspace
      </Link>
    </Modal>
  )
}

interface LocationState {
  upgradeSuccess: string
  isMicrosoftMarketplace?: boolean
}

export default function WelcomePage() {
  const { state } = useLocation<LocationState>()

  const { data: userData } = useQuery(getUserInfo)

  const [getPaddleDetails] = useLazyQuery(getPaddleSubscriptionDetails, {
    pollInterval: 2000,
  })
  const [fetchMicrosoftSubscriptionData] = useLazyQuery(
    getMicrosoftSubscriptionData,
  )

  const [showUpgradeSuccessModal, setShowUpgradeSuccessModal] = useState(false)
  const [showUtmSheetUploadModal, setShowUtmSheetUploadModal] = useState(false)

  const firstName = useMemo(() => {
    return userData ? userData.currentUser.firstName : ''
  }, [userData])

  const [welcomeName, setWelcomeName] = useState(
    `${timeOfDayGreeting(new Date(Date.now()))}${
      firstName ? ` ${firstName} 👋` : ''
    }`,
  )

  // Show utm spreadsheet modal if first session after company creation
  // Set in useAuthenticate hook
  useEffect(() => {
    if (window.sessionStorage.getItem('showSessionStorageModal') === 'true') {
      setShowUtmSheetUploadModal(true)
      window.sessionStorage.removeItem('showSessionStorageModal')
    }
  }, [])

  useEffect(() => {
    if (firstName) {
      setWelcomeName(
        `${timeOfDayGreeting(new Date(Date.now()))} ${firstName} 👋`,
      )
    }
  }, [firstName])

  useEffect(() => {
    if (state && state.upgradeSuccess) {
      if (state.isMicrosoftMarketplace) {
        fetchMicrosoftSubscriptionData()
      } else {
        getPaddleDetails()
      }
      setShowUpgradeSuccessModal(true)
    }
  }, [state])

  return (
    <>
      <SiteWrapper>
        <Layout>
          <Intro title={welcomeName}>
            <p>Welcome to your personalised dashboard.</p>
          </Intro>
          <TwoColumns>
            <Column main>
              <div className={styles.container}>
                <WelcomeChecklistContainer />
                <QuickAccess />
                <WelcomeFeedback />
                {whitelabelDomain === 'uplifter' && <ReferralVoucher />}
              </div>
            </Column>
            <Column side fixed className={styles.rightColumn}>
              <WelcomeRightModule />
            </Column>
          </TwoColumns>
        </Layout>
      </SiteWrapper>
      {showUpgradeSuccessModal && (
        <UpgradeSuccessModal onHideModal={setShowUpgradeSuccessModal} />
      )}
      {showUtmSheetUploadModal && (
        <UploadExistingUtmSpreadsheetModal
          onHideModal={setShowUtmSheetUploadModal}
        />
      )}
    </>
  )
}
