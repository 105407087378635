import React, { useCallback, useState } from 'react'
import { useMutation, useReactiveVar } from '@apollo/client'

import Button from './button'
import Input from './input'
import { FieldSlot, Cell, BodyItem } from './row'
import { currentUserDetails } from '../api/apollo/variables'
import { updateGeneratorParameterSelectAddOption } from '../api/graphql/track-edit-client'
import { GeneratorSelectFields, ValidationChecks } from '../api/types'
import { getItemByKeyValue, prepareInput } from '../helpers'
import useLogAction from '../hooks/useLogAction'
import useOnboarding from '../hooks/useOnboarding'
import styles from '../styles/campaign-codes-administration.module.scss'

export interface AddNewSelectFieldProps {
  fields: { optionName: string; optionValue: string }[]
  fieldName: string
  restrictDropdowns: boolean
  fieldID?: string
  validation?: ValidationChecks[] | null
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>
  shownValues?: any[]
}

const newFieldTemplate = {
  hide: false,
  optionName: '',
  optionValue: '',
  optionID: '',
}

export default function AddNewSelectField({
  fields,
  fieldName,
  restrictDropdowns,
  fieldID,
  validation,
  setSearchTerm,
  shownValues,
}: AddNewSelectFieldProps) {
  const logAction = useLogAction()

  const { workspaceID } = useReactiveVar(currentUserDetails)

  const { fullOnboardingSections, updateOnboardingSection } = useOnboarding()

  const [addSelectOption] = useMutation(updateGeneratorParameterSelectAddOption)

  const [newField, setNewField] = useState<GeneratorSelectFields>({
    ...newFieldTemplate,
  })
  const [fieldNameError, setFieldNameError] = useState(false)
  const [fieldValueError, setFieldValueError] = useState(false)

  const completeOnboardingSection = useCallback(() => {
    const editTaxonomy = fullOnboardingSections.account.find(
      (section) => section.onboardingSectionID === 'editTaxonomy',
    )

    if (editTaxonomy && !editTaxonomy.sectionCompleted) {
      updateOnboardingSection('editTaxonomy', 'account')
    }
  }, [fullOnboardingSections])

  return (
    <BodyItem className={styles.addNewField}>
      <Cell width={restrictDropdowns ? 340 : 503} className={styles.fieldCell}>
        <FieldSlot column className={styles.fieldSlot}>
          <Input
            name={`${fieldName} new optionName`}
            label={`${fieldName} new optionName`}
            placeholder="Add new dropdown name"
            value={newField.optionName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const { value } = e.target as HTMLInputElement

              setSearchTerm(value)

              const found = getItemByKeyValue(fields, 'optionName', value)

              if (found !== -1) {
                setFieldNameError(true)
              } else {
                setFieldNameError(false)
              }

              const optionValue = prepareInput(value, validation)

              setNewField({
                ...newField,
                optionName: value,
                optionValue,
              })
            }}
            onKeyUp={(e) => {
              if (
                e.key === 'Enter' &&
                !(
                  newField.optionName === '' ||
                  fieldNameError ||
                  fieldValueError
                )
              ) {
                if (fieldID) {
                  addSelectOption({
                    variables: {
                      fieldID,
                      optionName: newField.optionName,
                      optionValue: newField.optionValue,
                      hide: false,
                    },
                  })

                  logAction({
                    variables: {
                      action: 'update-generator-parameter-add-select-option',
                      extra: JSON.stringify({
                        fieldID,
                        newOption: {
                          ...newField,
                        },
                        accountID: workspaceID,
                      }),
                      websiteSection: 'track',
                      functionName: 'updateGeneratorParameterSelectAddOption',
                      pagePath: '/track/edit-dropdowns',
                    },
                  })

                  completeOnboardingSection()

                  setNewField({ ...newFieldTemplate })
                }
              }
            }}
          />
          {newField.optionName &&
            !fieldNameError &&
            shownValues &&
            shownValues.length > 0 && (
              <p className={styles.footNote}>
                Similar names found, could you use:
              </p>
            )}
          {fieldNameError && (
            <p className={styles.footNoteError}>
              This name is already being used.
            </p>
          )}
        </FieldSlot>
      </Cell>
      <Cell width={restrictDropdowns ? 340 : 503} className={styles.fieldCell}>
        <FieldSlot column className={styles.fieldSlot}>
          <Input
            key={`${fieldName} ${newField.optionName}`}
            name={`${fieldName} new optionValue`}
            label={`${fieldName} new optionValue`}
            value={newField.optionValue}
            placeholder="Add new dropdown value"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const { value } = e.target as HTMLInputElement

              setSearchTerm(value)

              const found = getItemByKeyValue(fields, 'optionValue', value)

              if (found !== -1) {
                setFieldValueError(true)
              } else {
                setFieldValueError(false)
              }

              setNewField({
                ...newField,
                optionValue: prepareInput(value, validation),
              })
            }}
            onKeyUp={(e) => {
              if (
                e.key === 'Enter' &&
                !(
                  newField.optionName === '' ||
                  fieldNameError ||
                  fieldValueError
                )
              ) {
                if (fieldID) {
                  addSelectOption({
                    variables: {
                      fieldID,
                      optionName: newField.optionName,
                      optionValue: newField.optionValue,
                      hide: false,
                    },
                  })

                  logAction({
                    variables: {
                      action: 'update-generator-parameter-add-select-option',
                      extra: JSON.stringify({
                        fieldID,
                        newOption: {
                          ...newField,
                        },
                        accountID: workspaceID,
                      }),
                      websiteSection: 'track',
                      functionName: 'updateGeneratorParameterSelectAddOption',
                      pagePath: '/track/edit-dropdowns',
                    },
                  })

                  completeOnboardingSection()
                }

                setNewField({ ...newFieldTemplate })
              }
            }}
          />
          {newField.optionValue &&
            !fieldValueError &&
            shownValues &&
            shownValues.length > 0 && (
              <p className={styles.footNote}>
                Similar values found, could you use:
              </p>
            )}
          {fieldValueError && (
            <p className={styles.footNoteError}>
              This code is already being used.
            </p>
          )}
        </FieldSlot>
      </Cell>
      {restrictDropdowns && (
        <Cell width={340}>
          <></>
        </Cell>
      )}
      <Cell width={120}>
        <Button
          isDisabled={
            newField.optionName === '' || fieldNameError || fieldValueError
          }
          onPress={() => {
            if (fieldID) {
              addSelectOption({
                variables: {
                  fieldID,
                  optionName: newField.optionName,
                  optionValue: newField.optionValue,
                  hide: false,
                },
              })

              logAction({
                variables: {
                  action: 'update-generator-parameter-add-select-option',
                  extra: JSON.stringify({
                    fieldID,
                    newOption: {
                      ...newField,
                    },
                    accountID: workspaceID,
                  }),
                  websiteSection: 'track',
                  functionName: 'updateGeneratorParameterSelectAddOption',
                  pagePath: '/track/edit-dropdowns',
                },
              })

              setNewField({ ...newFieldTemplate })
              setSearchTerm('')

              completeOnboardingSection()
            }
          }}
        >
          Confirm
        </Button>
      </Cell>
    </BodyItem>
  )
}
