import React, { useState } from 'react'
import classNames from 'classnames'

import { ButtonRow } from './button-row'
import Button, { DeleteButton } from './button'
import styles from '../styles/delete-button-with-confirmation.module.scss'

interface Props {
  children: React.ReactNode
  onClick: () => void
  className?: string
  odd?: boolean
  inlineMobile?: boolean
}

export default function DeleteButtonWithConfirmation({
  onClick,
  children,
  odd = false,
  inlineMobile,
  className = '',
}: Props): React.ReactElement {
  const [removeActive, setRemoveActive] = useState(false)

  const wrapperClassNames = classNames(className, styles.deleteAction, {
    [styles.odd]: odd,
    [styles.deleteActionActive]: removeActive,
    [styles.inlineMobile]: inlineMobile,
  })

  return (
    <div className={wrapperClassNames}>
      <div className={styles.message}>
        <DeleteButton
          className={styles.delete}
          onPress={() => setRemoveActive(true)}
        />
        {removeActive && <div className={styles.content}>{children}</div>}
      </div>
      {removeActive && (
        <>
          <ButtonRow className={styles.confirmationButtons}>
            <Button
              className={styles.pillButton}
              onPress={() => {
                setRemoveActive(false)
                onClick()
              }}
            >
              Yes
            </Button>
            <Button
              className={styles.pillButton}
              color="blue"
              onPress={() => {
                setRemoveActive(false)
              }}
            >
              No
            </Button>
          </ButtonRow>
        </>
      )}
    </div>
  )
}
