import React from 'react'
import { useReactiveVar } from '@apollo/client'
import { useHistory } from 'react-router-dom'

import Button from './button'
import Link from './link'
import Message from './login-message'
import SiteLogo, { LogoTagline } from './site-logo'
import { passwordState } from '../api/apollo/variables'
import { supportEmail } from '../core/constants'
import styles from '../styles/forgot-password.module.scss'

export default function ForgotPasswordResult(): React.ReactElement {
  const currentPasswordState = useReactiveVar(passwordState)

  const history = useHistory()

  if (currentPasswordState.forgotPassword.success) {
    return (
      <>
        <SiteLogo link className={styles.svg} />
        <LogoTagline />
        <div className={styles.divider} />
        <Message message="Password reset email has been sent. Please check your inbox or junk/spam folders." />
        <div className={styles.divider} />
        <Link
          className={styles.link}
          href="https://support.uplifter.ai/hc/en-us/articles/360018765078-I-can-t-login-to-Uplifter"
        >
          Need help logging in?
        </Link>
      </>
    )
  }

  return (
    <>
      <SiteLogo link className={styles.svg} />
      <LogoTagline />
      <div className={styles.divider} />
      <Message
        message={`We are currently experiencing some issues. Please [try again](/login) or contact us by emailing [${supportEmail}](mailto:${supportEmail}).`}
      />
      <div className={styles.divider} />
      <h2>Don't have an account?</h2>
      <Button color="grey" onPress={() => history.push('/create-account')}>
        Sign up here
      </Button>
      <Link
        className={styles.link}
        href="https://support.uplifter.ai/hc/en-us/articles/360018765078-I-can-t-login-to-Uplifter"
      >
        Need help logging in?
      </Link>
    </>
  )
}
