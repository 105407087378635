import React from 'react'
import classNames from 'classnames'

import { Tag } from './add-multi-values-tags'
import useLogAction from '../hooks/useLogAction'
import styles from '../styles/report-filters-summary.module.scss'
import { ReportFilterSummaryItem } from '../types/report-module'

export interface LinkFilter {
  id: string
  link: string
}

interface ReportFiltersSummaryProps {
  /** Used for interaction log. Lowercase, hyphenated. */
  interactionLogReportName: string
  availableDimensionsTotals?: { [dimensionParameterID: string]: number }
  appliedFilters: ReportFilterSummaryItem[]
  onRemoveFilterOption: ({
    dimensionParameterID,
    optionValue,
  }: {
    dimensionParameterID: string
    optionValue: string
  }) => Promise<void>
  /** Used for one-click reports from Track>View. Users should se which URL is selected */
  urlParamsFilter?: {
    checked: boolean
    filter: LinkFilter | null
    error: boolean
  }
  onRemoveUrlParam?: () => void
}

const ReportFiltersSummary = ({
  interactionLogReportName,
  availableDimensionsTotals,
  appliedFilters,
  onRemoveFilterOption,
  urlParamsFilter,
  onRemoveUrlParam,
}: ReportFiltersSummaryProps) => {
  const logAction = useLogAction()

  if (
    appliedFilters.length === 0 ||
    (availableDimensionsTotals &&
      appliedFilters.every(
        (appliedFilter) =>
          appliedFilter.dimensionOptions.length ===
          availableDimensionsTotals[appliedFilter.dimensionParameterID],
      ))
  ) {
    return null
  }

  return (
    <div className={styles.reportFiltersSummaryWrapper}>
      <p className={styles.summaryTitle}>
        <strong>Filtered on:</strong>
      </p>
      {urlParamsFilter?.filter && onRemoveUrlParam && (
        <Tag
          key={JSON.stringify(urlParamsFilter.filter)}
          className={classNames(styles.tag, styles.linkId)}
          compact
          onClick={onRemoveUrlParam}
        >
          <span>Link:</span> {urlParamsFilter.filter.link}
        </Tag>
      )}
      {appliedFilters.map((appliedFilter) => {
        const {
          dimensionParameterID,
          dimensionName,
          dimensionOptions,
        } = appliedFilter

        // Don't show tags if all options are selected
        if (
          availableDimensionsTotals &&
          availableDimensionsTotals[dimensionParameterID] ===
            dimensionOptions.length
        ) {
          return null
        }

        return dimensionOptions.map(({ optionName, optionValue }) => {
          return (
            <Tag
              key={JSON.stringify({
                dimensionParameterID,
                optionValue,
              })}
              className={styles.tag}
              compact
              onClick={async () => {
                // Send the ID back so it can be removed
                await onRemoveFilterOption({
                  dimensionParameterID,
                  optionValue,
                })

                logAction({
                  variables: {
                    action: `update-filters-${interactionLogReportName}-remove-option`,
                    extra: JSON.stringify({
                      dimensionParameterID,
                      optionValue,
                    }),
                    websiteSection: 'report',
                    functionName: 'edit',
                    pagePath: window.location.pathname,
                  },
                })
              }}
            >
              <span className={styles.tagTitle}>{dimensionName}:</span>{' '}
              {optionName === '' ? '(empty)' : optionName}
            </Tag>
          )
        })
      })}
    </div>
  )
}

export default ReportFiltersSummary
