import React from 'react'
import { useMutation, useQuery, useReactiveVar } from '@apollo/client'

import Row, { Slot } from './row'
import { currentUserDetails } from '../api/apollo/variables'
import {
  getAvailableModules,
  toggleModuleAvailability,
} from '../api/graphql/workspace-client'
import styles from '../styles/features-settings-card.module.scss'

export default function FeatureSettingsCard() {
  const { workspaceID: accountID, companyID } = useReactiveVar(
    currentUserDetails,
  )

  const { data } = useQuery(getAvailableModules)

  const [updateModuleAvailability] = useMutation(toggleModuleAvailability)

  const { trackAvailable, reportAvailable } = data?.currentAccount || {
    trackAvailable: true,
    reportAvailable: true,
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.body}>
        <Row className={styles.bodyItem} vAlign="center" align="flex-start">
          <Slot
            align="flex-start"
            vAlign="center"
            className={styles.bodyItemSlot}
          >
            <h3>Track</h3>
            <p>Make and manage campaign links, to optimise media spend.</p>
          </Slot>
          <Slot align="flex-end" vAlign="center" width={100}>
            <button
              className={trackAvailable ? styles.enabled : styles.disabled}
              onClick={async () => {
                await updateModuleAvailability({
                  variables: {
                    accountID,
                    companyID,
                    trackModule: !trackAvailable,
                  },
                })
              }}
            >
              {trackAvailable ? 'Enabled' : 'Disabled'}
            </button>
          </Slot>
        </Row>
        <Row className={styles.bodyItem} vAlign="center" align="flex-start">
          <Slot
            align="flex-start"
            vAlign="center"
            className={styles.bodyItemSlot}
          >
            <h3>Report</h3>
            <p>
              Create and share reports which explain your websites performance.
            </p>
          </Slot>
          <Slot align="flex-end" vAlign="center" width={100}>
            <button
              className={reportAvailable ? styles.enabled : styles.disabled}
              onClick={async () => {
                await updateModuleAvailability({
                  variables: {
                    accountID,
                    companyID,
                    reportModule: !reportAvailable,
                  },
                })
              }}
            >
              {reportAvailable ? 'Enabled' : 'Disabled'}
            </button>
          </Slot>
        </Row>
        {/* Explain module should be hidden for all users */}
        {/* <Row className={styles.bodyItem} vAlign="center" align="flex-start">
          <Slot
            align="flex-start"
            vAlign="center"
            className={styles.bodyItemSlot}
          >
            <h3>Explain</h3>
            <p>Spot anomalies, find insights and act to improve performance.</p>
          </Slot>
          <Slot align="flex-end" vAlign="center" width={100}>
            <button
              className={explainAvailable ? styles.enabled : styles.disabled}
              onClick={async () => {
                await updateModuleAvailability({
                  variables: {
                    accountID,
                    companyID,
                    explainModule: !explainAvailable,
                  },
                })
              }}
            >
              {explainAvailable ? 'Enabled' : 'Disabled'}
            </button>
          </Slot>
        </Row> */}
      </div>
    </div>
  )
}
