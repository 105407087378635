import React, { useState, useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { nanoid } from 'nanoid'
import classNames from 'classnames'

import { listRemoteDashboards } from '../api/graphql/report-client'
import Stars from '../assets/star-cluster-yellow.svg'
import { ButtonRow } from '../components/button-row'
import Button from '../components/button'
import ButtonTabs from '../components/button-tabs'
import Manage from '../components/dashboard-manage'
import HeaderPanel, { Panel } from '../components/header-panel'
import Intro from '../components/intro'
import Layout from '../components/layout'
import Link from '../components/link'
import SiteWrapper from '../components/site-wrapper'
import Tag from '../components/tag'
import { BoxedText, NoteText } from '../components/typography'
import { supportEmail } from '../core/constants'
import { getItemByKeyValue } from '../helpers'
import styles from '../styles/dashboard.module.scss'

interface EditProps {
  onClick: () => void
}

export function EditButton({ onClick }: EditProps): React.ReactElement {
  return (
    <>
      <Button
        variant="secondary"
        color="blue"
        className={styles.manageButton}
        onPress={onClick}
      >
        Add/Edit
      </Button>
    </>
  )
}

export default function DashboardPage(): React.ReactElement {
  const [manageActive, setActive] = useState(false)
  const [activeId, setActiveId] = useState('')
  const [embedHelpVisible, setEmbedHelpVisible] = useState(false)

  const { data: remoteDashboardsData, loading } = useQuery(listRemoteDashboards)

  const dashboards = useMemo(() => {
    if (!remoteDashboardsData) return []

    return remoteDashboardsData.report.listRemoteDashboards.remoteDashboards
  }, [remoteDashboardsData])

  const onClickHeaderButton = (currentID: string) => {
    setActiveId(currentID)
  }

  const currentEmbedItem = useMemo(() => {
    if (dashboards && dashboards.length > 0) {
      if (activeId === '') {
        return { ...dashboards[0] }
      }

      let item = getItemByKeyValue(dashboards, 'dashboardID', activeId)
      if (item === -1) {
        item = { ...dashboards[0] }
        setActiveId(item.dashboardID)
      }
      return item
    }
    return null
  }, [activeId, dashboards])

  return (
    <SiteWrapper>
      <Layout width={1400}>
        <Intro title="Dashboards">
          <p>
            View, share and discuss embedded dashboards here.{' '}
            {!loading && dashboards && dashboards.length > 0 && (
              <>
                <Button
                  variant="text"
                  color="blue"
                  onPress={() => setEmbedHelpVisible(!embedHelpVisible)}
                >
                  How to embed dashboards
                </Button>
                .
              </>
            )}
          </p>
          {((!loading && dashboards && dashboards.length === 0) ||
            embedHelpVisible) && (
            <Tag multiLine icon={Stars}>
              <ButtonTabs
                label="To embed your dashboard from:"
                tabsLabels={['Google Data Studio', 'PowerBI', 'Other']}
              >
                <>
                  <ol className={styles.numberedList}>
                    <li>
                      Login to data studio and navigate to{' '}
                      <Link href="https://datastudio.google.com/navigation/reporting">
                        datastudio.google.com/navigation/reporting
                      </Link>
                    </li>
                    <li>Open the dashboard that you would like to share.</li>
                    <li>
                      Click the blue <strong>'Edit'</strong> button in the
                      top-right.
                    </li>
                    <li>
                      Click the <strong>'Share'</strong> button in the
                      top-right.
                    </li>
                    <li>
                      In the manage access tab, select{' '}
                      <strong>'Anyone with this link can view'</strong>.{' '}
                    </li>
                    <li>
                      Close this pop up and select{' '}
                      <strong>'Embed report'</strong> from the dropdown list by
                      the share button.
                    </li>
                    <li>
                      Click the checkbox to <strong>'Enable embedding'</strong>,
                      and copy the value for <strong>'Embed URL'</strong>.
                    </li>
                    <li>
                      Navigate to{' '}
                      <BoxedText>
                        <Link
                          className={styles.backButton}
                          href="/report/dashboards"
                          newTab={false}
                        >
                          Report &gt; Custom embedded
                        </Link>
                      </BoxedText>{' '}
                      and click on the <strong>'Add/Edit'</strong> button.{' '}
                    </li>
                    <li>
                      Click on <strong>'Add a new dashboard +'</strong>, add a
                      dashboard name and paste the embed link.
                    </li>
                    <li>
                      Click <strong>'Save'</strong>.
                    </li>
                  </ol>
                </>

                <>
                  <ol className={styles.numberedList}>
                    <li>
                      Log in to the online PowerBI service:{' '}
                      <Link href="https://app.powerbi.com/home">
                        app.powerbi.com/home
                      </Link>
                    </li>
                    <li>Navigate to the workspace which holds your report.</li>
                    <li>
                      We only support public reports that are shared to the web.
                      Click the report that you would like to embed.
                    </li>
                    <li>
                      In the top panel, there is an option for{' '}
                      <strong>'Share'</strong>. Click this option and click{' '}
                      <strong>'Publish to Web'</strong>.
                    </li>
                    <li>
                      This will open a pop-up box that should say{' '}
                      <strong>'Embed Code'</strong> and contain an image of your
                      report.
                    </li>
                    <li>
                      Copy the link in the{' '}
                      <strong>'Link you can send in email'</strong> box.
                    </li>
                    <li>
                      Navigate to{' '}
                      <BoxedText>
                        <Link
                          className={styles.backButton}
                          href="/report/dashboards"
                          newTab={false}
                        >
                          Report &gt; Custom embedded
                        </Link>
                      </BoxedText>{' '}
                      and click on the <strong>'Add/Edit'</strong> button.
                    </li>
                    <li>
                      Click on <strong>'Add a new dashboard +'</strong> to add a
                      dashboard name and the dashboard embed link you copied
                      from PowerBI.
                    </li>
                  </ol>
                  <NoteText>
                    <>
                      You may get a warning when you click 'Publish to web'. In
                      this case, an administrator for your Microsoft account
                      must enable 'Publish to web' for your organisation. Direct
                      your administrator to this link to enable 'Allow existing
                      and new codes' within the PowerBI admin panel:{' '}
                      <Link href="https://docs.microsoft.com/en-us/power-bi/admin/service-admin-portal#publish-to-web">
                        docs.microsoft.com/en-us/power-bi/admin/service-admin-portal#publish-to-web
                      </Link>
                    </>
                  </NoteText>
                </>
                <p>
                  Looking for help, or looking to embed dashboards from other
                  providers? Email{' '}
                  <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link>{' '}
                  and let's talk!
                </p>
              </ButtonTabs>
            </Tag>
          )}
        </Intro>

        <HeaderPanel>
          <Panel>
            <ButtonRow>
              {dashboards &&
                dashboards.map(({ dashboardName, dashboardID }, index) => {
                  const active = activeId === dashboardID

                  return (
                    <Button
                      variant="text"
                      key={nanoid()}
                      color={
                        active || (activeId === '' && index) === 0
                          ? 'pink'
                          : 'grey'
                      }
                      className={classNames(styles.tabButton, {
                        [styles.tabButtonActive]:
                          activeId === '' && index === 0 ? true : active,
                      })}
                      onPress={() => onClickHeaderButton(dashboardID)}
                    >
                      {dashboardName}
                    </Button>
                  )
                })}
              {dashboards && (
                <>
                  {dashboards.length > 0 && <b className={styles.divider} />}
                  <EditButton
                    onClick={(): void => {
                      setActive(true)
                    }}
                  />
                </>
              )}
            </ButtonRow>
          </Panel>
        </HeaderPanel>

        {currentEmbedItem && (
          <div key={currentEmbedItem.id}>
            <div className={styles.embededIFrameWrapper}>
              <iframe
                title={currentEmbedItem.dashboardName}
                className={styles.embededIFrame}
                src={currentEmbedItem.embedLink}
              />
            </div>
          </div>
        )}
      </Layout>
      {manageActive && <Manage data={dashboards} toggleActive={setActive} />}
    </SiteWrapper>
  )
}
