import { gql } from '../../__gql-types__/gql'

// Accepted values for dimensionParameterID:
// ['any',  'createdBy', 'createdTime', 'shortLink', 'fullLink', '{parameterIDs from generator...}']
export const getMinCodesByAccount = gql(`
  query GetMinCodesByAccount (
		$codeIDList: [String!],
		$dimensionFilter: DimensionFilter,
		$filterByCurrentUser: Boolean,
		$limit: Int,
		$noReturn: Boolean,
		$offset: Int,
		$orderBy: SortFilter,
		$refreshCache: Boolean,
		$startDate: String,
		$endDate: String
	) {
		minCodesByAccount(
			codeIDList: $codeIDList,
			dimensionFilter: $dimensionFilter,
			filterByCurrentUser: $filterByCurrentUser
			limit: $limit,
			noReturn: $noReturn,
			offset: $offset,
			orderBy: $orderBy,
			refreshCache: $refreshCache,
			startDate: $startDate,
			endDate: $endDate
		) {
			accountID @client
			author
			codeDef
			codeID
			createdTime
			minGenDef {
				paramID
				paramName
			}
			fullLink
			shortLink
			totalCodes
			versionNumber
		}
	}
`)

export const getUrlValidationStatus = gql(`
  query GetUrlValidationStatus (
		$codeIDList: [String!]!,
	) {
		track {
			accountID @client
			trackValidationResults(
				codeIDList: $codeIDList,
			) {
				badUrl
				campaignCodeID
				noAnalyticsTag
				redirectedLandingPage
				slowLandingPage
			}
		}
	}
`)

export const getStoredCodeStats = gql(`
	query GetStoredCodesStats (
		$codeIDList: [String!],
		$dimensionFilter: DimensionFilter,
		$filterByCurrentUser: Boolean
		$limit: Int,
		$noReturn: Boolean,
		$offset: Int,
		$orderBy: SortFilter,
		$refreshCache: Boolean
	) {
		storedCodeStats(
			codeIDList: $codeIDList,
			dimensionFilter: $dimensionFilter,
			filterByCurrentUser: $filterByCurrentUser
			limit: $limit,
			noReturn: $noReturn,
			offset: $offset,
			orderBy: $orderBy,
			refreshCache: $refreshCache
		) {
			accountID @client
			codeIDs
			earliestClickDate
    	earliestMetricDate
			metricValues {
				displayName
				helpText
				metricID
				totalMetricValues
				units
			}
			status
			totalCodes
		}
	}
`)

export const quickUrlValidation = gql(`
	query QuickUrlValidation($urlList: [String!]!) {
		track {
			quickUrlValidation(urlList: $urlList) {
				validationResults {
					statusCode
					testUrl
				}
			}
		}
	}
`)

export const intensiveUrlValidation = gql(`
	query IntensiveUrlValidation($urlList: [String!]!) {
		track {
			intensiveUrlValidation(urlList: $urlList) {
				validationResults {
					badUrl
					clickedCookieConsent
					noAnalyticsTag
					redirectedLandingPage
					slowLandingPage
					statusCode
					testUrl
					validatorReturned
				}
			}
		}
	}
`)

export const addShortLinkExistingCode = gql(`
	mutation AddShortLinkExistingCode (
		$codeID: String!
		$customDomainID: String
		$newShortLinkID: String!
	) {
		track {
			addShortLinkExistingCode(
				codeID: $codeID,
				customDomainID: $customDomainID
				newShortLinkID: $newShortLinkID,
			) {
				c
				cID
				cDef {
					fID
					n
					v
				}
				e
				edB
				edT
				sL
				t
				vN
			}
		}
	}
`)

export const updateCode = gql(`
	mutation UpdateCode(
		$codeID: String!
		$fullCode: String
		$paramDefs: [ParamDefInput!]
		$customDomainID: String
	) {
		updateCode(
			codeID: $codeID,
			fullCode: $fullCode,
			paramDefs: $paramDefs,
			customDomainID: $customDomainID
		) {
			cDef {
				fID
				n
				v
			}
			c
			cID
			e
			edB
			edT
			sL
			t
			vN
		}
	}
`)

export const fetchLinkEditHistory = gql(`
	query FetchLinkEditHistory (
		$codeID: String!
	) {
		track {
			codeVersionHistory(
				codeID: $codeID
			) {
				changeMap
				editedBy
				editedTime
				versionHistory {
					linkID @client
					codeDef
					fullLink
					minGenDef {
						paramID
						paramName
					}
					totalCodes
					shortLink
					versionNumber
				}
				codeID
				createdTime
				author
			}
		}
	}
`)
