import React from 'react'
import { useReactiveVar } from '@apollo/client'
import { useHistory } from 'react-router-dom'

import { dataSourceReactive } from '../api/apollo/variables'
import SiteWrapper from '../components/site-wrapper'
import Layout from '../components/layout'
import Intro from '../components/intro'
import { NavigateButton } from '../components/button'
import CampaignIntroContent from '../components/campaign-intro-content'
import TrackHelpContent from '../components/track-help-content'

export default function TrackLearn() {
  const dataSource = useReactiveVar(dataSourceReactive)

  const history = useHistory()

  if (window.location.href.indexOf('/track/generator-help') > -1)
    history.replace('/track/learn')

  return (
    <SiteWrapper>
      <Layout width={1200}>
        <Intro title="Learn how to track campaigns">
          <CampaignIntroContent>
            <>
              <p>
                Click on a topic to start learning and take our test to get a
                LinkedIn certificate.
              </p>
            </>
          </CampaignIntroContent>
        </Intro>

        <TrackHelpContent
          adobe={!!dataSource && dataSource.connectionSource === 'adobe'}
        />

        <NavigateButton onPress={() => history.push('/track/create-links')}>
          Create campaign links
        </NavigateButton>
      </Layout>
    </SiteWrapper>
  )
}
