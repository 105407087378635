import React, { Dispatch, SetStateAction } from 'react'
import { useReactiveVar } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'
import _ from 'lodash'

import Button from './button'
import {
  InputRow,
  SelectionRow,
  DateRow,
  GroupedOptions,
} from './campaign-code-generator-rows'
import { currentUserDetails } from '../api/apollo/variables'
import { CampaignCodeGeneratorStructure } from '../api/types'
import { FullValidationCheck, brandName } from '../core/constants'
import { getItemByKeyValue } from '../helpers'
import {
  filterList,
  isFieldAChild,
  getChildrenValues,
  getParentValue,
  parentIsWorkspace,
} from '../helpers/track-module'
import styles from '../styles/campaign-code-generator.module.scss'

interface GeneratorFormFieldsProps {
  isAdmin: boolean
  single?: boolean
  form: CampaignCodeGeneratorStructure | null
  validationChecks: FullValidationCheck[] | null
  errors: string[]
  setErrors: Dispatch<SetStateAction<string[]>>
  updateForm: (
    name: string,
    value: string[],
    useForm?: CampaignCodeGeneratorStructure | null,
  ) => void
  updateErrorObject: (
    key: string,
    isValid: boolean,
    useErrorObject?: string[],
    setErrorValue?: boolean,
  ) => string[]
  onRequestNewField?: (value: string) => void
  submitOnEnter?: boolean
}

export default function GeneratorFormFields({
  isAdmin,
  single = true,
  form,
  validationChecks,
  errors,
  setErrors,
  updateForm,
  updateErrorObject,
  onRequestNewField,
  submitOnEnter = true,
}: GeneratorFormFieldsProps): React.ReactElement | null {
  const { workspaceID } = useReactiveVar(currentUserDetails)

  const history = useHistory()

  if (!form) return null

  return (
    <>
      {form.paramDefs.map((field, fieldIndex) => {
        const { metaParameter, fieldType, copyFromField } = field

        if (fieldType === 'fixed') return null

        const copyFromFieldItem = copyFromField
          ? copyFromField.map((item) => {
              const found = getItemByKeyValue(
                form.paramDefs,
                'fieldID',
                item.copyFromID,
              )
              return found !== -1 ? found.fieldName : ''
            })
          : null

        if (copyFromFieldItem && copyFromFieldItem.length > 0) {
          return null
        }

        const wrapperClassName = classNames(styles.rowWrapper, {
          [styles.isMetaParameter]: !!metaParameter,
        })

        let tooltipNote = ''

        if (metaParameter) {
          tooltipNote = `\n\n**This metadata will only be visible in ${brandName}.**`
        }

        const dependencies = field.parameterDependsOn

        let paramIsRequired = dependencies ? false : field.required

        if (dependencies) {
          // Only show the field if the dependency condition is met
          tooltipNote =
            '\n\n**This parameter only appears when certain dropdowns are selected.**'

          if (dependencies.parentFieldID === 'account') {
            paramIsRequired = field.required

            // Specific restriction based on current workspace
            if (dependencies.parentOptionIDs.indexOf(workspaceID) === -1) {
              // Remove field's values from form and hide field
              if (
                field.optionValue &&
                field.optionValue.length >= 1 &&
                !(field.optionValue.length === 1 && field.optionValue[0] === '')
              ) {
                updateForm(field.fieldName, [])
              }

              return null
            }
          } else {
            const dependentField = form.paramDefs.find(
              (param) => param.fieldID === dependencies.parentFieldID,
            )

            if (dependentField) {
              if (
                !dependentField.optionValue ||
                (dependentField.optionValue.length === 1 &&
                  dependentField.optionValue[0] === '')
              ) {
                // Remove field's values from form and hide field
                if (
                  field.optionValue &&
                  field.optionValue.length >= 1 &&
                  !(
                    field.optionValue.length === 1 &&
                    field.optionValue[0] === ''
                  )
                ) {
                  updateForm(field.fieldName, [])
                }

                if (errors.indexOf(field.fieldID) > -1) {
                  setErrors((curr) => {
                    const newErrors = [...curr]

                    const fieldErrorIndex = newErrors.indexOf(field.fieldID)

                    if (fieldErrorIndex > -1)
                      newErrors.splice(fieldErrorIndex, 1)

                    return newErrors
                  })
                }

                return null
              }

              let canShowField = false
              let valueIndex = 0

              while (valueIndex < dependentField.optionValue.length) {
                if (
                  dependencies.parentOptionIDs.indexOf(
                    dependentField.optionValue[valueIndex],
                  ) > -1
                ) {
                  canShowField = true

                  // If field is set to required, make it mandatory when shown
                  paramIsRequired = field.required

                  break
                }

                valueIndex += 1
              }

              if (!canShowField) {
                // Remove field's values from form and hide field
                if (
                  field.optionValue &&
                  field.optionValue.length >= 1 &&
                  !(
                    field.optionValue.length === 1 &&
                    field.optionValue[0] === ''
                  )
                ) {
                  updateForm(field.fieldName, [])
                }

                return null
              }
            }
          }
        }

        if (field.fieldType === 'select') {
          const list = filterList(form, field, workspaceID, single)

          const isChild = isFieldAChild(field)

          const groupedOptions: GroupedOptions[] = []

          const selectValue = field.optionValue ? [...field.optionValue] : []

          const parentValue = getParentValue(form, field)

          // Add labels for child fields
          // Only need to add labels when parent is a field, not a workspace
          if (isChild && !parentIsWorkspace(field) && parentValue.length > 0) {
            parentValue.forEach((item) => {
              const { optionID: parentID, optionName, useLabel } = item

              const childrenValues = getChildrenValues(field, item)

              groupedOptions.push({
                parentID,
                label: useLabel || optionName,
                options: childrenValues.sort((p, n) =>
                  p.optionName > n.optionName ? 1 : -1,
                ),
              })
            })

            const alwaysShownOptions = field.selectFields
              ? field.selectFields.filter(
                  (selectField) =>
                    !selectField.hide &&
                    (!selectField.optionFilter ||
                      selectField.optionFilter[0].parentOptionIDs.length === 0),
                )
              : []

            // TODO: Fix this
            // It's supposed to auto-set value for dependent field, but doesn't
            // if (single) {
            // groupedOptions.forEach((group) => {
            //   if (
            //     group.options.length === 1 &&
            //     selectValue.filter((val) => val !== '').length === 0
            //   ) {
            //     // if (single) {
            //     // selectValue = [group.options[0].optionID]
            //     // } else {
            //     //   selectValue.unshift(group.options[0].optionID)
            //     // }
            //   }
            // })

            // const onlyHasAlwaysShown = groupedOptions.find(
            //   (group) => group.options.length === 0,
            // )

            // if (
            //   !!onlyHasAlwaysShown &&
            //   alwaysShownOptions.length === 1 &&
            //   selectValue.filter((val) => val !== '').length === 0
            // ) {
            //   // if (single) {
            //   // selectValue = [alwaysShownOptions[0].optionID]
            //   // } else {
            //   //   selectValue.unshift(alwaysShownOptions[0].optionID)
            //   // }
            // }
            // }

            groupedOptions.push({
              parentID: parentValue[0].optionID,
              label: 'Always shown / Not assigned',
              options: alwaysShownOptions.sort((p, n) =>
                p.optionName > n.optionName ? 1 : -1,
              ),
            })
          } else {
            // Labels are hidden by default it's the only group in the dropdown
            groupedOptions.push({
              options: list.sort((p, n) =>
                p.optionName > n.optionName ? 1 : -1,
              ),
              label: field.fieldName,
            })
          }

          return (
            <SelectionRow
              // Key needs to change to update values based on other select fields
              key={`${field.fieldID}${JSON.stringify(list)}-${JSON.stringify(
                parentValue,
              )}`}
              id={field.fieldID}
              defaultError={
                !!(field.fieldID && errors.indexOf(field.fieldID) !== -1)
              }
              single={single}
              optional={!paramIsRequired}
              isMetaParameter={!!metaParameter}
              name={field.fieldName}
              updateForm={(value: string[]) =>
                updateForm(field.fieldName, value)
              }
              list={groupedOptions}
              value={selectValue}
              tooltip={`${field.helpText || ''}${tooltipNote}`}
              className={wrapperClassName}
              onValidation={(valid: boolean) =>
                updateErrorObject(field.fieldID, valid)
              }
            >
              <Button
                variant="text"
                className={styles.addButton}
                onPressStart={() => {
                  if (isAdmin) {
                    history.push(
                      `/track/edit-dropdowns?fieldID=${field.fieldID}`,
                    )
                  } else if (onRequestNewField) {
                    onRequestNewField(field.fieldName)
                  }
                }}
              >
                {isAdmin ? 'Add' : 'Request'} new {field.fieldName} +
              </Button>
            </SelectionRow>
          )
        }

        if (field.fieldType === 'date') {
          const dateValue =
            field.optionValue &&
            field.optionValue.length > 0 &&
            field.optionValue[0] !== ''
              ? field.optionValue[0]
              : null

          const dateError = !!(
            field.fieldID && errors.indexOf(field.fieldID) !== -1
          )

          return (
            <DateRow
              key={`${field.fieldID}${dateError}`}
              defaultError={dateError}
              dateFormat={field.dateFormat || 'DD/MM/YYYY'}
              optional={!paramIsRequired}
              isMetaParameter={!!metaParameter}
              name={field.fieldName}
              updateForm={(value: string[]): void =>
                updateForm(field.fieldName, value)
              }
              value={dateValue}
              tooltip={`${field.helpText || ''}${tooltipNote}`}
              className={wrapperClassName}
              onValidation={(valid: boolean) =>
                updateErrorObject(field.fieldID, valid)
              }
            />
          )
        }

        const isMetaParameter = !!metaParameter

        const { forceLowerCase = true } = field

        const inputValidation = validationChecks
          ? _.cloneDeep(validationChecks)
          : []

        const allLowerCaseRuleIndex = getItemByKeyValue(
          validationChecks,
          'name',
          'ALL_LOWER_CASE',
          true,
        )
        const allLowerCaseRule = getItemByKeyValue(
          validationChecks,
          'name',
          'ALL_LOWER_CASE',
        )

        if (forceLowerCase !== false) {
          // If not set globally, lowercase rule should be inserted for this field
          if (allLowerCaseRuleIndex === -1) {
            inputValidation.unshift({
              enabled: true,
              name: 'ALL_LOWER_CASE',
              value: null,
            })
          } else if (!allLowerCaseRule.enabled) {
            inputValidation.splice(allLowerCaseRuleIndex, 1, {
              ...allLowerCaseRule,
              enabled: true,
            })
          }
        }

        const inputRowDefaultError = !!(
          field.fieldID && errors.indexOf(field.fieldID) !== -1
        )

        const initialValue = field.optionValue || ['']

        return (
          <InputRow
            key={field.fieldID}
            defaultError={inputRowDefaultError}
            single={isMetaParameter ? true : single}
            validation={inputValidation}
            onValidation={(valid: boolean) =>
              updateErrorObject(field.fieldID, valid)
            }
            optional={!paramIsRequired}
            isMetaParameter={isMetaParameter}
            copyFromLabel={copyFromFieldItem || []}
            id={field.fieldID}
            name={field.fieldName}
            updateForm={(value: string[]): void =>
              updateForm(field.fieldName, value)
            }
            value={initialValue}
            tooltip={`${field.helpText || ''}${tooltipNote}`}
            className={wrapperClassName}
            submitOnEnter={
              submitOnEnter || fieldIndex === form.paramDefs.length - 1
            }
          />
        )
      })}
    </>
  )
}
