import React, { useCallback, useMemo, useState } from 'react'
import { nanoid } from 'nanoid'
import _ from 'lodash'

import Button from './button'
import { Heading } from './typography'
import Quiz from './quiz'
import ToggleBox from './toggle-box'
import useOnboarding from '../hooks/useOnboarding'
import { saveUserData, getUserData } from '../helpers/local-client'
import { adobeContent, googleContent } from '../static-copy/track-learn'
import styles from '../styles/track-learn.module.scss'

interface TrackHelpProps {
  adobe?: boolean
}

export default function TrackHelpContent({ adobe = false }: TrackHelpProps) {
  const saveState = getUserData()

  const { mergedOnboardingSections } = useOnboarding()

  const quizComplete = useMemo(() => {
    if (mergedOnboardingSections && mergedOnboardingSections.length > 0) {
      const learnOnboarding = mergedOnboardingSections.find(
        (section) => section.onboardingSectionID === 'learnCampaignLinks',
      )

      if (learnOnboarding && learnOnboarding.sectionCompleted) {
        return true
      }
    }

    return false
  }, [mergedOnboardingSections])

  const emptyFields: boolean[] = Array(11).fill(false, 0)

  // Auto-open tabs based on query
  const urlParams = new URLSearchParams(window.location.search)
  const lessonToShow = urlParams.get('lesson')

  if (lessonToShow) {
    emptyFields.splice(parseInt(lessonToShow, 10) - 1, 1, true)
  }

  const initialState =
    saveState && saveState.toggleBoxState
      ? saveState.toggleBoxState
      : emptyFields

  const [toggleBoxState, setToggleBoxState] = useState(initialState)
  const [openBoxState, setOpenBoxState] = useState(emptyFields)

  const updateState = useCallback(
    (index: number, value: boolean, toggleOpen = true) => {
      const copy = toggleBoxState.concat()
      copy[index] = value
      setToggleBoxState(copy)
      saveUserData({ toggleBoxState: copy })

      if (toggleOpen) {
        const copyOpenBoxState = openBoxState.map((x, i) => {
          // Open the next section
          if (i === index + 1) return true
          return false
        })

        setOpenBoxState(copyOpenBoxState)
      }
    },
    [],
  )

  const onToggle = (index: number, value: boolean) => {
    const copy = openBoxState.concat()
    copy[index] = value
    setOpenBoxState(copy)
  }

  const sections = adobe ? adobeContent : googleContent

  return (
    <div className={styles.container} key={nanoid()}>
      {sections.map((section, index) => {
        return index < sections.length - 1 ? (
          <ToggleBox
            key={section.heading}
            className={styles.toggleBox}
            fwdRef={(self: HTMLDivElement) => {
              if (self) {
                // Scroll into view if only one open
                if (
                  openBoxState[index] &&
                  openBoxState.filter((box) => box).length === 1
                ) {
                  self.scrollIntoView({ block: 'start', behavior: 'smooth' })
                }
              }
            }}
            heading={
              <Heading
                type={2}
                align="left"
                className={styles.toggleBoxHeading}
              >
                {section.heading}
              </Heading>
            }
            checked={toggleBoxState[index]}
            checkedLeft
            open={openBoxState[index]}
            onToggle={(state: boolean) => onToggle(index, state)}
          >
            <div className={styles.toggleInner}>
              {section.content}

              {index < sections.length - 1 && (
                <Button onPress={() => updateState(index, true)}>
                  {index === sections.length - 2 ? 'Got it' : 'Next lesson'}
                </Button>
              )}
            </div>
          </ToggleBox>
        ) : (
          <ToggleBox
            key={section.heading}
            className={styles.toggleBox}
            fwdRef={(self) => {
              if (self) {
                if (self) {
                  // Scroll into view if only one open
                  if (
                    openBoxState[index] &&
                    openBoxState.filter((box) => box).length === 1
                  ) {
                    self.scrollIntoView({ block: 'start', behavior: 'smooth' })
                  }
                }
              }
            }}
            heading={
              <Heading
                type={2}
                align="left"
                className={styles.toggleBoxHeading}
              >
                {section.heading}
              </Heading>
            }
            checked={quizComplete}
            checkedLeft
            open={openBoxState[index]}
            onToggle={(state: boolean) => {
              onToggle(index, state)
            }}
          >
            <div className={styles.toggleInner}>
              <Quiz isComplete={quizComplete} />
            </div>
          </ToggleBox>
        )
      })}
    </div>
  )
}
