import React from 'react'
import classNames from 'classnames'

import styles from '../styles/two-columns.module.scss'

interface InnerBoxProps {
  children?: React.ReactNode
  className?: string
  showDivider?: boolean
  noPadding?: boolean
  style?: React.CSSProperties
}

export function InnerBox({
  children,
  className,
  showDivider,
  noPadding,
  style,
}: InnerBoxProps) {
  return (
    <div
      className={classNames(className, styles.innerBox, {
        [styles.divider]: showDivider,
        [styles.noPadding]: noPadding,
      })}
      style={style}
    >
      {children}
    </div>
  )
}

interface Props {
  fwdRef?: (self: HTMLDivElement | null) => void
  children?: React.ReactNode
  panel?: boolean
  className?: string
  style?: React.CSSProperties
}

export function OuterBox({ fwdRef, children, panel, className, style }: Props) {
  return (
    <div
      ref={(self) => {
        if (fwdRef) fwdRef(self)
      }}
      className={classNames(className, styles.box, {
        [styles.panel]: panel,
      })}
      style={style}
    >
      {children}
    </div>
  )
}

export function Box({ children, panel, className }: Props) {
  return (
    <OuterBox className={className} panel={panel}>
      <InnerBox>{children}</InnerBox>
    </OuterBox>
  )
}

interface ColumnProps {
  main?: boolean
  side?: boolean
  fixed?: boolean
  equal?: boolean
  transparent?: boolean
  className?: string
  style?: any
  children?: React.ReactNode
}

export const Column = ({
  children,
  main,
  side,
  fixed,
  equal,
  transparent,
  className,
  style = null,
}: ColumnProps) => {
  return (
    <div
      style={style}
      className={classNames(className, styles.columnWrapper, {
        [styles.mainColumn]: main,
        [styles.sideColumn]: side,
        [styles.fixedColumn]: fixed,
        [styles.equalColumn]: equal,
        [styles.transparent]: transparent,
      })}
    >
      {children}
    </div>
  )
}

export default function TwoColumns({ children, className }: Props) {
  return <div className={classNames(className, styles.wrapper)}>{children}</div>
}
