import React, { Dispatch, SetStateAction } from 'react'
import classNames from 'classnames'
import moment from 'moment'

import { Heading } from './typography'
import Tooltip from './tooltip'
import { ParamColors } from '../api/types'
import styles from '../styles/track-edit-link-preview.module.scss'
import { GetCampaignCodeGeneratorQuery } from '../__gql-types__/graphql'

const today = new Date(Date.now())

function getExampleValue(
  type: string,
  dateFormat?: string | null,
  copyFrom?: boolean,
): string {
  if (type === 'date' && dateFormat) {
    return moment(today).format(dateFormat)
  }

  switch (type) {
    case 'select':
      return 'dropdown'
    case 'input':
      return copyFrom ? '(copied_from_other_parameters)' : 'free_text'
    default:
      return 'example'
  }
}

export interface LinkPreviewProps {
  masterPrefix?: string
  paramSeparator?: string
  paramsToShow: GetCampaignCodeGeneratorQuery['campaignCodeGenerator']['paramDefs']
  paramColors: ParamColors | null
  hoverItem: string | null
  setPrefixSeparatorOpen: Dispatch<SetStateAction<boolean>>
  showUplifterIDParam: boolean
}

export const LinkPreview = ({
  masterPrefix = '?',
  paramSeparator = '&',
  paramsToShow = [],
  paramColors = null,
  hoverItem = null,
  setPrefixSeparatorOpen,
  showUplifterIDParam,
}: LinkPreviewProps) => {
  return (
    <>
      <Heading type={2} align="left" className={styles.linkPreviewHeading}>
        <Tooltip
          id="link-preview-tooltip"
          useIcon
          tooltipPosition="right"
          tooltipMessage="This is what the full link will look like when a user creates a new one according to your parameters and rules."
        >
          Link preview
        </Tooltip>
      </Heading>
      <div className={styles.linkPreview}>
        <span
          className={classNames(styles.website, {
            [styles.toBack]: hoverItem !== null,
          })}
        >
          https://www.website.com/
        </span>
        <span
          className={classNames(styles.prefixSeparator, {
            [styles.toBack]: hoverItem !== null && hoverItem !== 'masterPrefix',
            [styles.previewHover]: hoverItem === 'masterPrefix',
          })}
          onClick={() => {
            setPrefixSeparatorOpen(true)
          }}
          role="button"
          tabIndex={0}
        >
          {masterPrefix}
        </span>
        {paramsToShow.map((param, index) => {
          return (
            <React.Fragment key={param.fieldID}>
              <span
                className={classNames({
                  [styles.toBack]:
                    hoverItem !== null && hoverItem !== param.fieldID,
                  [styles.previewHover]: hoverItem === param.fieldID,
                })}
                style={
                  paramColors
                    ? {
                        color: paramColors[param.fieldID],
                      }
                    : undefined
                }
              >
                {param.prefix}
              </span>
              <span
                className={classNames({
                  [styles.toBack]:
                    hoverItem !== null && hoverItem !== param.fieldID,
                  [styles.previewHover]: hoverItem === param.fieldID,
                })}
                style={
                  paramColors
                    ? {
                        color: paramColors[param.fieldID],
                      }
                    : undefined
                }
              >
                {param.fieldType === 'fixed'
                  ? param.fixedValue || '(fixed)'
                  : getExampleValue(
                      param.fieldType,
                      param.dateFormat,
                      !!param.copyFromField && param.copyFromField.length > 0,
                    )}
              </span>
              {index < paramsToShow.length - 1 && (
                <>
                  <span
                    className={classNames(styles.prefixSeparator, {
                      [styles.toBack]:
                        hoverItem !== null && hoverItem !== 'paramSeparator',
                      [styles.previewHover]: hoverItem === 'paramSeparator',
                    })}
                    onClick={() => setPrefixSeparatorOpen(true)}
                    role="button"
                    tabIndex={0}
                  >
                    {paramSeparator}
                  </span>
                </>
              )}
            </React.Fragment>
          )
        })}
        {showUplifterIDParam && (
          <>
            <span
              className={classNames({
                [styles.toBack]:
                  hoverItem !== null && hoverItem !== 'uplifterID',
                [styles.previewHover]: hoverItem === 'uplifterID',
              })}
              style={
                paramColors
                  ? {
                      color: paramColors.uplifterID,
                    }
                  : undefined
              }
            >
              &up_id=
            </span>
            <span
              className={classNames({
                [styles.toBack]:
                  hoverItem !== null && hoverItem !== 'uplifterID',
                [styles.previewHover]: hoverItem === 'uplifterID',
              })}
              style={
                paramColors
                  ? {
                      color: paramColors.uplifterID,
                    }
                  : undefined
              }
            >
              (unique)
            </span>
          </>
        )}
      </div>
    </>
  )
}
