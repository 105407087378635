import React, { useMemo, useEffect } from 'react'
import { LazyQueryExecFunction, useLazyQuery } from '@apollo/client'
import moment from 'moment'
import _ from 'lodash'

import { Label } from './input'
import Link from './link'
import { LoadingLabel } from './loader'
import { FieldSlot, FormRow, LabelSlot } from './row'
import SelectBox from './select-box'
import Tooltip from './tooltip'
import {
  getSalesforceEmailTemplates,
  getSavedPardotAcctList,
} from '../api/graphql/integrations-client'
import { htmlHasLinks } from '../helpers/track-module'
import styles from '../styles/email-code-generator-form.module.scss'
import {
  Exact,
  GetCampaignCodeGeneratorQuery,
  GetSalesforceEmailHtmlQuery,
  GetSalesforceEmailTemplatesQuery,
  InputMaybe,
  SalesforcePardotEmailTemplateInput,
} from '../__gql-types__/graphql'

type SalesforceEmailTemplate =
  | GetSalesforceEmailTemplatesQuery['track']['availableSalesforcePardotEmailTemplates']['classicBuilder'][0]
  | GetSalesforceEmailTemplatesQuery['track']['availableSalesforcePardotEmailTemplates']['lightningBuilder'][0]

export interface SalesforcePardotEmailDetails {
  emailSource: 'lightning' | 'classic'
  businessUnitId: string | null
  emailTemplate: SalesforceEmailTemplate | null
  emailHtml: string
  fetchedSavedBusinessUnit: boolean
  updateSuccess: boolean
}

type TemplateList = InputMaybe<
  SalesforcePardotEmailTemplateInput | SalesforcePardotEmailTemplateInput[]
>

export interface SalesforcePardotEmailFieldsProps {
  generatorData?: GetCampaignCodeGeneratorQuery
  emailDetails: SalesforcePardotEmailDetails
  setEmailDetails: React.Dispatch<React.SetStateAction<any>>
  fetchSFPardotEmailHTML: LazyQueryExecFunction<
    GetSalesforceEmailHtmlQuery,
    Exact<{
      classicPardotTemplateList?: TemplateList
      lightningPardotTemplateList?: TemplateList
    }>
  >
  errorFetchingSFPardotEmailHtml: boolean
}

const SalesforcePardotEmailFields = ({
  generatorData,
  emailDetails,
  setEmailDetails,
  fetchSFPardotEmailHTML,
  errorFetchingSFPardotEmailHtml,
}: SalesforcePardotEmailFieldsProps) => {
  const [
    getSFBusinessUnits,
    { data: sfBusinessUnitsData, loading: loadingSFBusinessUnits },
  ] = useLazyQuery(getSavedPardotAcctList)
  const [
    getSFEmailTemplates,
    { data: sfEmailTemplatesData, loading: loadingSFTemplates },
  ] = useLazyQuery(getSalesforceEmailTemplates)

  // Business units are only required for classic template
  const sfBusinessUnits = useMemo(() => {
    if (!sfBusinessUnitsData || emailDetails.emailSource !== 'classic')
      return []

    if (
      sfBusinessUnitsData.track.getSavedPardotAcctList.pardotList.length === 1
    ) {
      const {
        pardotID,
      } = sfBusinessUnitsData.track.getSavedPardotAcctList.pardotList[0]

      setEmailDetails({
        ...emailDetails,
        businessUnitId: pardotID,
      })

      getSFEmailTemplates({
        variables: {
          pardotID,
          templateLocation: emailDetails.emailSource,
        },
      })
    }

    return sfBusinessUnitsData.track.getSavedPardotAcctList.pardotList
  }, [sfBusinessUnitsData, emailDetails.emailSource])

  const sfEmailTemplates: {
    label?: string
    options: SalesforceEmailTemplate[]
  }[] = useMemo(() => {
    if (!sfEmailTemplatesData) return []

    if (emailDetails.emailSource === 'lightning') {
      const lightningTemplates =
        sfEmailTemplatesData.track.availableSalesforcePardotEmailTemplates
          .lightningBuilder

      return [
        {
          options: _.cloneDeep(lightningTemplates).sort((a, b) => {
            return moment(b.dateUpdated).diff(moment(a.dateUpdated))
          }),
        },
      ]
    }

    const classicTemplates = _.cloneDeep(
      sfEmailTemplatesData.track.availableSalesforcePardotEmailTemplates
        .classicBuilder,
    )

    const fullOrderedList = classicTemplates.sort((a, b) => {
      return moment(b.dateUpdated).diff(moment(a.dateUpdated))
    })

    return [
      {
        label: 'List emails',
        options: fullOrderedList.filter(
          (template) => template.isClassicListEmail,
        ),
      },
      {
        label: 'Email templates',
        options: fullOrderedList.filter(
          (template) => !template.isClassicListEmail,
        ),
      },
    ]
  }, [sfEmailTemplatesData, emailDetails.emailSource])

  // Check if a default business unit has been set
  // For Salesforce Pardot classic builder
  useEffect(() => {
    if (
      !generatorData ||
      !generatorData.campaignCodeGenerator.defaultPardotBusinessUnit ||
      emailDetails.fetchedSavedBusinessUnit
    )
      return

    setEmailDetails({
      ...emailDetails,
      businessUnitId:
        generatorData.campaignCodeGenerator.defaultPardotBusinessUnit,
      fetchedSavedBusinessUnit: true,
    })

    getSFEmailTemplates({
      variables: {
        pardotID: generatorData.campaignCodeGenerator.defaultPardotBusinessUnit,
        templateLocation: emailDetails.emailSource,
      },
    })
  }, [generatorData, emailDetails])

  // If Company has Salesforce integration, get Business units
  // But this only needs to be fetched for the classic templates
  useEffect(() => {
    if (emailDetails.emailSource === 'classic' && !sfBusinessUnitsData) {
      getSFBusinessUnits()
    } else if (
      emailDetails.emailSource === 'lightning' &&
      sfEmailTemplates.every((group) => group.options.length === 0)
    ) {
      getSFEmailTemplates({
        variables: {
          pardotID: undefined,
          templateLocation: emailDetails.emailSource,
        },
      })
    }
  }, [sfBusinessUnitsData, emailDetails, sfEmailTemplates])

  return (
    <>
      {emailDetails.emailSource === 'classic' && sfBusinessUnits.length > 1 && (
        <FormRow>
          <LabelSlot className={styles.labelSlot}>
            <Label id="salesforceBusinessUnit">
              <Tooltip
                id="salesforce-business-unit-tooltip"
                useIcon
                tooltipPosition="right"
                tooltipMessage={
                  <>
                    <p>
                      The name of the Salesforce business unit that has the
                      email template you want to find and replace links in.
                    </p>
                  </>
                }
              >
                Salesforce business unit
              </Tooltip>
            </Label>
          </LabelSlot>
          <FieldSlot>
            <SelectBox
              id="salesforceBusinessUnit"
              isClearable
              labelKey="pardotName"
              valueKey="pardotID"
              placeholder="Select business unit or start typing"
              isLoading={loadingSFBusinessUnits}
              loadingMessage={() => (
                <LoadingLabel label="Checking integrations" />
              )}
              value={sfBusinessUnits.find(
                (option) => option.pardotID === emailDetails.businessUnitId,
              )}
              options={sfBusinessUnits}
              onChange={async (newValue) => {
                setEmailDetails({
                  ...emailDetails,
                  businessUnitId: newValue?.pardotID || '',
                  emailTemplate: null,
                  emailHtml: '',
                  updateSuccess: false,
                })

                if (newValue) {
                  getSFEmailTemplates({
                    variables: {
                      pardotID: newValue.pardotID,
                      templateLocation: emailDetails.emailSource,
                    },
                  })
                }
              }}
            />
          </FieldSlot>
        </FormRow>
      )}
      <FormRow>
        <LabelSlot className={styles.labelSlot}>
          <Label id="salesforceEmailTemplate">
            <Tooltip
              id="salesforce-email-template-tooltip"
              useIcon
              tooltipPosition="right"
              tooltipMessage={
                <>
                  <p>The name of the email in Salesforce.</p>
                  {emailDetails.emailTemplate && (
                    <p>
                      <strong>Your Salesforce Account:</strong>{' '}
                      {emailDetails.emailTemplate.pardotName} (
                      {emailDetails.emailTemplate.pardotID})
                    </p>
                  )}
                </>
              }
            >
              Email template
            </Tooltip>
          </Label>
        </LabelSlot>
        <FieldSlot>
          <SelectBox
            id="salesforceEmailTemplate"
            isClearable
            labelKey="emailName"
            valueKey="emailID"
            placeholder="Select email or start typing"
            isDisabled={
              !loadingSFTemplates &&
              sfEmailTemplates.every((group) => group.options.length === 0)
            }
            isLoading={loadingSFTemplates}
            loadingMessage={() => <LoadingLabel label="Fetching templates" />}
            error={
              !!emailDetails.emailTemplate && !!errorFetchingSFPardotEmailHtml
            }
            aria-errormessage="email-template-error"
            value={emailDetails.emailTemplate}
            options={sfEmailTemplates}
            onChange={async (newValue) => {
              setEmailDetails({
                ...emailDetails,
                emailHtml: '',
                updateSuccess: false,
                emailTemplate: newValue,
              })

              if (!newValue) return

              const {
                pardotID,
                pardotName,
                emailID,
                uiType,
                isClassicListEmail,
              } = newValue

              const { data: htmlData } = await fetchSFPardotEmailHTML({
                variables: {
                  [`${emailDetails.emailSource}PardotTemplateList`]: [
                    {
                      pardotID,
                      pardotName,
                      emailID,
                      uiType,
                      isClassicListEmail,
                    },
                  ],
                },
              })

              if (
                !htmlData ||
                !htmlData.track.salesforcePardotEmailHTML[
                  `${emailDetails.emailSource}Builder`
                ][0].emailHTML
              )
                return

              setEmailDetails({
                ...emailDetails,
                emailTemplate: newValue,
                emailHtml: htmlData.track.salesforcePardotEmailHTML[
                  `${emailDetails.emailSource}Builder`
                ][0].emailHTML as string,
              })
            }}
          />
          {!errorFetchingSFPardotEmailHtml &&
            !loadingSFTemplates &&
            emailDetails.emailSource === 'classic' &&
            emailDetails.businessUnitId &&
            sfEmailTemplates.every((group) => group.options.length === 0) && (
              <p className={styles.footNoteError} id="email-template-error">
                No email templates found for the selected business unit.
              </p>
            )}
          {!!emailDetails.emailTemplate && !!errorFetchingSFPardotEmailHtml && (
            <p className={styles.footNoteError} id="email-template-error">
              Error fetching email HTML. Please reload the page or contact
              support (
              <Link href="mailto:support@uplifter.ai">support@uplifter.ai</Link>
              ).
            </p>
          )}
          {!errorFetchingSFPardotEmailHtml &&
            emailDetails.emailHtml &&
            !htmlHasLinks(emailDetails.emailHtml) && (
              <p className={styles.footNoteError} id="email-template-error">
                No links found in this email template.
              </p>
            )}
        </FieldSlot>
      </FormRow>
    </>
  )
}

export default SalesforcePardotEmailFields
