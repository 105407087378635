import React, { useState } from 'react'
import { useMutation, useReactiveVar } from '@apollo/client'
import moment from 'moment'
import classNames from 'classnames'

import Button from './button'
import StyledDatePicker from './date-picker'
import Input, { Label } from './input'
import { Preloader } from './loader'
import Modal from './modal'
import MultiList from './multi-list'
import { FieldSlot, FormRow, LabelSlot } from './row'
import { SelectBoxSimple } from './select-box'
import ToggleBox from './toggle-box'
import { Heading, NoteText } from './typography'
import { currentUserDetails } from '../api/apollo/variables'
import {
  addSupportUser,
  createEnterpriseCompany,
} from '../api/graphql/support-client'
import { messages } from '../core/constants'
import { isNonWhitelabelSupportUser, isSupportUser } from '../helpers'
import { validateEmail } from '../helpers/forms'
import useLogAction from '../hooks/useLogAction'
import styles from '../styles/support-settings.module.scss'

export const defaultEnterpriseFields: EnterpriseBillingDetails = {
  whitelabel: '',
  companyName: '',
  businessDivision: '',
  autoRenew: true,
  sponsorList: [],
  accountManagerList: [],
  contractStartDate: '',
  contractEndDate: '',
  revenuePerMonth: 0,
  totalContractValue: 0,
  breakClauseDate: null,
  domainLimit: 999,
  userLimit: 0,
  domains: '',
  legalEntityName: '',
  customTandC: false,
  companyNotes: '',
  dataSourceType: 'GA_VIEW',
  trackAvailable: true,
  auditAvailable: true,
  reportAvailable: true,
  explainAvailable: false,
}

const formShape = {
  whitelabel: {
    title: 'Whitelabel domain',
    type: 'select',
    // These options are hardcoded for now
    options: [
      {
        name: 'None (Regular Uplifter client)',
        value: 'support',
      },
      {
        name: 'Uptimal',
        value: 'uptimal',
      },
    ],
  },
  companyName: {
    title: 'Client',
    type: 'input',
  },
  businessDivision: {
    optional: true,
    title: 'Business unit',
    type: 'input',
  },
  sponsorList: {
    title: 'Sponsors',
    type: 'multi-list',
    placeholder: 'User name and surname',
    placeholderTwo: 'User email',
  },
  accountManagerList: {
    title: 'Account manager(s)',
    type: 'multi-list',
    placeholder: 'Add account manager',
  },
  legalEntityName: {
    title: 'Clients legal name',
    type: 'input',
  },
  contractStartDate: {
    title: 'Contract start date',
    type: 'date',
  },
  breakClauseDate: {
    optional: true,
    title: 'Break clause date',
    type: 'date',
  },
  contractEndDate: {
    title: 'Contract end date',
    type: 'date',
  },
  totalContractValue: {
    title: 'Total contract revenue',
    type: 'currency',
  },
  revenuePerMonth: {
    title: 'Monthly revenue',
    type: 'currency',
  },
  customTandC: {
    title: 'Custom Terms & Conditions?',
    type: 'toggle',
    label: ' ',
  },
  autoRenew: {
    title: 'Automatic contract renewal?',
    label: ' ',
    type: 'toggle',
  },
  domains: {
    optional: true,
    title: 'Website domains',
    type: 'textarea',
  },
  domainLimit: {
    title: 'Paid domain limit',
    type: 'number',
  },
  userLimit: {
    title: 'Paid users limit',
    type: 'number',
  },
  dataSourceType: {
    title: 'Data source',
    type: 'select',
    options: [
      {
        name: 'Google Analytics',
        value: 'GA_VIEW',
      },
      {
        name: 'Adobe Analytics',
        value: 'AA_REPORT',
      },
    ],
  },
  trackModules: {
    type: 'group',
    title: 'Paid modules',
    group: {
      trackAvailable: {
        title: 'Track',
        label: 'Module enabled',
        type: 'toggle',
      },
      reportAvailable: {
        title: 'Report',
        label: 'Module enabled',
        type: 'toggle',
      },
    },
  },
  companyNotes: {
    optional: true,
    title: 'Notes',
    type: 'textarea',
  },
}

interface EnterpriseOrgFormProps {
  modal?: boolean
  fields: EnterpriseBillingDetails
  onSave?: (fields: any) => void
  loading?: boolean
  readOnly?: boolean
  modalActive?: boolean
  setModalActive?: React.Dispatch<React.SetStateAction<boolean>>
  includeWhitelabelField?: boolean
}

export const EnterpriseOrgForm = ({
  fields: intialFields,
  onSave,
  modal,
  loading,
  readOnly = true,
  modalActive,
  setModalActive,
  includeWhitelabelField,
}: EnterpriseOrgFormProps) => {
  const [fields, setFields] = useState(intialFields)
  const [updated, setUpdated] = useState(false)

  const currencySign = '£'
  const updateFields = (key, value) => {
    setUpdated(true)
    setFields({ ...fields, [key]: value })
  }

  if (loading) {
    return (
      <Preloader
        style={{
          width: 90,
          height: 60,
          marginTop: 50,
          marginBottom: 50,
        }}
      />
    )
  }

  const getForm = () => {
    return (
      <>
        {Object.keys(formShape).map((key) => {
          const shapeItem = formShape[key]
          const { optional, type } = shapeItem

          const value = fields[key]

          if (key === 'whitelabel' && !includeWhitelabelField) {
            return null
          }

          switch (shapeItem.type) {
            case 'textarea':
              return (
                <FormRow key={key} wideFields>
                  <LabelSlot topAlign>
                    <Label
                      id={key}
                      optional={optional ? '(if applicable)' : ''}
                    >
                      {shapeItem.title}
                    </Label>
                  </LabelSlot>
                  <FieldSlot>
                    <Input
                      readOnly={readOnly}
                      required={!shapeItem.optional}
                      id={key}
                      name={key}
                      type="textArea"
                      onChange={(val: string) => {
                        updateFields(key, val)
                      }}
                      placeholder="Click here and start typing..."
                      value={value}
                    />
                  </FieldSlot>
                </FormRow>
              )
            case 'toggle':
            case 'group':
              return (
                <FormRow key={key} wideFields>
                  <LabelSlot topAlign>
                    <Label
                      id={key}
                      optional={optional ? '(if applicable)' : ''}
                    >
                      {shapeItem.title}
                    </Label>
                  </LabelSlot>
                  <FieldSlot>
                    {type === 'group' ? (
                      <span className={styles.groupFields}>
                        {Object.keys(shapeItem.group).map((groupKey) => {
                          const groupShapeItem = shapeItem.group[groupKey]
                          return (
                            <span key={groupKey} className={styles.row}>
                              <Input
                                readOnly={readOnly}
                                type="checkbox"
                                id={groupKey}
                                name={groupKey}
                                label={groupShapeItem.title}
                                checked={fields[groupKey]}
                                onChange={(
                                  e: React.ChangeEvent<HTMLInputElement>,
                                ) => {
                                  const {
                                    checked,
                                  } = e.target as HTMLInputElement
                                  updateFields(groupKey, checked)
                                }}
                              />
                            </span>
                          )
                        })}
                      </span>
                    ) : (
                      <Input
                        readOnly={readOnly}
                        type="checkbox"
                        id={key}
                        name={key}
                        label={shapeItem.label}
                        checked={value}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const { checked } = e.target as HTMLInputElement
                          updateFields(key, checked)
                        }}
                      />
                    )}
                  </FieldSlot>
                </FormRow>
              )
            case 'currency':
              return (
                <FormRow key={key} wideFields>
                  <LabelSlot topAlign>
                    <Label
                      id={key}
                      optional={optional ? '(if applicable)' : ''}
                    >
                      {shapeItem.title} ({currencySign})
                    </Label>
                  </LabelSlot>
                  <FieldSlot>
                    <Input
                      readOnly={readOnly}
                      key={key}
                      required={!shapeItem.optional}
                      onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        const { value: val } = e.target as HTMLInputElement
                        const parsedVal = val ? parseInt(val, 10) : 0
                        updateFields(
                          key,
                          !Number.isNaN(parsedVal) ? parsedVal : 0,
                        )
                      }}
                      label={shapeItem.title}
                      type="text"
                      name={key}
                      id={key}
                      value={value}
                    />
                  </FieldSlot>
                </FormRow>
              )
            case 'number':
            case 'input':
              return (
                <FormRow key={key} wideFields>
                  <LabelSlot topAlign>
                    <Label
                      id={key}
                      optional={optional ? '(if applicable)' : ''}
                    >
                      <span>{shapeItem.title}</span>
                    </Label>
                  </LabelSlot>
                  <FieldSlot>
                    <Input
                      readOnly={readOnly}
                      required={!shapeItem.optional}
                      autoComplete="off"
                      key={key}
                      onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        const { value: val } = e.target as HTMLInputElement

                        if (shapeItem.type === 'number') {
                          const parsedVal = val ? parseInt(val, 10) : 0
                          updateFields(
                            key,
                            !Number.isNaN(parsedVal) ? parsedVal : 0,
                          )
                        } else {
                          updateFields(key, val)
                        }
                      }}
                      label={shapeItem.title}
                      type="text"
                      name={key}
                      id={key}
                      value={value}
                    />
                  </FieldSlot>
                </FormRow>
              )
            case 'date': {
              let dateValue: null | Date = null
              if (value !== null && value !== '') {
                const dateFormatted = moment(value, 'YYYYMMDD').format(
                  'YYYY-MM-DD',
                )
                dateValue = new Date(Date.parse(dateFormatted))
                // check if date invalid
                if (Number.isNaN(dateValue.getTime())) {
                  dateValue = null
                }
              }
              return (
                <FormRow key={key} wideFields>
                  <LabelSlot topAlign>
                    <Label
                      id={key}
                      optional={optional ? '(if applicable)' : ''}
                    >
                      {shapeItem.title}
                    </Label>
                  </LabelSlot>
                  <FieldSlot>
                    <StyledDatePicker
                      disabled={readOnly}
                      dateFormat="dd/MM/y"
                      placeholderText="dd/MM/y"
                      key={key}
                      className="date-picker"
                      selected={dateValue}
                      onChange={(date) => {
                        if (date !== null) {
                          const dateF = moment(date.toString()).format(
                            'YYYYMMDD',
                          )
                          updateFields(key, dateF)
                        } else {
                          updateFields(key, date)
                        }
                      }}
                    />
                  </FieldSlot>
                </FormRow>
              )
            }
            case 'multi-list': {
              return (
                <FormRow key={key} wideFields>
                  <LabelSlot topAlign>
                    <Label
                      id={key}
                      optional={optional ? '(if applicable)' : ''}
                    >
                      {shapeItem.title}
                    </Label>
                  </LabelSlot>
                  <FieldSlot>
                    <MultiList
                      readOnly={readOnly}
                      id={key}
                      ItemOneLabel="Full Name"
                      ItemTwoLabel="Email"
                      className={styles.table}
                      list={value}
                      onChange={(val) => {
                        updateFields(key, val)
                      }}
                    />
                  </FieldSlot>
                </FormRow>
              )
            }
            case 'select': {
              return (
                <FormRow key={key} wideFields>
                  <LabelSlot topAlign>
                    <Label
                      id={key}
                      optional={optional ? '(if applicable)' : ''}
                    >
                      {shapeItem.title}
                    </Label>
                  </LabelSlot>
                  <FieldSlot>
                    <SelectBoxSimple
                      disabled={readOnly}
                      name={key}
                      key={key}
                      value={value}
                      onChange={(val) => {
                        updateFields(key, val)
                      }}
                    >
                      {shapeItem.options.map((i) => (
                        <option key={`${key}${i.value}`} value={i.value}>
                          {i.name}
                        </option>
                      ))}
                    </SelectBoxSimple>
                  </FieldSlot>
                </FormRow>
              )
            }
            default:
              return null
          }
        })}
      </>
    )
  }

  if (modal && modalActive && setModalActive) {
    return (
      <Modal
        setIsOpen={setModalActive}
        width="wide"
        modalHeader={
          <>
            <h3>Create a new enterprise client</h3>
            <p>
              This form should only be used when a new contract has been signed.
            </p>
          </>
        }
        footerContent={
          <Button form="newAccountForm">Create a new client</Button>
        }
      >
        <form
          id="newAccountForm"
          autoComplete="off"
          onSubmit={(e) => {
            e.preventDefault()
            if (onSave) {
              onSave(fields)
            }
          }}
        >
          {getForm()}
        </form>
      </Modal>
    )
  }

  return (
    <>
      <form
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault()
          if (onSave) {
            onSave(fields)
          }
        }}
      >
        {getForm()}
        <FormRow wideFields>
          <LabelSlot />
          <FieldSlot>
            <Button isDisabled={!updated} type="submit">
              Save
            </Button>
          </FieldSlot>
        </FormRow>
      </form>
    </>
  )
}

interface AddSupportUserFormState {
  email: string
  permissionLevel: 'support' | 'whitelabelSupport'
  whitelabelDomain: string | null
  loading: boolean
  success: string
  error: string
}

const SupportSettings = () => {
  const { userPermission, whiteLabelAdminDomain } = useReactiveVar(
    currentUserDetails,
  )

  const logAction = useLogAction()

  const [addAdminUser] = useMutation(addSupportUser)
  const [addEnterpriseAccount] = useMutation(createEnterpriseCompany)

  const [openSection, setOpenSection] = useState('')
  const [addSupportUserFormState, setAddSupportUserFormState] = useState<
    AddSupportUserFormState
  >({
    email: '',
    permissionLevel: isNonWhitelabelSupportUser(userPermission)
      ? 'support'
      : 'whitelabelSupport',
    whitelabelDomain: whiteLabelAdminDomain || null,
    loading: false,
    success: '',
    error: '',
  })
  const [createCompanyModalActive, setCreateCompanyModalActive] = useState(
    false,
  )

  const handleSubmit = async () => {
    try {
      const {
        email,
        permissionLevel,
        whitelabelDomain,
      } = addSupportUserFormState

      setAddSupportUserFormState((curr) => ({
        ...curr,
        loading: true,
        success: '',
        error: '',
      }))

      if (!email || !validateEmail(email)) {
        setAddSupportUserFormState((curr) => ({
          ...curr,
          loading: false,
          error: messages.notValidEmail,
        }))
        return
      }

      await addAdminUser({
        variables: {
          email,
          whitelabel: whitelabelDomain || undefined,
          permissionLevel,
        },
      })

      logAction({
        variables: {
          action: 'add-support-user',
          websiteSection: 'settings',
          pagePath: '/settings',
          functionName: 'addSupportUser',
          extra: JSON.stringify({
            email,
            permissionLevel,
            whiteLabelAdminDomain,
          }),
        },
      })

      setAddSupportUserFormState((curr) => ({
        ...curr,
        email: '',
        success: `${email} has been added as a support user${
          whitelabelDomain ? ` to the ${whitelabelDomain} domain.` : '.'
        }`,
        loading: false,
      }))
    } catch {
      setAddSupportUserFormState((curr) => ({
        ...curr,
        error: 'Unable to add - please try again or contact support.',
      }))
    }
  }

  if (!userPermission || !isSupportUser(userPermission)) {
    return null
  }

  return (
    <>
      <div className={styles.container}>
        <ToggleBox
          noPadding
          className={styles.settingsToggleBox}
          heading={
            <div className={styles.settingHeader}>
              <div className={styles.emoji}>
                <span role="img" aria-label="Billing">
                  🏢
                </span>
              </div>
              <div className={styles.headerText}>
                <Heading
                  type={2}
                  align="left"
                  className={styles.settingsToggleHeader}
                >
                  Create an enterprise client
                </Heading>
                <span>
                  Create a new paying enterprise client with their own
                  workspaces and users.
                </span>
              </div>
            </div>
          }
          open={openSection === 'new-enterprise-client'}
          onToggle={() => {
            if (openSection === 'new-enterprise-client') {
              setOpenSection('')
            } else {
              setOpenSection('new-enterprise-client')

              logAction({
                variables: {
                  action: 'open-settings-section',
                  extra: '{"sectionOpened": "new-enterprise-client"}',
                  websiteSection: 'Settings',
                  pagePath: '/settings',
                  functionName: 'toggleSection',
                },
              })
            }
          }}
        >
          <div className={styles.settingsToggleBoxInner}>
            <Button
              variant="secondary"
              onPress={() =>
                setCreateCompanyModalActive(!createCompanyModalActive)
              }
            >
              Create a new enterprise client
            </Button>
          </div>
        </ToggleBox>

        <ToggleBox
          noPadding
          className={styles.settingsToggleBox}
          heading={
            <div className={styles.settingHeader}>
              <div className={styles.emoji}>
                <span role="img" aria-label="Users">
                  👥
                </span>
              </div>
              <div className={styles.headerText}>
                <Heading
                  type={2}
                  align="left"
                  className={styles.settingsToggleHeader}
                >
                  Add super admin user
                </Heading>
                <span>
                  Create a user with access to every organisation and workspace
                  on this white label domain.
                </span>
              </div>
            </div>
          }
          open={openSection === 'new-support-user'}
          onToggle={() => {
            if (openSection === 'new-support-user') {
              setOpenSection('')
            } else {
              setOpenSection('new-support-user')

              logAction({
                variables: {
                  action: 'open-settings-section',
                  extra: '{"sectionOpened": "new-support-user"}',
                  websiteSection: 'Settings',
                  pagePath: '/settings',
                  functionName: 'toggleSection',
                },
              })
            }
          }}
        >
          <div className={styles.settingsToggleBoxInner}>
            <form
              className={styles.form}
              onSubmit={(e) => {
                e.preventDefault()
                handleSubmit()
              }}
            >
              <FormRow last className={styles.formRow}>
                <LabelSlot noPadding column>
                  <Label id="addEmail" small>
                    Email
                  </Label>
                </LabelSlot>
                <FieldSlot noPadding column>
                  <Input
                    name="addEmail"
                    type="text"
                    label="Email"
                    required
                    value={addSupportUserFormState.email}
                    onValueChange={(nextEmail) =>
                      setAddSupportUserFormState((curr) => ({
                        ...curr,
                        email: nextEmail,
                      }))
                    }
                  />
                </FieldSlot>
              </FormRow>
              {isNonWhitelabelSupportUser(userPermission) && (
                <FormRow last className={styles.formRow}>
                  <LabelSlot noPadding column>
                    <Label id="permission" small>
                      Whitelabel domain
                    </Label>
                  </LabelSlot>
                  <FieldSlot noPadding column>
                    <SelectBoxSimple
                      name="permission"
                      value={
                        addSupportUserFormState.whitelabelDomain || 'support'
                      }
                      onChange={(domain) => {
                        setAddSupportUserFormState((curr) => ({
                          ...curr,
                          permissionLevel:
                            domain === 'support'
                              ? 'support'
                              : 'whitelabelSupport',
                          whitelabelDomain:
                            domain === 'support' ? null : domain,
                        }))
                      }}
                    >
                      {/* Hardcoded options */}
                      <option value="support">Uplifter</option>
                      <option value="uptimal">Uptimal</option>
                    </SelectBoxSimple>
                  </FieldSlot>
                </FormRow>
              )}
              <FormRow
                className={classNames(styles.formRow, styles.formRowButton)}
              >
                <FieldSlot noPadding column>
                  <Button
                    loading={addSupportUserFormState.loading}
                    isDisabled={
                      addSupportUserFormState.email === '' ||
                      addSupportUserFormState.loading
                    }
                    type="submit"
                  >
                    Add super admin user
                  </Button>
                </FieldSlot>
              </FormRow>
            </form>
            {!addSupportUserFormState.whitelabelDomain && (
              <NoteText label="Warning">
                The user will have full access to all organisations on Uplifter.
              </NoteText>
            )}
            {addSupportUserFormState.success && (
              <p className={styles.success}>
                {addSupportUserFormState.success}
              </p>
            )}
            {addSupportUserFormState.error && (
              <p className={styles.error}>{addSupportUserFormState.error}</p>
            )}
          </div>
        </ToggleBox>
      </div>
      {createCompanyModalActive && (
        <EnterpriseOrgForm
          readOnly={false}
          modal
          fields={defaultEnterpriseFields}
          includeWhitelabelField={isNonWhitelabelSupportUser(userPermission)}
          onSave={async (newData: EnterpriseBillingDetails) => {
            await addEnterpriseAccount({
              variables: {
                ...newData,
                // Whitelabel support users can only create new orgs on their whitelabel domain
                whitelabel: isNonWhitelabelSupportUser(userPermission)
                  ? newData.whitelabel || undefined
                  : whiteLabelAdminDomain,
                revenuePerMonthPreBreakClause: 0,
                revenuePerMonthPostBreakClause: 0,
              },
            })

            setCreateCompanyModalActive(!createCompanyModalActive)
          }}
          modalActive={createCompanyModalActive}
          setModalActive={setCreateCompanyModalActive}
        />
      )}
    </>
  )
}

export default SupportSettings
