import React, { useMemo, useState } from 'react'
import { nanoid } from 'nanoid'

import { FieldSlot, Table, Body, Head, HeaderCell, Cell, BodyItem } from './row'

import Input, { ClickEditInput } from './input'
import styles from '../styles/multi-list.module.scss'
import Button from './button'
import DeleteButtonWithConfirmation from './delete-button-with-confirmation'

export interface AddNewValueProps {
  onChange: (newValue: { name: string; email: string }) => void
  ItemOneLabel: string
  ItemTwoLabel: string
}

export function AddNewValue({
  onChange,
  ItemOneLabel,
  ItemTwoLabel,
}: AddNewValueProps): React.ReactElement {
  const [newValue, setNewValue] = useState({ name: '', email: '' })
  const id = useMemo(() => {
    return nanoid()
  }, [])

  return (
    <BodyItem className={styles.addNewField}>
      <Cell width={290} className={styles.fieldCell}>
        <FieldSlot column className={styles.fieldSlot}>
          <Input
            id={`${id}-id-one`}
            name={`${id}-name-one`}
            placeholder={ItemOneLabel}
            value={newValue[0]}
            autoComplete="off"
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
              const { value: val } = e.target as HTMLInputElement
              setNewValue({ ...newValue, name: val })
            }}
          />
        </FieldSlot>
      </Cell>
      <Cell width={290} className={styles.fieldCell}>
        <FieldSlot column className={styles.fieldSlot}>
          <Input
            id={`${id}-id-two`}
            name={`${id}-name-two`}
            value={newValue[1]}
            autoComplete="off"
            placeholder={ItemTwoLabel}
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
              const { value: val } = e.target as HTMLInputElement
              setNewValue({ ...newValue, email: val })
            }}
          />
        </FieldSlot>
      </Cell>
      <Cell width={78}>
        <Button
          isDisabled={!(newValue[0] !== '' && newValue[1] !== '')}
          color="blue"
          onPress={() => onChange(newValue)}
        >
          Add
        </Button>
      </Cell>
    </BodyItem>
  )
}

interface MultiListProps {
  id: string
  hideHeader?: boolean
  className?: string
  list: any
  ItemOneLabel: string
  ItemTwoLabel: string
  onChange: (val: { name: string; email: string }[]) => void
  readOnly?: boolean
}

export default function MultiList({
  id,
  className = '',
  list = [],
  ItemOneLabel,
  ItemTwoLabel,
  onChange,
  hideHeader,
  readOnly = false,
}: MultiListProps): React.ReactElement {
  return (
    <Table className={className}>
      {!hideHeader && (
        <Head>
          <HeaderCell width={290}>{ItemOneLabel}</HeaderCell>
          <HeaderCell width={290}>{ItemTwoLabel}</HeaderCell>
          <HeaderCell width={78} />
        </Head>
      )}
      <Body>
        {list.length === 0 && readOnly && (
          <BodyItem>
            <Cell width={290 + 290 + 78}>No data</Cell>
          </BodyItem>
        )}
        {list.map(
          (value, index: number): React.ReactElement => (
            <BodyItem key={`${id} ${value.name} ${value.email}`}>
              <Cell width={290}>
                {readOnly ? (
                  <Input
                    readOnly
                    id={`${id} ${value.name} one`}
                    name={`${id} ${value.name} one`}
                    value={value.name}
                  />
                ) : (
                  <ClickEditInput
                    id={`${id} ${value.name} one`}
                    name={`${id} ${value.name} one`}
                    value={value.name}
                    onChange={(newValue: string): void => {
                      // eslint-disable-next-line react/prop-types
                      const val = list.concat()
                      val[index].name = newValue
                      onChange(val)
                    }}
                  />
                )}
              </Cell>
              <Cell width={290}>
                {readOnly ? (
                  <Input
                    readOnly
                    id={`${id} ${value.email} two`}
                    name={`${id} ${value.email} two`}
                    value={value.email}
                  />
                ) : (
                  <ClickEditInput
                    id={`${id} ${value.email} two`}
                    name={`${id} ${value.email} two`}
                    value={value.email}
                    onChange={(newValue: string): void => {
                      // eslint-disable-next-line react/prop-types
                      const val = list.concat()
                      val[index].email = newValue
                      onChange(val)
                    }}
                  />
                )}
              </Cell>
              <Cell width={78}>
                {!readOnly && (
                  <DeleteButtonWithConfirmation
                    odd={!(index % 2)}
                    onClick={(): void => {
                      // eslint-disable-next-line react/prop-types
                      const val = list.concat()
                      val.splice(index, 1)
                      onChange(val)
                    }}
                  >
                    <p>Remove this?</p>
                  </DeleteButtonWithConfirmation>
                )}
              </Cell>
            </BodyItem>
          ),
        )}
        {!readOnly && (
          <AddNewValue
            key={nanoid()}
            ItemOneLabel={ItemOneLabel}
            ItemTwoLabel={ItemTwoLabel}
            onChange={(newValue) => {
              // eslint-disable-next-line react/prop-types
              const val = list.concat()
              val.push(newValue)
              onChange(val)
            }}
          />
        )}
      </Body>
    </Table>
  )
}
