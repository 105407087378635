import React from 'react'
import { useReactiveVar } from '@apollo/client'

import { loggedInState } from '../api/apollo/variables'
import NoDataMessage from '../components/no-data-message'
import Layout from '../components/layout'
import SiteWrapper from '../components/site-wrapper'
import styles from '../styles/not-found.module.scss'

export default function NotFound(): React.ReactElement {
  const { authenticated, checked } = useReactiveVar(loggedInState)

  return (
    <SiteWrapper justLogo={!(authenticated && checked)}>
      <Layout width={1200} className={styles.layout}>
        <NoDataMessage errorMsg="404 Page not found." />
      </Layout>
    </SiteWrapper>
  )
}
