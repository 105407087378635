import React, { useMemo, useState } from 'react'
import { useMutation, useReactiveVar } from '@apollo/client'

import Button from './button'
import Input, { Label } from './input'
import Link from './link'
import Modal from './modal'
import { Heading } from './typography'
import { currentUserDetails } from '../api/apollo/variables'
import {
  cancelMicrosoftMarketplaceSubscription,
  changeMicrosoftMarketplacePlan,
  deleteCompany,
} from '../api/graphql/company-client'
import { sendFeedback } from '../api/graphql/onboarding-client'
import { logout } from '../api/REST/auth-client'
import {
  calendarBookingLink,
  deleteWord,
  supportEmail,
} from '../core/constants'
import useLogAction from '../hooks/useLogAction'
import useSubscriptionLevel from '../hooks/useSubscriptionLevel'
import {
  teamTierMaxUsers,
  teamTierMaxWorkspaces,
} from '../static-copy/subscription-features'

interface DowngradeDeleteBlockerModal {
  type: 'downgrade' | 'downgradeTeam' | 'delete' | null
  onHideModal: React.Dispatch<React.SetStateAction<boolean>>
  currentUserCount: number
  currentWorkspaceCount: number
}

export function DowngradeDeleteBlockerModal({
  type = 'downgrade',
  onHideModal,
  currentUserCount,
  currentWorkspaceCount,
}: DowngradeDeleteBlockerModal) {
  const { companyID } = useReactiveVar(currentUserDetails)

  const {
    isFree,
    isTeam,
    isPaddle,
    isMicrosoftMarketplace,
    isEnterprise,
    paddleData,
    microsoftSubscriptionData,
  } = useSubscriptionLevel()

  const logAction = useLogAction()

  const [sendFeedbackMutation] = useMutation(sendFeedback)
  const [permanentlyDeleteCompany, { error }] = useMutation(deleteCompany)
  const [cancelMicrosoftSubscription] = useMutation(
    cancelMicrosoftMarketplaceSubscription,
  )
  const [downgradeMicrosoftPlan] = useMutation(changeMicrosoftMarketplacePlan)

  const [feedbackInput, setFeedbackInput] = useState('')
  const [downgradeLoading, setDowngradeLoading] = useState(false)
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false)
  const [deletingAccount, setDeletingAccount] = useState(false)
  const [inputValue, setInputValue] = useState('')

  const { paddleCancelUrl, paddleSubscriptionId } = useMemo(() => {
    if (!paddleData)
      return {
        paddleCancelUrl: null,
        paddleSubscriptionId: null,
      }

    return paddleData.currentCompany
  }, [paddleData])

  // Trying to downgrade a non-Paddle account
  // Or trying to delete an enterprise account
  if (
    (isEnterprise && !isMicrosoftMarketplace) ||
    (type === 'downgrade' && !isTeam) ||
    (type === 'downgradeTeam' && !isEnterprise)
  )
    return null

  if (
    (isTeam && (currentUserCount > 1 || currentWorkspaceCount > 1)) ||
    (isEnterprise &&
      (currentWorkspaceCount > teamTierMaxWorkspaces ||
        currentUserCount > teamTierMaxUsers))
  ) {
    return (
      <Modal
        setIsOpen={onHideModal}
        modalHeader="Remove users and workspaces"
        noText="Close"
        yesText="Remove users"
        onYes={() => window.location.replace('/settings?show=users')}
      >
        <p>
          In order to cancel your current plan{' '}
          {type === 'delete' ? 'and delete your account' : ''}, please delete
          all but{' '}
          {type === 'downgradeTeam' ? teamTierMaxWorkspaces.toString() : 'one'}{' '}
          of your workspaces and{' '}
          {type === 'downgradeTeam'
            ? `have less than ${teamTierMaxUsers} total`
            : ''}{' '}
          users.
        </p>
      </Modal>
    )
  }

  if (showDeleteConfirm) {
    return (
      <Modal
        setIsOpen={onHideModal}
        modalHeader="Are you sure you want to delete your account?"
        noText="Cancel"
        yesText="Delete account"
        yesButtonDisabled={inputValue !== deleteWord}
        yesButtonLoading={deletingAccount}
        onYes={async () => {
          if (inputValue === deleteWord && companyID) {
            setDeletingAccount(true)

            if (isMicrosoftMarketplace) {
              // Cancel subscription
              // Doesn't matter which tier subscription is, including Free
              await cancelMicrosoftSubscription({
                variables: { companyID },
              })
            }

            await permanentlyDeleteCompany({
              variables: {
                companyID,
              },
            })

            setDeletingAccount(false)

            logout()
          }
        }}
      >
        <p>This will remove all data and cannot be reversed.</p>
        <Label id="deleteAccount" modalHeading>
          Type in {deleteWord} to confirm account deletion
        </Label>
        <Input
          name="deleteAccount"
          type="text"
          required
          value={inputValue}
          onValueChange={(e) => setInputValue(e)}
        />
        {error && (
          <p>
            Failed to delete account. Please contact{' '}
            <Link href={`mailto:${supportEmail}`}>support</Link>.
          </p>
        )}
      </Modal>
    )
  }

  return (
    <Modal
      setIsOpen={onHideModal}
      modalHeader={
        <Heading type={3} align="left">
          It breaks our{' '}
          <span role="img" aria-label="heart">
            ❤️
          </span>{' '}
          to see you {type === 'delete' ? 'leave' : 'downgrade'}
        </Heading>
      }
      noText="Cancel"
      yesButtonLoading={downgradeLoading}
      yesText="Get support"
      onYes={() => {
        window.open(calendarBookingLink, '_blank')
      }}
      footerContent={
        <>
          {!downgradeLoading && (
            <Button
              variant="secondary"
              style={{
                paddingLeft: 16,
                marginLeft: 'auto',
                marginRight: 16,
              }}
              isDisabled={feedbackInput === ''}
              onPress={async () => {
                setDowngradeLoading(true)

                await sendFeedbackMutation({
                  variables: {
                    message: feedbackInput,
                    page: '/upgrade',
                    feedbackContext: {
                      feedbackPrompt:
                        type === 'delete'
                          ? 'Delete account'
                          : `Downgrade to ${
                              type === 'downgradeTeam' ? 'team' : 'free'
                            } account`,
                      feedbackResponse: `Why are you ${
                        type === 'delete'
                          ? 'deleting your account'
                          : 'downgrading'
                      }?`,
                    },
                  },
                })

                // Cancel Paddle subscription
                if (isTeam && isPaddle && paddleCancelUrl) {
                  window.Paddle.Checkout.open({
                    override: paddleCancelUrl,
                    passthrough: companyID,
                    closeCallback: () => setDowngradeLoading(false),
                    successCallback: async () => {
                      logAction({
                        variables: {
                          action: 'cancel-paddle-subscription',
                          extra: JSON.stringify({
                            paddleSubscriptionId,
                          }),
                          websiteSection: 'settings',
                          pagePath: '/settings',
                          functionName: 'cancelPaddleSubscription',
                        },
                      })

                      if (type === 'downgrade') {
                        window.location.replace('/settings?show=billing')
                      }
                    },
                  })
                }

                // Downgrade via MS Marketplace
                if (isMicrosoftMarketplace) {
                  await downgradeMicrosoftPlan({
                    variables: {
                      companyID,
                      newPlanID:
                        type === 'downgradeTeam'
                          ? 'team_edition'
                          : 'free_edition',
                    },
                  })

                  logAction({
                    variables: {
                      action: 'downgrade-microsoft-subscription',
                      extra: JSON.stringify({
                        microsoftSubscriptionID:
                          microsoftSubscriptionData?.currentCompany
                            .microsoftSubscriptionData?.subscriptionID,
                      }),
                      websiteSection: 'settings',
                      pagePath: '/settings',
                      functionName: 'downgradeMicrosoftSubscription',
                    },
                  })

                  setDowngradeLoading(false)

                  if (type !== 'delete') {
                    window.location.replace('/settings?show=billing')
                  }
                }

                if (type !== 'delete') return

                setDowngradeLoading(false)
                setShowDeleteConfirm(true)
              }}
            >
              {type === 'delete' ? (
                <>{isTeam ? 'Confirm downgrade' : 'Submit feedback'}</>
              ) : (
                `Downgrade to ${type === 'downgradeTeam' ? 'team' : 'free'}`
              )}
            </Button>
          )}
        </>
      }
    >
      <p>
        If something isn't working as expected,{' '}
        <Link href={calendarBookingLink}>book a meeting with us</Link> so we can
        support you and fix it.
      </p>
      <p>
        If you still wish to{' '}
        {type === 'delete'
          ? 'delete your account'
          : `downgrade to a ${
              type === 'downgradeTeam' ? 'team' : 'free'
            } account`}
        , please let us know why so we can improve.
      </p>
      <p>Please provide some details*</p>
      <Input
        name="feedbackInput"
        type="textArea"
        label="Please provide some details"
        value={feedbackInput}
        onValueChange={(val) => setFeedbackInput(val)}
        required
        placeholder="Enter feedback"
        multilineInput
        textAreaHeight={80}
        lineBreakAll={false}
      />
      {type === 'delete' && (
        <p>
          Once {isFree ? 'submitted' : 'downgraded'}, you will be asked to
          confirm account deletion.
        </p>
      )}
    </Modal>
  )
}
