import React, { useState, useCallback } from 'react'

import { NavigateButton } from './button'
import FileDragAndDrop from './file-drag-and-drop'
import Link from './link'
import Modal from './modal'
import { BoxedText } from './typography'
import {
  getDownloadTemplateLinkGQL,
  uploadBulkTemplateHistorical,
} from '../api/REST/track-client'
import { supportEmail } from '../core/constants'
import useLogAction from '../hooks/useLogAction'

export interface BulkImportHistoricalProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
  refetchData: () => Promise<void>
}

// This version only uploads full codes that have already been created
// It should be used for storing older codes in Uplifter that were not created here
export default function BulkImportHistorical({
  setShowModal,
  refetchData,
}: BulkImportHistoricalProps): React.ReactElement {
  const logAction = useLogAction()

  const [error, setError] = useState<string | React.ReactElement>('')
  const [success, setSuccess] = useState<string | React.ReactElement>('')

  const [importInProgress, setImportInProgress] = useState(false)

  const onDrop = useCallback(async (acceptedFiles) => {
    setSuccess('')

    if (acceptedFiles.length > 0) {
      setImportInProgress(true)

      const res = await uploadBulkTemplateHistorical({
        file: acceptedFiles.pop(),
      })

      if (res === true) {
        setError('')
        setSuccess('Your links have been added.')
        setImportInProgress(false)

        // Refetch codes
        refetchData()

        logAction({
          variables: {
            action: 'bulk-import-historical-codes-success',
            functionName: 'bulkImportHistorical',
            pagePath: '/track/view-links',
            websiteSection: 'track',
          },
        })

        return
      }

      const type = res?.error?.response?.data?.detail || ''

      if (
        type === 'BAD_TEMPLATE' ||
        type === 'Template file does not match expected format'
      ) {
        setError(
          'Incorrect import template. Please download and use the sample template.',
        )
      } else if (type === 'BAD_FILE_FORMAT') {
        setError('Incorrect file type.')
      } else if (type === 'FAILED_UPLOAD') {
        setError('File upload has failed, please try again.')
      } else {
        setError(
          <span>
            File upload has failed. Please email{' '}
            <Link href={`mailto:${supportEmail}`}>{supportEmail}</Link> for
            help.
          </span>,
        )
      }

      logAction({
        variables: {
          action: 'bulk-import-historical-codes-failed',
          functionName: 'bulkImportHistorical',
          pagePath: '/track/view-links',
          websiteSection: 'track',
          extra: type,
        },
      })

      setImportInProgress(false)
    }
  }, [])

  return (
    <Modal
      setIsOpen={setShowModal}
      width="wide"
      modalHeader="Import historical links (admin only)"
    >
      <p>
        Import previously created links from historical UTM spreadsheets. You
        cannot import short links here and we don't validate your parameters to
        match your rules.
      </p>
      <p>
        To <strong>bulk create new links</strong> (including short links) from a
        CSV file, use{' '}
        <BoxedText>
          <Link href="/track/create">
            Track &gt; Create links &gt; Bulk CSV
          </Link>
        </BoxedText>
        , where we apply validation to make sure your links will work with you
        rules.
      </p>
      <ol>
        <li>
          <NavigateButton
            onPress={async () => {
              await getDownloadTemplateLinkGQL()
            }}
          >
            Download CSV template
          </NavigateButton>
        </li>
        <li>Add historical links, landing pages and parameters</li>
        <li>
          Save the CSV template in CSV UTF-8 (Comma delimited) (*CSV) format
        </li>
        <li>Upload your CSV below</li>
      </ol>
      <FileDragAndDrop
        error={error}
        success={success}
        onDrop={onDrop}
        inProgress={importInProgress}
      />
    </Modal>
  )
}
