import { gql } from '../../__gql-types__/gql'

// ! Not used
// Originally used to check if 'Edit dropdowns' nav option should be shown
// Not significantly faster than getCampaignCodeGenerator (full request)
// So that is used instead
export const getCampaignCodeGeneratorFieldTypes = gql(`
	query GetCampaignCodeGeneratorFieldTypes {
		campaignCodeGenerator {
			accountID @client
			paramDefs {
				fieldID
				fieldType
			}
		}
	}
`)

export const getCampaignCodeGenerator = gql(`
	query GetCampaignCodeGenerator {
		campaignCodeGenerator {
			accountID @client
			defaultPardotBusinessUnit
			existingParametersAddedToStart
			isLocked
			masterPrefix
			paramSeparator
			paramDefs {
				copyFromField {
					copyFromID
				}
				dateFormat
				emailDefault {
					optionID
					optionName
					optionValue
					parameterID
				}
				fieldAvailable
				fieldID
				fieldName
				fieldType
				fixedValue
				forceLowerCase
				helpText
				isCampaignField
				metaParameter
				parameterDependsOn {
					parentFieldID
					parentOptionIDs
				}
				prefix
				required
				selectFields {
					hide
					optionFilter {
						parentFieldID
						parentOptionIDs
					}
					optionID
					optionName
					optionValue
				}
			}
			validationChecks {
				enabled
				name
				value
			}
		}
	}
`)

export const getShortLinkCustomDomains = gql(`
  query GetShortLinkCustomDomains {
		currentCompany {
			companyID
			availableShortLinkCustomDomains {
				customDomainID
				customDomainName
			}
		}
	}
`)

export const getShortLinkCandidates = gql(`
	query GetShortLinkCandidates (
		$nLinks: Int!
		$customDomainID: String
	) {
  shortLinkCandidates(
		nLinks: $nLinks,
		customDomainID: $customDomainID
	) {
    ... on ShortLinkCandidates {
      __typename
      availableLinkIDs
    }
    ... on ShortLinkBatchCandidates {
      __typename
      bulkStart
      nLinks
    }
  }
}
`)

export const validateShortLinkCandidate = gql(`
	query ValidateShortLinkCandidate(
		$testCandidate: String!
		$customDomainID: String
	) {
		track {
			validateShortLinkCandidate(
				testCandidate: $testCandidate
				customDomainID: $customDomainID
			) {
				availableUntil
				isAvailable
				shortLinkID
			}
		}
	}
`)

export const createNewLink = gql(`
	mutation CreateNewLink(
			$customDomainID: String
			$codeList: [AddCode!]!
			$bulkStart: String
	) {
		addCodes(
			codeList: $codeList,
			customDomainID: $customDomainID,
			bulkStart: $bulkStart,
		) {
			accountID @client
			author
			codeDef
			codeID
			createdTime
			minGenDef {
				paramID
				paramName
			}
			fullLink
			shortLink
			totalCodes
			versionNumber
		}
	}
`)

export const sendGeneratorUpdateRequest = gql(`
	mutation sendUpdateRequest(
		$changeRequests: [ChangeRequestInput!]!
		$requestNote: String!
	) {
		track {
			sendUpdateRequest(
				requestNote: $requestNote
				updateRequestList: $changeRequests
			) {
				requestID
			}
		}
	}
`)

export const getUplifterIDCurrentTotal = gql(`
	query GetUplifterIDCurrentTotal {
		track {
			currentSequentialCodeID {
				accountID @client
				isEnabled
				currentTotal
				etag
				prefix
				acctPrefix
			}
		}
	}
`)

export const updateCurrentSequentialCodeID = gql(`
	mutation UpdateCurrentSequentialCodeID (
		$currentTotal: String!
		$etag: String!
		$newTotal: String!
	) {
		track {
			updateCurrentSequentialCodeID (
				currentTotal: $currentTotal,
				etag: $etag,
				newTotal: $newTotal
			) {
				currentTotal
				etag
				acctPrefix
				updatedTotal
			}
		}
	}
`)
