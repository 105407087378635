import React, { useCallback, useEffect, useRef, useState } from 'react'
import { AriaButtonProps, useButton } from 'react-aria'
import classNames from 'classnames'
import { nanoid } from 'nanoid'

import { Preloader } from './loader'
import Arrow from '../assets/svgs/arrow'
import styles from '../styles/button-dropdown.module.scss'

interface RenderElementWithNewProps<T> {
  item: React.ReactElement<T>
  renderItem: (props: T) => React.ReactElement<T>
}

const RenderElementWithNewProps = <T,>({
  item,
  renderItem,
}: RenderElementWithNewProps<T>) => {
  return renderItem(item.props)
}

export interface DropdownButtonItemProps extends AriaButtonProps<'button'> {
  loading?: boolean
  className?: string
  setExpanded?: React.Dispatch<React.SetStateAction<boolean>>
  children: React.ReactNode
}

export const DropdownButtonItem = ({
  loading = false,
  className,
  setExpanded,
  children,
  ...props
}: DropdownButtonItemProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null)

  const { buttonProps } = useButton(
    {
      ...props,
      onPress: async (e) => {
        if (props.onPress) await props.onPress(e)

        if (setExpanded) setExpanded(false)
      },
      isDisabled: loading || props.isDisabled,
    },
    buttonRef,
  )

  return (
    <button
      {...buttonProps}
      ref={buttonRef}
      className={classNames(className, styles.dropdownButtonItem, {
        [styles.loading]: loading,
      })}
    >
      {loading ? <Preloader className={styles.loadingBars} /> : children}
    </button>
  )
}

interface DropdownLabelProps {
  children: React.ReactNode
}

export const DropdownLabel = ({ children }: DropdownLabelProps) => {
  return (
    <div key={nanoid()} className={styles.itemLabel}>
      <span>{children}</span>
    </div>
  )
}

const isDropdownButton = (
  item:
    | React.ReactElement<DropdownButtonItemProps>
    | React.ReactElement<DropdownLabelProps>,
): item is React.ReactElement<DropdownButtonItemProps> => {
  return Object.prototype.hasOwnProperty.call(item.props, 'onPress')
}

interface PressEvent {
  type: 'pressstart' | 'pressend' | 'pressup' | 'press'
  pointerType: 'mouse' | 'pen' | 'touch' | 'keyboard' | 'virtual'
  target: Element
  shiftKey: boolean
  ctrlKey: boolean
  metaKey: boolean
  altKey: boolean
}

interface ButtonDropdownProps {
  variant?: 'primary' | 'secondary'
  color?: 'pink' | 'blue' | 'white'
  buttonText: string
  separateMainAction?: boolean
  mainAction?: (e: PressEvent) => void
  containerClassName?: string
  dropdownClassName?: string
  loading?: boolean
  disabled?: boolean
  /** Button hover states are ignored. Do not provide onPress if set to true */
  demoOnly?: boolean
  children?:
    | React.ReactElement<DropdownButtonItemProps | DropdownLabelProps>
    | React.ReactElement<DropdownButtonItemProps | DropdownLabelProps>[]
}

const ButtonDropdown = ({
  variant = 'primary',
  color = 'pink',
  buttonText,
  separateMainAction = true,
  mainAction,
  containerClassName,
  dropdownClassName,
  loading = false,
  disabled = false,
  demoOnly,
  children,
}: ButtonDropdownProps) => {
  const mainButtonRef = useRef<HTMLDivElement>(null)

  const [loadingMargin, setLoadingMargin] = useState(0)
  const [expanded, setExpanded] = useState(false)

  const { buttonProps: mainButtonProps } = useButton(
    {
      elementType: 'div',
      isDisabled: loading || disabled,
      onPress: (e) => {
        if (demoOnly) return

        if (mainAction) {
          mainAction(e)

          if (separateMainAction) return
        }

        setExpanded(!expanded)
      },
    },
    mainButtonRef,
  )

  const { buttonProps: arrowButtonProps } = useButton(
    {
      elementType: 'div',
      isDisabled: loading || disabled,
      onPress: (e) => {
        if (demoOnly) return

        if (mainAction && !separateMainAction) mainAction(e)

        setExpanded(!expanded)
      },
    },
    mainButtonRef,
  )

  // Ensures the button's width is preserved when it enters loading state
  useEffect(() => {
    if (
      !mainButtonRef.current ||
      loading ||
      mainButtonRef.current.offsetWidth <= 35
    )
      return

    setLoadingMargin((mainButtonRef.current.offsetWidth - 35 - 24) / 2)
  }, [mainButtonRef.current, loading])

  // Ensures blur is not triggered when interacting with dropdown buttons
  const handleBlur = useCallback((e: React.FocusEvent) => {
    const { currentTarget } = e

    requestAnimationFrame(() => {
      if (!currentTarget.contains(document.activeElement)) {
        setExpanded(false)
      }
    })
  }, [])

  return (
    <div
      className={classNames(containerClassName, styles.dropdownButtonContainer)}
      onBlur={handleBlur}
    >
      <div
        className={classNames(
          styles.mainButtonContainer,
          styles[variant],
          styles[`color-${color}`],
          {
            [styles.disabled]: loading || disabled,
          },
        )}
      >
        <div
          aria-label={mainAction ? 'Main action button' : 'Show all actions'}
          {...mainButtonProps}
          ref={mainButtonRef}
          className={classNames(styles.dropdownMainButton, {
            [styles.isSeparate]: !!mainAction && separateMainAction,
            [styles.demoOnly]: demoOnly,
          })}
        >
          {loading ? (
            <Preloader
              className={styles.loadingBars}
              style={{ margin: `0 ${loadingMargin}px` }}
            />
          ) : (
            buttonText
          )}
        </div>
        {children && (
          <div
            aria-label="Show all actions"
            {...arrowButtonProps}
            className={classNames(styles.dropdownArrowButton, {
              [styles.isSeparate]: !!mainAction && separateMainAction,
              [styles.demoOnly]: demoOnly,
            })}
          >
            <Arrow
              className={classNames(styles.arrowIcon, {
                [styles.isExpanded]: expanded,
              })}
            />
          </div>
        )}
      </div>
      {/* TODO: Animate this appearing with framer motion */}
      {expanded && children && (
        <div
          className={classNames(
            dropdownClassName,
            styles.dropdownItemsContainer,
          )}
        >
          {/* If a button, allows parent's setExpanded prop to be passed to child */}
          {/* Which is then automatically passed at the end of the child's onPress event */}
          {Array.isArray(children) ? (
            children.map((child) => {
              return isDropdownButton(child) ? (
                <RenderElementWithNewProps
                  key={child.key}
                  item={child}
                  renderItem={(props) => (
                    <DropdownButtonItem
                      key={child.key}
                      {...props}
                      setExpanded={setExpanded}
                    />
                  )}
                />
              ) : (
                <React.Fragment key={child.key}>{child}</React.Fragment>
              )
            })
          ) : (
            <>
              {isDropdownButton(children) ? (
                <RenderElementWithNewProps
                  item={children}
                  renderItem={(props) => (
                    <DropdownButtonItem
                      key={nanoid()}
                      {...props}
                      setExpanded={setExpanded}
                    />
                  )}
                />
              ) : (
                <React.Fragment key={nanoid()}>{children}</React.Fragment>
              )}
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default ButtonDropdown
