import React from 'react'
import numeral from 'numeraljs'

import { GeneratedCode } from '../helpers/track-module'
import styles from '../styles/campaign-code-generator.module.scss'

export default function CodeSummary({
  previewCodes,
}: {
  previewCodes: GeneratedCode[]
}): React.ReactElement {
  const { length: filteredLength } = previewCodes.filter(
    (item) => item.selected,
  )
  const { length } = previewCodes

  let code = numeral(length).format('0,0')

  if (filteredLength !== length) {
    code = `${numeral(filteredLength).format('0,0')}`
  }

  return (
    <p className={styles.buttonsSummary}>
      {code} links will be created based on your current selection.
    </p>
  )
}
