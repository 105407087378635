import _ from 'lodash'
import FileSaver from 'file-saver'
import numeral from 'numeraljs'
import moment from 'moment'

import { getItemByKeyValue } from './index'
import {
  CampaignCodeGeneratorStructure,
  GeneratorFields,
  GeneratorSelectFields,
  MinCodesByUserResult,
} from '../api/types'
import { brandName, messages } from '../core/constants'
import { GetMinCodesByAccountQuery } from '../__gql-types__/graphql'

export function getShareEmails(
  shareEmails: string[],
  typedValue: string,
): string[] {
  if (typedValue === '') return shareEmails

  if (shareEmails.length > 0) {
    if (shareEmails[shareEmails.length - 1] === typedValue) return shareEmails

    return [...shareEmails, ...[typedValue]]
  }

  return [typedValue]
}

export const downloadSpecificCodes = async (
  data: string | ArrayBuffer,
  excelFile = false,
): Promise<any> => {
  const blob = new Blob(
    [excelFile ? new Uint8Array(data as ArrayBuffer) : `\ufeff${data}`],
    {
      type: excelFile
        ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        : 'text/plain;charset=utf-8-sig',
    },
  )
  const now = new Date(Date.now())

  await FileSaver.saveAs(
    blob,
    `${moment(now).format('YYYY-MM-DD')} ${brandName} Campaign Codes.${
      excelFile ? 'xlsx' : 'csv'
    }`,
  )

  return true
}

export function getUrlMessages(
  validatedUrl: any,
  createdToday: boolean,
  hasMetricData: boolean,
  isGoodUrl: boolean,
  hasNoLandingPage?: boolean,
) {
  const urlMessages: string[] = []

  if (hasNoLandingPage) {
    urlMessages.push(messages.validateUrlMessage.noUrl)
    return urlMessages.join('')
  }

  if (validatedUrl) {
    if (isGoodUrl) {
      urlMessages.push(messages.validateUrlMessage.goodUrl)
    } else if (validatedUrl.badUrl) {
      urlMessages.push(messages.validateUrlMessage.badUrl)
    } else if (validatedUrl.slowLandingPage) {
      urlMessages.push(messages.validateUrlMessage.slowLandingPage)
    } else if (validatedUrl.noAnalyticsTag) {
      urlMessages.push(messages.validateUrlMessage.noAnalyticsTag)
    } else if (validatedUrl.redirectedLandingPage) {
      urlMessages.push(messages.validateUrlMessage.redirectedLandingPage)
    }
  } else if (createdToday) {
    urlMessages.push(messages.validateUrlMessage.validating)
  } else if (!hasMetricData) {
    urlMessages.push(messages.validateUrlMessage.noData)
  } else {
    urlMessages.push(messages.validateUrlMessage.unknownUrl)
  }
  return urlMessages.join('')
}

export function formatMetricValue(value, units): string {
  let formattedNumber = value

  if (typeof value === 'number') {
    if (units === 'percentage') {
      formattedNumber = value === -1 ? '' : `${numeral(value).format('0,0')}%`
    } else if (value !== 0) {
      if (units === 'duration') {
        const time = moment.utc(value * 1000).format('mm:ss')
        formattedNumber = `${time}s`
      } else {
        formattedNumber = `${numeral(value).format('0,0.[0]a')}`
      }
    }
  }

  return formattedNumber
}

export function makeLinkSecure(link): string {
  if (
    link &&
    typeof link === 'string' &&
    link.search(/(https:\/\/)|(http:\/\/)/gi) === -1 &&
    link.search(/.\../i) !== -1
  ) {
    return `https://${link}`
  }
  return link
}

export function getAnchorFromString(url: string): string {
  const hasAnchor = url.lastIndexOf('#') !== -1

  let useAnchor = ''

  if (hasAnchor) {
    // Exclude # used in hash based routing (/#/)
    const searchRegEx = new RegExp(`#(?!\/)([^?/])*`, 'i')

    const urlMatch = url.match(searchRegEx)

    if (urlMatch && urlMatch.length > 0) {
      useAnchor = urlMatch[0].replace('?', '')
    }
  }

  return useAnchor
}

export function getExistingQueryString(url: string) {
  const existingQueryString = url.match(/\?[^#]+/)

  if (!existingQueryString) return ''

  return existingQueryString[existingQueryString.length - 1].replace('?', '')
}

export function isFieldAChild(field: GeneratorFields): boolean {
  const { selectFields } = field

  if (selectFields) {
    const haveParents = selectFields
      .filter((item) => !item.hide)
      .filter((item) => {
        const { optionFilter } = item

        if (optionFilter && optionFilter.length > 0) {
          const { parentOptionIDs } = optionFilter[0]
          return !!(parentOptionIDs && parentOptionIDs.length > 0)
        }

        return false
      })

    return !!(haveParents.length > 0)
  }
  return false
}

export function isFieldAParent(
  form: CampaignCodeGeneratorStructure,
  field: GeneratorFields,
): boolean {
  const { paramDefs } = form
  const { fieldID } = field

  const allOtherParamDefs = paramDefs.filter(
    (item) => !!(item.fieldID !== fieldID && item.selectFields),
  )

  if (allOtherParamDefs.length > 0) {
    const haveChildren = allOtherParamDefs.filter((item) => {
      const { selectFields } = item

      if (selectFields) {
        const selected = selectFields
          .filter((i) => !i.hide)
          .filter((selectField) => {
            const { optionFilter } = selectField

            if (optionFilter && optionFilter.length > 0) {
              const { parentFieldID, parentOptionIDs } = optionFilter[0]

              return !!(
                parentOptionIDs &&
                parentOptionIDs.length > 0 &&
                parentFieldID === fieldID
              )
            }

            return false
          })

        return !!(selected.length > 0)
      }

      return false
    })

    return !!(haveChildren.length > 0)
  }
  return false
}

export interface ParentSelectFields extends GeneratorSelectFields {
  useLabel: string
}

export function getChildrenValues(
  field: GeneratorFields,
  parent: ParentSelectFields,
) {
  const { selectFields } = field

  const { optionID: parentOptionId } = parent

  const result: GeneratorSelectFields[] = []

  if (selectFields && selectFields.length > 0) {
    selectFields.forEach((item) => {
      if (item.hide) {
        return
      }

      const { optionFilter } = item

      if (optionFilter && optionFilter.length > 0) {
        const { parentOptionIDs } = optionFilter[0]

        if (parentOptionIDs && parentOptionIDs.length > 0) {
          if (parentOptionIDs.indexOf(parentOptionId) !== -1) {
            result.push(item)
          }
        }
      }
    })
  }
  return result
}

export const parentIsWorkspace = (field: GeneratorFields) => {
  if (!field.selectFields) return false

  const hasFilter = field.selectFields.find(
    (selectField) =>
      selectField.optionFilter && selectField.optionFilter.length > 0,
  )?.optionFilter

  if (!hasFilter) return false

  return hasFilter[0].parentFieldID === 'account'
}

export function getParentValue(
  form: CampaignCodeGeneratorStructure,
  field: GeneratorFields,
) {
  const { paramDefs } = form
  const { fieldID, selectFields } = field

  const selectedParentOptions: ParentSelectFields[] = []
  const parentIds: string[] = []

  const allOtherActiveSelectFields = paramDefs.filter(
    (item) =>
      !!(
        item.fieldID !== fieldID &&
        item.selectFields &&
        item.optionValue &&
        item.optionValue.length > 0 &&
        item.optionValue[0] !== ''
      ),
  )

  if (allOtherActiveSelectFields.length > 0 && selectFields) {
    selectFields
      .filter((i) => !i.hide)
      .forEach((item) => {
        const { optionFilter } = item

        if (optionFilter && optionFilter.length > 0) {
          const { parentOptionIDs, parentFieldID } = optionFilter[0]

          if (
            parentOptionIDs &&
            parentOptionIDs.length > 0 &&
            parentIds.indexOf(parentFieldID) === -1
          ) {
            parentIds.push(parentFieldID)
          }
        }
      })

    const parents = allOtherActiveSelectFields.filter(
      (i) => parentIds.indexOf(i.fieldID) !== -1,
    )

    parents.forEach((parent) => {
      const {
        selectFields: parentSelectFields,
        optionValue,
        fieldName,
      } = parent

      if (parentSelectFields) {
        selectedParentOptions.push(
          ...parentSelectFields
            .filter(
              (i) => optionValue && optionValue.indexOf(i.optionID) !== -1,
            )
            .map((i) => {
              return {
                ...i,
                useLabel: `${fieldName}: ${i.optionName}`,
              }
            }),
        )
      }
    })
  }

  return selectedParentOptions
}

export function getChildValue(
  form: CampaignCodeGeneratorStructure,
  field: GeneratorFields,
) {
  const { paramDefs } = form
  const { fieldID } = field

  const selectedChildOptions: any[] = []

  const allOtherParamDefs = paramDefs.filter(
    (item) =>
      !!(
        item.fieldID !== fieldID &&
        item.selectFields &&
        item.optionValue &&
        item.optionValue.length > 0 &&
        item.optionValue[0] !== ''
      ),
  )

  if (allOtherParamDefs.length > 0) {
    allOtherParamDefs.forEach((paramDef) => {
      const { selectFields, optionValue } = paramDef

      if (selectFields) {
        selectedChildOptions.push(
          ...selectFields.filter(
            (i) =>
              !i.hide && optionValue && optionValue.indexOf(i.optionID) !== -1,
          ),
        )
      }
    })
  }

  return selectedChildOptions.filter((item) => {
    const { optionFilter } = item
    if (optionFilter && optionFilter.length > 0) {
      const { parentFieldID, parentOptionIDs } = optionFilter[0]

      return (
        parentFieldID === fieldID &&
        parentOptionIDs &&
        parentOptionIDs.length > 0
      )
    }

    return false
  })
}

export function filterByParentValue(
  filtered: GeneratorSelectFields[],
  parentValue: ParentSelectFields[],
) {
  const parentValueOptionIDs = parentValue.map((i) => i.optionID)

  const result = filtered.filter((item) => {
    const { optionFilter } = item

    if (optionFilter && optionFilter.length > 0) {
      const { parentOptionIDs } = optionFilter[0]

      if (parentOptionIDs && parentOptionIDs.length > 0) {
        const containsParent = parentOptionIDs.filter(
          (i) => parentValueOptionIDs.indexOf(i) !== -1,
        )

        return containsParent.length > 0
      }
    }
    return true
  })

  return result
}

export function filterByChildValue(
  filtered: GeneratorSelectFields[],
  childValue: GeneratorSelectFields[],
) {
  const permittedParentOptionIDs: string[] = []

  childValue.forEach((item) => {
    const { optionFilter } = item

    if (optionFilter && optionFilter.length > 0) {
      const { parentOptionIDs } = optionFilter[0]

      if (parentOptionIDs && parentOptionIDs.length > 0) {
        permittedParentOptionIDs.push(
          ...parentOptionIDs.filter(
            (i) => permittedParentOptionIDs.indexOf(i) === -1,
          ),
        )
      }
    }
  })

  const result = filtered.filter((item) => {
    return permittedParentOptionIDs.indexOf(item.optionID) !== -1
  })

  return result
}

interface CodeGroup {
  optionName: string
  optionValue: string
  optionID?: string
  urlValue: string
  fieldName: string
  fieldID: string
  copyFrom?: string[]
  isMeta?: boolean
  includeEmpty: boolean
  hasPrefix: boolean
  selectFieldsParent?: {
    parentID: string
    parentValues: string[]
  }
  parameterDependsOn?: {
    parentFieldID: string
    parentOptionIDs: string[]
  } | null
}

export interface GeneratedCode {
  pDfs: [string, string, string, string, string][]
  tC: string
  code: string
  url: string
  selected: boolean
  urlWithHash?: string
}

export default function generateCode(
  urls: string[],
  codeDef: CampaignCodeGeneratorStructure | null,
  moveExistingParams?: boolean,
): GeneratedCode[] {
  if (codeDef === null) return []

  const { paramDefs, masterPrefix, paramSeparator, validationChecks } = codeDef

  const includePrefixWithCopy = getItemByKeyValue(
    validationChecks,
    'name',
    'INCLUDE_PREFIX_WITH_COPY_FROM',
  )

  const includePrefix = includePrefixWithCopy && includePrefixWithCopy.enabled

  const includeEmptyValues = getItemByKeyValue(
    validationChecks,
    'name',
    'INCLUDE_EMPTY_VALUES',
  )

  const includeEmpty = !!(includeEmptyValues && includeEmptyValues.enabled)

  let results: CodeGroup[][] = [[]]

  for (let i = 0; i < paramDefs.length; i += 1) {
    const currentSubArray = paramDefs[i]

    const {
      fieldName,
      fieldType,
      fixedValue,
      prefix,
      selectFields,
      metaParameter,
      optionValue,
      copyFromField,
      fieldID,
      parameterDependsOn,
    } = currentSubArray

    const isCopyFromField = copyFromField && copyFromField.length > 0

    const useValue =
      isCopyFromField || !optionValue || optionValue.length === 0
        ? ['']
        : optionValue

    const tempCodes: CodeGroup[][] = []

    for (let j = 0; j < results.length; j += 1) {
      for (let k = 0; k < useValue.length; k += 1) {
        const value = fieldType === 'fixed' ? fixedValue || '' : useValue[k]

        if (['input', 'fixed'].indexOf(fieldType) > -1 || !selectFields) {
          tempCodes.push(
            results[j].concat([
              {
                optionName: value,
                optionValue: value,
                urlValue: metaParameter === true ? '' : `${prefix}${value}`,
                fieldName,
                fieldID,
                copyFrom: copyFromField
                  ? copyFromField.map((field) => field.copyFromID)
                  : undefined,
                isMeta: !!metaParameter,
                includeEmpty,
                hasPrefix: prefix !== '',
                parameterDependsOn,
              },
            ]),
          )
        } else {
          // Select fields use IDs instead of values to handle duplicates
          const found: GeneratorSelectFields | -1 = getItemByKeyValue(
            selectFields,
            'optionID',
            value,
          )

          const useName = found === -1 ? '' : found.optionName
          const useOptionValue = found === -1 ? '' : found.optionValue
          const useOptionID = found === -1 ? '' : found.optionID

          const valToPush: CodeGroup = {
            optionName: useName,
            optionValue: useOptionValue,
            optionID: useOptionID,
            urlValue:
              metaParameter === true ? '' : `${prefix}${useOptionValue}`,
            fieldName,
            fieldID,
            copyFrom: copyFromField
              ? copyFromField.map((field) => field.copyFromID)
              : undefined,
            isMeta: !!metaParameter,
            includeEmpty,
            hasPrefix: prefix !== '',
            parameterDependsOn,
          }

          if (
            found !== -1 &&
            found.optionFilter &&
            found.optionFilter.length > 0 &&
            found.optionFilter[0].parentOptionIDs.length > 0
          ) {
            const filter = found.optionFilter[0]

            valToPush.selectFieldsParent = {
              parentID: filter.parentFieldID,
              parentValues: filter.parentOptionIDs,
            }
          }

          tempCodes.push(results[j].concat([valToPush]))
        }
      }
    }

    results = tempCodes
  }

  let validCombinations = results

  // Filter out invalid select field parent-child combinations
  if (
    results.find((result) => result.find((field) => field.selectFieldsParent))
  ) {
    validCombinations = results.filter((result) => {
      const children = result.filter((field) => field.selectFieldsParent)

      return children.every((child) => {
        // Workspace restriction is not an invalid combination
        if (
          child.selectFieldsParent &&
          child.selectFieldsParent.parentID === 'account'
        )
          return true

        const parent = result.find(
          (field) =>
            child.selectFieldsParent &&
            child.selectFieldsParent.parentID === field.fieldID,
        )

        return (
          parent &&
          parent.optionID &&
          child.selectFieldsParent &&
          child.selectFieldsParent.parentValues.indexOf(parent.optionID) > -1
        )
      })
    })
  }

  // Set optionName, optionValue, optionID and urlValue to empty when dependency criteria is not met
  if (
    validCombinations.find((result) =>
      result.find((field) => field.parameterDependsOn),
    )
  ) {
    validCombinations = validCombinations.map((result) => {
      const fieldsWithDependencies = result.filter(
        (resultField) => !!resultField.parameterDependsOn,
      )

      if (fieldsWithDependencies.length === 0) return result

      const removedDependentValues = _.cloneDeep(result)

      fieldsWithDependencies.forEach((fieldWithDependency) => {
        const fieldDepIndex = removedDependentValues.findIndex(
          (val) => val.fieldID === fieldWithDependency.fieldID,
        )

        const {
          parentFieldID,
          parentOptionIDs,
        } = fieldWithDependency.parameterDependsOn || {
          parentFieldID: null,
          parentOptionIDs: [] as string[],
        }

        if (!parentFieldID) return

        const parentField = result.find(
          (field) => field.fieldID === parentFieldID,
        )

        if (!parentField) return

        if (
          parentField.optionID &&
          parentOptionIDs.indexOf(parentField.optionID) === -1
        ) {
          const newFieldWithDep = _.cloneDeep(fieldWithDependency)

          newFieldWithDep.optionID = undefined
          newFieldWithDep.optionName = ''
          newFieldWithDep.optionValue = ''
          newFieldWithDep.urlValue = ''

          removedDependentValues.splice(fieldDepIndex, 1, newFieldWithDep)
        }
      })

      return removedDependentValues
    })
  }

  // Filter out duplicates after dependency is finished
  validCombinations = validCombinations.reduce((acc, curr) => {
    const codeString = JSON.stringify(curr)

    if (acc.find((existing) => JSON.stringify(existing) === codeString))
      return acc

    acc.push(curr)

    return acc
  }, [] as CodeGroup[][])

  const copyFromSeparator = getItemByKeyValue(
    validationChecks,
    'name',
    'COPY_FROM_SEPARATOR',
  )

  const useSeparator = copyFromSeparator !== -1 ? copyFromSeparator.value : '|'

  const addCopiedValues = validCombinations.map((group) => {
    return group.map((item) => {
      const copyFromField = item.copyFrom

      if (copyFromField) {
        // Do not add copyFrom field if parent criteria are not met
        if (item.parameterDependsOn) {
          const { parentFieldID, parentOptionIDs } = item.parameterDependsOn

          const parentField = group.find(
            (field) => field.fieldID === parentFieldID,
          )

          if (
            parentField &&
            parentField.optionID &&
            parentOptionIDs.indexOf(parentField.optionID) === -1
          ) {
            return { ...item }
          }
        }

        const valuesToCopy = copyFromField
          .map((field, index) => {
            const found: CodeGroup | -1 = getItemByKeyValue(
              group,
              'fieldID',
              field,
            )

            // get the value with the prefix included if that rule is enabled
            const res =
              found !== -1
                ? found[
                    includePrefix && found.optionValue
                      ? 'urlValue'
                      : 'optionValue'
                  ]
                : ''

            // First copied value should not have the copyFrom separator
            if (index === 0 || res === '') {
              return res
            }

            // Special case: do not use copyFrom separator if the copied param does not have a prefix
            // The copied values might combine to a single value for the generator, so should not use copyFrom separator
            if (found !== -1 && !found.hasPrefix && !includePrefix) {
              return res
            }

            return `${useSeparator}${res}`
          })
          .filter((val) => val !== '')
          .join('')

        const copy = { ...item }

        copy.optionName = item.optionName + valuesToCopy
        copy.optionValue = item.optionValue + valuesToCopy

        // Exception for meta parameters so they are not included in the code string
        copy.urlValue = item.isMeta ? '' : item.urlValue + valuesToCopy

        return copy
      }

      return { ...item }
    })
  })

  const finalResult: GeneratedCode[] = []

  urls.forEach((url) => {
    const addCopiedValuesWithUrl = addCopiedValues.map((item) => {
      const pDfs: [
        string,
        string,
        string,
        string,
        string,
      ][] = item.map(
        ({ optionName, optionValue, fieldName, fieldID, optionID }) => [
          optionName,
          optionValue,
          fieldName,
          fieldID,
          optionID || '',
        ],
      )

      const tC = item
        .map((def) => {
          // return empty string for empty values, unless includeEmpty is enabled
          return !def.includeEmpty && def.optionName === '' ? '' : def.urlValue
        })
        .filter((i) => i !== '')
        .join(paramSeparator)

      // Get existing prefix, if any
      const hasPrefix = url.indexOf('?') !== -1

      const useAnchor = getAnchorFromString(url)
      // Add negative lookahead to accomodate hash based routing
      // https://example.com/#/# should not have the first hash removed
      const anchorReplaceRegex = new RegExp(
        `#(?!\/)${useAnchor.replace('#', '')}`,
        'i',
      )
      const useUrl = url.replace(anchorReplaceRegex, '')

      let code: string

      // Check if existing params in URL should be moved
      if (hasPrefix && moveExistingParams) {
        const existingQueryString = getExistingQueryString(useUrl)

        const urlWithoutExistingParams = useUrl
          .replace(existingQueryString, '')
          .replace('?', '')

        code = `${urlWithoutExistingParams}${masterPrefix}${tC}${
          existingQueryString ? `&${existingQueryString}` : ''
        }${useAnchor}`
      } else {
        const isPrefixLastCharacter =
          hasPrefix && url.indexOf('?') === url.length - 1

        const prefixToReplace = isPrefixLastCharacter ? '' : '&'

        code = `${useUrl}${
          hasPrefix ? masterPrefix.replace('?', prefixToReplace) : masterPrefix
        }${tC}${useAnchor}`
      }

      return {
        url: useUrl,
        pDfs,
        tC,
        code,
        selected: true,
        urlWithHash: url,
      }
    })

    finalResult.push(...addCopiedValuesWithUrl)
  })

  return finalResult
}

export function filterList(
  form: CampaignCodeGeneratorStructure,
  field: GeneratorFields,
  workspaceID: string,
  single = true,
) {
  let filtered = field.selectFields
    ? field.selectFields.filter((item) => !item.hide)
    : []

  const isChild = isFieldAChild(field)
  const isParent = isFieldAParent(form, field)
  const workspaceParent = parentIsWorkspace(field)

  if (isChild) {
    // Only show values that are allowed for the current workspace
    if (workspaceParent) {
      return filtered.filter((option) => {
        if (!option.optionFilter || option.optionFilter.length === 0)
          return true

        const { parentOptionIDs } = option.optionFilter[0]

        return (
          parentOptionIDs.length === 0 ||
          parentOptionIDs.indexOf(workspaceID) > -1
        )
      })
    }

    const parentValue = getParentValue(form, field)

    if (parentValue.length > 0) {
      // filter by parent value
      filtered = filterByParentValue(filtered, parentValue)
    }
    return filtered
  }

  // For multi journey we need to exclude this filter
  if (isParent && single) {
    const childValue = getChildValue(form, field)

    if (childValue.length > 0) {
      // filter by child value
      filtered = filterByChildValue(filtered, childValue)
    }
  }

  return filtered
}

export function getCsvHeader(codes: MinCodesByUserResult) {
  const result: string[] = []
  result.push('User')
  result.push('Timestamp')
  result.push('Short link')
  result.push('Landing page with query parameters')

  codes.params.forEach((param) => {
    result.push(param.paramName)
  })

  return result.map((r) => `"${r.replace(/\"/gi, '""')}"`) // escape quotes
}

interface GetCsvRowsProps {
  userEmail: string
  codes: MinCodesByUserResult[]
  selectedCodes: string[]
}

export function getCsvRows({
  userEmail,
  codes,
  selectedCodes,
}: GetCsvRowsProps) {
  let result: string[][] = []

  const getCode = (code: MinCodesByUserResult) => {
    const row: string[] = []
    row.push(userEmail)
    row.push(code.createdTime)
    row.push(code.shortLink ? `${code.shortLink}` : '')
    row.push(code.fullLink)

    code.params.forEach((paramDef) => {
      row.push(paramDef.paramValue)
    })

    return row.map((r) => `"${r.replace(/\"/gi, '""')}"`) // escape quotes
  }

  if (selectedCodes.length === 0) {
    result = codes.map((code) => getCode(code))
  } else {
    codes.forEach((c) => {
      if (c && c.codeID && selectedCodes.indexOf(c.codeID) !== -1) {
        result.push(getCode(c))
      }
    })
  }
  return result
}

interface GetCsvStringProps {
  userEmail: string
  codes: MinCodesByUserResult[]
  selectedCodes: string[]
}

export function getCsvString({
  userEmail,
  codes,
  selectedCodes,
}: GetCsvStringProps): string {
  const csvRows: string[] = []

  csvRows.push(getCsvHeader(codes[0]).join(','))

  const rows = getCsvRows({
    userEmail,
    selectedCodes,
    codes,
  })

  rows.reverse().forEach((row) => {
    csvRows.push(row.join(','))
  })

  return csvRows.join('\n')
}

export function checkParamNameIsInvalid(
  name: string,
  allParams: string[],
  callback?: any,
  currName?: string,
): boolean {
  if (name === '') {
    if (callback) {
      callback(true)
    }
    return true
  }

  if (allParams.length > 0) {
    const paramExists =
      allParams.filter((param) => {
        return param === name && (!currName || param !== currName)
      }).length > 0
    if (callback) {
      callback(paramExists)
    }
    return paramExists
  }
  return false
}

// fields validation
export function isAllFormDataValid(data: CampaignCodeGeneratorStructure) {
  // Find all empty values that should not be empty
  const emptyValues = { ...data }.paramDefs.filter((item) => {
    const { fieldType, optionValue, required, parameterDependsOn } = item

    if (required) {
      if (parameterDependsOn) {
        // Only set as required if the parameter's dependency is met
        const { parentFieldID, parentOptionIDs } = parameterDependsOn

        const parentField = getItemByKeyValue(
          data.paramDefs,
          'fieldID',
          parentFieldID,
        )

        let hasValidParentValues = false

        parentOptionIDs.forEach((option) => {
          if (
            parentField.optionValue &&
            parentField.optionValue.indexOf(option) > -1
          )
            hasValidParentValues = true
        })

        // Check if parent field has a valid value
        if (!hasValidParentValues) {
          return false
        }
      }

      if (typeof optionValue !== 'undefined' && optionValue.length > 0) {
        if (fieldType === 'select' && item.selectFields) {
          const fieldsThatExist = optionValue.filter((val: string) => {
            if (item.selectFields) {
              return item.selectFields.some(
                (i: GeneratorSelectFields): boolean =>
                  // Select fields no longer use optionValues for their values, they use IDs
                  // i.optionValue === val ||
                  i.optionID === val,
              )
            }

            return false
          })

          return fieldsThatExist.length !== optionValue.length
        }

        return (
          (fieldType === 'input' || fieldType === 'date') &&
          optionValue.join('') === ''
        )
      }

      return true
    }

    return false
  })

  return emptyValues.length === 0
}

export function getCopyFromValues(paramDefs, paramDef: GeneratorFields) {
  if (paramDef.fieldType === 'input')
    return paramDefs.filter(
      (item: GeneratorFields): boolean => item.fieldID !== paramDef.fieldID,
    )

  return []
}

export const parentOptionsAreEqual = (a: string[], b: string[]) => {
  if (a.length !== b.length) return false

  return !a.find((val) => b.indexOf(val) === -1)
}

// Ensures that parameters can't be dependent on each other
// Dependency chain must start with the ID of the initial parameter being checked
export const checkCircularDependency = (
  paramToCheck: GeneratorFields,
  allDropdownParams: GeneratorFields[],
  dependencyChain: string[],
) => {
  if (!paramToCheck.parameterDependsOn) return false

  const { parentFieldID } = paramToCheck.parameterDependsOn

  if (
    // Check it's not dependent on itself
    parentFieldID === paramToCheck.fieldID ||
    dependencyChain.indexOf(parentFieldID) > -1
  )
    return true

  // paramToCheck has a dependency but not one that's already in the chain
  // Recurse through until base cases are met
  const newDependencyChain = [...dependencyChain, parentFieldID]

  const newParamToCheck = getItemByKeyValue(
    allDropdownParams,
    'fieldID',
    parentFieldID,
  )

  if (newParamToCheck === -1) return false

  return checkCircularDependency(
    newParamToCheck,
    allDropdownParams,
    newDependencyChain,
  )
}

export function getMultiCodesToCopy(item: MinCodesByUserResult[]) {
  if (item.length === 0) {
    return { code: '', all: [] }
  }

  const hasShortLink = item.some((i) => i.shortLink !== '')

  const copyHeader = [
    hasShortLink ? 'Short link' : null,
    'Landing page with query parameters',
    ...item[0].params.map(({ paramName }) => paramName).filter((i) => i !== ''),
  ]
    .filter((i) => i !== null)
    .join('\t')

  const code = item.map(({ fullLink, shortLink: sL, params }) => {
    const shortLink = sL && sL !== '' ? sL : ''

    return [
      hasShortLink ? shortLink : null,
      fullLink,
      params
        .map(({ paramValue }) => paramValue)
        .filter((i) => i !== '')
        .join('\t'),
    ]
      .filter((i) => i !== null)
      .join('\t')
  })

  return { code, all: [copyHeader, ...code] }
}

/** lastIndex needs to be reset if used, because it has global flag! https://stackoverflow.com/questions/15276873/is-javascript-test-saving-state-in-the-regex */
export const hrefRegex = /href=(3D)?((\\)?\'http(s)?:\/\/[^{}\s]+(\\)?\'|(\\)?\"http(s)?:\/\/[^{}\s]+(\\)?\")/g
/** Used for textContent object in Salesforce Marketing Cloud emails. Their API is a mess. Using line breaks (\n) here to separate links */
export const httpRegex = /http(s)?:\/\/[^{}\s\\]+/g

export const htmlHasLinks = (html: string, excludeHref = false) => {
  const out = (excludeHref ? httpRegex : hrefRegex).test(html)

  // Reset regex state
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/test
  hrefRegex.lastIndex = 0
  httpRegex.lastIndex = 0

  return out
}

export const buildMinCodesByUserList = (
  minCodes: GetMinCodesByAccountQuery['minCodesByAccount'],
): MinCodesByUserResult[] => {
  const {
    codeID,
    fullLink,
    shortLink,
    createdTime,
    minGenDef,
    codeDef,
  } = minCodes

  const minCodesByUser: MinCodesByUserResult[] = []

  codeID.forEach((cid, cidIndex) => {
    const minCodeByUser: MinCodesByUserResult = {
      codeID: cid,
      createdTime: moment
        .unix(parseInt(createdTime[cidIndex], 10))
        .toISOString(),
      fullLink: fullLink[cidIndex],
      shortLink: shortLink ? shortLink[cidIndex] : '',
      params: [],
    }

    minGenDef.forEach((paramDef, paramDefIndex) => {
      const { paramID, paramName } = paramDef

      minCodeByUser.params.push({
        paramID,
        paramName,
        paramValue: codeDef[cidIndex][paramDefIndex],
      })
    })

    minCodesByUser.push(minCodeByUser)
  })

  return minCodesByUser
}
