import React, { useEffect, useMemo, useState } from 'react'
import { useQuery, useReactiveVar } from '@apollo/client'
import classNames from 'classnames'

import AccountSupportSettings from './account-support-settings'
import Preferences from './preferences'
import ProfileSettings from './profile-settings'
import ToggleBox from './toggle-box'
import { Heading } from './typography'
import { currentUserDetails } from '../api/apollo/variables'
import { getUserInfo } from '../api/graphql/user-client'
import missingAvatar from '../assets/missing-avatar.png'
import { getUrlQuery } from '../helpers'
import useLogAction from '../hooks/useLogAction'
import styles from '../styles/regular-user-settings.module.scss'

interface RegularUserSettingsProps {
  topBorder?: boolean
}

export default function RegularUserSettings({
  topBorder = false,
}: RegularUserSettingsProps) {
  const { userPermission } = useReactiveVar(currentUserDetails)

  const { data: currentUserData } = useQuery(getUserInfo)

  const logAction = useLogAction()

  const [openSection, setOpenSection] = useState('')

  // Open section according to URL query
  const intialOpen = useMemo(() => {
    const query = getUrlQuery()

    return query?.get('show')
  }, [])

  useEffect(() => {
    if (intialOpen) setOpenSection(intialOpen)
  }, [intialOpen])

  const profileImgLink = useMemo(() => {
    if (!currentUserData) return ''

    return currentUserData.currentUser.profileImgLink
  }, [currentUserData])

  if (!userPermission) return null

  return (
    <div
      className={classNames(styles.container, {
        [styles.noTopBorder]: !topBorder,
      })}
    >
      <ToggleBox
        noPadding
        className={styles.settingsToggleBox}
        heading={
          <div className={styles.settingHeader}>
            <img
              src={profileImgLink || missingAvatar}
              alt="Profile"
              style={{ borderRadius: '50%' }}
            />
            <div className={styles.headerText}>
              <Heading
                type={2}
                align="left"
                className={styles.settingsToggleHeader}
              >
                Profile
              </Heading>
              <span>Update your contact information.</span>
            </div>
          </div>
        }
        open={openSection === 'profile'}
        onToggle={() => {
          if (openSection === 'profile') {
            setOpenSection('')
          } else {
            setOpenSection('profile')

            logAction({
              variables: {
                action: 'open-settings-section',
                extra: '{"sectionOpened": "profile"}',
                websiteSection: 'Settings',
                pagePath: '/settings',
                functionName: 'toggleSection',
              },
            })
          }
        }}
      >
        <ProfileSettings className={styles.settingsToggleBoxInner} />
      </ToggleBox>
      <ToggleBox
        noPadding
        className={styles.settingsToggleBox}
        heading={
          <div className={styles.settingHeader}>
            <div className={styles.emoji}>
              <span role="img" aria-label="Preferences">
                ❤️
              </span>
            </div>
            <div className={styles.headerText}>
              <Heading
                type={2}
                align="left"
                className={styles.settingsToggleHeader}
              >
                Preferences
              </Heading>
              <span>Set your homepage and notifications.</span>
            </div>
          </div>
        }
        open={openSection === 'preferences'}
        onToggle={() => {
          if (openSection === 'preferences') {
            setOpenSection('')
          } else {
            setOpenSection('preferences')

            logAction({
              variables: {
                action: 'open-settings-section',
                extra: '{"sectionOpened": "preferences"}',
                websiteSection: 'Settings',
                pagePath: '/settings',
                functionName: 'toggleSection',
              },
            })
          }
        }}
      >
        <Preferences className={styles.settingsToggleBoxInner} />
      </ToggleBox>
      <ToggleBox
        noPadding
        className={styles.settingsToggleBox}
        heading={
          <div className={styles.settingHeader}>
            <div className={styles.emoji}>
              <span role="img" aria-label="Account Info">
                🏢
              </span>
            </div>
            <div className={styles.headerText}>
              <Heading type={2} align="left">
                Support
              </Heading>
              <span>View account IDs for debugging.</span>
            </div>
          </div>
        }
        open={openSection === 'support'}
        onToggle={() => {
          if (openSection === 'support') {
            setOpenSection('')
          } else {
            setOpenSection('support')
          }
        }}
      >
        <AccountSupportSettings className={styles.settingsToggleBoxInner} />
      </ToggleBox>
    </div>
  )
}
