import { makeVar } from '@apollo/client'

import { getToken } from '../../helpers'

// Used to set the page seen when a user logs in
// If they tried to access a specific page in-account
export const pageRedirect = makeVar('')

export interface LoginState {
  email: string
  password: string
  onFail: {
    message: string
    attempts: number
  }
}

export const loginForm = makeVar<LoginState>({
  email: '',
  password: '',
  onFail: {
    message: '',
    attempts: 0,
  },
})

export const registerForm = makeVar({
  success: false,
  onFail: {
    message: '',
    attempts: 0,
  },
})

interface OnboardingState {
  onError: string
  createdBillingUser: boolean
  paddleData: PaddleData | {}
}

export const onboardingState = makeVar<OnboardingState>({
  onError: '',
  createdBillingUser: false,
  paddleData: {},
})

export type LastLoginMethods =
  | 'inviteOpen'
  | 'microsoft'
  | 'google'
  | 'okta'
  | 'password'
  | ''

interface Policy {
  lastMethod: LastLoginMethods
  domainExists: boolean
  notFound: boolean
}

interface LoggedInState {
  authenticated: boolean
  checked: boolean
  runningDebug: boolean
  token: string
  isNewlyCreated?: boolean
  details: null | UserDetails
  copy: any
  policy: Policy | null
}

export const loggedInState = makeVar<LoggedInState>({
  authenticated: false,
  checked: false,
  runningDebug: false,
  token: getToken(),
  details: null,
  copy: null,
  policy: null,
})

interface PasswordState {
  forgotPassword: {
    requested: boolean
    success: boolean
  }
  resetPassword: {
    requested: boolean
    success: boolean
  }
}

export const passwordState = makeVar<PasswordState>({
  forgotPassword: {
    requested: false,
    success: false,
  },
  resetPassword: {
    requested: false,
    success: false,
  },
})

// Set in router during authentication
// Used for fast querying/referencing
interface CurrentUser {
  userID: string
  userEmail: string
  userFirstName: string
  userLastName: string
  workspaceID: string
  workspaceName: string
  userPermission: PermissionLevel | ''
  companyID: string
  companyName: string
  isDemoAccount: boolean
  companySubscriptionLevel?: string // 'free', 'enterprise', 'preSetup', '587...' (Paddle PlanID), 'beta'
  hasCreatedLinks?: boolean
  /** Used for whitelabel accounts */
  whiteLabelAdminDomain?: string | null
}

export const currentUserDetails = makeVar<CurrentUser>({
  userID: '',
  userEmail: '',
  userFirstName: '',
  userLastName: '',
  workspaceID: '',
  workspaceName: '',
  userPermission: '',
  companyID: '',
  companyName: '',
  isDemoAccount: false,
})

export interface RecentlyValidatedUrl {
  statusCode?: number
  intensiveStatusCode?: number
  badUrl: boolean
  clickedCookieConsent?: boolean
  noAnalyticsTag?: boolean
  redirectedLandingPage?: boolean
  slowLandingPage?: boolean
  validatorReturned?: boolean
}

export interface RecentlyValidatedUrls {
  [url: string]: RecentlyValidatedUrl
}

export const recentlyValidatedUrls = makeVar<RecentlyValidatedUrls>({})

export interface AvailableShortLinkDomain {
  optionName: string
  optionValue: string
  selected: boolean
}

export const availableShortLinkDomains = makeVar<AvailableShortLinkDomain[]>([])

export interface ShortLinkAliasesByDomain {
  [domain: string]: {
    individual: string[]
    batch?: string
  }
}

export const currentShortLinkDomain = makeVar('')

export const shortLinkAliasesByDomain = makeVar<ShortLinkAliasesByDomain>({})

export interface DataSourceDetails {
  connectionSource: 'not-connected' | 'adobe' | 'google'
  requiresReconnect?: boolean
}

/** Used to see which data source the workspace is connected to (if any) and if it needs reconnecting. Set on router.tsx */
export const dataSourceReactive = makeVar<DataSourceDetails | null>(null)
