import React, { useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import classNames from 'classnames'

import Button from './button'
import StyledDatePicker from './date-picker'
import { LoadingLabel, Preloader } from './loader'
import SelectBox, { SelectBoxChecklist } from './select-box'
import ClearIcon from '../assets/icon-close-grey.svg'
import {
  defaultReportValues,
  granularityFilterData,
  reportDates,
  setGranularityByDateRange,
  yesterday,
} from '../helpers/report-module'
import useLogAction from '../hooks/useLogAction'
import styles from '../styles/report-controls.module.scss'
import { AvailableDimension } from '../__gql-types__/graphql'
import {
  Granularity,
  ReportDataConfigFull,
  ReportDate,
  ReportFilterItem,
} from '../types/report-module'

interface DimensionSelectorProps {
  dimension: AvailableDimension
  onChange: (option: ReportFilterItem, blockApplyFilter?: boolean) => void
  defaultValue?: ReportFilterItem
  headlineFilter?: boolean
  optionIsRequired?: boolean
}

const DimensionSelector = ({
  dimension,
  onChange,
  defaultValue,
  headlineFilter = false,
  optionIsRequired,
}: DimensionSelectorProps) => {
  const {
    dimensionName,
    dimensionOptions,
    parameterType,
    dateFormat,
  } = dimension

  const [value, setValue] = useState<ReportFilterItem>(
    JSON.parse(JSON.stringify(defaultValue)),
  )
  const [savedOptions, setSavedOptions] = useState<string[] | null>(null)

  const useDateFormat = useMemo(() => {
    return dateFormat
      ? dateFormat
          .replace(/Y/gi, 'y')
          .replace(/D/gi, 'd')
          .replace(/(\[Q\])/gi, 'QQ')
      : ''
  }, [dateFormat])

  useEffect(() => {
    setValue(JSON.parse(JSON.stringify(defaultValue)))
  }, [defaultValue])

  if (parameterType === 'date' && !!dateFormat) {
    return (
      <div className={styles.filterSelect}>
        <StyledDatePicker
          grey
          wrapperClassName={styles.dateWrapper}
          isClearable
          dateFormat={useDateFormat}
          placeholderText={dimensionName}
          selected={
            value.dimensionOptions.length > 0
              ? moment(value.dimensionOptions[0], dateFormat).toDate()
              : null
          }
          onChange={(date) => {
            const formattedDateString = date
              ? moment(date).format(dateFormat)
              : ''

            const useValue = formattedDateString
              ? {
                  ...value,
                  dimensionOptions: [formattedDateString],
                }
              : { ...value, dimensionOptions: [] }

            setValue(useValue)
            onChange(useValue)
          }}
        />
      </div>
    )
  }

  const fullFilterList = dimensionOptions
    ? dimensionOptions.map((i) => {
        return { optionName: i === '' ? '(empty)' : i, optionValue: i }
      })
    : []

  if (parameterType === 'select' && !optionIsRequired) {
    fullFilterList.unshift({ optionName: '(not set)', optionValue: '' })
  }

  return (
    <div className={styles.filterSelect}>
      <SelectBoxChecklist
        id={dimensionName}
        variant="grey"
        excludeAny
        excludeNone
        allLabel=""
        controlLabel={
          headlineFilter &&
          dimensionOptions &&
          (value.dimensionOptions.length === 0 ||
            value.dimensionOptions.length === dimensionOptions.length)
            ? `all ${dimensionName}s`
            : dimensionName
        }
        placeholder={
          headlineFilter &&
          dimensionOptions &&
          (value.dimensionOptions.length === 0 ||
            value.dimensionOptions.length === dimensionOptions.length)
            ? `all ${dimensionName}s`
            : dimensionName
        }
        isSearchable
        labelKey="optionName"
        valueKey="optionValue"
        value={fullFilterList.filter(
          (option) => value.dimensionOptions.indexOf(option.optionValue) > -1,
        )}
        options={fullFilterList}
        onChange={(newValue) => {
          const newDimensionOptions = newValue.map(
            ({ optionValue }) => optionValue,
          )

          setValue((curr) => ({
            ...curr,
            dimensionOptions: newDimensionOptions,
          }))

          onChange(
            { ...value, dimensionOptions: newDimensionOptions },
            newValue.length > 0 && newValue.length !== fullFilterList.length,
          )

          setSavedOptions(
            newValue.length > 0 && newValue.length !== fullFilterList.length
              ? newDimensionOptions
              : null,
          )
        }}
        onBlur={async () => {
          if (savedOptions) {
            onChange({ ...value, dimensionOptions: savedOptions })

            setSavedOptions(null)
          }
        }}
      />
    </div>
  )
}

export type ReportControllers =
  | 'date'
  | 'successMetric'
  | 'granularity'
  | 'filters'

interface ReportControlsProps<
  CurrentReportConfig,
  MetricItem,
  PrimaryBreakdownID extends string
> {
  /** Used for interaction log. Lowercase, hyphenated. */
  interactionLogReportName: string
  /** Overall loading state for all controls */
  loadingData?: boolean
  /** Copy or extra dropdowns shown before any of the control inputs */
  controlsPrefix?: React.ReactNode
  /** Variables that can update report data */
  currentDataConfig: CurrentReportConfig
  /** Set variables that can update report data */
  setCurrentDataConfig: React.Dispatch<
    React.SetStateAction<CurrentReportConfig>
  >
  /** The ID used in the first dropdown (only one to appear before date) */
  primaryBreakdownID?: PrimaryBreakdownID
  /** Include the dimensions the report can be broken down by. Default = false */
  includePrimaryBreakdown?: boolean
  loadingPrimaryBreakdowns?: boolean
  fullPrimaryBreakdownList?: MatchTypeProps[]
  /** Include the date selector in the controls. Default = true */
  includeDate?: boolean
  /** Used to update the initial date without triggering onChange, for e.g. one-click reports */
  initialDate?: {
    dateValue: string
    startDate: string
    endDate: string
  }
  /** Include the success metric in the controls. Default = false */
  includeSuccessMetric?: boolean
  loadingMetrics?: boolean
  /** Always uses metricID value and displayName for label. */
  fullMetricsList?: MetricItem[]
  /** Used to include additional options at bottom of the dropdown */
  successMetricsButtons?: React.ReactElement
  /** Include the 'grouped by' selector in the controls. Default = true */
  includeGranularity?: boolean
  /** Include button to show collapsible filters content. Default = false */
  includeFilter?: boolean
  filterPropertyName?: string
  loadingFilters?: boolean
  filterError?: boolean
  /** 'required' param is used to add a '(not set)' option to filter list items that are optional */
  filterData?: (AvailableDimension & { required?: boolean })[]
  /** Refetch data and other changes. Can change the action based on which controller was changed. */
  onChange: (
    newDataConfig: CurrentReportConfig,
    changeType?: ReportControllers | PrimaryBreakdownID,
  ) => Promise<void>
}

const ReportControls = <
  CurrentReportConfig extends ReportDataConfigFull,
  MetricItem extends { metricID: string; displayName: string },
  PrimaryBreakdownID extends string
>({
  interactionLogReportName,
  loadingData = false,
  controlsPrefix = 'Show data for',
  currentDataConfig,
  setCurrentDataConfig,
  primaryBreakdownID = 'matchType' as PrimaryBreakdownID,
  includePrimaryBreakdown = false,
  loadingPrimaryBreakdowns = false,
  fullPrimaryBreakdownList,
  includeDate = true,
  initialDate,
  includeSuccessMetric = false,
  loadingMetrics = false,
  fullMetricsList,
  successMetricsButtons,
  includeGranularity = true,
  includeFilter = false,
  filterPropertyName = 'applyFilters',
  loadingFilters = false,
  filterError = false,
  filterData,
  onChange,
}: ReportControlsProps<
  CurrentReportConfig,
  MetricItem,
  PrimaryBreakdownID
>) => {
  const logAction = useLogAction()

  const [showCalendar, setShowCalendar] = useState(
    currentDataConfig.startDate === 'custom',
  )
  const [calendarOpen, setCalendarOpen] = useState(false)

  // Defaults to last 365 full days
  const [dateValue, setDateValue] = useState<ReportDate | null>(
    initialDate && defaultReportValues.indexOf(initialDate.dateValue)
      ? (reportDates.find(
          (date) => date.metricValue === initialDate.dateValue,
        ) as ReportDate)
      : {
          displayName: 'last 365 full days',
          metricValue: 'thisYear',
          label: 'the last 12 months',
          granularity: 'monthly',
        },
  )
  const [customDates, setCustomDates] = useState<[Date | null, Date | null]>([
    initialDate?.startDate ? new Date(initialDate.startDate) : null,
    initialDate?.endDate ? new Date(initialDate.endDate) : null,
  ])
  const [filterOpen, setFilterOpen] = useState(false)

  // One-click reports need to update to a custom date
  useEffect(() => {
    if (
      initialDate &&
      reportDates.find((date) => date.metricValue === initialDate.dateValue)
    ) {
      setDateValue(
        reportDates.find(
          (date) => date.metricValue === initialDate.dateValue,
        ) as ReportDate,
      )

      if (initialDate.dateValue === 'custom') {
        setCustomDates([
          new Date(initialDate.startDate),
          new Date(initialDate.endDate),
        ])
        setShowCalendar(true)
      }
    }
  }, [initialDate])

  // Remove custom dates when not custom
  useEffect(() => {
    const dateValueToUse =
      currentDataConfig.dateValue || currentDataConfig.startDate

    const foundDateValue = reportDates.find(
      (date) => date.metricValue === dateValueToUse,
    )

    if (foundDateValue) {
      setDateValue(foundDateValue)
      setCustomDates([null, null])
      setShowCalendar(false)
    } else {
      setDateValue({
        displayName: 'custom date range',
        metricValue: 'custom',
        label: 'custom',
        granularity: 'daily',
      })
      setCustomDates([
        new Date(currentDataConfig.startDate) || null,
        new Date(currentDataConfig.endDate) || null,
      ])
      setShowCalendar(true)
      setCalendarOpen(false)
    }
  }, [currentDataConfig.dateValue, currentDataConfig.startDate])

  // Placeholder to show instead of 'custom date range'
  const customDateCopy = useMemo(() => {
    const [start, end] = customDates

    if (!start || !end) return 'custom date range'

    const st = moment(start).format('DD/MM/YYYY')
    const en = moment(end).format('DD/MM/YYYY')

    return `${st} - ${en}`
  }, [customDates])

  return (
    <div className={styles.filterWrapper}>
      <div className={styles.reportControlsWrapper}>
        <div className={styles.reportControlContainer}>
          {typeof controlsPrefix === 'string' ? (
            <span className={styles.reportControlCopy}>{controlsPrefix} </span>
          ) : (
            <>{controlsPrefix}</>
          )}
        </div>
        {includePrimaryBreakdown && fullPrimaryBreakdownList && (
          <div className={styles.reportControlContainer}>
            <SelectBox
              variant="grey"
              id={primaryBreakdownID}
              className={styles.controlsSelector}
              isSearchable={false}
              isLoading={loadingData || loadingPrimaryBreakdowns}
              isDisabled={loadingData}
              labelKey="name"
              defaultValue={fullPrimaryBreakdownList[0]}
              value={
                currentDataConfig[primaryBreakdownID as string]
                  ? fullPrimaryBreakdownList.find(
                      (breakdownItem) =>
                        breakdownItem.value ===
                        currentDataConfig[primaryBreakdownID as string],
                    )
                  : fullPrimaryBreakdownList[0]
              }
              options={fullPrimaryBreakdownList}
              onChange={async (newValue) => {
                if (!newValue) return

                const newDataConfig = {
                  ...currentDataConfig,
                  [primaryBreakdownID]: newValue.value,
                }

                setCurrentDataConfig(newDataConfig)

                await onChange(newDataConfig, primaryBreakdownID)

                logAction({
                  variables: {
                    action: `update-${primaryBreakdownID}-${interactionLogReportName}`,
                    extra: newValue.value,
                    websiteSection: 'report',
                    functionName: `update_${primaryBreakdownID}`,
                    pagePath: window.location.pathname,
                  },
                })
              }}
            />
          </div>
        )}
        {includeDate && (
          <div className={styles.reportControlContainer}>
            {includePrimaryBreakdown && (
              <span className={styles.reportControlCopy}>over </span>
            )}
            <SelectBox
              variant="grey"
              className={classNames(
                styles.controlsSelector,
                styles.dateSelector,
              )}
              isLoading={loadingData}
              isDisabled={loadingData}
              labelKey="displayName"
              valueKey="metricValue"
              isSearchable={false}
              defaultValue={reportDates.find(
                (date) => date.metricValue === 'thisYear',
              )}
              placeholder={loadingData ? 'date range' : customDateCopy}
              value={showCalendar || loadingData ? null : dateValue}
              options={reportDates}
              onChange={async (newValue) => {
                if (!newValue) return

                const { metricValue, granularity } = newValue

                if (metricValue === 'custom') {
                  // Don't update the date range until it has been selected
                  setShowCalendar(true)
                  setDateValue(null)
                  setCalendarOpen(true)
                } else {
                  setShowCalendar(false)
                  setCalendarOpen(false)

                  let dateValueToUse: string | undefined
                  let startDate = metricValue
                  let endDate = ''
                  let dateBasedGranularity = granularity

                  const currentDate = new Date(Date.now())
                  const currentYear = currentDate.getFullYear()
                  const lastYear = currentYear - 1

                  // Used for 'last x days' calls
                  const newStartDate = new Date(yesterday)

                  switch (metricValue) {
                    case 'ytd':
                      dateValueToUse = metricValue
                      // Get first day of current year
                      startDate = moment(new Date(currentYear, 0, 1)).format(
                        'YYYY-MM-DD',
                      )
                      endDate = moment(yesterday).format('YYYY-MM-DD')
                      dateBasedGranularity = setGranularityByDateRange(
                        moment().startOf('year').toDate(),
                        yesterday,
                      )
                      break
                    case 'lytd':
                      dateValueToUse = metricValue
                      startDate = moment(new Date(lastYear, 0, 1)).format(
                        'YYYY-MM-DD',
                      )
                      endDate = moment(new Date(lastYear, 11, 31)).format(
                        'YYYY-MM-DD',
                      )
                      break
                    case 'l7d':
                      dateValueToUse = metricValue
                      newStartDate.setDate(yesterday.getDate() - 6)
                      startDate = moment(newStartDate).format('YYYY-MM-DD')
                      endDate = moment(yesterday).format('YYYY-MM-DD')
                      break
                    case 'l30d':
                      dateValueToUse = metricValue
                      newStartDate.setDate(yesterday.getDate() - 29)
                      startDate = moment(newStartDate).format('YYYY-MM-DD')
                      endDate = moment(yesterday).format('YYYY-MM-DD')
                      break
                    case 'l60d':
                      dateValueToUse = metricValue
                      newStartDate.setDate(yesterday.getDate() - 59)
                      startDate = moment(newStartDate).format('YYYY-MM-DD')
                      endDate = moment(yesterday).format('YYYY-MM-DD')
                      break
                    case 'l90d':
                      dateValueToUse = metricValue
                      newStartDate.setDate(yesterday.getDate() - 89)
                      startDate = moment(newStartDate).format('YYYY-MM-DD')
                      endDate = moment(yesterday).format('YYYY-MM-DD')
                      break
                    default:
                      break
                  }

                  const newDataConfig: CurrentReportConfig = {
                    ...currentDataConfig,
                    dateValue: dateValueToUse,
                    startDate,
                    endDate,
                    granularity: dateBasedGranularity,
                  }

                  setCurrentDataConfig(newDataConfig)
                  setDateValue(newValue)

                  await onChange(newDataConfig, 'date')

                  logAction({
                    variables: {
                      action: `update-date-range-${interactionLogReportName}`,
                      extra: newValue.metricValue,
                      websiteSection: 'report',
                      functionName: 'updateDateRange',
                      pagePath: window.location.pathname,
                    },
                  })
                }
              }}
            />
            {showCalendar && (
              <StyledDatePicker
                buttonOnly
                wrapperClassName={styles.calendarDateWrapper}
                startOpen={calendarOpen}
                selectsRange
                startDate={customDates[0]}
                endDate={customDates[1]}
                onChange={(dates) => {
                  const [start, end] = dates

                  setCustomDates(dates)

                  if (!start || !end) return

                  const st = moment(start).format('YYYY-MM-DD')
                  const en = moment(end).format('YYYY-MM-DD')
                  const days = moment(end).diff(start, 'days')

                  const newDataConfig = {
                    ...currentDataConfig,
                    dateValue: undefined,
                    startDate: st,
                    endDate: en,
                    granularity: setGranularityByDateRange(start, end),
                  }

                  setCurrentDataConfig(newDataConfig)

                  onChange(newDataConfig, 'date')

                  if (st !== en) {
                    logAction({
                      variables: {
                        action: `update-date-range-${interactionLogReportName}`,
                        extra: `custom - start: ${st} end: ${en} days: ${days}`,
                        websiteSection: 'report',
                        functionName: 'updateDateRange',
                        pagePath: window.location.pathname,
                      },
                    })
                  }
                }}
                maxDate={new Date(Date.now())}
              />
            )}
          </div>
        )}
        {includeSuccessMetric && (
          <div className={styles.reportControlContainer}>
            <span className={styles.reportControlCopy}> ranked by </span>
            <SelectBox
              variant="grey"
              id="successMetric"
              className={styles.controlsSelector}
              placeholder="success metric"
              isLoading={loadingData || loadingMetrics}
              isDisabled={loadingData}
              labelKey="displayName"
              valueKey="metricID"
              value={
                fullMetricsList?.find(
                  (metric) =>
                    metric.metricID === currentDataConfig.selectedMetric,
                ) || null
              }
              options={fullMetricsList || []}
              onChange={(newValue) => {
                if (!newValue) return

                const { metricID } = newValue

                const newDataConfig: CurrentReportConfig = {
                  ...currentDataConfig,
                  selectedMetric: metricID,
                }

                setCurrentDataConfig(newDataConfig)

                onChange(newDataConfig, 'successMetric')

                logAction({
                  variables: {
                    action: `update-success-metric-${interactionLogReportName}`,
                    extra: metricID,
                    websiteSection: 'report',
                    functionName: 'updateSuccessMetric',
                    pagePath: window.location.pathname,
                  },
                })
              }}
            >
              {successMetricsButtons}
            </SelectBox>
          </div>
        )}
        {includeGranularity && (
          <div className={styles.reportControlContainer}>
            <span className={styles.reportControlCopy}> grouped by </span>
            <SelectBox
              variant="grey"
              className={styles.controlsSelector}
              isLoading={loadingData}
              isDisabled={loadingData}
              labelKey="name"
              valueKey="value"
              isSearchable={false}
              defaultValue={{ name: 'Month', value: 'monthly' }}
              value={granularityFilterData.find(
                (item) => item.value === currentDataConfig.granularity,
              )}
              options={granularityFilterData}
              onChange={async (newValue) => {
                if (!newValue) return

                const newDataConfig = {
                  ...currentDataConfig,
                  granularity: newValue.value as Granularity,
                }

                setCurrentDataConfig(newDataConfig)

                await onChange(newDataConfig, 'granularity')

                logAction({
                  variables: {
                    action: `update-granularity-${interactionLogReportName}`,
                    extra: newValue.value,
                    websiteSection: 'report',
                    functionName: 'updateGranularity',
                    pagePath: window.location.pathname,
                  },
                })
              }}
            />
          </div>
        )}
        {includeFilter && (
          <div className={styles.reportControlContainer}>
            <Button
              className={classNames(styles.filtersButton, {
                [styles.isExpanded]: filterOpen,
              })}
              isDisabled={filterError || !filterData}
              variant="secondary"
              onPress={async () => {
                if (!filterOpen) {
                  await logAction({
                    variables: {
                      action: `edit-filters-${interactionLogReportName}`,
                      extra: '',
                      websiteSection: 'report',
                      functionName: 'openFilters',
                      pagePath: window.location.pathname,
                    },
                  })
                }

                setFilterOpen((curr) => !curr)
              }}
            >
              {filterOpen ? 'Hide filters' : 'Apply filters'}
            </Button>
          </div>
        )}
      </div>
      {filterOpen && (
        <>
          {loadingData || loadingFilters ? (
            <div className={styles.filtersLoading}>
              <Preloader className={styles.filtersPreloader} />
              <LoadingLabel label="Loading filters" fixedWidth />
            </div>
          ) : (
            <div className={styles.filterDropdownsContainer}>
              {filterData &&
                filterData.map((filterItem) => {
                  const hasValues =
                    filterItem.dimensionOptions &&
                    filterItem.dimensionOptions.length > 0

                  const fullFilterList: AvailableDimension[] =
                    currentDataConfig[filterPropertyName] || []

                  const initialSelectedValue = fullFilterList.find(
                    (dimension) =>
                      dimension.dimensionParameterID ===
                      filterItem.dimensionParameterID,
                  )

                  const optionIsRequired =
                    typeof filterItem.required === 'boolean'
                      ? filterItem.required
                      : true

                  return (
                    <DimensionSelector
                      defaultValue={
                        !hasValues || !initialSelectedValue
                          ? {
                              dimensionParameterID:
                                filterItem.dimensionParameterID,
                              dimensionName: filterItem.dimensionName,
                              dimensionOptions: [],
                            }
                          : {
                              ...initialSelectedValue,
                              dimensionOptions:
                                initialSelectedValue.dimensionOptions || [],
                            }
                      }
                      dimension={filterItem}
                      key={JSON.stringify(filterItem)}
                      optionIsRequired={optionIsRequired}
                      onChange={async (val, blockApplyFilter) => {
                        const newDataConfig = {
                          ...currentDataConfig,
                        }

                        // Update filter list
                        if (
                          val.dimensionOptions.length > 0 &&
                          (!currentDataConfig[filterPropertyName] ||
                            currentDataConfig[filterPropertyName].length === 0)
                        ) {
                          newDataConfig[filterPropertyName] = [
                            {
                              ...val,
                            },
                          ]
                        } else {
                          const newFilterList = [
                            ...currentDataConfig[filterPropertyName],
                          ]

                          const dimensionFilterIndex = newFilterList.findIndex(
                            (dim) =>
                              dim.dimensionParameterID ===
                              filterItem.dimensionParameterID,
                          )

                          if (
                            dimensionFilterIndex === -1 &&
                            val.dimensionOptions.length > 0
                          ) {
                            newFilterList.push({
                              ...val,
                            })
                          } else if (val.dimensionOptions.length > 0) {
                            newFilterList.splice(dimensionFilterIndex, 1, {
                              ...val,
                            })
                          } else {
                            // Do not include filter item if it has no values
                            newFilterList.splice(dimensionFilterIndex, 1)
                          }

                          newDataConfig[filterPropertyName] = newFilterList
                        }

                        // Clear the filter property if it's empty
                        if (newDataConfig[filterPropertyName].length === 0) {
                          newDataConfig[filterPropertyName] = undefined
                        }

                        setCurrentDataConfig(newDataConfig)

                        // Prevents filters being applied immediately for multiselect
                        if (!blockApplyFilter) {
                          await onChange(newDataConfig, 'filters')

                          logAction({
                            variables: {
                              action: `update-filters-${interactionLogReportName}`,
                              extra: JSON.stringify(newDataConfig),
                              websiteSection: 'report',
                              functionName: 'updateFilters',
                              pagePath: window.location.pathname,
                            },
                          })
                        }
                      }}
                    />
                  )
                })}
              {!loadingFilters && filterData && filterData.length !== 0 && (
                <div className={styles.wrapper}>
                  <Button
                    className={styles.resetButton}
                    variant="text"
                    color="grey"
                    onPress={async () => {
                      const newDataConfig = {
                        ...currentDataConfig,
                        [filterPropertyName]: undefined,
                      }

                      setCurrentDataConfig(newDataConfig)

                      await onChange(newDataConfig, 'filters')

                      logAction({
                        variables: {
                          action: `update-filters-${interactionLogReportName}`,
                          extra: JSON.stringify(newDataConfig),
                          websiteSection: 'report',
                          functionName: 'updateFIlters',
                          pagePath: window.location.pathname,
                        },
                      })
                    }}
                    icon={{
                      src: ClearIcon,
                      alt: 'Clear all',
                    }}
                  >
                    Reset filters
                  </Button>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default ReportControls
