import React, { useEffect, useMemo, useState } from 'react'
import { useLazyQuery, useReactiveVar } from '@apollo/client'

import { currentUserDetails } from '../api/apollo/variables'
import { getCampaignCodeGenerator } from '../api/graphql/track-create-client'
import BulkImport from '../components/bulk-import'
import ButtonTabs from '../components/button-tabs'
import CampaignCodeGeneratorForm from '../components/campaign-code-generator-form'
import CampaignCodeGeneratorCloneTab from '../components/campaign-code-generator-clone-tab'
import CampaignIntroContent from '../components/campaign-intro-content'
import EmailCodeGeneratorForm from '../components/email-code-generator-form'
import Intro from '../components/intro'
import Layout from '../components/layout'
import Link from '../components/link'
import { NewEmailHTMLPreview } from '../components/new-email-html-preview'
import RequestField, { RequestFieldModal } from '../components/request-field'
import SiteWrapper from '../components/site-wrapper'
import RecentlyCreatedLinks from '../components/track-recently-created-links'
import { ShowLatestCode } from '../components/track-show-latest-link'
import TwoColumns, { Column } from '../components/two-columns'
import { trackCreateTabs } from '../core/constants'
import useLogAction from '../hooks/useLogAction'
import useOnboarding from '../hooks/useOnboarding'
import { getLocalItem } from '../helpers/local-client'

interface TrackCreateIntroProps {
  hasCreatedCode?: boolean
}

export function TrackCreateIntro({
  hasCreatedCode = false,
}: TrackCreateIntroProps) {
  return (
    <Intro title="Create links">
      <CampaignIntroContent firstTime={!hasCreatedCode}>
        <p>
          Create campaign links here to help you track and understand campaign
          traffic. &nbsp;
          <Link type="arrowForward" href="/track/learn">
            Learn more
          </Link>
        </p>
      </CampaignIntroContent>
    </Intro>
  )
}

export default function TrackCreate() {
  const { workspaceID, userID } = useReactiveVar(currentUserDetails)

  const logAction = useLogAction()

  const {
    fullOnboardingSections: { user: userOnboardingSections },
  } = useOnboarding()

  const [getGenerator, { data: generatorData }] = useLazyQuery(
    getCampaignCodeGenerator,
  )

  const [currentTab, setCurrentTab] = useState<keyof typeof trackCreateTabs>(
    'single',
  )
  const [newLinks, setNewLinks] = useState<string | string[]>('')
  const [previousEmailHtml, setPreviousEmailHtml] = useState('')
  const [newEmailHtml, setNewEmailHtml] = useState<GeneratedEmailHTML | null>(
    null,
  )
  const [isIntegrationEmail, setIsIntegrationEmail] = useState(false)
  const [requestFieldName, setRequestFieldName] = useState('')
  const [requestFieldModalActive, setRequestFieldModalActive] = useState(false)

  // Wait for workspace ID so generator can be cached
  useEffect(() => {
    if (!userID || !workspaceID) return

    // Set active tab
    const savedData = getLocalItem('track-create') || null

    if (
      savedData &&
      Object.prototype.hasOwnProperty.call(savedData, workspaceID)
    ) {
      if (savedData[workspaceID].email) {
        const prevEmail = savedData[workspaceID].email.generatedEmailHtml

        if (prevEmail) setPreviousEmailHtml(prevEmail)
      }

      setCurrentTab(savedData[workspaceID]?.options?.active || 'single')
    }

    // Ensure generator (fast request) is fetched before getUserLinks (slow request)
    // On prod, getUserLinks can block getGenerator
    // https://en.wikipedia.org/wiki/Head-of-line_blocking#:~:text=Head-of-line%20blocking%20(,multiple%20requests%20in%20HTTP%20pipelining.
    const fetchTrackCreateData = async () => {
      await getGenerator()
    }

    fetchTrackCreateData()
  }, [userID, workspaceID])

  const generatedStructure = useMemo(() => {
    if (!generatorData) return null

    return generatorData.campaignCodeGenerator
  }, [generatorData])

  // Check if onboarding step is completed
  const hasCreatedCode = useMemo(() => {
    const createCampaignLinkSection = userOnboardingSections.find(
      (section) => section.onboardingSectionID === 'createCampaignLink',
    )

    return !!(
      createCampaignLinkSection && createCampaignLinkSection.sectionCompleted
    )
  }, [userOnboardingSections])

  return (
    <>
      <SiteWrapper>
        <Layout width={1200}>
          <TrackCreateIntro hasCreatedCode={hasCreatedCode} />
          <TwoColumns>
            <Column main>
              <div>
                <ButtonTabs
                  selected={Object.keys(trackCreateTabs).indexOf(currentTab)}
                  isTopOfBox
                  tabsLabels={Object.values(trackCreateTabs)}
                  type="tabs"
                  onChange={(index) => {
                    if (!generatedStructure) return

                    setCurrentTab(
                      Object.keys(trackCreateTabs)[
                        index
                      ] as keyof typeof trackCreateTabs,
                    )

                    logAction({
                      variables: {
                        action: 'track-create-change-tab',
                        pagePath: '/track/create-links',
                        functionName: 'changeTab',
                        websiteSection: 'track',
                        extra: Object.values(trackCreateTabs)[index],
                      },
                    })
                  }}
                >
                  <CampaignCodeGeneratorForm
                    key="generator-single"
                    loading={!generatedStructure}
                    single
                    setNewLinks={setNewLinks}
                    onRequestNewField={(requestedValue) => {
                      setRequestFieldName(requestedValue)
                      setRequestFieldModalActive(true)
                    }}
                    hasCreatedCode={hasCreatedCode}
                  />
                  <CampaignCodeGeneratorForm
                    key="generator-multi"
                    loading={!generatedStructure}
                    single={false}
                    setNewLinks={setNewLinks}
                    hasCreatedCode={hasCreatedCode}
                  />
                  <CampaignCodeGeneratorCloneTab key="generator-clone" />
                  <BulkImport
                    key="generator-bulk"
                    loading={!generatedStructure}
                    hasCreatedCode={hasCreatedCode}
                  />
                  <EmailCodeGeneratorForm
                    key="generator-email"
                    loading={!generatedStructure}
                    newEmailHtml={newEmailHtml}
                    setNewEmailHtml={setNewEmailHtml}
                    setIsIntegrationEmail={setIsIntegrationEmail}
                    onRequestNewField={(requestedValue) => {
                      setRequestFieldName(requestedValue)
                      setRequestFieldModalActive(true)
                    }}
                    setNewLinks={setNewLinks}
                    hasCreatedCode={hasCreatedCode}
                  />
                </ButtonTabs>
              </div>

              {/* QR code preview for single link */}
              {currentTab === 'single' &&
                typeof newLinks === 'string' &&
                newLinks !== '' && <ShowLatestCode newLink={newLinks} />}

              {/* Most recently generated email HTML */}
              {/* If created in current session, highlights where URLs were changed */}
              {currentTab === 'email' &&
                (newEmailHtml || previousEmailHtml) && (
                  <NewEmailHTMLPreview
                    newEmailHtml={newEmailHtml}
                    previousEmailHtml={previousEmailHtml}
                    isIntegrationEmail={isIntegrationEmail}
                  />
                )}
            </Column>
            <Column side fixed>
              <RecentlyCreatedLinks
                loading={!generatedStructure}
                generatedStructure={generatedStructure}
                newLinks={newLinks}
              />
              <RequestField />
            </Column>
          </TwoColumns>
        </Layout>
      </SiteWrapper>
      {requestFieldModalActive && (
        <RequestFieldModal
          active={requestFieldModalActive}
          toggleActive={setRequestFieldModalActive}
          requestFieldName={requestFieldName}
        />
      )}
    </>
  )
}
