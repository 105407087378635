import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'
import numeral from 'numeraljs'
import reactStringReplace from 'react-string-replace'
import moment from 'moment'
import FileSaver from 'file-saver'
import _ from 'lodash'

import Button, { ClearButton, CopyButton, NavigateButton } from './button'
import WarningModal from './campaign-code-generator-warning-modal'
import EmailPreviewTable from './email-preview-table'
import GeneratorFormFields from './generator-form-fields'
import Input, { Label } from './input'
import Link from './link'
import Loader, { LoadingLabel } from './loader'
import Modal from './modal'
import { RequestShortLinksModal } from './upgrade-modals'
import { FieldSlot, FormRow, LabelSlot } from './row'
import SelectBox from './select-box'
import { ShortLinkFull } from './short-link'
import Tooltip from './tooltip'
import EloquaEmailFields, {
  EloquaEmailDetails,
} from './track-create-eloqua-email-fields'
import SalesforceMCEmailFields, {
  SalesforceMCEmailDetails,
} from './track-create-salesforce-mc-email-fields'
import SalesforcePardotEmailFields, {
  SalesforcePardotEmailDetails,
} from './track-create-salesforce-pardot-email-fields'
import { InnerBox, OuterBox } from './two-columns'
import { currentUserDetails, dataSourceReactive } from '../api/apollo/variables'
import {
  getEloquaTemplateHTML,
  getIntegrationsStatus,
  getSalesforceEmailHTML,
  getSalesforceMCEmailTemplateDetail,
  updateEloquaTemplateEmailHTML,
  updateSalesforceEmailHTMLByEmailID,
  updateSalesforceMCEmailTemplate,
} from '../api/graphql/integrations-client'
import {
  createNewLink,
  getCampaignCodeGenerator,
  getUplifterIDCurrentTotal,
  updateCurrentSequentialCodeID,
} from '../api/graphql/track-create-client'
import { CampaignCodeGeneratorStructure } from '../api/types'
import {
  FullValidationCheck,
  brandName,
  maxBatchShortLinks,
  messages,
  minBatchShortLinks,
  supportEmail,
} from '../core/constants'
import {
  getCustomDomainID,
  getItemByKeyValue,
  getValidationCheck,
  getValidationChecksObject,
  isAdminUser,
  isValidInput,
  isValidUrl,
  loopApiCall,
} from '../helpers'
import generateCode, {
  GeneratedCode,
  getAnchorFromString,
  hrefRegex,
  htmlHasLinks,
  httpRegex,
  isAllFormDataValid,
} from '../helpers/track-module'
import useLogAction from '../hooks/useLogAction'
import useOnboarding from '../hooks/useOnboarding'
import useShortLinks from '../hooks/useShortLinks'
import {
  getFormData,
  getUserData,
  returnUnique,
  saveFormData,
  saveUserData,
} from '../helpers/local-client'
import styles from '../styles/email-code-generator-form.module.scss'

type EmailSources = 'html' | 'salesforceMC' | 'lightning' | 'classic' | 'eloqua'

const emailSourceOptions: {
  emailSource: EmailSources
  emailSourceName: string
}[] = [
  {
    emailSource: 'eloqua',
    emailSourceName: 'Eloqua',
  },
  {
    emailSource: 'salesforceMC',
    emailSourceName: 'Salesforce Marketing Cloud',
  },
  {
    emailSource: 'classic',
    emailSourceName: 'Salesforce, MCAE (Pardot): Classic Builder',
  },
  {
    emailSource: 'lightning',
    emailSourceName: 'Salesforce, MCAE (Pardot): Lightning Builder',
  },
  {
    emailSource: 'html',
    emailSourceName: 'Other (Copy & paste HTML manually)',
  },
]

interface HTMLEmailDetails {
  emailSource: 'html'
  emailHtml: string
}

type EmailDetails =
  | HTMLEmailDetails
  | SalesforcePardotEmailDetails
  | EloquaEmailDetails
  | SalesforceMCEmailDetails

function isSalesforceMCEmail(
  emailDetails: EmailDetails,
): emailDetails is SalesforceMCEmailDetails {
  return emailDetails.emailSource === 'salesforceMC'
}

function isSalesforcePardotEmail(
  emailDetails: EmailDetails,
): emailDetails is SalesforcePardotEmailDetails {
  return ['lightning', 'classic'].indexOf(emailDetails.emailSource) > -1
}

function isEloquaEmail(
  emailDetails: EmailDetails,
): emailDetails is EloquaEmailDetails {
  return emailDetails.emailSource === 'eloqua'
}

interface EmailCodeGeneratorFormProps {
  loading?: boolean
  newEmailHtml: GeneratedEmailHTML | null
  setNewEmailHtml: Dispatch<SetStateAction<GeneratedEmailHTML | null>>
  setIsIntegrationEmail: Dispatch<SetStateAction<boolean>>
  onRequestNewField?: (value: string) => void
  setNewLinks: Dispatch<SetStateAction<string | string[]>>
  hasCreatedCode?: boolean
}

const EmailCodeGeneratorForm = ({
  loading = false,
  newEmailHtml,
  setNewEmailHtml,
  setIsIntegrationEmail,
  setNewLinks,
  onRequestNewField,
  hasCreatedCode = true,
}: EmailCodeGeneratorFormProps) => {
  const history = useHistory()

  const logAction = useLogAction()

  const { canUseShortLinks, useAliases } = useShortLinks()

  const {
    workspaceID,
    userPermission: currentAccountPermission,
    companySubscriptionLevel,
  } = useReactiveVar(currentUserDetails)
  const dataSource = useReactiveVar(dataSourceReactive)

  const isAdmin = isAdminUser(currentAccountPermission)

  const { updateOnboardingSection } = useOnboarding()

  const { data: integrationData, loading: loadingIntegrationsData } = useQuery(
    getIntegrationsStatus,
  )
  const [getGenerator, { data: generatorData }] = useLazyQuery(
    getCampaignCodeGenerator,
  )
  const [
    getUplifterID,
    { data: uplifterIdData, error: uplifterIdError },
  ] = useLazyQuery(getUplifterIDCurrentTotal, { fetchPolicy: 'network-only' })
  const [checkUplifterID, { loading: checkingUplifterIDs }] = useMutation(
    updateCurrentSequentialCodeID,
    {
      notifyOnNetworkStatusChange: true,
    },
  )
  const [addCode, { loading: addingCodes }] = useMutation(createNewLink)
  const [
    fetchSFMCEmailHTML,
    { loading: fetchingSFMCEmailHtml, error: errorFetchingSFMCEmailHtml },
  ] = useLazyQuery(getSalesforceMCEmailTemplateDetail)
  const [
    fetchSFPardotEmailHTML,
    {
      loading: fetchingSFPardotEmailHtml,
      error: errorFetchingSFPardotEmailHtml,
    },
  ] = useLazyQuery(getSalesforceEmailHTML, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })
  const [
    fetchEloquaEmailHtml,
    { loading: fetchingEloquaEmailHtml, error: errorFetchingEloquaEmailHtml },
  ] = useLazyQuery(getEloquaTemplateHTML, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  })
  const [updateSFMCEmailHtml, { loading: updatingSFMCEmail }] = useMutation(
    updateSalesforceMCEmailTemplate,
  )
  const [updatePardotEmailHtml, { loading: updatingPardotEmail }] = useMutation(
    updateSalesforceEmailHTMLByEmailID,
  )
  const [updateEloquaEmailHtml, { loading: updatingEloquaEmail }] = useMutation(
    updateEloquaTemplateEmailHTML,
  )

  // Wait for account ID so generator can be cached
  useEffect(() => {
    if (!workspaceID) return

    getGenerator()
  }, [workspaceID])

  // Adobe accounts don't use UplifterID
  useEffect(() => {
    if (
      !!workspaceID &&
      (!dataSource || dataSource.connectionSource !== 'adobe')
    ) {
      getUplifterID()
    }
  }, [workspaceID, dataSource])

  const generatedStructure = useMemo(() => {
    if (!generatorData) return null

    return generatorData.campaignCodeGenerator
  }, [generatorData])

  const useUplifterID = useMemo(() => {
    if (!uplifterIdData || dataSource?.connectionSource === 'adobe')
      return false

    return !!uplifterIdData.track.currentSequentialCodeID.isEnabled
  }, [uplifterIdData])

  const savedState = getUserData()
  const initialState = savedState?.linkType || 'full'
  const [linkType, setLinkType] = useState(initialState)

  const [shortLinkDomain, setShortLinkDomain] = useState('')
  const [shortLinkStatus, setShortLinkStatus] = useState<UrlStatus>('')
  const [previewTableShortLinks, setPreviewTableShortLinks] = useState<{
    [code: string]: string
  }>({})

  const [showShortLinkModal, setShowShortLinkModal] = useState(false)
  const [showPreviewModal, setShowPreviewModal] = useState(false)
  const [warningModalType, setWarningModalType] = useState<WarningTypes>('')
  const [showWarningModal, setShowWarningModal] = useState(false)

  const [emailDetails, setEmailDetails] = useState<EmailDetails>({
    emailSource: 'html',
    emailHtml: '',
  })
  const [emailLinks, setEmailLinks] = useState<GeneratedCode[]>([])

  const [form, setForm] = useState<CampaignCodeGeneratorStructure | null>(
    generatedStructure,
  )

  const [submitDisabled, setSubmitDisabled] = useState(true)
  const [linksFound, setLinksFound] = useState<string[]>([])
  const [noLinksFound, setNoLinksFound] = useState(false)
  const [errors, setErrors] = useState<string[]>([])
  const [submitError, setSubmitError] = useState(false)
  const [createLinksError, setCreateLinksError] = useState(false)
  const [domainValidationFailed, setDomainValidationFailed] = useState(false)

  const {
    salesforceMCConnected,
    salesforcePardotConnected,
    eloquaConnected,
  } = useMemo(() => {
    if (!integrationData)
      return {
        salesforceMCConnected: false,
        salesforcePardotConnected: false,
        eloquaConnected: false,
      }

    let _salesforcePardotConnected: 'requiresReconnect' | boolean =
      integrationData.currentCompany.pardotIntegrationStatus === 'active'

    if (
      integrationData.currentCompany.pardotIntegrationStatus ===
      'needs_reconnect'
    ) {
      _salesforcePardotConnected = 'requiresReconnect'
    }

    return {
      salesforceMCConnected:
        integrationData.currentCompany.salesforceMcIntegrationStatus ===
        'active',
      salesforcePardotConnected: _salesforcePardotConnected,
      eloquaConnected:
        integrationData.currentCompany.eloquaIntegrationStatus === 'active',
    }
  }, [integrationData])

  // Set email source to Eloqua/SF if connected
  useEffect(() => {
    if (eloquaConnected) {
      setEmailDetails({
        emailSource: 'eloqua',
        eloquaOrg: null,
        emailGroup: null,
        emailTemplate: null,
        emailHtml: '',
        updateSuccess: false,
      })
    } else if (salesforcePardotConnected) {
      setEmailDetails({
        emailSource: 'classic',
        businessUnitId: null,
        emailTemplate: null,
        emailHtml: '',
        fetchedSavedBusinessUnit: false,
        updateSuccess: false,
      })
    } else if (salesforceMCConnected) {
      setEmailDetails({
        emailSource: 'salesforceMC',
        businessUnitId: null,
        businessUnitName: null,
        templateID: null,
        templateType: null,
        slotMap: null,
        textContent: null,
        emailHtml: '',
        updateSuccess: false,
      })
    }
  }, [salesforceMCConnected, salesforcePardotConnected, eloquaConnected])

  // Salesforce Classic builders can use 'list templates'
  // We can't update list templates via API
  // So templates like this must have instructions shown for them
  const isListTemplate = useMemo(() => {
    return (
      isSalesforcePardotEmail(emailDetails) &&
      emailDetails.emailTemplate?.isClassicListEmail
    )
  }, [emailDetails])

  // Don't allow short links from local storage for unpaid accounts
  useEffect(() => {
    if (companySubscriptionLevel && linkType === 'short' && !canUseShortLinks) {
      setLinkType('full')
      saveUserData({ linkType: 'full' })
    }
  }, [companySubscriptionLevel])

  const inlineErrorsPresent = errors.length !== 0

  const softDisable = useMemo(() => {
    let emailHasNoLinks = !htmlHasLinks(emailDetails.emailHtml)

    // Salesforce Marketing Cloud emails also need their slotMap and textContent checked
    if (
      emailHasNoLinks &&
      isSalesforceMCEmail(emailDetails) &&
      emailDetails.templateType === 'templatebasedemail' &&
      emailDetails.slotMap &&
      emailDetails.textContent
    ) {
      emailHasNoLinks =
        !emailDetails.slotMap.find((slot) => {
          return slot.hasLinks
        }) && !htmlHasLinks(emailDetails.textContent, true)
    }

    return (
      inlineErrorsPresent ||
      submitDisabled ||
      emailHasNoLinks ||
      (emailDetails.emailHtml && emailDetails.emailHtml.length >= 100000)
    )
  }, [inlineErrorsPresent, submitDisabled, emailDetails])

  // Reset errors when toggling form type
  useEffect(() => {
    setSubmitError(false)
    setErrors([])
  }, [emailDetails.emailSource])

  useEffect(() => {
    if (submitError && errors.length === 0) setSubmitError(false)
  }, [submitError, errors])

  const existingParametersAddedToStart = useMemo(() => {
    return generatedStructure?.existingParametersAddedToStart
  }, [generatedStructure])

  const validationChecks: FullValidationCheck[] | null = useMemo(() => {
    return getValidationChecksObject(generatedStructure)
  }, [generatedStructure])

  // Initial value setter for link type based on validationChecks
  useEffect(() => {
    if (!canUseShortLinks) return

    const foundShortLink = getItemByKeyValue(
      validationChecks,
      'name',
      'FORCE_SHORT_LINK',
    )

    if (
      foundShortLink !== -1 &&
      foundShortLink.enabled &&
      foundShortLink.value
    ) {
      const shortLinkValue = JSON.parse(foundShortLink.value)

      const selectedRule = shortLinkValue.find((option: any) => option.selected)
        .optionValue

      if (selectedRule === 'force-short-links' && linkType === 'full') {
        setLinkType('short')
        return
      }

      if (selectedRule === 'force-long-links' && linkType === 'short') {
        setLinkType('full')
        return
      }

      if (!savedState?.linkType) {
        setLinkType(selectedRule === 'recommend-short-links' ? 'short' : 'full')
      }
    }

    // If rule not found, set based on subscription level
    if (!savedState?.linkType) {
      setLinkType(canUseShortLinks ? 'short' : 'full')
    }
  }, [savedState, generatedStructure, canUseShortLinks])

  const {
    hideLongLinkOption,
    hideShortLinkOption,
    showShortLinkOptionFirst,
  } = useMemo(() => {
    if (!canUseShortLinks) {
      return {
        hideLongLinkOption: false,
        hideShortLinkOption: false,
        showShortLinkOptionFirst: false,
      }
    }

    const foundShortLink = getItemByKeyValue(
      validationChecks,
      'name',
      'FORCE_SHORT_LINK',
    )

    if (foundShortLink && foundShortLink.enabled && foundShortLink.value) {
      const shortLinkValue = JSON.parse(foundShortLink.value)

      const selectedRule = shortLinkValue.find((option: any) => option.selected)
        .optionValue

      switch (selectedRule) {
        case 'force-short-links':
          return {
            hideLongLinkOption: true,
            hideShortLinkOption: false,
            showShortLinkOptionFirst: false,
          }
        case 'force-long-links':
          return {
            hideLongLinkOption: false,
            hideShortLinkOption: true,
            showShortLinkOptionFirst: false,
          }
        case 'recommend-short-links':
          return {
            hideLongLinkOption: false,
            hideShortLinkOption: false,
            showShortLinkOptionFirst: true,
          }
        case 'recommend-long-links':
          return {
            hideLongLinkOption: false,
            hideShortLinkOption: false,
            showShortLinkOptionFirst: false,
          }
        default:
          return {
            hideLongLinkOption: false,
            hideShortLinkOption: false,
            showShortLinkOptionFirst: canUseShortLinks,
          }
      }
    }

    return {
      hideLongLinkOption: false,
      hideShortLinkOption: false,
      showShortLinkOptionFirst: canUseShortLinks,
    }
  }, [generatedStructure, canUseShortLinks])

  const showShortLinkEdit = useMemo(() => {
    return linkType === 'short' || linkType === 'combined'
  }, [linkType])

  const landingLengthLimit = useMemo(() => {
    return getValidationCheck(validationChecks, 'LIMIT_URL_LENGTH')
  }, [validationChecks])

  const queryLengthLimit = useMemo(() => {
    return getValidationCheck(validationChecks, 'LIMIT_QUERY_LENGTH')
  }, [validationChecks])

  const emailDomains = useMemo(() => {
    return getValidationCheck(validationChecks, 'EMAIL_DOMAIN_LIST')
  }, [validationChecks])

  const updateErrorObject = useCallback(
    (
      key: string,
      isValid: boolean,
      useErrorObject: string[] = errors.slice(),
      setErrorValue = true,
    ) => {
      let newError = useErrorObject

      if (isValid) {
        newError = newError.filter((errorKey) => {
          return errorKey !== key
        })
      } else if (key && newError.indexOf(key) === -1) {
        newError.push(key)
      }

      if (setErrorValue) {
        setErrors(newError)
      }

      return newError
    },
    [errors],
  )

  const updateForm = useCallback(
    (name: string, value: string | string[], useForm = form) => {
      if (
        isSalesforcePardotEmail(emailDetails) ||
        isEloquaEmail(emailDetails)
      ) {
        setEmailDetails({ ...emailDetails, updateSuccess: false })
      }

      if (
        !useForm ||
        (useForm && !Object.prototype.hasOwnProperty.call(useForm, 'paramDefs'))
      ) {
        return
      }

      // Email HTML saved to local storage should only ever be from raw HTML method
      // NOT Salesforce/Eloqua, since it could change
      let useEmailHtml = emailDetails.emailHtml

      if (
        !isSalesforceMCEmail(emailDetails) &&
        !isSalesforcePardotEmail(emailDetails) &&
        !isEloquaEmail(emailDetails) &&
        name === 'emailHtml' &&
        emailDetails.emailHtml !== value
      ) {
        setEmailDetails({ ...emailDetails, emailHtml: value as string })
        useEmailHtml = value as string
      }

      const currentItem = getItemByKeyValue(
        useForm.paramDefs,
        'fieldName',
        name,
      )

      const paramDefs = useForm.paramDefs.map((item: any): any => {
        return name === item.fieldName
          ? {
              ...currentItem,
              optionValue: value,
            }
          : item
      })

      const data = {
        ...useForm,
        paramDefs: [...paramDefs],
      }

      const disabled = !isAllFormDataValid(data)

      setSubmitDisabled(disabled)

      setForm(data)

      saveFormData({
        form: data,
        currentAccount: workspaceID,
        formType: 'email',
        emailHtml: useEmailHtml,
      })
    },
    [emailDetails, form, workspaceID],
  )

  const addUplifterIdsToGeneratedCodes = useCallback(
    async (codes: GeneratedCode[]): Promise<GeneratedCode[]> => {
      if (!generatedStructure || !useUplifterID || !uplifterIdData) return codes

      const {
        track: {
          currentSequentialCodeID: { currentTotal, etag, prefix, acctPrefix },
        },
      } = uplifterIdData

      try {
        // Rerun Uplifter ID check until sent etag and currentTotal match new values
        // If checked > 50 times, exit
        const {
          currentTotal: newCurrentTotal,
          updatedTotal,
        } = await loopApiCall(
          async (variables) => {
            const { data: newData } = await checkUplifterID({
              variables,
            })

            return (
              newData?.track.updateCurrentSequentialCodeID || {
                currentTotal: currentTotal || '0',
                etag: '',
                updatedTotal: null,
              }
            )
          },
          // If updatedTotal is null, check failed and must be rerun
          (res) =>
            !!(
              res.etag &&
              res.updatedTotal !== undefined &&
              res.updatedTotal !== null
            ),
          {
            currentTotal: currentTotal || '0',
            etag,
            newTotal: (
              parseInt(currentTotal || '0', 16) + codes.length
            ).toString(16),
          },
          (res) => ({
            currentTotal: res.currentTotal,
            etag: res.etag,
            newTotal: (parseInt(res.currentTotal, 16) + codes.length).toString(
              16,
            ),
          }),
          50,
        )

        if (updatedTotal === null) {
          throw new Error('Reached limit of attempts to get valid Uplifter IDs')
        }

        // Success: Prefix returns null
        const newCodes: GeneratedCode[] = []

        // Add Uplifter ID (before hash) for all codes
        codes.forEach((code, codeIndex) => {
          let fullUplifterID = `${prefix}${acctPrefix}`

          if (codeIndex === 0) {
            fullUplifterID += (parseInt(newCurrentTotal, 16) + 1).toString(16)
          } else {
            fullUplifterID += (
              parseInt(newCurrentTotal, 16) +
              codeIndex +
              1
            ).toString(16)
          }

          const useAnchor = getAnchorFromString(code.code)

          // Add negative lookahead to accomodate hash based routing
          // https://example.com/#/# should not have the first hash removed
          const anchorReplaceRegex = new RegExp(
            `#(?!\/)${useAnchor.replace('#', '')}`,
            'i',
          )

          const useCode = code.code.replace(anchorReplaceRegex, '')
          const useTc = code.tC.replace(anchorReplaceRegex, '')

          newCodes.push({
            ...code,
            code: `${useCode}${fullUplifterID}${useAnchor}`,
            tC: `${useTc}${fullUplifterID}${useAnchor}`,
          })
        })

        return newCodes
      } catch {
        throw new Error('Issue with Uplifter IDs')
      }
    },
    [useUplifterID, uplifterIdData, generatedStructure],
  )

  const onSubmit = async (e: React.SyntheticEvent): Promise<void> => {
    e.preventDefault()

    if (form === null) return

    if (softDisable) {
      setSubmitError(true)

      const paramErrors = errors.filter((error) => error !== 'email-html')

      if (paramErrors.length > 0) {
        logAction({
          variables: {
            action: 'track-error-missing-required-field',
            functionName: 'addCodes',
            pagePath: '/track/create-links',
            websiteSection: 'track',
            extra: JSON.stringify(paramErrors),
          },
        })
      }

      return
    }

    setSubmitError(false)

    const foundLinksFull = emailDetails.emailHtml.match(hrefRegex)

    const additionalFoundLinks: string[] = []

    // Add links from slotMap and textContent to full list
    if (
      isSalesforceMCEmail(emailDetails) &&
      emailDetails.templateType === 'templatebasedemail'
    ) {
      if (emailDetails.slotMap) {
        const slotMapFoundLinks = emailDetails.slotMap
          .filter((slot) => slot.hasLinks)
          .reduce<string[]>((acc, curr) => {
            const contentLinks = curr.content.match(hrefRegex)

            if (contentLinks) acc.push(...contentLinks)

            const blockLinks = curr.blocks.reduce<string[]>(
              (accBlock, currBlock) => {
                const currBlockLinks = currBlock.content.match(hrefRegex)

                if (currBlockLinks) accBlock.push(...currBlockLinks)

                return accBlock
              },
              [],
            )

            acc.push(...blockLinks)

            return acc
          }, [])

        additionalFoundLinks.push(...slotMapFoundLinks)
      }

      if (emailDetails.textContent) {
        const textContentFoundLinks = emailDetails.textContent.match(httpRegex)

        if (textContentFoundLinks) {
          additionalFoundLinks.push(...textContentFoundLinks)
        }
      }
    }

    const uniqueAdditionalFoundLinks = [...new Set(additionalFoundLinks)]

    if (!foundLinksFull && uniqueAdditionalFoundLinks.length === 0) {
      setNoLinksFound(true)
      return
    }

    let foundLinks =
      foundLinksFull?.map((link) =>
        link.replaceAll(/(href=)?(3D)?['"]*/g, ''),
      ) || []

    foundLinks.push(...uniqueAdditionalFoundLinks)

    foundLinks = foundLinks.filter((link) => isValidUrl(link))

    if (foundLinks.length === 0) {
      setNoLinksFound(true)
      return
    }

    const filteredLinks =
      emailDomains.enabled && emailDomains.value
        ? foundLinks.filter((link) => {
            return (
              (emailDomains.value as string)
                .split(/(,|;)/)
                .findIndex(
                  (domain) => domain !== '' && link.indexOf(domain) > -1,
                ) > -1
            )
          })
        : [...foundLinks]

    if (filteredLinks.length === 0) {
      setDomainValidationFailed(true)
      return
    }

    setNoLinksFound(false)
    setDomainValidationFailed(false)

    setLinksFound(filteredLinks)

    const uniqueFilteredLinks = [...new Set(filteredLinks)]

    const codes = generateCode(
      uniqueFilteredLinks,
      form,
      !existingParametersAddedToStart,
    )

    // Exclude hash-based routing
    let hasAnchor = false

    uniqueFilteredLinks.forEach((link) => {
      if (hasAnchor) return
      const anchorPresent = link.match(/#/g)

      if (
        anchorPresent &&
        !(anchorPresent.length === 1 && link.indexOf('/#/') !== -1)
      ) {
        hasAnchor = true
      }
    })

    // TODO: make this check all codes
    let query = codes[0].code.replace(codes[0].url, '')

    if (uplifterIdData && useUplifterID) {
      const {
        acctPrefix,
        prefix,
        currentTotal,
      } = uplifterIdData.track.currentSequentialCodeID
      query += `${prefix || ''}${acctPrefix}${currentTotal}`
    }

    const queryValidLength = isValidInput(query, [queryLengthLimit])

    const urlsWithIds = codes.map((item) => {
      if (!uplifterIdData || !useUplifterID) return item.code

      const {
        acctPrefix,
        prefix,
        currentTotal,
      } = uplifterIdData.track.currentSequentialCodeID

      return `${item.code}${prefix || ''}${acctPrefix}${currentTotal}`
    })

    const urlValidLength = isValidInput(urlsWithIds, [landingLengthLimit])

    const uniqueCodes = codes.filter((code, pos) => {
      return codes.findIndex((foundCode) => foundCode.url === code.url) === pos
    })

    setEmailLinks(uniqueCodes)

    if (!hasAnchor && urlValidLength && queryValidLength) {
      setShowPreviewModal(true)
    } else {
      const invalidLinks = codes.filter((item) => isValidUrl(item.urlWithHash))

      setShowWarningModal(true)

      if (!queryValidLength) {
        setWarningModalType('invalid-query-length')

        const maxLength = parseInt(queryLengthLimit.value || '', 10)

        const { length } = uniqueCodes[0].code.replace(uniqueCodes[0].url, '')

        logAction({
          variables: {
            action: 'track-error-landing-query-length',
            functionName: 'addCodes',
            pagePath: '/track/create-links',
            websiteSection: 'track',
            extra: JSON.stringify({
              totalInvalidLinks: invalidLinks.length,
              linkOverLimitBy: length - maxLength,
            }),
          },
        })
      } else if (!urlValidLength) {
        setWarningModalType('invalid-length')

        const maxLength = parseInt(landingLengthLimit.value || '', 10)

        const { length } = uniqueCodes[0].code.replace(uniqueCodes[0].url, '')

        logAction({
          variables: {
            action: 'track-error-landing-query-length',
            functionName: 'addCodes',
            pagePath: '/track/create-links',
            websiteSection: 'track',
            extra: JSON.stringify({
              totalInvalidLinks: invalidLinks.length,
              linkOverLimitBy: length - maxLength,
            }),
          },
        })
      } else if (hasAnchor) {
        setWarningModalType('has-anchor')

        logAction({
          variables: {
            action: 'track-error-anchor-warning',
            functionName: 'addCodes',
            pagePath: '/track/create-links',
            websiteSection: 'track',
            extra: JSON.stringify({
              totalInvalidLinks: invalidLinks.length,
            }),
          },
        })
      } else {
        setWarningModalType('no-url')
      }
    }
  }

  // Set email defaults when form first loads
  // But don't overwrite existing saved values if any
  useEffect(() => {
    if (!generatedStructure) return

    const savedForm = getFormData(generatedStructure, '', workspaceID, 'email')

    if (savedForm.form && savedForm.form.paramDefs) {
      savedForm.form.paramDefs.forEach((param, index) => {
        if (
          param.emailDefault &&
          param.emailDefault.optionID !== '' &&
          (!param.optionValue ||
            (Array.isArray(param.optionValue) &&
              (param.optionValue.length === 0 ||
                (param.optionValue.length === 1 &&
                  param.optionValue[0] === ''))))
        ) {
          savedForm.form.paramDefs[index].optionValue = [
            param.emailDefault.optionID,
          ]
        }
      })
    }

    updateForm('emailHtml', savedForm.emailHtml, savedForm.form)
  }, [generatedStructure])

  const previewIsDisabled = useMemo(() => {
    const shortLinksToPreview =
      linkType === 'short' || linkType === 'combined'
        ? previewTableShortLinks
        : {}

    const urls = Object.keys(shortLinksToPreview)

    const usePreview =
      emailLinks.length > 0 && (linkType === 'short' || linkType === 'combined')

    const selectedLinks = emailLinks.filter((item) => item.selected)

    const shortLinks = Object.values(previewTableShortLinks)

    return (
      selectedLinks.length === 0 ||
      shortLinkStatus === 'refetching' ||
      (usePreview &&
        (urls.length < emailLinks.length ||
          shortLinks.length < selectedLinks.length ||
          shortLinks.findIndex((sL) => sL === '') > -1))
    )
  }, [emailLinks, previewTableShortLinks, linkType, shortLinkStatus])

  // Tracking
  useEffect(() => {
    if (showWarningModal && emailLinks.length > 0) {
      let desc = {}

      if (
        warningModalType === 'invalid-query-length' ||
        warningModalType === 'invalid-length'
      ) {
        const maxLength =
          warningModalType === 'invalid-length'
            ? landingLengthLimit.value
            : queryLengthLimit.value

        const length =
          warningModalType === 'invalid-length'
            ? emailLinks[0].code.length
            : emailLinks[0].code.replace(emailLinks[0].url, '').length

        desc = {
          maxLength,
          length,
        }
      }

      logAction({
        variables: {
          action: `warning-modal-${warningModalType}`,
          functionName: 'addCodes',
          pagePath: '/track/create-links',
          websiteSection: 'track',
          extra: JSON.stringify(desc),
        },
      })
    }
  }, [showWarningModal, warningModalType])

  // Loading state
  if (
    loading ||
    (dataSource &&
      dataSource.connectionSource !== 'adobe' &&
      !uplifterIdData &&
      !uplifterIdError)
  ) {
    return (
      <OuterBox className={styles.outerBox}>
        <InnerBox className={styles.innerBox}>
          <p className={styles.intro}>
            <span>
              Find and replace links in email templates.{' '}
              <Link
                type="arrowForward"
                href="https://support.uplifter.ai/hc/en-us/articles/7984756219677-How-to-track-email-links-in-Uplifter"
              >
                Learn more
              </Link>
            </span>
          </p>
          <form>
            {new Array(4).fill(1).map((i, index, arr) => {
              const isLast = index === arr.length - 1

              return (
                <FormRow
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  className={isLast ? styles.lastFormRow : undefined}
                >
                  <LabelSlot>
                    <Loader className={styles.loader} height="35px" />
                  </LabelSlot>
                  <FieldSlot>
                    <Loader
                      className={classNames(styles.loader, {
                        [styles.lastLoader]: isLast,
                      })}
                      height="35px"
                    />
                    {isLast && (
                      <Button isDisabled>Fetching link builder</Button>
                    )}
                  </FieldSlot>
                </FormRow>
              )
            })}
          </form>
        </InnerBox>
      </OuterBox>
    )
  }

  return (
    <>
      <OuterBox className={styles.outerBox}>
        <InnerBox className={styles.innerBox}>
          <p className={styles.intro}>
            <span>
              Find and replace links in email templates.{' '}
              <Link
                type="arrowForward"
                href="https://support.uplifter.ai/hc/en-us/articles/7984756219677-How-to-track-email-links-in-Uplifter"
              >
                Learn more
              </Link>
            </span>
            {form &&
              form.paramDefs.find(
                (param) => param.optionValue && param.optionValue.length > 0,
              ) && (
                <ClearButton
                  onPress={() =>
                    updateForm('emailHtml', [''], generatedStructure)
                  }
                >
                  Clear all
                </ClearButton>
              )}
          </p>
          <FormRow>
            <LabelSlot className={styles.labelSlot}>
              <Label id="link-type">
                <Tooltip
                  id="email-platform-tooltip"
                  useIcon
                  tooltipPosition="right"
                  tooltipMessage="Select which tool you use to draft emails. If not listed, use 'Other' to find and replace links manually in email HTML."
                >
                  Email platform
                </Tooltip>
              </Label>
            </LabelSlot>
            <FieldSlot>
              <SelectBox
                id="emailSource"
                labelKey="emailSourceName"
                valueKey="emailSource"
                placeholder="Select platform"
                isLoading={loadingIntegrationsData}
                loadingMessage={() => (
                  <LoadingLabel label="Checking integrations" />
                )}
                value={emailSourceOptions.find(
                  (option) => option.emailSource === emailDetails.emailSource,
                )}
                options={emailSourceOptions}
                onChange={(newValue) => {
                  if (!newValue) return

                  if (newValue.emailSource === 'salesforceMC') {
                    if (!salesforceMCConnected) {
                      history.push('/connect')
                    } else {
                      setEmailDetails({
                        emailSource: newValue.emailSource,
                        businessUnitId: '',
                        businessUnitName: '',
                        templateID: null,
                        templateType: null,
                        slotMap: null,
                        textContent: null,
                        emailHtml: '',
                        updateSuccess: false,
                      })
                    }

                    return
                  }

                  if (
                    newValue.emailSource === 'lightning' ||
                    newValue.emailSource === 'classic'
                  ) {
                    if (
                      !salesforcePardotConnected ||
                      salesforcePardotConnected === 'requiresReconnect'
                    ) {
                      history.push('/connect')
                    } else {
                      setEmailDetails({
                        emailSource: newValue.emailSource,
                        businessUnitId: '',
                        emailTemplate: null,
                        emailHtml: '',
                        fetchedSavedBusinessUnit: false,
                        updateSuccess: false,
                      })
                    }

                    return
                  }

                  if (newValue.emailSource === 'eloqua') {
                    if (!eloquaConnected) {
                      history.push('/connect')
                    } else {
                      setEmailDetails({
                        emailSource: newValue.emailSource,
                        eloquaOrg: null,
                        emailGroup: null,
                        emailTemplate: null,
                        emailHtml: '',
                        updateSuccess: false,
                      })
                    }

                    return
                  }

                  setEmailDetails({
                    emailSource: newValue.emailSource,
                    emailHtml: '',
                  })
                }}
              >
                <Button
                  variant="text"
                  className={styles.addButton}
                  onPressStart={() => history.push('/connect')}
                >
                  Connect a new platform +
                </Button>
              </SelectBox>
              {salesforcePardotConnected === 'requiresReconnect' && (
                <p className={styles.footNoteError}>
                  Salesforce Pardot account requires{' '}
                  <Link type="arrowForward" newTab={false} href="/connect">
                    reconnection
                  </Link>
                </p>
              )}
            </FieldSlot>
          </FormRow>
          <form onSubmit={onSubmit}>
            {eloquaConnected && isEloquaEmail(emailDetails) && (
              <EloquaEmailFields
                emailDetails={emailDetails}
                setEmailDetails={setEmailDetails}
                fetchEloquaEmailHtml={fetchEloquaEmailHtml}
                errorFetchingEloquaEmailHtml={!!errorFetchingEloquaEmailHtml}
              />
            )}
            {salesforcePardotConnected &&
              isSalesforcePardotEmail(emailDetails) && (
                <SalesforcePardotEmailFields
                  generatorData={generatorData}
                  emailDetails={emailDetails}
                  setEmailDetails={setEmailDetails}
                  fetchSFPardotEmailHTML={fetchSFPardotEmailHTML}
                  errorFetchingSFPardotEmailHtml={
                    !!errorFetchingSFPardotEmailHtml
                  }
                />
              )}
            {salesforceMCConnected && isSalesforceMCEmail(emailDetails) && (
              <SalesforceMCEmailFields
                emailDetails={emailDetails}
                setEmailDetails={setEmailDetails}
                fetchSFMCEmailHTML={fetchSFMCEmailHTML}
                errorFetchingSFMCEmailHtml={!!errorFetchingSFMCEmailHtml}
              />
            )}
            {!isEloquaEmail(emailDetails) &&
              !isSalesforcePardotEmail(emailDetails) &&
              !isSalesforceMCEmail(emailDetails) && (
                <FormRow>
                  <Input
                    type="textArea"
                    className={styles.textArea}
                    maxLength={100000}
                    placeholder="Paste email HTML here, before selecting values to be added to email links."
                    name="Email HTML"
                    value={emailDetails.emailHtml}
                    onValueChange={(value: string) => {
                      const valid = value !== '' && htmlHasLinks(value)

                      if (valid && errors.indexOf('email-html') > -1) {
                        updateErrorObject('email-html', valid)
                      }

                      updateForm('emailHtml', value, form)
                    }}
                    error={errors.indexOf('email-html') > -1}
                  >
                    {emailDetails.emailHtml &&
                      emailDetails.emailHtml.length >= 100000 && (
                        <p className={styles.footNoteError}>
                          <span className={styles.footNoteError}>
                            Too many characters. Try only copying the email
                            body.
                          </span>
                        </p>
                      )}
                    {errors.indexOf('email-html') > -1 && (
                      <p className={styles.footNoteError}>
                        <span className={styles.footNoteError}>
                          You must enter some valid email HTML that contains
                          links (e.g. href="https://uplifter.tech").
                        </span>
                      </p>
                    )}
                  </Input>
                </FormRow>
              )}
            {form !== null && (
              <GeneratorFormFields
                isAdmin={isAdmin}
                form={form}
                validationChecks={validationChecks}
                errors={errors}
                setErrors={setErrors}
                updateForm={updateForm}
                updateErrorObject={updateErrorObject}
                onRequestNewField={onRequestNewField}
              />
            )}
            {!hideLongLinkOption && !hideShortLinkOption && (
              <FormRow
                className={classNames(styles.noPadding, {
                  [styles.rowNoBorder]: linkType === 'full',
                })}
              >
                <LabelSlot className={styles.labelSlot}>
                  <Label id="link-type">
                    <Tooltip
                      id="link-type-tooltip"
                      useIcon
                      maxWidth={300}
                      tooltipPosition="right"
                      tooltipMessage={messages.linkTypes}
                    >
                      Link type
                    </Tooltip>
                  </Label>
                </LabelSlot>
                <FieldSlot>
                  <div className={styles.inlineWrap}>
                    <div className={styles.radioPanel}>
                      {!showShortLinkOptionFirst && (
                        <Input
                          type="radio"
                          id="link-type-2"
                          name="link-type"
                          label={
                            <>
                              Basic link{' '}
                              {canUseShortLinks && (
                                <span className={styles.recommended}>
                                  (recommended)
                                </span>
                              )}
                            </>
                          }
                          checked={linkType === 'full'}
                          onClick={() => {
                            const val = 'full'
                            setLinkType(val)
                            saveUserData({
                              linkType: val,
                            })
                          }}
                        />
                      )}
                      <Input
                        type="radio"
                        id="link-type-1"
                        name="link-type"
                        disabled={!canUseShortLinks}
                        label={
                          <>
                            Short link{' '}
                            {(showShortLinkOptionFirst ||
                              !canUseShortLinks) && (
                              <span className={styles.recommended}>
                                (recommended)
                              </span>
                            )}
                          </>
                        }
                        checked={
                          linkType === 'short' || linkType === 'combined'
                        }
                        onClick={() => {
                          if (!canUseShortLinks) {
                            // Disabled for unpaid accounts - show modal
                            // @ts-ignore
                            if (window.dataLayer && window.dataLayer.push) {
                              // @ts-ignore
                              window.dataLayer.push({
                                event: 'click-shortlink-upgrade-blocker',
                              })
                            }

                            logAction({
                              variables: {
                                action: 'click-shortlink-upgrade-blocker',
                                websiteSection: 'track',
                                pagePath: window.location.pathname,
                                functionName: 'clickUpgrade',
                                extra: 'email',
                              },
                            })

                            setShowShortLinkModal(true)

                            return
                          }

                          const val = 'short'
                          setLinkType(val)
                          saveUserData({
                            linkType: val,
                          })
                        }}
                      />
                      {showShortLinkOptionFirst && (
                        <Input
                          type="radio"
                          id="link-type-2"
                          name="link-type"
                          label="Basic link"
                          checked={linkType === 'full'}
                          onClick={() => {
                            const val = 'full'
                            setLinkType(val)
                            saveUserData({
                              linkType: val,
                            })
                          }}
                        />
                      )}
                    </div>
                  </div>
                </FieldSlot>
              </FormRow>
            )}
            <FormRow
              className={classNames(styles.noPadding, styles.lastFormRow)}
            >
              <LabelSlot className={styles.labelSlot}>
                {showShortLinkEdit && (
                  <Label id="link-type">
                    <Tooltip
                      id="short-link-domain-tooltip"
                      useIcon
                      tooltipPosition="right"
                      tooltipMessage={messages.selectDomain}
                    >
                      Short link domain
                    </Tooltip>
                  </Label>
                )}
              </LabelSlot>
              <FieldSlot>
                {showShortLinkEdit && (
                  <div className={styles.linkInputWrapper}>
                    <ShortLinkFull
                      domainSelectorOnly
                      onDomainChange={(domain) => setShortLinkDomain(domain)}
                    />
                  </div>
                )}
                <Button
                  type="submit"
                  className={classNames({
                    [styles.softDisableButton]: softDisable,
                  })}
                  loading={addingCodes || checkingUplifterIDs}
                  isDisabled={
                    ((linkType === 'short' || linkType === 'combined') &&
                      shortLinkDomain === '') ||
                    ['invalid', 'refetching', 'validating'].indexOf(
                      shortLinkStatus,
                    ) > -1 ||
                    addingCodes ||
                    checkingUplifterIDs ||
                    !!uplifterIdError
                  }
                  onPress={async () => {
                    if (softDisable) {
                      let newErrors = errors.slice()

                      if (
                        !htmlHasLinks(emailDetails.emailHtml) ||
                        newErrors.indexOf('email-html') > -1
                      ) {
                        newErrors = updateErrorObject(
                          'email-html',
                          false,
                          newErrors,
                          false,
                        )
                      } else {
                        newErrors = updateErrorObject(
                          'email-html',
                          true,
                          newErrors,
                          false,
                        )
                      }

                      if (form !== null && form.paramDefs.length > 0) {
                        form.paramDefs.forEach((item) => {
                          const {
                            fieldID,
                            required,
                            fieldAvailable,
                            optionValue,
                          } = item

                          const valueNotSet =
                            typeof optionValue === 'undefined' ||
                            (Array.isArray(optionValue) &&
                              optionValue.join('') === '')

                          if (required && fieldAvailable && valueNotSet) {
                            newErrors = updateErrorObject(
                              fieldID,
                              false,
                              newErrors,
                              false,
                            )
                          } else {
                            newErrors = updateErrorObject(
                              fieldID,
                              true,
                              newErrors,
                              false,
                            )
                          }
                        })
                      }

                      setErrors(newErrors)
                    }
                  }}
                >
                  {!fetchingSFMCEmailHtml &&
                  !fetchingSFPardotEmailHtml &&
                  !fetchingEloquaEmailHtml
                    ? 'Find links'
                    : 'Fetching email HTML...'}
                </Button>
                {isSalesforcePardotEmail(emailDetails) && isListTemplate && (
                  <p
                    className={classNames(styles.footNoteError, styles.warning)}
                  >
                    Email list templates cannot be updated via API. You will
                    need to copy the new email HTML into the template in your{' '}
                    <NavigateButton
                      onPress={() => {
                        window.open(
                          emailDetails.emailTemplate?.directLink as string,
                          '_blank',
                        )
                      }}
                    >
                      Salesforce account
                    </NavigateButton>
                  </p>
                )}
                {uplifterIdError && (
                  <p className={styles.footNoteError}>
                    Error generating a unique link ID. Please reload the page
                    and try again, or contact{' '}
                    <Link href={supportEmail}>{supportEmail}</Link>.
                  </p>
                )}
                {submitError && (
                  <p className={styles.footNoteError}>
                    Please have a value for every required parameter.
                  </p>
                )}
                {noLinksFound && (
                  <p className={styles.footNoteError}>
                    No valid links were found in your email HTML.
                  </p>
                )}
                {domainValidationFailed && (
                  <p className={styles.footNoteError}>
                    None of the links found matched any in your list of domains.
                    Admins can edit the list in{' '}
                    <Link href="/track/edit-parameters-and-rules">
                      advanced options
                    </Link>
                    .
                  </p>
                )}
              </FieldSlot>
            </FormRow>
          </form>
        </InnerBox>
      </OuterBox>
      {showShortLinkModal && (
        <RequestShortLinksModal onHideModal={setShowShortLinkModal} />
      )}
      {showWarningModal && emailLinks.length > 0 && (
        <WarningModal
          url={linksFound}
          single={false}
          type={warningModalType}
          maxLength={
            warningModalType === 'invalid-length'
              ? parseInt(landingLengthLimit.value || '1024', 10)
              : parseInt(queryLengthLimit.value || '255', 10)
          }
          length={
            warningModalType === 'invalid-length'
              ? emailLinks[0].code.length
              : emailLinks[0].code.replace(emailLinks[0].url, '').length
          }
          onYes={() => {
            setShowWarningModal(false)
            setShowPreviewModal(true)
          }}
          onNo={setShowWarningModal}
          uplifterIdData={uplifterIdData}
        />
      )}
      {showPreviewModal && linksFound.length > 0 && (
        <>
          {(isSalesforceMCEmail(emailDetails) ||
            isSalesforcePardotEmail(emailDetails) ||
            isEloquaEmail(emailDetails)) &&
          emailDetails.updateSuccess ? (
            <Modal
              className={styles.sfModal}
              setIsOpen={setShowPreviewModal}
              isWarning={isListTemplate}
              headerColor={isListTemplate ? 'pink' : undefined}
              modalHeader={
                isSalesforcePardotEmail(emailDetails) && isListTemplate
                  ? 'Action required: Copy HTML into Salesforce'
                  : 'Links updated'
              }
              footerContent={
                isSalesforcePardotEmail(emailDetails) && isListTemplate ? (
                  <NavigateButton
                    onPress={() => {
                      window.open(
                        emailDetails.emailTemplate?.directLink as string,
                        '_blank',
                      )
                    }}
                  >
                    Open Salesforce
                  </NavigateButton>
                ) : undefined
              }
            >
              <>
                {isSalesforcePardotEmail(emailDetails) && isListTemplate ? (
                  <>
                    <p>
                      This is a list email. You need to manually copy the below
                      HTML into Salesforce.
                    </p>
                    <FieldSlot className={styles.emailPreviewRow}>
                      <p className={styles.emailPreviewHeader}>
                        <Tooltip
                          id="salesforce-pardot-tooltip"
                          useIcon
                          tooltipPosition="right"
                          tooltipMessage="We've replaced your old HTML with this new HTML."
                        >
                          New Salesforce Pardot HTML
                        </Tooltip>
                      </p>
                      <div className={styles.copyBtns}>
                        <CopyButton
                          className={styles.copyBtn}
                          value={newEmailHtml?.raw}
                        >
                          Copy
                        </CopyButton>
                        <Button
                          variant="secondary"
                          onPress={async () => {
                            const data = newEmailHtml?.raw

                            const blob = new Blob([data as string], {
                              type: 'text/plain;charset=utf-8',
                            })
                            const now = new Date(Date.now())

                            await FileSaver.saveAs(
                              blob,
                              `${moment(now).format(
                                'YYYY-MM-DD',
                              )} ${brandName} Email HTML.txt`,
                            )
                          }}
                        >
                          Download
                        </Button>
                      </div>
                    </FieldSlot>
                    <FieldSlot className={styles.emailPreviewField}>
                      <div className={styles.previewCode}>
                        <code>{newEmailHtml?.preview}</code>
                      </div>
                    </FieldSlot>
                  </>
                ) : (
                  <p style={{ margin: 0 }}>
                    Your{' '}
                    {isEloquaEmail(emailDetails)
                      ? 'Eloqua'
                      : `Salesforce ${
                          isSalesforceMCEmail(emailDetails)
                            ? 'Marketing Cloud'
                            : 'Pardot'
                        }`}{' '}
                    links have been successfuly replaced.
                  </p>
                )}
              </>
            </Modal>
          ) : (
            <Modal
              setIsOpen={setShowPreviewModal}
              width="superWide"
              modalHeader={`${numeral(linksFound.length).format('0,0')} link${
                linksFound.length > 1 ? 's' : ''
              } found in your email`}
              yesButtonLoading={
                addingCodes ||
                checkingUplifterIDs ||
                updatingSFMCEmail ||
                updatingPardotEmail ||
                updatingEloquaEmail
              }
              yesButtonDisabled={
                previewIsDisabled ||
                emailLinks.filter((link) => link.selected).length >
                  maxBatchShortLinks
              }
              yesText="Replace links"
              onYes={async () => {
                try {
                  const shortLinksToPreview =
                    linkType === 'short' || linkType === 'combined'
                      ? previewTableShortLinks
                      : {}

                  const useCodesUrl: string[] = returnUnique(
                    emailLinks.map((i) => i.url),
                  )

                  let newEmailHtmlToUse = emailDetails.emailHtml

                  let newTextContent =
                    isSalesforceMCEmail(emailDetails) &&
                    emailDetails.templateType === 'templatebasedemail'
                      ? emailDetails.textContent || undefined
                      : undefined

                  const newSlotMap =
                    isSalesforceMCEmail(emailDetails) &&
                    emailDetails.templateType === 'templatebasedemail' &&
                    emailDetails.slotMap
                      ? emailDetails.slotMap.filter(({ hasLinks }) => hasLinks)
                      : undefined

                  const replacedStrings: string[] = []

                  const emailLinksWithUplifterIds = await addUplifterIdsToGeneratedCodes(
                    emailLinks,
                  )

                  // Save codes
                  const emailCodesToAdd = emailLinksWithUplifterIds
                    .filter((item) => item !== null && item.selected)
                    .map((item) => ({
                      fC: item.code, // Full code: URL + params
                      lP: item.urlWithHash || item.url || '',
                      tC: item.tC.replaceAll(' ', '%20'),
                      pDfs: item.pDfs.map((i) => [
                        i[0],
                        i[1].replaceAll(' ', '%20'),
                      ]),
                      shortLinkID:
                        Object.prototype.hasOwnProperty.call(
                          shortLinksToPreview,
                          item.code,
                        ) &&
                        shortLinksToPreview[item.code] &&
                        emailLinksWithUplifterIds.length < minBatchShortLinks
                          ? (shortLinksToPreview[item.code] as string)
                          : '',
                    }))

                  const createdCodes = await addCode({
                    variables: {
                      customDomainID: getCustomDomainID(shortLinkDomain),
                      codeList: emailCodesToAdd,
                      bulkStart:
                        emailLinksWithUplifterIds.length >= minBatchShortLinks
                          ? Object.values(shortLinksToPreview)[0]
                          : undefined,
                    },
                  })

                  // Refetch totals
                  // Posible TODO: Update the cached object's value for currentTotal instead of refetching
                  if (
                    (!dataSource || dataSource.connectionSource !== 'adobe') &&
                    useUplifterID
                  ) {
                    await getUplifterID()
                  }

                  // Only replace links after response so batch aliases are correct (0x-1, ...)
                  if (createdCodes.data) {
                    const {
                      codeID,
                      fullLink,
                      shortLink,
                    } = createdCodes.data.addCodes

                    codeID.forEach((c, cIndex) => {
                      const replacedWith =
                        linkType === 'short' &&
                        Object.prototype.hasOwnProperty.call(
                          shortLinksToPreview,
                          fullLink[cIndex],
                        ) &&
                        shortLinksToPreview[fullLink[cIndex]] &&
                        shortLink &&
                        shortLink[cIndex]
                          ? shortLink[cIndex]
                          : fullLink[cIndex]

                      replacedStrings.push(replacedWith)

                      const originalLink = emailLinksWithUplifterIds.find(
                        (addedCode) => addedCode.code === fullLink[cIndex],
                      )

                      if (originalLink) {
                        const urlToReplace =
                          originalLink.urlWithHash || originalLink.url
                        const urlToReplaceRegex = urlToReplace.replace(
                          /[.*+?^${}()|[\]\\]/g,
                          '\\$&',
                        )

                        // Include quotes to prevent replacement of links contained in other links
                        const replacerRegex = new RegExp(
                          `(\\\'|\\\")${urlToReplaceRegex}(\\\'|\\\")`,
                          'g',
                        )

                        newEmailHtmlToUse = newEmailHtmlToUse.replaceAll(
                          replacerRegex,
                          `"${replacedWith}"`,
                        )

                        // Replace text content for SF MC templatebasedemails
                        if (newTextContent) {
                          newTextContent = newTextContent.replaceAll(
                            new RegExp(urlToReplaceRegex, 'g'),
                            replacedWith,
                          )
                        }

                        // Replace slotMap for SF MC templatebasedemails
                        if (newSlotMap && newSlotMap.length > 0) {
                          newSlotMap.map((slot) => {
                            // Replace content
                            const newContent = slot.content.replaceAll(
                              replacerRegex,
                              `"${replacedWith}"`,
                            )

                            const newBlocks = slot.blocks.map((block) => {
                              const newBlockContent = block.content.replaceAll(
                                replacerRegex,
                                `"${replacedWith}"`,
                              )

                              return {
                                ...block,
                                content: newBlockContent,
                              }
                            })

                            return {
                              ..._.cloneDeep(slot),
                              content: newContent,
                              blocks: newBlocks,
                            }
                          })
                        }
                      }
                    })
                  }

                  // Used to highlight replaced text
                  const combinedStrings = replacedStrings.reduce(
                    (acc, curr, index) => {
                      const cleaned = curr.replace(
                        /[.*+?^${}()|[\]\\]/g,
                        '\\$&',
                      )

                      if (replacedStrings.length === 1)
                        return `${acc}${cleaned})`
                      if (index === 0) return `${acc}${cleaned}`
                      if (index === replacedStrings.length - 1)
                        return `${acc}|${cleaned})`

                      return `${acc}|${cleaned}`
                    },
                    `(`,
                  )

                  const newEmailHtmlPreview = reactStringReplace(
                    newEmailHtmlToUse,
                    new RegExp(combinedStrings, 'g'),
                    (match, i) => (
                      <span key={match + i} className={styles.highlight}>
                        {match}
                      </span>
                    ),
                  )

                  setNewEmailHtml({
                    raw: newEmailHtmlToUse,
                    preview: <>{newEmailHtmlPreview}</>,
                  })

                  if (isSalesforceMCEmail(emailDetails)) {
                    if (
                      !salesforceMCConnected ||
                      !emailDetails.businessUnitId ||
                      !emailDetails.templateID
                    ) {
                      return
                    }

                    const newEmailDetails = {
                      ...emailDetails,
                      updateSuccess: true,
                    }

                    await updateSFMCEmailHtml({
                      variables: {
                        mid: emailDetails.businessUnitId,
                        templateID: emailDetails.templateID,
                        updatedHTMLMessage: newEmailHtmlToUse,
                        updatedSlotMap:
                          newSlotMap?.map(({ slotID, content, blocks }) => ({
                            slotID,
                            content,
                            blocks,
                          })) || undefined,
                        updatedTextContent: newTextContent,
                      },
                    })

                    // Refetch the selected email's HTML
                    const { data: htmlData } = await fetchSFMCEmailHTML({
                      variables: {
                        mid: emailDetails.businessUnitId,
                        templateID: emailDetails.templateID,
                      },
                    })

                    if (
                      !htmlData ||
                      !htmlData.track.salesforceMarketingCloudQueries
                        .getTemplateDetail.templateContent
                    ) {
                      return
                    }

                    const {
                      templateType,
                      templateContent,
                      slotMap,
                      textContent,
                    } = htmlData.track.salesforceMarketingCloudQueries.getTemplateDetail

                    const flaggedSlotMap =
                      templateType === 'templatebasedemail' &&
                      Array.isArray(slotMap) &&
                      slotMap.length > 0
                        ? slotMap.map((slot) => {
                            let hasLinks = htmlHasLinks(slot.content)

                            // For efficiency, only run this check if main content does not have links
                            if (!hasLinks) {
                              hasLinks = !!slot.blocks.find((block) =>
                                htmlHasLinks(block.content),
                              )
                            }

                            return {
                              ...slot,
                              hasLinks,
                            }
                          })
                        : null

                    newEmailDetails.emailHtml = templateContent
                    newEmailDetails.slotMap = flaggedSlotMap
                    newEmailDetails.textContent = textContent

                    setIsIntegrationEmail(true)
                    setEmailDetails(newEmailDetails)
                  } else if (isSalesforcePardotEmail(emailDetails)) {
                    if (
                      !salesforcePardotConnected ||
                      !emailDetails.emailTemplate
                    )
                      return

                    // Do not hit 'updatePardotEmailHtml' for classic list templates
                    // These must be updated by the user manually

                    const newEmailDetails = {
                      ...emailDetails,
                      updateSuccess: true,
                    }

                    if (!emailDetails.emailTemplate.isClassicListEmail) {
                      const {
                        emailID,
                        pardotID,
                        pardotName,
                        uiType,
                        isClassicListEmail,
                      } = emailDetails.emailTemplate

                      await updatePardotEmailHtml({
                        variables: {
                          emailID,
                          generatedPardotLinks: [],
                          // @ts-ignore
                          pardotID,
                          // @ts-ignore
                          pardotName,
                          uiType,
                          updatedHTMLMessage: newEmailHtmlToUse,
                        },
                      })

                      // Refetch the selected email's HTML
                      const { data: htmlData } = await fetchSFPardotEmailHTML({
                        variables: {
                          [`${emailDetails.emailSource}PardotTemplateList`]: [
                            {
                              pardotID,
                              pardotName,
                              emailID,
                              uiType,
                              isClassicListEmail,
                            },
                          ],
                        },
                      })

                      if (
                        !htmlData ||
                        !htmlData.track.salesforcePardotEmailHTML[
                          `${emailDetails.emailSource}Builder`
                        ][0].emailHTML
                      ) {
                        return
                      }

                      newEmailDetails.emailHtml = htmlData.track
                        .salesforcePardotEmailHTML[
                        `${emailDetails.emailSource}Builder`
                      ][0].emailHTML as string
                    }

                    setIsIntegrationEmail(true)
                    setEmailDetails(newEmailDetails)
                  } else if (isEloquaEmail(emailDetails)) {
                    if (
                      !eloquaConnected ||
                      !emailDetails.eloquaOrg ||
                      !emailDetails.emailTemplate
                    )
                      return

                    // Do not hit 'updatePardotEmailHtml' for classic list templates
                    // These must be updated by the user manually
                    const newEmailDetails = {
                      ...emailDetails,
                      updateSuccess: true,
                    }

                    const { eloquaOrgID } = emailDetails.eloquaOrg

                    const { eloquaID } = emailDetails.emailTemplate

                    await updateEloquaEmailHtml({
                      variables: {
                        eloquaOrgID,
                        templateID: eloquaID,
                        updatedHTMLMessage: newEmailHtmlToUse,
                      },
                    })

                    // Refetch the selected email's HTML
                    const { data: htmlData } = await fetchEloquaEmailHtml({
                      variables: {
                        eloquaOrgID,
                        templateID: eloquaID,
                      },
                    })

                    if (
                      !htmlData ||
                      !htmlData.track.eloquaQueries.getEloquaEmailTemplateDetail
                        .emailHtml
                    ) {
                      return
                    }

                    newEmailDetails.emailHtml =
                      htmlData.track.eloquaQueries.getEloquaEmailTemplateDetail.emailHtml

                    setIsIntegrationEmail(true)
                    setEmailDetails(newEmailDetails)
                  } else {
                    // Only save to local storage if raw HTML
                    saveFormData({
                      form,
                      currentAccount: workspaceID,
                      formType: 'email',
                      emailHtml: emailDetails.emailHtml,
                      generatedEmailHtml: newEmailHtmlToUse,
                    })
                    setIsIntegrationEmail(false)
                  }

                  let integrationEvent = ''

                  if (eloquaConnected && isEloquaEmail(emailDetails)) {
                    integrationEvent = '-eloqua'
                  } else if (
                    salesforcePardotConnected &&
                    isSalesforcePardotEmail(emailDetails)
                  ) {
                    integrationEvent = '-pardot'
                  } else if (
                    salesforceMCConnected &&
                    isSalesforceMCEmail(emailDetails)
                  ) {
                    integrationEvent = '-salesforceMC'
                  }

                  // @ts-ignore
                  if (window.dataLayer && window.dataLayer.push) {
                    // @ts-ignore
                    window.dataLayer.push({
                      event: 'create_campaign_link',
                      link_creation_type:
                        linkType === 'short' || linkType === 'combined'
                          ? `add-codes-email-short-link${integrationEvent}`
                          : `add-codes-email${integrationEvent}`,
                      link_count: emailCodesToAdd.length,
                    })
                  }

                  logAction({
                    variables: {
                      action:
                        linkType === 'short' || linkType === 'combined'
                          ? `add-codes-email-short-link${integrationEvent}`
                          : `add-codes-email${integrationEvent}`,
                      functionName: 'addCodes',
                      pagePath: '/track/create-links',
                      websiteSection: 'track',
                      extra: emailCodesToAdd.length.toString(),
                      getParams: JSON.stringify(useCodesUrl),
                    },
                  })

                  // Updates the backend cache for onboarding state
                  if (!hasCreatedCode) {
                    updateOnboardingSection('createCampaignLink', 'user')
                  }

                  // Remove & replace used short links
                  if (linkType === 'short' || linkType === 'combined') {
                    const removeShortLinks = [
                      ...new Set(Object.values(shortLinksToPreview)),
                    ]

                    useAliases(removeShortLinks)

                    setPreviewTableShortLinks({})
                  }

                  const filteredCodes = emailLinksWithUplifterIds
                    .map((item) => (item.selected ? item : null))
                    .filter((item) => item !== null)

                  setNewLinks(
                    (filteredCodes as GeneratedCode[]).map(({ code }) => code),
                  )

                  // Integration instances need to show a confirmation modal
                  if (
                    !(
                      salesforcePardotConnected &&
                      isSalesforcePardotEmail(emailDetails)
                    ) &&
                    !(eloquaConnected && isEloquaEmail(emailDetails)) &&
                    !(
                      salesforceMCConnected && isSalesforceMCEmail(emailDetails)
                    )
                  ) {
                    setShowPreviewModal(false)
                  }
                } catch {
                  setCreateLinksError(true)
                }
              }}
              footerContent={
                createLinksError ? (
                  <p className={styles.footNoteError}>
                    Error creating links, please refresh and try again.
                  </p>
                ) : (
                  <>
                    {' '}
                    {emailLinks.filter((link) => link.selected).length >
                    maxBatchShortLinks ? (
                      <p className={styles.overLimit}>
                        Your email has over {maxBatchShortLinks.toString()}{' '}
                        links. Please remove or deselect some to use this
                        feature.
                      </p>
                    ) : (
                      <p style={{ margin: 0 }}>
                        All instances of{' '}
                        {numeral(
                          emailLinks.filter((link) => link.selected).length,
                        ).format('0,0')}{' '}
                        link
                        {emailLinks.filter((link) => link.selected).length !==
                          1 && 's'}{' '}
                        will be replaced in your email.
                      </p>
                    )}
                  </>
                )
              }
            >
              <div className={styles.modalBody}>
                <p>
                  Deselect the links you don't wish to track and edit individual
                  link parameters if required.
                </p>
                <EmailPreviewTable
                  form={form}
                  validationChecks={validationChecks || []}
                  url={linksFound}
                  emailLinks={emailLinks}
                  setEmailLinks={setEmailLinks}
                  linkType={linkType}
                  previewTableShortLinks={previewTableShortLinks}
                  setPreviewTableShortLinks={setPreviewTableShortLinks}
                  setShortLinkStatus={setShortLinkStatus}
                />
              </div>
            </Modal>
          )}
        </>
      )}
    </>
  )
}

export default EmailCodeGeneratorForm
