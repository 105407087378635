import React from 'react'
import classNames from 'classnames'

import { SelectBoxSimple } from './select-box'

interface PermissionSelectorProps {
  permission: string
  onChange: (permission: PermissionLevel) => void
  className?: string
}

const PermissionSelector = ({
  permission,
  onChange,
  className,
}: PermissionSelectorProps) => {
  return (
    <SelectBoxSimple
      className={classNames(className)}
      name="permission"
      value={permission}
      onChange={(permissionType) => {
        if (permissionType === 'admin' || permissionType === 'regular') {
          onChange(permissionType as PermissionLevel)
        }
      }}
    >
      <option value="regular">Regular</option>
      <option value="admin">Admin</option>
    </SelectBoxSimple>
  )
}

export default PermissionSelector
