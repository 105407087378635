import React, { useEffect, useMemo, useRef, useState } from 'react'
import { AriaButtonProps, mergeProps, useButton, useHover } from 'react-aria'
import classNames from 'classnames'

import { Preloader } from './loader'
import Delete from '../assets/bin.svg'
import ClearIcon from '../assets/icon-close-grey.svg'
import CopyIcon from '../assets/icon-copy.svg'
import LinkArrow from '../assets/drop-down-arrow.svg'
import { copyString } from '../helpers'
import styles from '../styles/button.module.scss'

// Done this way so storybook auto-updates with new values
export const ALL_BUTTON_TYPES = [
  'primary',
  'secondary',
  'plainBox',
  'text',
  'iconOnly',
] as const
type ButtonTuple = typeof ALL_BUTTON_TYPES
type ButtonVariants = ButtonTuple[number]

export const ALL_COLOURS = [
  'none',
  'pink',
  'blue',
  'yellow',
  'green',
  'red',
  'grey',
] as const
type ColourTuple = typeof ALL_COLOURS
type ColourVariants = ColourTuple[number]

interface IconProps {
  src: string
  alt: string
  iconAfter?: boolean
  hoverImg?: string
  imgHeight?: number
}

interface HoverEvent {
  type: 'hoverstart' | 'hoverend'
  pointerType: 'mouse' | 'pen'
  target: HTMLElement
}

interface ButtonProps extends AriaButtonProps<'button'> {
  variant?: ButtonVariants
  color?: ColourVariants
  className?: string
  style?: React.CSSProperties
  /** Must include metadata for image alt text and whether it appears before or after text */
  icon?: IconProps
  /** Renders the preloader instead of children, maintaining button width */
  loading?: boolean
  onHoverStart?: (e: HoverEvent) => void
  onHoverEnd?: (e: HoverEvent) => void
  /** ID of the form that the button should submit. Use if button is not inside the <form> element */
  form?: string
  children?: React.ReactNode
  /** Button hover states are ignored. Do not provide onPress if set to true */
  demoOnly?: boolean
}

/**
 * Button using Uplifter styles<br>
 * https://react-spectrum.adobe.com/react-aria/useButton.html<br>
 * Testing: https://react-spectrum.adobe.com/react-spectrum/testing.html#triggering-events
 */

const Button = ({
  variant = 'primary',
  color = 'pink',
  className,
  style,
  icon,
  loading = false,
  onHoverStart,
  onHoverEnd,
  form,
  children,
  demoOnly,
  ...props
}: ButtonProps) => {
  const { src: iconSrc, alt: iconAlt, iconAfter, hoverImg, imgHeight } =
    icon || {}

  const buttonRef = useRef<HTMLButtonElement>(null)

  const { buttonProps } = useButton(
    { ...props, isDisabled: !demoOnly && (loading || props.isDisabled) },
    buttonRef,
  )

  const { hoverProps, isHovered } = useHover({
    isDisabled: !demoOnly && (buttonProps.disabled || loading),
    onHoverStart: (e) => {
      if (demoOnly) return

      if (onHoverStart) onHoverStart(e)
    },
    onHoverEnd: (e) => {
      if (demoOnly) return

      if (onHoverEnd) onHoverEnd(e)
    },
  })

  const [loadingMargin, setLoadingMargin] = useState(0)

  // Allows icon to be changed on hover
  const iconToUse = useMemo(() => {
    if (!hoverImg || demoOnly) return iconSrc

    return isHovered ? hoverImg : iconSrc
  }, [isHovered, icon])

  // Ensures the button's width is preserved when it enters loading state
  useEffect(() => {
    if (!buttonRef.current || loading || buttonRef.current.offsetWidth <= 35)
      return

    setLoadingMargin((buttonRef.current.offsetWidth - 35 - 24) / 2)
  }, [buttonRef.current, loading])

  const renderIcon = (after = false) => (
    <img
      src={iconToUse}
      alt={iconAlt}
      className={classNames(styles.buttonIcon, {
        [styles.iconBefore]: !after && !!children,
        [styles.iconAfter]: after && !!children,
      })}
      style={{ height: imgHeight }}
    />
  )

  return (
    <button
      aria-label={iconAlt}
      {...mergeProps(buttonProps, hoverProps)}
      ref={buttonRef}
      form={form || buttonProps.form}
      type={
        props.type || (form || buttonProps.form ? 'submit' : buttonProps.type)
      }
      disabled={!demoOnly && (buttonProps.disabled || loading)}
      className={classNames(
        className,
        styles.button,
        styles[variant],
        styles[`color-${color}`],
        {
          [styles.loading]: loading,
          [styles.demoOnly]: demoOnly,
        },
      )}
      style={style}
    >
      <div>
        {loading ? (
          <Preloader
            className={styles.loadingBars}
            style={{ margin: `0 ${loadingMargin}px` }}
          />
        ) : (
          <>
            {iconToUse && !iconAfter && renderIcon()}
            {children}
            {iconToUse && iconAfter && renderIcon(true)}
          </>
        )}
      </div>
    </button>
  )
}

export default Button

interface NavigateButtonProps extends ButtonProps {
  back?: boolean
}

export const NavigateButton = ({
  back = false,
  ...props
}: NavigateButtonProps) => {
  return (
    <Button
      variant="text"
      {...props}
      className={classNames(props.className, styles.navigateButton, {
        [styles.back]: back,
      })}
      icon={{
        src: LinkArrow,
        alt: 'Navigate',
        iconAfter: !back,
        imgHeight: 12,
      }}
    >
      {props.children}
    </Button>
  )
}

interface CopyButtonProps extends ButtonProps {
  value?: string | string[]
  iconAfter?: boolean
}

export const CopyButton = ({
  value,
  onPress,
  iconAfter = false,
  ...props
}: CopyButtonProps) => {
  return (
    <Button
      variant="text"
      {...props}
      className={classNames(props.className, styles.copyButton)}
      icon={{
        src: CopyIcon,
        alt: 'Copy',
        iconAfter,
      }}
      onPress={(e) => {
        if (value) copyString(value)

        if (onPress) onPress(e)
      }}
    >
      {props.children}
    </Button>
  )
}

export const DeleteButton = ({ className, ...props }: ButtonProps) => {
  return (
    <Button
      {...props}
      icon={{
        src: Delete,
        alt: 'delete',
      }}
      className={classNames(className, styles.deleteButton)}
    />
  )
}

export const ClearButton = ({ className, children, ...props }: ButtonProps) => {
  return (
    <Button
      {...props}
      variant="text"
      color="grey"
      icon={{
        src: ClearIcon,
        alt: 'Clear',
      }}
      className={classNames(className, styles.clearButton)}
    >
      {children}
    </Button>
  )
}
