import { brandName } from '../core/constants'

export const teamTierMaxUsers = 10
export const teamTierMaxWorkspaces = 3

export const teamTierDefaultPrice = '£29.00'
export const enterpriseTierDefaultPrice = '£1,000'

export const currencyLookup = {
  GBP: '£',
  ARS: 'ARS ',
  AUD: 'A$',
  BRL: 'R$',
  CAD: 'CA$',
  CNY: 'CN¥',
  CZK: 'CZK ',
  DKK: 'DKK ',
  EUR: '€',
  HKD: 'HK$',
  HUF: 'HUF ',
  INR: '₹',
  ILS: '₪',
  JPY: '¥',
  MXN: 'MX$',
  TWD: 'NT$',
  NZD: 'NZ$',
  NOK: 'NOK ',
  PLN: 'PLN ',
  RUB: 'RUB ',
  SGD: 'SGD ',
  ZAR: 'ZAR ',
  KRW: '₩',
  SEK: 'SEK ',
  CHF: 'CHF ',
  THB: 'THB ',
  TRY: 'TRY ',
  UAH: 'UAH ',
  USD: '$',
}

export const freeTierTagline =
  'Great for individuals who hate messy UTM spreadsheets and fiddly Google Analytics reports.'
export const teamTierTagline =
  'Great for letting teams self-serve consistent links, QR codes, reports and insights.'
export const enterpriseTierTagline =
  'For companies that need enhanced integrations, security, training and support.'

export type FeatureCategory =
  | 'users'
  | 'workspaces'
  | 'shortlinks'
  | 'connectors'

interface TierFeature {
  id?: string
  category?: FeatureCategory
  text: string
  tooltip?: string
}

export const freeTierFeatures: TierFeature[] = [
  {
    id: 'upgrade-free-users',
    category: 'users',
    text: '1 user',
    tooltip:
      'Upgrade to let other users self-serve and collaborate on consistent links, dropdowns, reports and insights.',
  },
  {
    id: 'upgrade-free-workspaces',
    category: 'workspaces',
    text: '1 workspace',
    tooltip:
      'A single workspace for one client, brand, website or channel. Upgrade to have multiple workspaces with different parameters, dropdowns, reports and integrations.',
  },
  {
    id: 'upgrade-free-links',
    text: 'Create links',
    tooltip: 'Create thousands of accurate, consistent campaign links, fast.',
  },
  {
    id: 'upgrade-free-qr',
    text: 'Create QR codes',
    tooltip:
      'Create branded QR codes that help you connect online and offline activity, from OOH, print and events to digital campaigns.',
  },
  {
    id: 'upgrade-free-params',
    text: 'Custom parameters',
    tooltip:
      'Add parameters for business units, products, geographies and more. Concatenate them to existing UTMs or make your own.',
  },
  {
    id: 'upgrade-free-performance',
    text: 'Compare performance',
    tooltip:
      "View metrics for each campaign link, see what's working and what's not with our custom reports.",
  },
  {
    id: 'upgrade-free-training',
    text: 'Training & certification 🥉',
    tooltip:
      'Learn how to track campaigns like a pro with our online learning module, mini test and Campaign Tracking Expert LinkedIn certificate.',
  },
]

export const teamTierFeatures: TierFeature[] = [
  {
    id: 'upgrade-team-users',
    category: 'users',
    text: `Up to ${teamTierMaxUsers} users`,
    tooltip:
      'Add colleagues and agencies to let them self-serve and collaborate on consistent links, dropdowns, reports and insights.',
  },
  {
    id: 'upgrade-team-workspaces',
    category: 'workspaces',
    text: `Up to ${teamTierMaxWorkspaces} workspaces`,
    tooltip:
      'Create multiple workspaces for different clients, brands, websites or channels. Each can have different parameters, dropdowns, reports, users and intergrations.',
  },
  {
    id: 'upgrade-team-shortlinks',
    text: 'Create short links',
    category: 'shortlinks',
    tooltip:
      'Increase brand awareness and engagement by creating short, bespoke links for your campaigns. Count every clickthrough and compare performance.',
  },
  {
    id: 'upgrade-team-approval',
    text: 'Approval flow',
    tooltip:
      'Take the stress out of managing consistency. Regular users can request new dropdown values, for admins to on-click approve, reject or edit.',
  },
  {
    id: 'upgrade-team-anomalies',
    text: 'Anomaly detection',
    tooltip:
      'Find anomalies not visible by the naked eye. Our AI anomaly detection trends every dimension to spot problems and opportunities.',
  },
  {
    id: 'upgrade-team-onboarding',
    text: 'Onboarding support',
    tooltip: `Free onboarding workshop to make sure you and your team get the most of ${brandName}.`,
  },
  {
    id: 'upgrade-team-support',
    text: 'Email support 🥈',
    tooltip:
      'Get campaign problems solved with excellent email support, average response times of under 30 minutes and resolutions within 24 hours.',
  },
]

export const enterpriseTierFeatures: TierFeature[] = [
  {
    id: 'upgrade-enterprise-users',
    category: 'users',
    text: 'Unlimited users',
    tooltip:
      'Add colleagues and agencies to let them self-serve and collaborate on consistant links, dropdowns, reports and insights.',
  },
  {
    id: 'upgrade-enterprise-workspaces',
    category: 'workspaces',
    text: 'Unlimited workspaces',
    tooltip:
      'Create multiple workspaces for different clients, brands, websites or channels. Each can have different parameters, dropdowns, reports, users and integrations.',
  },
  {
    id: 'upgrade-enterprise-shortlinks',
    category: 'shortlinks',
    text: 'Branded shortlinks',
    tooltip:
      'Create memorable signposting by making shortened links people will remember and trust, increasing clickthrough rate by 39% compared with generic URLs.',
  },
  {
    id: 'upgrade-enterprise-integrations',
    category: 'connectors',
    text: 'Custom integrations',
    tooltip:
      'Make smarter decisions, faster, with one-click campaign dashboards. Report on the success using blended data direct from any marketing data sources.',
  },
  {
    id: 'upgrade-enterprise-api',
    text: 'Open API',
    tooltip:
      'Retrieve the data you need, in the format you want. Automate your reports and processes with our API.',
  },
  {
    id: 'upgrade-enterprise-sso',
    text: 'Single Sign-On',
    tooltip:
      'Increase security, save time and reduce the number of passwords you need to remember. Login with your Microsoft, Google or Okta account.',
  },
  {
    id: 'upgrade-enterprise-training',
    text: 'Custom training',
    tooltip:
      'Get consitent links and reports for everyone, with custom onboarding and unlimited training with a real person online, in any time zone.',
  },
  {
    id: 'upgrade-enterprise-support',
    text: 'Priority support 🥇',
    tooltip:
      'Get campaign problems solved quickly with priority email support, average response times of under 10 minutes and resolutions within 2 hours.',
  },
]
